import * as types from 'app/constants/ActionTypes';

export const formsInitialState = {
  successMessage: null,
  formFieldsStatus: 'default',
  auth: {
    login: {
      title: 'Login',
      status: 'default',
    },
    forgotpassword: {
      title: 'Forgot password',
      status: 'default',
    },
    register: {
      title: 'Create an account',
      status: 'default',
    },
  },
  enquiries: {
    add: {
      title: 'Add a new enquiry',
      status: 'default',
      fields: [],
    },
    edit: {
      title: 'Edit an enquiry',
      status: 'default',
      fields: [],
    },
  },
  'enrolment-application': {
    register: {
      title: 'Create an account',
      status: 'default',
      disabled: false,
      buttonLabel: 'Sign up',
      fields: [
        {
          type: 'dropdown',
          name: 'who',
          label: 'Who is filling out this application?',
          source: [
            {
              value: 'Student’s Mother',
              label: 'Student’s Mother',
            },
            {
              value: 'Student’s Father',
              label: 'Student’s Father',
            },
            {
              value: 'Student’s Grandparent',
              label: 'Student’s Grandparent',
            },
            {
              value: 'The student',
              label: 'The student',
            },
          ],
          required: true,
        },
        {
          type: 'text',
          name: 'last_name',
          label: 'Your Family Name or Surname',
          required: true,
        },
        {
          type: 'text',
          name: 'first_name',
          label: 'Your First names (as shown on passport)',
          required: true,
        },
        {
          type: 'email',
          name: 'email',
          label: 'Your email address',
          required: true,
        },
        {
          type: 'password',
          name: 'password',
          label: 'Enter your password',
          required: true,
        },
        {
          type: 'password',
          name: 'confirm_password',
          label: 'Confirm your password',
          required: true,
        },
      ],
    },
  },
  documents: {
    upload: {
      title: 'Upload documents',
      status: 'default',
      fields: [],
    },
  },
  leads: {
    add: {
      title: 'Create a new lead',
      status: 'default',
      fields: [],
    },
    edit: {
      title: 'Edit a lead',
      status: 'default',
      fields: [],
    },
  },
  permissions: {
    add: {
      title: 'Add a user',
      status: 'default',
      fields: [],
    },
    edit: {
      title: 'Edit a user',
      status: 'default',
      fields: [],
    },
  },
  school: {
    edit: {
      title: 'Manage school settings',
      status: 'default',
      fields: [],
    },
  },
  agents: {
    add: {
      title: 'Create a new agent',
      status: 'default',
      fields: [],
    },
    edit: {
      title: 'Manage application settings',
      status: 'default',
      fields: [],
    },
  },
  applications: {
    edit: {
      title: 'Manage application settings',
      status: 'default',
      fields: [],
    },
    withdraw: {
      title: 'Withdraw application',
      status: 'default',
      fields: [],
    },
    share: {
      title: 'Share Application',
      status: 'default',
      fields: [],
    },
  },
  review: {
    sendflightandarrivaldetails: {
      title: 'Flight information',
      status: 'default',
    },
    skip: {
      title: 'Skip step',
      status: 'default',
    },
  },
  profile: {
    edit: {
      title: 'Profile',
      status: 'default',
    },
  },
  notes: {
    add: {
      status: 'default',
    },
    edit: {
      status: 'default',
    },
  },
  payment: {
    summary: {
      status: 'default',
    },
  },
  invoices: {
    edit: {
      status: 'default',
    },
    add: {
      status: 'default',
    },
    addpayment: {
      status: 'default',
    },
    share: {
      title: 'Send Invoice',
      status: 'default',
      fields: [],
    },
  },
};

export const formsReducer = (state = formsInitialState, action) => {
  switch (action.type) {
    case types.AGENTS_ADD_RESPONSE:
    case types.INVITATIONS_EXPIRE_RESPONSE:
    case types.INSTITUTION_USERS_ADD_RESPONSE:
    case types.INSTITUTION_USERS_EDIT_RESPONSE:
    case types.INSTITUTION_USERS_TOGGLE_STATUS_RESPONSE:
    case types.SCHOOL_EDIT_RESPONSE:
    case types.APPLICATIONS_EDIT_RESPONSE:
    case types.USER_REGISTER_RESPONSE:
    case types.APPLICATION_ARCHIVE_RESPONSE:
    case types.APPLICATION_LINK_LEAD_RESPONSE:
    case types.APPLICATIONS_SEND_FLIGHT_DETAILS_RESPONSE:
    case types.APPLICATION_TRANSFER_OWNERSHIP_RESPONSE:
    case types.NOTES_ADD_RESPONSE:
    case types.NOTES_DELETE_RESPONSE:
    case types.USER_GET_RESEND_ACCOUNT_VERIFICATION_RESPONSE:
    case types.SHARE_APPLICATION_RESPONSE:
    case types.RESEND_SHARE_RESPONSE:
    case types.REVOKE_SHARE_RESPONSE:
    case types.SHARE_INVOICE_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          successMessage: action.payload.message,
        };
      }

      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};
