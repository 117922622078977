import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import shareAPI from 'app/api/shareAPI';

export function resendLink(share, onShareApplication, slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.RESEND_SHARE,
    });

    shareAPI
      .PATCH({
        params: {
          slug,
          user: share.id,
        },
      })
      .then((response) => {
        if (response.status.code === 200 || response.status.code === 201) {
          onShareApplication(response.entity.data);
          dispatch({
            type: ActionTypes.RESEND_SHARE_RESPONSE,
            payload: {
              success: true,
              message: response.entity.message,
              share: response.entity.data,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.REVOKE_SHARE_RESPONSE,
            payload: {
              success: false,
            },
          });

          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        ErrorActions.show(error)(dispatch);
      });
  };
}

export function revokeAccess(share, onShareApplication, applicationId, slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.REVOKE_SHARE,
    });

    shareAPI
      .DELETE({
        params: {
          slug,
          application: applicationId,
          user: share.id,
        },
      })
      .then((response) => {
        if (response.status.code === 200) {
          onShareApplication();
          dispatch({
            type: ActionTypes.REVOKE_SHARE_RESPONSE,
            payload: {
              success: true,
              message: response.entity.message,
              share,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.REVOKE_SHARE_RESPONSE,
            payload: {
              success: false,
            },
          });

          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        ErrorActions.show(error)(dispatch);
      });
  };
}
