import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface ApiJsonFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

/**
 * Field that will contain JSON return from
 * the partner API
 * Displays a multi line textarea field
 */
export const ApiJsonField = ({ label, disabled, value, onChange, error, name, readOnly }: ApiJsonFieldProps) => {
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="api-json">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <TextField
          size="small"
          aria-labelledby={`${name}-label`}
          multiline
          rows={5}
          sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
          InputProps={{
            sx: {
              fontFamily: 'Menlo, Monaco, "Courier New", monospace',
              fontSize: '1.2rem',
            },
          }}
          value={value}
          error={!!error}
          disabled={disabled}
          onChange={(event) => onChange(event.target.value)}
          inputProps={{ readOnly }}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default ApiJsonField;
