import * as types from 'app/constants/ActionTypes';

export const institutionUsersInitialState = {
  status: 'default',
  users: [],
  editStatus: 'default',
  editMessage: null,
  addStatus: 'default',
  addMessage: null,
  deleteStatus: 'default',
  deleteMessage: null,
  enableStatus: 'default',
  searchStatus: 'default',
  sortField: 'name',
  sortOrder: 'asc',
  userDetails: null,
  currentName: '',
};

export const institutionUsersReducer = (state = institutionUsersInitialState, action) => {
  switch (action.type) {
    case types.INSTITUTION_USERS_GET:
      return {
        ...state,
        deleteStatus: 'default',
        editStatus: 'default',
        enableStatus: 'default',
        status: 'loading',
      };
    case types.INSTITUTION_USERS_GET_RESPONSE: {
      const users = action.payload.users;

      return {
        ...state,
        status: 'default',
        users,
      };
    }
    case types.INSTITUTION_USERS_ADD:
      return {
        ...state,
        addStatus: 'loading',
      };
    case types.INSTITUTION_USERS_ADD_IDLE:
      return {
        ...state,
        addStatus: 'idle',
      };
    case types.INSTITUTION_USERS_EDIT_IDLE:
      return {
        ...state,
        editStatus: 'idle',
      };
    case types.INSTITUTION_USERS_ADD_RESPONSE: {
      const addUserState = { ...state };

      if (action.payload.success) {
        addUserState.addStatus = 'success';
        addUserState.addMessage = action.payload.message;
      } else {
        addUserState.addStatus = 'error';
        // @ts-expect-error TS(2322): Type '"Error"' is not assignable to type 'null'.
        addUserState.addMessage = 'Error';
      }

      return {
        ...addUserState,
      };
    }
    case types.INSTITUTION_USERS_GET_DETAILS:
      return {
        ...state,
        editStatus: 'loading',
      };
    case types.INSTITUTION_USERS_GET_DETAILS_RESPONSE:
      return {
        ...state,
        editStatus: 'default',
        currentName: `${action.payload.userDetails.first_name} ${action.payload.userDetails.last_name}`,
      };
    case types.INSTITUTION_USERS_EDIT:
      return {
        ...state,
        editStatus: 'loading',
      };
    case types.INSTITUTION_USERS_EDIT_RESPONSE: {
      const editUserState = {};

      if (action.payload.success) {
        // @ts-expect-error TS(2339): Property 'editStatus' does not exist on type '{}'.
        editUserState.editStatus = 'refresh';
        // @ts-expect-error TS(2339): Property 'editMessage' does not exist on type '{}'... Remove this comment to see the full error message
        editUserState.editMessage = action.payload.message;
      } else {
        // @ts-expect-error TS(2339): Property 'editStatus' does not exist on type '{}'.
        editUserState.editStatus = 'error';
        // @ts-expect-error TS(2339): Property 'editMessage' does not exist on type '{}'... Remove this comment to see the full error message
        editUserState.editMessage = 'Error';
      }

      return {
        ...state,
        ...editUserState,
      };
    }
    case types.INSTITUTION_USERS_TOGGLE_STATUS: {
      const status = { ...state };

      if (action.method === 'disable') {
        status.deleteStatus = 'loading';
        status.enableStatus = 'default';
      } else {
        status.enableStatus = 'loading';
        status.deleteStatus = 'default';
      }

      return {
        ...state,
        ...status,
      };
    }
    case types.INSTITUTION_USERS_TOGGLE_STATUS_RESPONSE: {
      const toggleState = { ...state };

      if (action.payload.success) {
        if (action.payload.method === 'disable') {
          toggleState.deleteStatus = 'success';
        } else {
          toggleState.enableStatus = 'success';
        }
      }

      return {
        ...state,
        ...toggleState,
      };
    }
    case types.INSTITUTION_USERS_SEARCH:
      return {
        ...state,
        status: 'updating',
        searchStatus: 'loading',
      };
    case types.INSTITUTION_USERS_SEARCH_RESPONSE: {
      const searchableLeads = state.users.slice();

      const searchTerms = action.payload.searchTerm.split(' ');

      const results = searchableLeads.filter((lead) => {
        return searchTerms.some((term) => {
          const regex = new RegExp(`^(${term})$`, 'i');

          // @ts-expect-error TS(2339): Property 'first_name' does not exist on type 'neve... Remove this comment to see the full error message
          return regex.test(lead.first_name) || regex.test(lead.last_name);
        });
      });

      return {
        ...state,
        users: results,
        status: 'default',
        searchStatus: 'default',
      };
    }
    case types.INSTITUTION_USERS_SORT:
      return {
        ...state,
        sortField: action.field,
        sortOrder: action.order,
      };
    default:
      return {
        ...state,
      };
  }
};
