import { Control, ControllerProps, FieldError, useController } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { useApplication } from 'contexts/ApplicationContext';
import TextField from './TextField';

interface CustomFieldsPlaceholderFieldProps {
  control: Control;
  label: string;
  disabled: boolean;
  validationRules: ControllerProps['rules'];
  readOnly: boolean;
}

const CustomQuestion = ({ name, label, control, disabled, validationRules, readOnly }) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name,
    control,
    rules: validationRules,
    disabled,
  });

  return <TextField disabled={disabled} name={name} label={label} value={value} onChange={onChange} error={errors[name] as FieldError} readOnly={readOnly} />;
};

const CustomFieldsPlaceholderField = ({ control, label, disabled, validationRules, readOnly }: CustomFieldsPlaceholderFieldProps) => {
  const {
    state: {
      // @ts-expect-error TS(2339): Property 'custom_questions' does not exist on type... Remove this comment to see the full error message
      application: { custom_questions: questions },
    },
  } = useApplication();

  return (
    questions && (
      <Stack data-cy-field-type="custom-fields-placeholder">
        <Typography variant="h5" component="h2" mb={2}>
          {label}
        </Typography>
        {Object.keys(questions).map((questionId) => {
          const questionLabel = questions[questionId];
          return (
            questionLabel && (
              <CustomQuestion
                key={questionId}
                disabled={disabled}
                name={questionId}
                label={questionLabel}
                control={control}
                validationRules={validationRules}
                readOnly={readOnly}
              />
            )
          );
        })}
      </Stack>
    )
  );
};

export default CustomFieldsPlaceholderField;
