import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const api = {};
const { apiUrl } = getConfig();

const invoiceAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/invoices{/id}{/action}`,
});

// @ts-expect-error TS(2339): Property 'GET' does not exist on type '{}'.
api.GET = invoiceAPI.wrap(defaultRequest, {
  method: 'GET',
});

// POST      | api/schools/{school}              | schools
// @ts-expect-error TS(2339): Property 'POST' does not exist on type '{}'.
api.POST = invoiceAPI.wrap(defaultRequest, {
  method: 'POST',
});

export default api;
