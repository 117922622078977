import { useState } from 'react';
import { addDays, addMonths, addWeeks, addYears, isValid, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { DatePicker } from '@mui/x-date-pickers';

interface DatePickerFieldProps {
  name: string;
  label: string;
  disabled: boolean;
  value: Date;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  readOnly: boolean;
  min: string;
  minPeriod: 'days' | 'weeks' | 'months' | 'years';
  minDirection: 'before' | 'after';
  max: string;
  maxPeriod: 'days' | 'weeks' | 'months' | 'years';
  maxDirection: 'before' | 'after';
  message: string;
  messageComparison: string;
  messageAmount: number | null;
  messagePeriod: string;
  messageIsAfter: boolean;
}

export const DatePickerField = ({
  disabled,
  value,
  onChange,
  error,
  name,
  label,
  readOnly,
  min,
  minPeriod,
  minDirection,
  max,
  maxPeriod,
  maxDirection,
  message,
  messageComparison,
  messageAmount,
  messagePeriod,
  messageIsAfter,
}: DatePickerFieldProps) => {
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const handleToggleWarningDialog = () => {
    setShowWarningDialog(!showWarningDialog);
    setWarningMessage(message);
  };

  const handleChange = (newValue: Date | null, onChange: ControllerRenderProps['onChange']) => {
    if (newValue === null || !isValid(newValue)) return;

    if (message && messageComparison && messageAmount && messagePeriod && messageIsAfter) {
      let comparisonDate = new Date();

      switch (messagePeriod) {
        case 'days':
          comparisonDate = messageIsAfter ? addDays(comparisonDate, messageAmount) : subDays(comparisonDate, messageAmount);
          break;
        case 'weeks':
          comparisonDate = messageIsAfter ? addWeeks(comparisonDate, messageAmount) : subWeeks(comparisonDate, messageAmount);
          break;
        case 'months':
          comparisonDate = messageIsAfter ? addMonths(comparisonDate, messageAmount) : subMonths(comparisonDate, messageAmount);
          break;
        case 'years':
          comparisonDate = messageIsAfter ? addYears(comparisonDate, messageAmount) : subYears(comparisonDate, messageAmount);
          break;
      }

      if ((messageComparison === 'lessthan' && newValue < comparisonDate) || (messageComparison === 'morethan' && newValue > comparisonDate)) {
        handleToggleWarningDialog();
        return;
      }
    }

    onChange(newValue);
  };

  const getMinOrMaxDate = (option: string, period: 'days' | 'weeks' | 'months' | 'years', direction: 'before' | 'after') => {
    const add = direction === 'after';
    const amount = parseInt(option, 10);

    switch (period) {
      case 'days':
        return add ? addDays(new Date(), amount) : subDays(new Date(), amount + 1);
      case 'weeks':
        return add ? addWeeks(new Date(), amount) : subWeeks(new Date(), amount + 1);
      case 'months':
        return add ? addMonths(new Date(), amount) : subMonths(new Date(), amount + 1);
      case 'years':
        return add ? addYears(new Date(), amount) : subYears(new Date(), amount + 1);
    }
  };

  const minDate = getMinOrMaxDate(min, minPeriod, minDirection);
  const maxDate = getMinOrMaxDate(max, maxPeriod, maxDirection);

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="date-picker">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <DatePicker
          aria-labelledby={`${name}-label`}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          readOnly={disabled || readOnly}
          slotProps={{
            textField: { size: 'small', error: !!error },
          }}
          onChange={(newValue) => handleChange(newValue, onChange)}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}

      <Dialog open={showWarningDialog} onClose={handleToggleWarningDialog}>
        <DialogTitle variant="h4" component="h2">
          Warning
        </DialogTitle>
        <DialogContent>
          <p>{warningMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleWarningDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DatePickerField;
