import {
  PAYMENT_STATUS_COMPLETE,
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_ADJUSTMENT,
  PAYMENT_STATUS_OVERPAID,
} from 'invoices/InvoiceStatuses';
import { Stack, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';

interface PaymentStatusProps {
  status: string;
}

export const PaymentStatus = ({ status }: PaymentStatusProps) => {
  const renderTickIcon = [PAYMENT_STATUS_ADJUSTMENT, PAYMENT_STATUS_COMPLETE].includes(status);
  const renderErrorIcon = [PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_FAILED, PAYMENT_STATUS_OVERPAID].includes(status);
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Tooltip title={status}>
        {renderTickIcon ? <CheckCircleIcon color="success" /> : renderErrorIcon ? <ErrorIcon color="error" /> : <PendingIcon color="warning" />}
      </Tooltip>
      <Typography component="h4">Payment {status}</Typography>
    </Stack>
  );
};
