import { Checkbox, FormControl, FormControlLabel, FormLabel, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

import { slugify } from 'utils/slugify';

interface ConditionsCheckboxsetFieldProps {
  label: string;
  disabled: boolean;
  value: any[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: string; value: string }[];
}

export const ConditionsCheckboxsetField = ({ label, disabled, value, onChange, error, name, readOnly, options }: ConditionsCheckboxsetFieldProps) => {
  return (
    <div data-cy-field-type="conditions-checkbox-set">
      <FormLabel>{label}</FormLabel>
      {options.map((option) => {
        const key = slugify(`${name}-option-${option.value}`);

        return (
          <FormControl fullWidth size="small" key={key} sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy={key}
                  value={option.value}
                  checked={value.indexOf(option.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `${name}-label` }}
                  disabled={disabled}
                  onChange={() => {
                    const fieldValues = value.slice();

                    if (fieldValues.indexOf(option.value) !== -1) {
                      fieldValues.splice(fieldValues.indexOf(option.value), 1);
                    } else {
                      fieldValues.push(option.value);
                    }

                    onChange(fieldValues);
                  }}
                />
              }
              label={option.value}
            />
          </FormControl>
        );
      })}
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </div>
  );
};

// for React.lazy
export default ConditionsCheckboxsetField;
