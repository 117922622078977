import irdnz from 'ird-nz';
import { isValid, isFuture } from 'date-fns';
import * as ErrorMessages from 'app/constants/ErrorMessages';

/**
 * Check if a field has a value that can be tested
 * @param  {string}   value   Value of field
 * @return {boolean}          If the field has a value
 */
function hasValue(value) {
  return typeof value !== 'undefined' && value !== null && value !== '';
}

const messages = {
  required: ErrorMessages.ERROR_REQUIRED,
  email: ErrorMessages.ERROR_EMAIL,
  number: ErrorMessages.ERROR_NUMBER,
  file: ErrorMessages.ERROR_FILE,
  password: ErrorMessages.ERROR_PASSWORD,
  confirmpassword: ErrorMessages.ERROR_PASSWORD_CONFIRM,
  gstNumber: ErrorMessages.ERROR_GST_NUMBER,
  tuition: ErrorMessages.TUITION_FEE,
};

type ValidatorFunction = (...args: any[]) => boolean | string;
const validators: Record<string, ValidatorFunction> = {};

/**
 * Return error message for an invalid field
 * @param  {string}      name    Name of field
 * @param  {fieldType}   type    Type of validation message
 * @return {string}              Error message
 */
validators.message = (name, fieldType) => {
  const requiredMessage = name + ' ' + messages.required;

  if (!fieldType || fieldType === 'required') {
    return requiredMessage;
  }

  return fieldType in messages ? messages[fieldType] : requiredMessage;
};

/**
 * Validate input as required
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
validators.required = (value) => {
  return hasValue(value);
};

/**
 * Validate input as array of items
 * @param  {array}   values    Values of checkboxset field
 * @return {boolean}           Input is valid
 */
validators.checkboxset = (values) => {
  return values.length;
};

/**
 * Validate input as array of items
 * @param  {array}   values    Values of checkboxset field
 * @return {boolean}           Input is valid
 */
validators.monthyear = (values) => {
  return hasValue(values.month) && hasValue(values.year);
};

/**
 * Validate input as an email address
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
validators.email = (value) => {
  if (hasValue(value)) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    );
  }

  return true;
};

/**
 * Validate input as a number
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
validators.number = (value) => {
  if (hasValue(value)) {
    return /^-?\$?[0-9]*[0-9,]*[0-9]\.?[0-9]{0,2}$/.test(value);
  }

  return true;
};

/**
 * Validate that 2 values are the same
 * @param  {string}   value    Value of field
 * @param  {string}   match    Value of another field
 * @return {boolean}           Input is valid
 */
validators.match = (value, match) => {
  return hasValue(value) && hasValue(match) && match === value;
};

/**
 * Validate input as a file
 * @param  {string}   files    Value of field
 * @return {boolean}           Input is valid
 */
validators.file = (files, isRequired) => {
  if (isRequired) {
    return files ? files.length : true;
  }

  return true;
};

/**
 * Validate password for character requirements
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
validators.password = (value) => {
  // Allow lowercase, uppercase, numbers and symbols
  return hasValue(value) && /^([a-zA-Z0-9!@#?$%^&*]+){6,}$/.test(value);
};

validators.confirmpassword = (password, confirmpassword) => {
  return hasValue(password) && password === confirmpassword;
};

validators.gstNumber = (value) => {
  return hasValue(value) && irdnz.isValid(value);
};

/**
 * Validate string for tuition fee
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
validators.tuition = (value) => {
  return hasValue(value) && /tuition/is.test(value);
};

/**
 * Validate date of birth
 * @param  {Date}   date         Date of birth
 * @return {boolean | string}    Date is valid, otherwise error message
 */

validators.dateOfBirth = (date) => {
  const dateToValidate = new Date(date);

  if (!isValid(dateToValidate)) {
    return 'Date of birth is invalid';
  }

  if (isFuture(dateToValidate)) {
    return 'Date of birth must be a past date';
  }

  return true;
};

export default validators;
