import SharedApp from 'app/SharedApp';
import { SharedPageHeader } from 'components/Header/SharedPageHeader';
import { ErrorBoundary } from '@sentry/react';
import { AppProvider } from 'app/AppContext';
import Server from 'server/Server';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from 'auth0/Auth0ProviderWithNavigate';
import styled from 'styled-components';

const AppContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${(props) => props.theme.lg} {
    min-height: 60rem;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

export const SharedAppRouter = () => {
  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AppProvider>
          <AppContainer>
            <SharedPageHeader />
            <PageContainer>
              <ErrorBoundary>{typeof window === 'undefined' ? <Server /> : <SharedApp />}</ErrorBoundary>
            </PageContainer>
          </AppContainer>
        </AppProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
};
