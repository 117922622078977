import { ApiFormBuilderField } from '../types/api/ApiFormField';

const isEmpty = (value: unknown): boolean => {
  if (value === null) return true;
  if (value === undefined) return true;
  if (typeof value === 'string' && value === '') return true;
  if (typeof value === 'boolean' && !value) return true;
  if (Array.isArray(value)) {
    return !value.length;
  }

  return false;
};

export const evaluateValidationConditional = (fieldMap: Map<string, ApiFormBuilderField>, condition: string, value: unknown, reference: string): boolean => {
  // first check conditions that don't reference another field
  switch (condition) {
    case 'empty':
      return isEmpty(value);
    case 'notempty':
      return !isEmpty(value);
  }

  const referenceField = fieldMap.get(reference);

  if (referenceField === undefined || referenceField.value === undefined) {
    return false;
  }

  switch (condition) {
    case 'equal':
      return referenceField.value === value;
    case 'notequal':
      return referenceField.value !== value;
    case 'contains':
      return referenceField.value.includes(value as string);
    case 'doesnotcontain':
      return !referenceField.value.includes(value as string);
    case 'lessthan':
      return parseInt(referenceField.value, 10) < (value as number);
    case 'greaterthan':
      return parseInt(referenceField.value, 10) > (value as number);
    default:
      return false;
  }
};
