import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Controller, useForm } from 'react-hook-form';
import { email } from 'forms/consumer/validators';
import { getErrorMessage } from 'utils/errors';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { useStatelessPost } from 'hooks/useStatelessPost';
import { ResponseEnvelope } from 'types/ResponseEnvelope';

interface ShareInvoiceFormValues {
  email: string;
}

export const ShareInvoiceDialog = ({ active, onToggle }) => {
  const invoiceId = useAppSelector((state) => state.invoice.id);
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ShareInvoiceFormValues>({
    defaultValues: { email: '' },
  });
  const { showErrorMessage } = useNotificationMessages();
  const emailWatch = watch('email');
  const postShareInvoice = useStatelessPost<ResponseEnvelope<any>>(`/schools/${schoolSlug}/invoices/${invoiceId}/send`);

  const onSubmit = handleSubmit(async (data: ShareInvoiceFormValues) => {
    try {
      setIsLoading(true);
      await postShareInvoice(data);
      setIsLoading(false);
      setIsSent(true);
    } catch (error) {
      setIsLoading(false);
      showErrorMessage(getErrorMessage(error));
    }
  });

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Send Invoice
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {isSent ? (
          <>
            <Typography>
              Thank you, <strong>{emailWatch}</strong> will now receive an email with a payment link and instructions on how to pay the invoice.
            </Typography>
            <DialogActions>
              <Button variant="contained" onClick={onToggle}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <Typography sx={{ mb: 4 }}>
              Please enter the email address of the person you wish to request to pay this invoice. An email with a payment link will then be sent with
              instructions on how to pay the invoice.
            </Typography>
            <form onSubmit={onSubmit}>
              <FormControl required error={!!errors.email}>
                <FormLabel>Email address</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Email is required',
                    validate: {
                      valid: (val) => (email(val) ? true : 'Email is not valid'),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField required value={value} onChange={onChange} size="small" error={!!errors.email} data-cy="shared-invoice-email" />
                  )}
                />
              </FormControl>
              {errors.email && <Typography color="error">{errors.email.message}</Typography>}
              <DialogActions>
                <LoadingButton variant="contained" type="submit" loading={isLoading}>
                  Send
                </LoadingButton>
                <Button variant="contained" disabled={isLoading} onClick={onToggle}>
                  Cancel
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
