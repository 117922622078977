import { useMemo, useState } from 'react';
import { UsePutOptions, useStatelessPut } from 'hooks/useStatelessPut';

export const usePut = <T>(url: string, options?: UsePutOptions): [boolean, (data?: unknown, params?: URLSearchParams) => Promise<T>] => {
  const [loading, setIsLoading] = useState(false);
  const put = useStatelessPut<T>(url, options);

  return [
    loading,
    useMemo(
      () => async (data?: unknown, params?: URLSearchParams) => {
        try {
          setIsLoading(true);
          const response = await put(data, params);
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      },
      [put],
    ),
  ];
};
