import { Route, Routes } from 'react-router-dom';
import React from 'react';

const Workflows = React.lazy(() => import(/* webpackChunkName: 'management-workflows' */ 'views/Management/Workflows'));

const Workflow = React.lazy(() => import(/* webpackChunkName: 'management-workflows' */ 'views/Management/Workflow'));

export const ManagementRoutes = () => {
  return (
    <Routes>
      <Route path="workflows" element={<Workflows />} />
      <Route path="workflows/:workflowId" element={<Workflow />} />
    </Routes>
  );
};
