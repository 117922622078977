import { addDays, addMonths, format, setDate } from 'date-fns';
import { DATE_DASH_FORMAT, DATE_SLASH_FORMAT } from 'app/constants/DateFormats';

const invoiceDueDateOptions = [
  {
    value: '',
    label: 'None',
    source: 'default',
  },
  {
    value: '',
    date: addDays(new Date(), 7),
    label: '1 week',
    source: 'default',
  },
  {
    value: '',
    date: addDays(new Date(), 14),
    label: '2 weeks',
    source: 'default',
  },
  {
    value: '',
    date: addMonths(new Date(), 1),
    label: '1 month',
    source: 'default',
  },
  {
    value: '',
    date: setDate(new Date(), 20),
    label: '20th of this month',
    source: 'default',
  },
  {
    value: '',
    date: setDate(addMonths(new Date(), 1), 20),
    label: '20th of next month',
    source: 'default',
  },
  {
    value: 'custom',
    label: 'Custom',
    source: 'default',
  },
].map((item) => {
  if (item.value || item.label === 'None') {
    return item;
  }

  item.value = format(item.date!, DATE_DASH_FORMAT);
  item.label += format(item.date!, ` ${DATE_SLASH_FORMAT}`);

  return item;
});

export default invoiceDueDateOptions;
