import * as React from 'react';

/**
 * Placeholder for field components
 * because they're loaded using React.lazy
 * @param props
 * @returns {*}
 * @constructor
 */
const Placeholder = (props) => {
  const classNames = ['placeholder', `placeholder--${props.fieldType}`];
  const placeholders = [];
  let amount = 1;

  if (props.fieldType === 'radiogroup' || (props.fieldType === 'checkboxset' && props.total)) {
    amount = props.total || 0;
  }

  for (let i = 0; i < amount; i++) {
    placeholders.push(
      // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
      <div className="placeholder__element" key={`placeholder-${i}`} />,
    );
  }

  if (props.animate) {
    classNames.push('placeholder--animate');
  }

  return <div className={classNames.join(' ')}>{placeholders}</div>;
};

export default React.memo(Placeholder);
