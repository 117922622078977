import * as types from 'app/constants/ActionTypes';

function setSchoolValues(school) {
  const schoolValues = {};

  Object.keys(school).forEach((name) => {
    if (name !== 'logo' && name !== 'application_settings') {
      if (name === 'country') {
        // @ts-expect-error TS(2339): Property 'school_edit_country_id' does not exist o... Remove this comment to see the full error message
        schoolValues.school_edit_country_id = school.country.id;
      } else {
        schoolValues[`school_edit_${name}`] = school[name] || '';
      }
    }
  });
  return schoolValues;
}

function setApplicationSettings(school) {
  const settings = school.application_settings;

  if (settings) {
    return {
      applications_edit_accommodation_types: settings.accommodation_types || '',
      applications_edit_insurance: settings.insurance || '',
      applications_edit_subjects: school.subjects || [],
      applications_edit_notification_emails: settings.notification_emails || '',
      applications_edit_question_1: settings.custom_questions && settings.custom_questions.question_1 ? settings.custom_questions.question_1 : '',
      applications_edit_question_2: settings.custom_questions && settings.custom_questions.question_2 ? settings.custom_questions.question_2 : '',
      applications_edit_question_3: settings.custom_questions && settings.custom_questions.question_3 ? settings.custom_questions.question_3 : '',
      applications_edit_email_1:
        settings.notification_emails && settings.notification_emails.length && settings.notification_emails[0] ? settings.notification_emails[0] : '',
      applications_edit_email_2:
        settings.notification_emails && settings.notification_emails.length && settings.notification_emails[1] ? settings.notification_emails[1] : '',
      applications_edit_email_3:
        settings.notification_emails && settings.notification_emails.length && settings.notification_emails[2] ? settings.notification_emails[2] : '',
      applications_edit_email_4:
        settings.notification_emails && settings.notification_emails.length && settings.notification_emails[3] ? settings.notification_emails[3] : '',
      applications_edit_email_5:
        settings.notification_emails && settings.notification_emails.length && settings.notification_emails[4] ? settings.notification_emails[4] : '',
    };
  }
}

export const valuesInitialState = {
  name: 'Enroller',
  color: '#3ead47',
  slug: '',
  logo: '/images/icons/favicon-120.png',
};

export const valuesReducer = (state = valuesInitialState, action) => {
  switch (action.type) {
    case types.INSTITUTION_USERS_GET_DETAILS_RESPONSE:
      return {
        ...state,
        permissions_edit_last_name: action.payload.userDetails.last_name,
        permissions_edit_first_name: action.payload.userDetails.first_name,
        permissions_edit_email: action.payload.userDetails.email,
        permissions_edit_role: action.payload.userDetails.roles[0]?.name || '',
      };
    case types.SCHOOL_SELECT:
      return {
        ...state,
        ...setSchoolValues(action.school),
      };
    case types.SCHOOL_GET_RESPONSE: {
      const schoolValues = {};

      if (action.payload.success) {
        Object.keys(action.payload.school).forEach((name) => {
          if (name === 'country') {
            // @ts-expect-error TS(2339): Property 'school_edit_country_id' does not exist o... Remove this comment to see the full error message
            schoolValues.school_edit_country_id = action.payload.school.country.id;
          } else if (name === 'properties') {
            action.payload.school.properties.forEach(
              (property, index) => (schoolValues[`applications_edit_${property.key}`] = !!+action.payload.school.properties[index].value),
            );
          } else if (name === 'accommodation_types') {
            // @ts-expect-error TS(2339): Property 'applications_edit_accommodation_types' d... Remove this comment to see the full error message
            schoolValues.applications_edit_accommodation_types = [];
            action.payload.school.accommodation_types.forEach(
              (accommodation, index) =>
                !!+action.payload.school.accommodation_types[index].value &&
                // @ts-expect-error TS(2339): Property 'applications_edit_accommodation_types' d... Remove this comment to see the full error message
                schoolValues.applications_edit_accommodation_types.push(action.payload.school.accommodation_types[index].name),
            );
          } else {
            if (action.payload.school[name]) {
              schoolValues[`school_edit_${name}`] = action.payload.school[name];
            }
          }
        });

        return {
          ...state,
          ...schoolValues,
          school_edit_school_color: action.payload.school.color,
          ...setApplicationSettings(action.payload.school),
        };
      }

      return { ...state };
    }
    case types.SCHOOL_ADDRESS_AUTOCOMPLETE: {
      const { address } = action;

      const googleValues = {
        address_line_1: address.addressLine1,
        city: address.city,
        postcode: address.postCode,
        state: address.state,
      };

      return {
        ...state,
        ...setSchoolValues(googleValues),
      };
    }
    case types.APPLICATIONS_EDIT_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          ...setApplicationSettings(action.payload.school),
        };
      }

      return {
        ...state,
      };
    case types.AGENTS_ADD_RESPONSE: {
      const resetAgentsForm = { ...state };

      if (action.payload.success) {
        Object.keys(state).forEach((fieldName) => {
          if (fieldName.match('agents_add_')) {
            resetAgentsForm[fieldName] = '';
          }
        });
      }

      return {
        ...state,
        ...resetAgentsForm,
      };
    }
    case types.INSTITUTION_USERS_ADD_RESPONSE: {
      const resetAddUserForm = { ...state };

      if (action.payload.success) {
        Object.keys(state).forEach((fieldName) => {
          if (fieldName.match('permissions_add_')) {
            resetAddUserForm[fieldName] = '';
          }
        });
      }

      return {
        ...state,
        ...resetAddUserForm,
      };
    }
    case types.NOTES_ADD_RESPONSE: {
      const resetAddNoteForm = { ...state };

      if (action.payload.success) {
        Object.keys(resetAddNoteForm).forEach((fieldName) => {
          if (fieldName.match('notes_add_')) {
            delete resetAddNoteForm[fieldName];
          }
        });
      }

      return {
        ...resetAddNoteForm,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
