import getCurrentStage from 'workflow/utils/getCurrentStage';

/**
 * Get the current step of the Application
 * @param workflow
 * @param stageId
 * @param stepId
 * @returns {*}
 */
const getCurrentStep = (workflow, stageId, stepId) => {
  const stage = getCurrentStage(workflow, stageId);

  if (!stage) {
    return null;
  }

  let stepIndex = 0;
  let step = stage.steps[stepIndex];

  if (stepId) {
    step = stage.steps.find((step, index) => {
      stepIndex = index;
      return step.id === stepId;
    });
  }

  return {
    ...step,
    index: stepIndex,
  };
};

export default getCurrentStep;
