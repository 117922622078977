import { decimal } from 'currency/currency';

export const itemsWithTotal = function (items, currencyCode, locale) {
  if (!items || !items.length) return [];
  return items.map((item) => {
    const total = parseFloat(item.quantity) * decimal(item.amount, currencyCode, locale);

    return {
      ...item,
      total: decimal(total, currencyCode, locale),
    };
  });
};

/**
 * Get total of items
 * @param items
 * @param currencyCode
 * @param locale
 * @returns {number|*|number}
 */
const totals = function (items, currencyCode, locale) {
  if (!items || !items.length) {
    return 0;
  }

  const itemsWithTotalAmountOfQuantity = itemsWithTotal(items, currencyCode, locale);

  return itemsWithTotalAmountOfQuantity.reduce(
    (a, b) => {
      const valA = a.total ? a.total.toString().replace(/,/g, '') : 0;
      const valB = b.total ? b.total.toString().replace(/,/g, '') : 0;

      return {
        total: parseFloat(valA) + parseFloat(valB),
      };
    },
    {
      total: 0,
    },
  ).total;
};

export default totals;
