import { useMemo, useState } from 'react';
import { convertTZ } from 'utils/getLocalTime';
import TIMEZONES from 'app/constants/timezones';
import { Autocomplete, Box, Divider, FormControl, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { TIME_FORMAT } from 'app/constants/DateFormats';

interface LocalTimeFieldProps {
  label: string;
  disabled: boolean;
  value: any[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  studentTimezone: string;
  schoolTimezone?: string;
}

export const LocalTimeField = ({ label, disabled, value, onChange, error, name, readOnly, studentTimezone, schoolTimezone }: LocalTimeFieldProps) => {
  const [showExtraTZ, setShowExtraTZ] = useState(false);
  const timeZoneSource = useMemo(() => {
    const map = new Map();
    TIMEZONES.forEach((timezone) => {
      map.set(timezone.name, `${timezone.name} ${timezone.offset}`);
    });
    return map;
  }, []);

  // Format date to show in the summary under the fields
  const dateWithTime = useMemo(() => {
    if (value[0] && value[1]) {
      const timeString = format(value[1], TIME_FORMAT);
      const [hours, minutes] = timeString.split(':');
      const date = new Date(value[0]);
      date.setHours(parseInt(hours));
      date.setMinutes(parseInt(minutes));
      return date;
    }
  }, [value]);

  return (
    <Box sx={{ mb: 2 }}>
      <FormLabel>{label}</FormLabel>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth size="small" data-cy-field-type="local-time--date-picker">
          <FormLabel id={`${name}-date-picker-label`}>Date</FormLabel>
          <DatePicker
            disablePast
            aria-labelledby={`${name}-date-picker-label`}
            value={value[0]}
            readOnly={disabled || readOnly}
            slotProps={{
              textField: { size: 'small', error: !!error },
            }}
            onChange={(newValue) => {
              if (isValid(newValue)) {
                value[0] = newValue;
                onChange(value);
              }
            }}
          />
        </FormControl>
        <FormControl fullWidth size="small" data-cy-field-type="local-time--time">
          <FormLabel id={`${name}-time-label`}>Time</FormLabel>
          <TimePicker
            aria-labelledby={`${name}-time-label`}
            value={value[1]}
            slotProps={{
              textField: {
                size: 'small',
                error: !!error,
                inputProps: { readOnly },
              },
              openPickerButton: { disabled: readOnly },
            }}
            disabled={disabled}
            onChange={(v) => {
              if (isValid(v)) {
                value[1] = v;
              }
              onChange(value);
            }}
          />
        </FormControl>
        <FormControl fullWidth size="small" data-cy-field-type="local-time--timezone">
          <FormLabel id={`${name}-timezone-label`}>Your Timezone</FormLabel>
          <Autocomplete
            disableClearable
            disablePortal
            disabled={disabled}
            aria-labelledby={`${name}-timezone-label`}
            size="small"
            getOptionLabel={(o) => timeZoneSource.get(o)}
            options={Array.from(timeZoneSource.keys())}
            value={value[2]}
            renderInput={(params) => {
              return <TextField {...params} error={!!error} placeholder="Select option" />;
            }}
            onChange={(_, newValue) => {
              value[2] = newValue;
              onChange(value);
            }}
            readOnly={readOnly}
          />
        </FormControl>
      </Stack>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
      {studentTimezone && dateWithTime && (
        <>
          <Typography
            onClick={() => setShowExtraTZ(!showExtraTZ)}
            sx={{
              lineHeight: '2em',
              transition: 'all 0.3s ease-in',
              cursor: 'pointer',
              '&:hover': { color: 'primary.main' },
            }}
          >
            {convertTZ(dateWithTime, value[2], studentTimezone)} <span>Student Time Zone - {studentTimezone}</span>
          </Typography>
          {showExtraTZ && (
            <Typography>
              {convertTZ(dateWithTime, value[2], schoolTimezone)} <span>School Time Zone - {schoolTimezone || 'Unknown'}</span>
            </Typography>
          )}
          <Divider />
        </>
      )}
    </Box>
  );
};

// for React.lazy
export default LocalTimeField;
