import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useNotificationMessages = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessMessage = useCallback(
    (message: string) =>
      enqueueSnackbar(message, {
        variant: 'success',
        key: message,
        preventDuplicate: true,
      }),
    [enqueueSnackbar],
  );

  const showErrorMessage = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
        key: message,
        preventDuplicate: true,
      });
      console.error(message);
    },
    [enqueueSnackbar],
  );

  return { showSuccessMessage, showErrorMessage };
};
