import { PaymentOptions } from 'payment/components/PaymentOptions';
import { ControllerRenderProps } from 'react-hook-form';

interface PaymentOptionsFieldProps {
  value: string;
  onChange: ControllerRenderProps['onChange'];
  invoiceId: string;
  readOnly: boolean;
}

export const PaymentOptionsField = ({ value, onChange, invoiceId, readOnly }: PaymentOptionsFieldProps) => {
  return (
    <div data-cy-field-type="payment-options">
      <PaymentOptions invoiceId={invoiceId} onChange={onChange} selectedPaymentSlug={value} readOnly={readOnly} />
    </div>
  );
};

// for React.lazy
export default PaymentOptionsField;
