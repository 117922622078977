import { useMemo, useState } from 'react';
import { UseDeleteOptions, useStatelessDelete } from 'hooks/useStatelessDelete';

export const useDelete = <T>(url: string, options?: UseDeleteOptions): [boolean, () => Promise<T>] => {
  const [loading, setIsLoading] = useState(false);
  const _delete = useStatelessDelete<T>(url, options);

  return [
    loading,
    useMemo(
      () => async () => {
        try {
          setIsLoading(true);
          const response = await _delete();
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      },
      [_delete],
    ),
  ];
};
