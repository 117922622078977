import { set } from 'lodash';
import * as types from 'notes/constants/NotesActionTypes';
import { ERROR_SHOW } from 'app/constants/ActionTypes';

export const notesInitialState = {
  status: 'loading',
  notes: [],
};

export const notesReducer = (state = notesInitialState, action) => {
  switch (action.type) {
    case types.NOTES_GET:
      return {
        ...state,
        status: 'loading',
      };
    case types.NOTES_GET_RESPONSE:
      return {
        ...state,
        status: 'default',
        notes: action.payload.notes.map((note) => {
          return {
            ...note,
            status: 'default',
          };
        }),
      };
    case ERROR_SHOW:
      return {
        ...state,
        status: 'default',
      };
    case types.NOTES_ADD:
      return {
        ...state,
        status: 'saving',
      };
    case types.NOTES_ADD_RESPONSE: {
      const currentNotes = state.notes.slice();

      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      currentNotes.unshift(action.payload.note);

      return {
        ...state,
        status: 'default',
        notes: currentNotes,
      };
    }
    case types.NOTES_DELETE: {
      const notes: any[] = state.notes.slice();
      const indexToMarkForDelete = notes.findIndex((note) => note.id === action.noteId);

      notes[indexToMarkForDelete] = {
        ...notes[indexToMarkForDelete],
        status: 'deleting',
      };

      return {
        ...state,
        status: 'deleting',
        notes,
      };
    }
    case types.NOTES_DELETE_RESPONSE: {
      const notesToDelete = state.notes.slice();
      const indexToDelete = notesToDelete.findIndex(
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        (note) => note.id === action.payload.noteId,
      );

      notesToDelete.splice(indexToDelete, 1);

      return {
        ...state,
        status: 'default',
        notes: notesToDelete,
      };
    }
    case types.NOTES_EDIT: {
      const notesToEdit = state.notes.slice();
      const indexToEdit = notesToEdit.findIndex(
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        (note) => note.id === action.id,
      );

      const editedNote = {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        ...notesToEdit[indexToEdit],
      };

      set(editedNote, action.field, action.value);

      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      notesToEdit[indexToEdit] = editedNote;

      return {
        ...state,
        status: 'default',
        notes: notesToEdit,
      };
    }
    case types.NOTES_IDLE:
      return {
        ...state,
        status: 'idle',
      };
    case types.NOTES_UPDATE:
      return {
        ...state,
        status: 'editing',
      };
    case types.NOTES_UPDATE_RESPONSE: {
      const updatedNotes = state.notes.slice();
      const noteIndex = updatedNotes.findIndex(
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        (note) => note.id === action.payload.note.id,
      );

      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      updatedNotes[noteIndex] = action.payload.note;

      return {
        ...state,
        notes: updatedNotes,
        status: 'default',
      };
    }
    case types.TASK_COMPLETE:
      return {
        ...state,
        status: 'completing',
      };
    case types.TASK_COMPLETE_RESPONSE: {
      const notesUpdated = state.notes.slice();
      const taskIndex = notesUpdated.findIndex(
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        (note) => note.id === action.payload.note.id,
      );

      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      notesUpdated[taskIndex] = action.payload.note;

      return {
        ...state,
        notes: notesUpdated,
        status: 'default',
      };
    }
    default:
      return {
        ...state,
      };
  }
};
