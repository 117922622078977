import * as React from 'react';
import { BusinessTogglesContext } from 'businessToggles/BusinessToggles';

export const HasFeature = ({ children, fallback = <></>, name }) => {
  const renderIfHasFeature = (context) => {
    const hasFeature = context.has(name);

    if (hasFeature) {
      return children;
    } else {
      return fallback;
    }
  };

  return <BusinessTogglesContext.Consumer>{renderIfHasFeature}</BusinessTogglesContext.Consumer>;
};

export default HasFeature;
