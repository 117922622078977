import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { MenuLink } from 'components/MenuLink/MenuLink';
import RemindersDialog from 'enquiries/reminders/RemindersDialog';
import { MobileMenu } from 'components/MobileMenu/MobileMenu';
import HasAccess from 'permissions/HasAccess';
import { ProductsAccess } from 'permissions/ProductsAccess';
import { AppContext } from 'app/AppContext';
import { mainNav } from 'config/routes';
import { reminders } from 'enquiries/enquiriesSelector';
import { Badge, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import { slugify } from 'utils/slugify';
import { ProfileMenu } from 'components/Menu/ProfileMenu';
import { LocaleMenu } from 'components/Menu/LocaleMenu';
import { PERMISSION_SELECT_LANGUAGE } from 'permissions/constants/Roles';

const isCurrent = (url) => {
  const regex = new RegExp(`(${url})(.)?`);
  return regex.test(window.location.pathname);
};

const MainNavItem = ({ nav, index, slug, schoolColor, handleSubnavToggle, subnavIndex }) => {
  const isRoot = nav.url[0] === '/';
  let url = nav.url;

  if (!isRoot && nav.requiresSchool) {
    url = `/${slug}/${nav.url}`;
  }

  url = nav.external ? nav.url : url;

  const props = {
    color: schoolColor,
    active: isCurrent(nav.url),
    navChildren: nav.children,
    label: nav.title,
    onClose: handleSubnavToggle,
    open: subnavIndex === index,
    to: url,
    external: nav.external,
    titleIcon: nav.titleIcon,
    smallLink: nav.external,
    alignRight: nav.alignRight,
  };

  return (
    <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
      <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
        <MenuLink {...props} />
      </ProductsAccess>
    </HasAccess>
  );
};

const MainNavMenu = ({ items, slug, schoolColor, handleSubnavToggle, subnavIndex }) => {
  const theme = useTheme();
  const hideMainMenu = useMediaQuery(theme.breakpoints.down('lg'));

  if (hideMainMenu) return <></>;

  return items.map((nav, index) => {
    if (nav.title === 'Profile') {
      return (
        <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
          <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
            <ProfileMenu slug={slug} />
          </ProductsAccess>
        </HasAccess>
      );
    }

    return (
      <MainNavItem
        key={slugify(nav.title)}
        {...{
          nav,
          index,
          slug,
          schoolColor,
          handleSubnavToggle,
          subnavIndex,
        }}
      />
    );
  });
};

export const Menu = () => {
  const [subnavIndex, setSubnavIndex] = useState(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const slug = useAppSelector((state) => state.school.slug);
  const schoolColor = useAppSelector((state) => state.school.color);
  const localeLabel = useAppSelector((state) => state.user.localeLabel);
  const localeImg = useAppSelector((state) => state.user.localeImg);
  // @ts-ignore
  const totalReminders = useAppSelector((state) => reminders(state).length);

  const handleSubnavToggle = (index) => {
    setSubnavIndex(index !== subnavIndex ? index : null);
  };

  const handleNotificationToggle = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const mainNavItems = mainNav.filter((nav) => {
    if (nav.hide) return false;

    const isRoot = nav.url[0] === '/';

    if (!isRoot) {
      if (nav.requiresSchool && !slug && nav.title !== 'Profile' && !nav.external) {
        return false;
      }
    }

    return true;
  });
  const leftMainNavItems = mainNavItems.filter((nav) => !nav.alignRight);
  const rightMainNavItems = mainNavItems.filter((nav) => nav.alignRight);

  const renderReminders = () => {
    return (
      <RemindersDialog
        key={1}
        // @ts-ignore
        active={isNotificationsOpen}
        onToggle={handleNotificationToggle}
      />
    );
  };

  const renderNotificationBadge = () => {
    if (typeof window === 'undefined' || totalReminders <= 1) {
      return <></>;
    }

    return [
      <Tooltip key={0} title="Notifications">
        <IconButton onClick={handleNotificationToggle}>
          <Badge badgeContent={totalReminders}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>,
      renderReminders(),
    ];
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
      <Stack flex={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={4} mr={3}>
        <MainNavMenu items={leftMainNavItems} {...{ slug, schoolColor, handleSubnavToggle, subnavIndex }} />
      </Stack>
      <Stack flex={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        {renderNotificationBadge()}
        <MainNavMenu items={rightMainNavItems} {...{ slug, schoolColor, handleSubnavToggle, subnavIndex }} />
        <HasAccess for="permission" name={[PERMISSION_SELECT_LANGUAGE]}>
          <LocaleMenu
            {...{
              schoolColor,
              localeLabel,
              localeImg,
              handleSubnavToggle,
            }}
          />
        </HasAccess>
      </Stack>
      <AppContext.Consumer>
        {(appContext) => (
          <MobileMenu
            mainNav={mainNav}
            localeChooser={
              <HasAccess for="permission" name={[PERMISSION_SELECT_LANGUAGE]}>
                <LocaleMenu
                  {...{
                    schoolColor,
                    localeLabel,
                    localeImg,
                    handleSubnavToggle,
                  }}
                />
              </HasAccess>
            }
          />
        )}
      </AppContext.Consumer>
    </Stack>
  );
};
