import { Link } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { POS_LEFT, POS_RIGHT } from 'workflow/actions/constants/Actions';
import { WorkflowActionList } from './actions/WorkflowActionList';
import { FormBuilderAction } from '../FormBuilder/types/FormBuilderAction';

interface NewStepActionsProps {
  actions: FormBuilderAction[];
  previousStepUrl?: string;
  isFormLoading: boolean;
  setActionId: (action) => void;
  submitWithoutValidation: () => void;
  workflowEndpoint: string;
}
const NewStepActions = ({ actions, previousStepUrl, isFormLoading, submitWithoutValidation, setActionId, workflowEndpoint }: NewStepActionsProps) => {
  const leftActions = actions.filter((action) => action.position === POS_LEFT);
  const rightActions = actions.filter((action) => action.position === POS_RIGHT);

  if (!leftActions.length && !rightActions.length) return <></>;

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ backgroundColor: (theme) => theme.palette.grey[50] }} mt={2} pt={2}>
      <Stack gap={1} direction="row">
        {previousStepUrl && (
          <Button component={Link} startIcon={<ArrowBackIcon />} to={previousStepUrl}>
            Back
          </Button>
        )}
        <WorkflowActionList
          actions={leftActions}
          isFormLoading={isFormLoading}
          setActionId={setActionId}
          workflowEndpoint={workflowEndpoint}
          submitWithoutValidation={submitWithoutValidation}
        />
      </Stack>
      <Stack gap={1} direction="row">
        <WorkflowActionList
          actions={rightActions}
          isFormLoading={isFormLoading}
          setActionId={setActionId}
          workflowEndpoint={workflowEndpoint}
          submitWithoutValidation={submitWithoutValidation}
        />
      </Stack>
    </Stack>
  );
};

export default NewStepActions;
