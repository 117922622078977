import { format } from 'date-fns';
import { DATE_SLASH_FORMAT } from 'app/constants/DateFormats';
import { useApplicant } from 'contexts/ApplicantContext';
import Loader from 'components/Loader';
import { FormLabel, Stack, Typography } from '@mui/material';

const ApplicantDetailItem = ({ label, value }) => {
  return (
    <Stack gap={1}>
      <FormLabel>{label}</FormLabel>
      <Typography variant="body2">{value}</Typography>
    </Stack>
  );
};

export const ApplicantDetailsField = () => {
  // TODO: is this possibly passed in, or directly calls API?
  const {
    state: { applicant },
  } = useApplicant();

  if (!applicant) {
    return <Loader />;
  }

  return (
    <Stack direction="row" justifyContent="space-between" data-cy-field-type="application-summary">
      <ApplicantDetailItem label="Student name" value={`${applicant.first_name} ${applicant.last_name}`} />
      <ApplicantDetailItem label="Gender" value={applicant.gender} />
      <ApplicantDetailItem label="Date of birth" value={format(new Date(applicant.date_of_birth), DATE_SLASH_FORMAT)} />
      <ApplicantDetailItem label="Nationality" value={applicant.nationality?.name || 'Unknown'} />
    </Stack>
  );
};

// for React.lazy
export default ApplicantDetailsField;
