import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SkipStepDialog from 'components/Dialogs/SkipStepDialog';
import { useApplication } from 'contexts/ApplicationContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import { usePost } from 'hooks/usePost';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { ButtonProps } from '@mui/material';

interface SkipToStepProps {
  actionId: string;
  workflowEndpoint: string;
  label: string;
  description: string;
  shouldShowDialog?: boolean;
  dialogLabel?: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const SkipToStep = ({ actionId, workflowEndpoint, label, description, shouldShowDialog, dialogLabel, disabled, color, variant }: SkipToStepProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const { loadApplication } = useApplication();
  const { slug, applicant: applicantId, application: applicationId } = useParams() as ApplicantRouteParams;
  const navigate = useNavigate();
  const [isSubmitting, postSkip] = usePost<ResponseEnvelope<any>>(`${workflowEndpoint}/actions/${actionId}`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const handleSubmit = (payload) => {
    postSkip(payload)
      .then((response) => {
        const { success, data, message } = response;
        const applicationProfileURL = `/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}`;

        if (success) {
          setShowDialog(false);
          showSuccessMessage(message);
          const nextStageSlug = data.next_step && data.next_step.stage.slug;
          const nextStepSlug = data.next_step && data.next_step.slug;

          if (nextStageSlug && nextStepSlug) {
            navigate(`${applicationProfileURL}/stages/${nextStageSlug}/steps/${nextStepSlug}`);
          } else {
            navigate(applicationProfileURL);
          }

          return loadApplication();
        } else {
          showErrorMessage(message);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  return (
    <>
      <LoadingButton
        variant={variant}
        color={color}
        loading={isSubmitting}
        disabled={disabled}
        onClick={() => {
          if (shouldShowDialog) {
            setShowDialog(true);
          } else {
            handleSubmit({});
          }
        }}
      >
        {label}
      </LoadingButton>
      <SkipStepDialog
        title={description}
        label={dialogLabel || ''}
        active={showDialog}
        loading={isSubmitting}
        onToggle={() => setShowDialog(!showDialog)}
        onSubmit={(payload) => {
          handleSubmit(payload);
        }}
      />
    </>
  );
};
