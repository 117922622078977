import * as types from 'enquiries/constants/EnquiriesActionTypes';
import { SCHOOL_SELECT } from 'app/constants/ActionTypes';

export const enquiriesInitialState = {
  addMessage: '',
  addStatus: 'default',
  currentName: '',
  editMessage: null,
  editStatus: 'default',
  enquireStatus: 'default',
  enquiries: [],
  enquiryDetails: null,
  leadStatus: 1,
  searchStatus: 'default',
  sortField: 'status',
  sortOrder: 'asc',
  status: 'default',
};

export const enquiriesReducer = (state = enquiriesInitialState, action) => {
  switch (action.type) {
    case types.ENQUIRIES_GET:
      return {
        ...state,
        leadStatus: action.status,
        editStatus: 'default',
        editMessage: null,
        status: 'loading',
      };
    case types.ENQUIRIES_GET_RESPONSE: {
      const enquiries = action.payload.leads;

      return {
        ...state,
        status: 'default',
        enquiries,
        enquiryDetails: null,
      };
    }
    case SCHOOL_SELECT:
      return {
        ...enquiriesInitialState,
      };
    default:
      return {
        ...state,
      };
  }
};
