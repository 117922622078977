import { yearLevel, startDate, endDate } from 'utils/applicationDetails';
import { parse } from 'date-fns';
import { DATE_SLASH_FORMAT } from 'app/constants/DateFormats';
const LOW_PRIORITY = 99;

/**
 * Return alternate or combined fields
 * when given an alias or field name
 * @param  {Object} obj   Object to be sorted
 * @param  {String} field Field name or alias
 * @return {String}       The combined/alternate value
 */
const fieldsByName = function (obj, field) {
  switch (field) {
    case 'name':
      return obj.first_name.toLowerCase() + ' ' + obj.last_name.toLowerCase();
    case 'agent':
      return obj.agent ? obj.agent.first_name.toLowerCase() + ' ' + obj.agent.last_name.toLowerCase() : null;
    case 'country':
      return obj.country.iso_3166_2;
    case 'enquiry_status':
      return obj.lead_state ? obj.lead_state.priority : LOW_PRIORITY;
    case 'enquiry_application_status':
      return obj.application_states.length > 0 ? obj.application_states[0].priority : LOW_PRIORITY;
    case 'account_type':
      return obj.account_type ? 'Enabled' : 'Disabled';
    case 'created_at':
      return obj.created_at;
    case 'registered_user_id':
      return obj.registered_user_id ? `Yes ${obj.created_at}` : `No ${obj.created_at}`;
    case 'filename':
      return obj.name.toLowerCase();
    case 'created_by':
      return obj.created_by ? obj.created_by.account_type : null;
    case 'agency':
      return obj.additional_details && obj.additional_details.agency_name ? obj.additional_details.agency_name.toLowerCase() : '-';
    case 'origin':
      return obj.origin && obj.origin.name ? obj.origin.name : null;
    /* Student summary table */
    case 'student_last_name':
      return obj.student && obj.student.last_name ? obj.student.last_name.toLowerCase() : null;
    case 'student_first_name':
      return obj.student && obj.student.first_name ? obj.student.first_name.toLowerCase() : null;
    case 'student_year_level':
      return parseInt(yearLevel(obj).split('Year ')[1]);
    case 'student_start_date':
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      return parse(startDate(obj), DATE_SLASH_FORMAT, new Date());
    case 'student_end_date':
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      return parse(endDate(obj), DATE_SLASH_FORMAT, new Date());
    case 'flight_date':
      return obj.flight_details ? new Date(obj.flight_details.flight_date).getTime() : null;
    case 'last_updated':
      return obj.updated_at ? new Date(obj.updated_at).getTime() : null;
    default:
      return obj[field];
  }
};

/**
 * Sort in ascending order
 * @param  {String} sortField   Field to sort by
 * @return {Function}           Sort function to be passed to .sort
 */
export const asc = function (sortField) {
  return function (a, b) {
    if (fieldsByName(a, sortField) < fieldsByName(b, sortField)) {
      return -1;
    }

    if (fieldsByName(a, sortField) > fieldsByName(b, sortField)) {
      return 1;
    }

    return 0;
  };
};

/**
 * Sort in descending order
 * @param  {String} sortField   Field to sort by
 * @return {Function}           Sort function to be passed to .sort
 */
export const desc = function (sortField) {
  return function (a, b) {
    if (fieldsByName(a, sortField) > fieldsByName(b, sortField)) {
      return -1;
    }

    if (fieldsByName(a, sortField) < fieldsByName(b, sortField)) {
      return 1;
    }

    return 0;
  };
};

export const dateSort = (a = '', b = '', sortType) => {
  const format = sortType.split('.')[1] || DATE_SLASH_FORMAT;
  const innerSpaces = format.split(' ').length - 1;
  const a1 = a
    .split(' ')
    .slice(0, innerSpaces + 1)
    .join(' ');
  const b1 = b
    .split(' ')
    .slice(0, innerSpaces + 1)
    .join(' ');

  const d1 = parse(a1, format, new Date('1970')).getTime() || 0;
  const d2 = parse(b1, format, new Date('1970')).getTime() || 0;

  return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
};

export const numberSort = (a = '', b = '') => {
  const num1 = Number(a.replace(/[^0-9]/g, ''));
  const num2 = Number(b.replace(/[^0-9]/g, ''));
  return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
};
