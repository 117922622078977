import { Payment } from 'payment/Payment';
interface PaymentFieldProps {
  invoiceId: string;
  selectedPaymentMethodId: string;
}

export const PaymentField = ({ invoiceId, selectedPaymentMethodId }: PaymentFieldProps) => {
  return (
    <div data-cy-field-type="payment">
      <Payment invoiceId={invoiceId} selectedPaymentMethod={selectedPaymentMethodId} />
    </div>
  );
};

export default PaymentField;
