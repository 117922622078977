import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { usePost } from 'hooks/usePost';
import { useApplicant } from 'contexts/ApplicantContext';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

export const ApplyAction = () => {
  const navigate = useNavigate();
  const {
    state: { applicant },
  } = useApplicant();
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [loading, postApply] = usePost<ResponseEnvelope<any>>(`/schools/${schoolSlug}/applicants/${applicant?.id}/applications`);
  const { showErrorMessage } = useNotificationMessages();

  const handleApply = async () => {
    try {
      const response = await postApply();

      if (response.success) {
        const { applicant_id: applicantId, id: applicationId, current_stage: currentStage, current_step: currentStep } = response.data;
        navigate(
          `/${schoolSlug}/enrolment-application/applicant/${applicantId}/application/${applicationId}/stages/${currentStage.slug}/steps/${currentStep.slug}`,
        );
      } else {
        showErrorMessage(getErrorMessage(response));
      }
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <LoadingButton variant="contained" onClick={() => handleApply()} loading={loading || !applicant}>
      Start new course application
    </LoadingButton>
  );
};
