import * as types from 'app/constants/ActionTypes';

export const applicantionInitialState = {
  confirmationStatus: 'default',
  currentApplication: null,
  dismissedAutoSuggestLeadIds: [],
  editApplicationStatus: 'default',
  fetchStatus: 'default',
  id: null,
  leads: [],
  leadSearchStatus: 'default',
  offerFees: [],
  selectedLeadId: '',
  status: 'default',
  transferOwnershipStatus: 'default',
};

export const applicationReducer = (state = applicantionInitialState, action) => {
  switch (action.type) {
    case types.APPLICATION_SEARCH_LEADS:
      return {
        ...state,
        leadSearchStatus: 'searching',
      };
    case types.APPLICATION_SEARCH_LEADS_IDLE:
      return {
        ...state,
        leadSearchStatus: 'idle',
      };
    case types.APPLICATION_SEARCH_LEADS_RESPONSE:
      if (action.payload.success) {
        let selectedLeadId = state.selectedLeadId;

        if (action.payload.findFirst && action.payload.leads.length) {
          selectedLeadId = action.payload.leads[0].id;
        } else {
          // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
          selectedLeadId = null;
        }

        return {
          ...state,
          leadSearchStatus: 'default',
          leads: action.payload.leads,
          selectedLeadId,
        };
      }

      return {
        ...state,
      };
    case types.APPLICATION_LINK_LEAD:
      return {
        ...state,
        leadSearchStatus: 'loading',
      };
    case types.APPLICATION_LINK_LEAD_RESPONSE:
      return {
        ...state,
        currentApplication: action.payload.application,
        leadSearchStatus: 'default',
      };
    case types.APPLICATION_SELECT_LEAD_LINK:
      return {
        ...state,
        selectedLeadId: action.id,
      };
    case types.APPLICATION_DISMISS_AUTO_SUGGEST_LINK_LEAD: {
      const dismissedAutoSuggestLeadIds = state.dismissedAutoSuggestLeadIds.slice();

      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      dismissedAutoSuggestLeadIds.push(action.applicationId);

      return {
        ...state,
        selectedLeadId: '',
        dismissedAutoSuggestLeadIds,
      };
    }
    case types.APPLICATIONS_SEND_FLIGHT_DETAILS_RESPONSE:
    case types.APPLICATION_ARCHIVE_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          currentApplication: action.payload.application,
        };
      }

      return {
        ...state,
      };
    case types.SHARE_APPLICATION_RESPONSE:
    case types.RESEND_SHARE_RESPONSE:
      return {
        ...state,
        currentApplication: {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...state.currentApplication,
          share: action.payload.share,
        },
      };
    case types.REVOKE_SHARE_RESPONSE:
      return {
        ...state,
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        currentApplication: { ...state.currentApplication, share: null },
      };
    case types.APPLICATION_TRANSFER_OWNERSHIP:
      return {
        ...state,
        transferOwnershipStatus: 'loading',
      };
    case types.APPLICATION_TRANSFER_OWNERSHIP_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          currentApplication: {
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            ...state.currentApplication,
            created_by: action.payload.applicationCreatedBy,
          },
          transferOwnershipStatus: 'default',
        };
      }

      return {
        ...state,
        transferOwnershipStatus: 'default',
      };
    default:
      return {
        ...state,
      };
  }
};
