import { useEffect, useState } from 'react';
import SideMenu from 'particulars/SideMenu';
import { useParams } from 'react-router-dom';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import { WorkflowStage } from '../components/Workflow/types/Workflow';

const getFieldContent = (data: WorkflowStage[]) => {
  if (!data) return [];

  const content: any[] = [];
  data.forEach((stage) => {
    stage.steps.forEach((step) => {
      if (step.fields.length > 0) {
        content.push({
          id: step.id,
          label: step.description,
          fields: step.fields,
          status: stage.completed,
        });
      }
    });
  });

  return content;
};

export const Particulars = ({ onToggle }) => {
  const { slug, application: applicationId } = useParams() as ApplicantRouteParams;
  const [content, setContent] = useState<any[]>([]);
  const getEditableFields = useStatelessGet<ResponseEnvelope<any>>(`/schools/${slug}/applications/${applicationId}/editable`);

  useEffect(() => {
    getEditableFields().then((response) => setContent(getFieldContent(response.data.workflow.stages)));
  }, [getEditableFields]);

  return <SideMenu content={content} onToggle={onToggle} />;
};
