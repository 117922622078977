import { Route, Routes } from 'react-router-dom';
import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';
import { ApplicantEnrolmentsLayout } from './ApplicantEnrolmentsLayout';
import { WorkflowApplicationLayout } from './WorkflowApplicationLayout';
import { ApplicationProvider } from '../../contexts/ApplicationContext';

export interface ApplicantRouteParams extends SchoolWorkflowRouteParams {
  application: string;
}

export const Workflow = () => {
  return (
    <Routes>
      <Route path="/" element={<ApplicantEnrolmentsLayout />} />
      <Route
        path="application/:application/*"
        element={
          <ApplicationProvider>
            <WorkflowApplicationLayout />
          </ApplicationProvider>
        }
      />
    </Routes>
  );
};
