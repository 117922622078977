import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { sortedAgents } from 'app/selectors/agentsSelector';
import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { transferOwnership } from 'app/actions/applicationActions';
import { getAgents } from 'app/actions/agentsActions';
import { useMountEffect } from 'hooks/useMountEffect';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

const LoaderSpacer = styled.div`
  display: flex;
  align-items: center;
  min-height: 30rem;
`;

export const TransferOwnershipAgentsTable = ({ applicantId, loadApplicant, applicantOwnerId }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const dispatch = useAppDispatch();
  const agents = useAppSelector(sortedAgents);
  const agentsFetchStatus = useAppSelector((state) => state.agents.agentsStatus);
  const transferOwnershipStatus = useAppSelector((state) => state.application.transferOwnershipStatus);

  useMountEffect(() => {
    dispatch(getAgents(schoolSlug, 1));
  });

  const handleTransferOwnership = (agent) => {
    dispatch(transferOwnership(applicantId, loadApplicant, agent, schoolSlug));
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Agency</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const renderTransferButton = (agent) => {
    if (applicantOwnerId === agent.id) {
      return 'Owner';
    }

    return (
      <Button variant="contained" disabled={transferOwnershipStatus === 'loading'} onClick={() => handleTransferOwnership(agent)}>
        Make owner
      </Button>
    );
  };

  const renderTableRows = () => {
    if (!agents.length) {
      return <></>;
    }

    return agents.map((agent, index) => {
      const agencyName = agent.additional_details && agent.additional_details.agency_name ? agent.additional_details.agency_name : '';

      return (
        <TableRow key={`row-${index}`}>
          <TableCell>
            {agent.first_name} {agent.last_name}
          </TableCell>
          <TableCell>{agent.email || ''}</TableCell>
          <TableCell>{agencyName}</TableCell>
          <TableCell>{renderTransferButton(agent)}</TableCell>
        </TableRow>
      );
    });
  };

  if (!agents.length && agentsFetchStatus === 'loading') {
    return (
      <LoaderSpacer>
        <Loader center />
      </LoaderSpacer>
    );
  }

  return (
    <Stack alignItems="center">
      <Table>
        {renderTableHeader()}
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
    </Stack>
  );
};
