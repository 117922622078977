import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Divider, Stack, Typography } from '@mui/material';
import { ApplicantsTable } from 'applicants/ApplicantsTable';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import AddIcon from '@mui/icons-material/Add';

export const Applicants = ({ applicants }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const navigate = useNavigate();

  const handleRowClick = (applicantId) => {
    navigate(`/${schoolSlug}/enrolment-application/applicant/${applicantId}`);
  };

  return (
    <Grid container justifyContent="center" py={3}>
      <Grid lg={8} xl={6}>
        <Card sx={{ p: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Applicants ({applicants.length})</Typography>
            <Link to={`/${schoolSlug}/applicants/new`}>
              <Button variant="contained" data-cy="start-button" startIcon={<AddIcon />}>
                Add new applicant
              </Button>
            </Link>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography sx={{ mb: 2 }}>To start a new application, select an existing applicant or create a new one.</Typography>
          <ApplicantsTable applicants={applicants} onRowClick={handleRowClick} />
        </Card>
      </Grid>
    </Grid>
  );
};
