import * as types from 'app/constants/ActionTypes';

export const applicationSettingsInitialState = {
  status: 'default',
  editStatus: 'default',
};

export const applicationSettingsReducer = (state = applicationSettingsInitialState, action) => {
  switch (action.type) {
    case types.APPLICATIONS_EDIT:
      return {
        ...state,
        editStatus: 'loading',
      };
    case types.APPLICATIONS_EDIT_RESPONSE:
      if (action.payload.success) {
        const savedTemplates = action.payload.school.application_settings.fee_structures;

        return {
          ...state,
          invoiceTemplates: savedTemplates,
          editStatus: 'success',
        };
      }

      return {
        ...state,
        editStatus: 'default',
      };
    default:
      return {
        ...state,
      };
  }
};
