import { Button } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';

interface ToggleButtonFieldProps {
  label: string;
  disabled: boolean;
  value: boolean;
  onChange: ControllerRenderProps['onChange'];
}

export const ToggleButtonField = ({ label, disabled, value, onChange }: ToggleButtonFieldProps) => {
  const buttonLabel = `${value ? 'Hide' : 'View'} ${label}`;
  return (
    <Button
      data-cy-field-type="togglebutton"
      variant="outlined"
      color="secondary"
      disabled={disabled}
      onClick={() => onChange(!value)}
      sx={{ borderRadius: '9px', float: 'right' }}
    >
      {buttonLabel}
    </Button>
  );
};

export default ToggleButtonField;
