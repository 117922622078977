import interceptor from 'rest/interceptor';
import { authBypass } from 'app/api/authBypass';

function authorization(token) {
  return 'Bearer ' + token;
}

const authInterceptor = interceptor({
  request: async function (request) {
    const isAuthenticated = authBypass.getIsAuthenticated();
    request.headers = request.headers || {};

    if (isAuthenticated) {
      const accessToken = await authBypass.getAccessTokenSilently();

      if (accessToken) {
        request.headers.Authorization = authorization(accessToken);
      }
    }

    return request;
  },
});

export default authInterceptor;
