import * as types from 'app/constants/ActionTypes';
import totals from 'payment/totals';
import { FLYWIRE_PAYMENT_PROVIDER } from 'payment/PaymentProviders';

export const paymentInitialState = {
  status: 'default',
  selectedPaymentSlug: FLYWIRE_PAYMENT_PROVIDER,
  paymentProviders: [],
  total: null,
  items: [] as any[],
};

export const paymentReducer = (state = paymentInitialState, action) => {
  switch (action.type) {
    case types.PAYMENT_SELECT_OPTION:
      return {
        ...state,
        selectedPaymentSlug: action.slug,
      };
    case types.PAYMENT_GET_PROVIDERS:
      return {
        ...state,
        paymentProviders: [],
        status: 'loading',
      };
    case types.PAYMENT_GET_PROVIDERS_RESPONSE:
      return {
        ...state,
        paymentProviders: action.payload.paymentProviders,
        status: 'default',
      };
    case types.PAYMENT_UPDATE:
      return {
        ...state,
        status: 'loading',
      };
    case types.PAYMENT_UPDATE_RESPONSE:
      return {
        ...state,
        status: 'success',
      };
    case types.INVOICE_UPDATE_ROW:
      if (action.section === 'offer-payment') {
        const updatedOfferItems = state.items.slice();

        updatedOfferItems[action.index][action.field] = action.value;

        return {
          ...state,
          items: updatedOfferItems,
          total: totals(updatedOfferItems, action.currencyCode, action.locale),
        };
      }

      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};
