import * as React from 'react';
import styled from 'styled-components';

const H = styled.div`
  display: flex;
  flex: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 7;
  background-color: white;
  padding: 1rem;
  height: 6rem;
  align-items: center;
  box-shadow: ${(props: any) =>
    props.$slug ? '' : '0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12), 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2)'};

  @media print {
    display: none;
  }

  img {
    @media all {
      height: 26px;
    }
  }
`;

// @ts-expect-error TS(2769): No overload matches this call.
const EnrollerHeader = (props) => <H $slug={props.slug}>{props.children}</H>;

export default EnrollerHeader;
