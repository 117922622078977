import { useMemo } from 'react';
import TIMEZONES from 'app/constants/timezones';
import { FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface TimezoneFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const TimezoneField = ({ label, disabled, value, onChange, error, name, readOnly }: TimezoneFieldProps) => {
  const source = useMemo(
    () =>
      TIMEZONES.map((timezone) => {
        return {
          value: timezone.name,
          label: `${timezone.name} ${timezone.offset}`,
        };
      }).sort((a, b) => {
        const x = a.label.toLowerCase();
        const y = b.label.toLowerCase();

        return x < y ? -1 : x > y ? 1 : 0;
      }),
    [],
  );

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="timezone">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Select
          aria-labelledby={`${name}-label`}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          error={!!error}
          disabled={disabled}
          readOnly={readOnly}
        >
          {source.map((timezone) => (
            <MenuItem key={timezone.value} value={timezone.value}>
              {timezone.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default TimezoneField;
