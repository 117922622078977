import { useMemo } from 'react';
import TIMEZONES from 'app/constants/timezones';
import { Autocomplete, FormControl, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { useCountries } from 'app/CountriesContext';
import { Country } from 'app/constants/countries';
import Placeholder from 'forms/consumer/components/Placeholder';

interface CountryAndTimezoneFieldProps {
  label: string;
  disabled: boolean;
  value: any[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const CountryAndTimezoneField = ({ label, disabled, value, onChange, error, name, readOnly }: CountryAndTimezoneFieldProps) => {
  const countries = useCountries();
  const countryList = useMemo(() => countries.map((c) => c.id), [countries]);
  const countryMap: Map<number, Country> = useMemo(() => {
    const map = new Map<number, Country>();
    countries.forEach((country) => {
      map.set(country.id, country);
    });
    return map;
  }, [countries]);

  const getTimezoneOptions = (countryId) => {
    const timezones = TIMEZONES.map((timezone) => {
      return {
        value: timezone.offset,
        label: `${timezone.name} ${timezone.offset}`,
      };
    });

    if (!countryId) {
      return timezones;
    }

    const filtered = TIMEZONES.filter((timezone) => {
      const country = countryMap.get(countryId);

      if (country) {
        return country.name === timezone.country;
      }

      return false;
    });

    if (filtered.length) {
      return filtered.map((timezone) => {
        const val = timezone.name.replace(/\s/g, '_');

        return {
          value: val,
          label: `${timezone.name} ${timezone.offset}`,
        };
      });
    }

    return timezones;
  };

  if (countries.length === 0) {
    return (
      <FormControl fullWidth>
        <FormLabel>{label}</FormLabel>
        <Placeholder fieldType="profile" animate />
      </FormControl>
    );
  }

  return (
    <div>
      <>
        <FormLabel>{label}</FormLabel>
        <FormControl fullWidth size="small" data-cy-field-type="country-and-timezone--country">
          <FormLabel id={`${name}-country-label`}>Country</FormLabel>
          <Autocomplete
            disableClearable
            disablePortal
            disabled={disabled}
            aria-labelledby={`${name}-country-label`}
            size="small"
            options={countryList}
            getOptionLabel={(o) => countryMap.get(o)!.name}
            value={value[0]}
            renderInput={(params) => {
              return <TextField {...params} error={!!error} placeholder="Select country" />;
            }}
            onChange={(_, newValue) => {
              const fieldValues = value.slice();
              fieldValues[0] = newValue;
              onChange(fieldValues);
            }}
            readOnly={readOnly}
          />
        </FormControl>
        <FormControl fullWidth size="small" data-cy-field-type="country-and-timezone--timezone">
          <FormLabel id={`${name}-timezone-label`}>Timezone</FormLabel>
          <Select
            displayEmpty
            size="small"
            aria-labelledby={`${name}-timezone-label`}
            value={value[1]}
            onChange={(event) => {
              const fieldValues = value.slice();
              fieldValues[1] = event.target.value;
              onChange(fieldValues);
            }}
            error={!!error}
            disabled={disabled}
            readOnly={readOnly}
          >
            <MenuItem key="none" value="">
              <em>Select timezone</em>
            </MenuItem>
            {getTimezoneOptions(value[0]).map((timezone) => (
              <MenuItem key={timezone.value} value={timezone.value}>
                {timezone.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
      </>
    </div>
  );
};

// for React.lazy
export default CountryAndTimezoneField;
