import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import defaultLogo from 'images/icons/favicon-180.png';

const Logo = styled.img`
  max-height: 100%;
  width: auto;
`;

export const MobileMenuHeader = ({ onClose }) => {
  const schoolName = useAppSelector((state) => state.school.name);
  const schoolLogo = useAppSelector((state) => state.school.logo);
  const schoolColor = useAppSelector((state) => state.school.color);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ backgroundColor: schoolColor, height: '7rem' }}>
      <Logo src={schoolLogo || defaultLogo} alt={`${schoolName} logo`} height="57" />
      <IconButton
        aria-label="close"
        title="Close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
