import { Radio, Stack } from '@mui/material';
import { useEffect } from 'react';

export const PaymentOption = ({ children, onSelect, onlyOption, readOnly, selected, slug }) => {
  useEffect(() => {
    if (onlyOption) {
      onSelect(slug);
    }
  }, [onSelect, onlyOption, slug]);

  return onlyOption ? (
    children
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => {
        if (!readOnly) {
          onSelect(slug);
        }
      }}
    >
      <Radio checked={selected} disabled={readOnly} />
      {children}
    </Stack>
  );
};
