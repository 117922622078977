import { WorkflowActionList } from './WorkflowActionList';
import { POS_PIPELINE } from 'workflow/actions/constants/Actions';
import { FormBuilderAction } from '../../FormBuilder/types/FormBuilderAction';
import { useApplication } from '../../../contexts/ApplicationContext';

interface NewWorkflowActionsProps {
  actions: FormBuilderAction[];
  workflowEndpoint: string;
}

export const NewWorkflowActions = ({ actions, workflowEndpoint }: NewWorkflowActionsProps) => {
  const {
    state: { application },
  } = useApplication();

  if (!application) return <></>;

  const filteredActions = actions.filter((action) => action.position === POS_PIPELINE);
  return <WorkflowActionList actions={filteredActions} workflowEndpoint={workflowEndpoint} />;
};
