import { useState } from 'react';
import fileDownload from 'js-file-download';
import ExportDropDown from 'components/ExportDropDown/ExportDropDown';
import { usePost } from 'hooks/usePost';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { ButtonProps } from '@mui/material';

interface ExportProps {
  actionId: string;
  workflowEndpoint: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}
export const Export = ({ actionId, workflowEndpoint, disabled, color, variant }: ExportProps) => {
  const [exportingId, setExportingId] = useState<string | null>(null);
  const [isExporting, postExport] = usePost<ResponseEnvelope<any>>(`${workflowEndpoint}/actions/${actionId}`);
  const { showErrorMessage } = useNotificationMessages();

  const handleExport = async () => {
    setExportingId(actionId);

    try {
      const response = await postExport({
        template_id: actionId,
      });
      const blob = new Blob([response.data.file], {
        type: response.data.mime,
      });
      fileDownload(blob, response.data.filename);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    } finally {
      setExportingId(null);
    }
  };

  return (
    <ExportDropDown disabled={disabled} isExporting={isExporting} currentlyExportingId={exportingId} onExport={handleExport} variant={variant} color={color} />
  );
};

export default Export;
