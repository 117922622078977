import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

export type Tab = { label: string; counter?: number };

interface TabsProps {
  active: number;
  center?: boolean;
  disableEmpty?: boolean;
  offsetTop?: boolean;
  onClick: (index: number, tab: Tab) => void;
  tabs: Tab[];
}

export const Tabs = ({ active, center = false, disableEmpty = false, offsetTop, onClick, tabs }: TabsProps) => {
  const muiTabsSx = {
    top: offsetTop ? '2rem' : '0',
  };
  const muiTabSx = {
    height: '4rem',
    lineHeight: '4rem',
    fontSize: '1.6rem',
  };

  return (
    <MuiTabs value={active} onChange={(_, newValue) => onClick(newValue, tabs[newValue])} sx={muiTabsSx}>
      {tabs?.map((tab, index) => (
        <MuiTab
          key={`tab-${index}`}
          label={`${tab.label}${tab.counter ? ` (${tab.counter})` : ''}`}
          disabled={disableEmpty ? !tab.counter : false}
          sx={muiTabSx}
        />
      ))}
    </MuiTabs>
  );
};
