import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WithdrawApplicantDialog } from 'components/Dialogs/WithdrawApplicantDialog';
import { useApplication } from 'contexts/ApplicationContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import { usePost } from 'hooks/usePost';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { Application } from 'types/Application';
import { ButtonProps } from '@mui/material';

interface WithdrawProps {
  actionId: string;
  workflowEndpoint: string;
  label: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const Withdraw = ({ actionId, workflowEndpoint, label, disabled, color, variant }: WithdrawProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const { slug, applicant: applicantId, application: applicationId } = useParams() as ApplicantRouteParams;
  const navigate = useNavigate();
  const {
    state: { application },
    loadApplication,
  } = useApplication();
  const [isSubmitting, postWithdraw] = usePost<ResponseEnvelope<Application>>(`${workflowEndpoint}/actions/${actionId}`);
  const { showSuccessMessage, showErrorMessage } = useNotificationMessages();

  const handleSubmit = (payload) => {
    postWithdraw(payload)
      .then(async (response) => {
        const { success, message } = response;

        if (success) {
          setShowDialog(false);
          showSuccessMessage(message);
          await loadApplication();

          navigate(`/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}`);
        } else {
          showErrorMessage(message);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  return (
    <>
      <LoadingButton variant={variant} color={color} type="submit" loading={isSubmitting} disabled={disabled} onClick={() => setShowDialog(true)}>
        {label}
      </LoadingButton>
      <WithdrawApplicantDialog
        active={showDialog}
        // @ts-expect-error TS(2322): Type '{ active: boolean; applicantId: any; applica... Remove this comment to see the full error message
        applicantId={application.applicant.id}
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        applicationId={application.id}
        applicationUserType={
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          application.created_by && application.created_by.roles[0]
        }
        isSubmitting={isSubmitting}
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        name={`${application.applicant.first_name} ${application.applicant.last_name}`}
        onWithdraw={(payload) => {
          handleSubmit(payload);
        }}
        onToggle={() => setShowDialog(!showDialog)}
      />
    </>
  );
};
