import * as React from 'react';
import styled from 'styled-components';
import HTMLContent from 'workflow/components/HTMLContent';
import { T } from 'components/Translation/Translation';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
`;

export const PostSubmissionMessage = ({ message, translationKey, backLink }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <HTMLContent message={message} />
        <T name={translationKey} />
      </Content>
      <IconButton
        aria-label="close"
        title="Close"
        onClick={() => navigate(backLink)}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Container>
  );
};
