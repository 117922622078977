import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiFormBuilderStep } from '../../Workflow/WorkflowFormBuilder';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useApplication } from 'contexts/ApplicationContext';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { FormField } from '../FormField';
import { Control } from 'react-hook-form';
import { FormBuilderField } from '../types/FormBuilderField';
import { convertApiFieldsToBuilderFields } from '../utils/convertApiFieldsToBuilderFields';
import { getValuesFromFormData } from '../FormBuilder';
import Loader from '../../Loader';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface StepSummaryFieldProps {
  steps: { id: string; name: string }[];
  control: Control;
}

export const StepItem = ({ step, sourceUrl, control }) => {
  const [fields, setFields] = useState<FormBuilderField[]>([]);
  const [values, setValues] = useState<Record<string, any>>(new Map());
  const getFields = useStatelessGet<ResponseEnvelope<ApiFormBuilderStep>>(sourceUrl);
  const {
    state: { application },
  } = useApplication();

  useEffect(() => {
    getFields().then((response) => {
      if (response.data.fields) {
        const fieldsResponse = convertApiFieldsToBuilderFields(response.data.fields);
        setValues(getValuesFromFormData(fieldsResponse, application?.custom_questions || {}, application?.custom_question_answers || {}));
        setFields(fieldsResponse);
      }
    });
  }, [application?.custom_question_answers, application?.custom_questions, getFields]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{step.name}</AccordionSummary>
      <AccordionDetails>
        {fields.map((field) => (
          <FormField value={values[field.slug]} key={field.id} fields={fields} field={field} control={control} disabled />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const StepSummaryField = ({ steps, control }: StepSummaryFieldProps) => {
  const {
    state: { application },
  } = useApplication();
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  if (!application) return <Loader />;

  return (
    <Stack data-cy-field-type="step-summary" gap={1}>
      {steps.map((step, index) => {
        const sourceUrl = `/schools/${schoolSlug}/applications/${application.id}/stages/${application.current_stage_id}/steps/${step.id}`;

        return (
          <div data-cy={`step-${index}`} key={step.id}>
            <StepItem step={step} sourceUrl={sourceUrl} control={control} />
          </div>
        );
      })}
    </Stack>
  );
};

export default StepSummaryField;
