import { Tag } from 'components/Tag/Tag';
import { TicketCourse } from 'components/Ticket/TicketCourse';
import theme from 'config/theme';
import { Box, Stack } from '@mui/material';

const ApplicantPipelineStageColumn = ({ applications, id, name, nameTranslationKey, totalApplications }) => (
  <Stack key={id} flex="1 1 0%" minWidth={0} sx={{ px: 3 }}>
    <Box>
      <Tag
        title={name}
        translationKey={nameTranslationKey}
        counter={totalApplications}
        withTotals
        // @ts-expect-error TS(2322): Type '{ title: any; translationKey: any; counter: ... Remove this comment to see the full error message
        theme={theme}
      />
    </Box>
    <Box sx={{ p: 1, backgroundColor: (theme) => theme.palette.grey[200] }}>
      {applications.map((application) => (
        <TicketCourse
          key={application.id}
          course={application.application_label}
          action={application.current_step ? application.current_step.institutional_message || application.current_step.name : ''}
          permanence={application.updated_at}
          delay={Math.floor(Math.random() * (480 - 220) + 220)}
          application={application}
          isShared={application.shared_users && !!application.shared_users.length}
        />
      ))}
    </Box>
  </Stack>
);

export default ApplicantPipelineStageColumn;
