import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import formDataInterceptor from 'app/api/common/formDataInterceptor';
import { getConfig } from '../utils/config';

const api = {};
const { apiUrl } = getConfig();

const paymentsAPI = client
  .wrap(formDataInterceptor)
  .wrap(template)
  .wrap(defaultRequest, {
    path: `${apiUrl}/schools/{slug}/payments{/id}{/action}`,
  });

// @ts-expect-error TS(2339): Property 'PUT' does not exist on type '{}'.
api.PUT = paymentsAPI.wrap(defaultRequest, {
  method: 'PUT',
});

// @ts-expect-error TS(2339): Property 'DELETE' does not exist on type '{}'.
api.DELETE = paymentsAPI.wrap(defaultRequest, {
  method: 'DELETE',
});

export default api;
