export const ENQUIRY_STATUS_TYPES = [
  {
    value: '1',
    label: 'Active',
  },
  {
    value: '0',
    label: 'Inactive',
  },
];

export const PROFILE_TYPES = [
  {
    value: 'parent',
    label: 'Parent or Caregiver',
  },
  {
    value: 'applicant',
    label: 'Student',
  },
  {
    value: 'agent',
    label: 'Agent',
  },
];

export const PROFILE_TYPES_MAP = {
  agent: 'Agent',
  applicant: 'Applicant',
  parent: 'Parent/Caregiver',
};
