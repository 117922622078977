import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { useGet } from 'hooks/useGet';
import { UseGetOptions } from 'hooks/useStatelessGet';

const Error = styled.p`
  padding: 2rem;
  background: mistyrose;
  color: ${(props) => props.theme.error};
  border: 0rem solid ${(props) => props.theme.error};
  font-family: monospace;
  font-size: 1.6rem;
`;

interface HandleFetchProps {
  children: any;
  onLoad?: () => void;
  onError?: () => void;
  sourceUrl: string;
  sourceParams?: URLSearchParams;
  small?: boolean;
  getOptions?: Partial<UseGetOptions>;
}

export const HandleFetch = ({ children, onLoad, onError, sourceUrl, sourceParams, small = false, getOptions }: HandleFetchProps) => {
  const [loading, getSource] = useGet<any>(sourceUrl, getOptions);
  const [error, setError] = useState<Error | undefined>();
  const [response, setResponse] = useState<unknown>();

  const fetchSource = useCallback(
    () =>
      getSource(sourceParams)
        .then((resp) => {
          setResponse(resp);
          onLoad?.();
        })
        .catch((err) => {
          setError(err);
          onError?.();
        }),
    [getSource, sourceParams, onLoad, onError],
  );

  useEffect(() => {
    if (sourceUrl) {
      fetchSource();
    }
  }, [fetchSource, sourceUrl]);

  if (error) {
    return <Error>Error: {error.message}</Error>;
  }

  if (loading || !response) {
    return <Loader center small={small} />;
  }

  return children(response, fetchSource);
};
