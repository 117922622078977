import { ReactElement } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface SelectLocalTimeFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: ReactElement; value: string; key: string }[];
}

export const SelectLocalTimeField = ({ label, disabled, value, onChange, error, name, readOnly, options }: SelectLocalTimeFieldProps) => (
  <>
    <FormControl fullWidth size="small" data-cy-field-type="select-local-time" sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <RadioGroup aria-labelledby={`${name}-label`} value={value} onChange={(_, newValue) => onChange(newValue.split(','))}>
        {options.map((option) => {
          const isChecked = (typeof value === 'string' ? value : (value as any).join(',')) === option.value;

          return (
            <FormControlLabel
              data-cy={`${name}-radio-${option.key}`}
              key={`${name}-radio-${option.key}`}
              value={option.value}
              control={<Radio />}
              disabled={disabled}
              checked={isChecked}
              label={option.label}
            />
          );
        })}
        <FormControlLabel
          data-cy={`${name}-radio-none`}
          key={`${name}-radio-none`}
          value="None"
          control={<Radio />}
          disabled={disabled}
          label="None of these"
        />
      </RadioGroup>
    </FormControl>
    {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
  </>
);

export default SelectLocalTimeField;
