import { FormControl, FormLabel, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface TimeFieldProps {
  label: string;
  disabled: boolean;
  value: Date;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const TimeField = ({ label, disabled, value, onChange, error, name, readOnly }: TimeFieldProps) => {
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="time">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <TimePicker
          aria-labelledby={`${name}-time-label`}
          value={value}
          slotProps={{
            textField: {
              size: 'small',
              error: !!error,
              inputProps: { readOnly },
            },
            openPickerButton: { disabled: readOnly },
          }}
          disabled={disabled}
          onChange={onChange}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default TimeField;
