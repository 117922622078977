/**
 * Get the current stage of the Application
 * @param workflow
 * @param stageId
 * @returns {|StageColumnType|*}
 */
const getCurrentStage = (workflow, stageId) => {
  let stage = workflow.stages[0];

  if (stageId) {
    stage = workflow.stages.find((stage) => stage.id === stageId);
  }

  return stage;
};

export default getCurrentStage;
