// User
export const AUTO_LOGOUT = 'AUTO_LOGOUT';
export const USER_SET_LOCALE = 'USER_SET_LOCALE';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_IDLE = 'USER_REGISTER_IDLE';
export const USER_REGISTER_RESPONSE = 'USER_REGISTER_RESPONSE';
export const USER_GET_RESEND_ACCOUNT_VERIFICATION = 'USER_GET_RESEND_ACCOUNT_VERIFICATION';
export const USER_GET_RESEND_ACCOUNT_VERIFICATION_RESPONSE = 'USER_GET_RESEND_ACCOUNT_VERIFICATION_RESPONSE';
// Errors
export const ERROR_SHOW = 'ERROR_SHOW';
export const ERROR_HIDE = 'ERROR_HIDE';
// Agents
export const AGENTS_GET = 'AGENTS_GET';
export const AGENTS_GET_RESPONSE = 'AGENTS_GET_RESPONSE';
export const AGENTS_ADD = 'AGENTS_ADD';
export const AGENTS_ADD_RESPONSE = 'AGENTS_ADD_RESPONSE';
// Invitations
export const INVITATIONS_GET = 'INVITATIONS_GET';
export const INVITATIONS_GET_RESPONSE = 'INVITATIONS_GET_RESPONSE';
export const INVITATIONS_EXPIRE = 'INVITATIONS_EXPIRE';
export const INVITATIONS_EXPIRE_RESPONSE = 'INVITATIONS_EXPIRE_RESPONSE';
export const INVITATIONS_SORT = 'INVITATIONS_SORT';
// Institution users
export const INSTITUTION_USERS_GET = 'INSTITUTION_USERS_GET';
export const INSTITUTION_USERS_GET_RESPONSE = 'INSTITUTION_USERS_GET_RESPONSE';
export const INSTITUTION_USERS_GET_DETAILS = 'INSTITUTION_USERS_GET_DETAILS';
export const INSTITUTION_USERS_GET_DETAILS_RESPONSE = 'INSTITUTION_USERS_GET_DETAILS_RESPONSE';
export const INSTITUTION_USERS_ADD = 'INSTITUTION_USERS_ADD';
export const INSTITUTION_USERS_ADD_IDLE = 'INSTITUTION_USERS_ADD_IDLE';
export const INSTITUTION_USERS_EDIT = 'INSTITUTION_USERS_EDIT';
export const INSTITUTION_USERS_EDIT_IDLE = 'INSTITUTION_USERS_EDIT_IDLE';
export const INSTITUTION_USERS_ADD_RESPONSE = 'INSTITUTION_USERS_ADD_RESPONSE';
export const INSTITUTION_USERS_EDIT_RESPONSE = 'INSTITUTION_USERS_EDIT_RESPONSE';
export const INSTITUTION_USERS_TOGGLE_STATUS = 'INSTITUTION_USERS_TOGGLE_STATUS';
export const INSTITUTION_USERS_TOGGLE_STATUS_RESPONSE = 'INSTITUTION_USERS_TOGGLE_STATUS_RESPONSE';
export const INSTITUTION_USERS_SEARCH = 'INSTITUTION_USERS_SEARCH';
export const INSTITUTION_USERS_SEARCH_RESPONSE = 'INSTITUTION_USERS_SEARCH_RESPONSE';
export const INSTITUTION_USERS_SORT = 'INSTITUTION_USERS_SORT';
// Apply
export const APPLICATION_ARCHIVE_RESPONSE = 'APPLICATION_ARCHIVE_RESPONSE';
export const APPLICATION_SEARCH_LEADS = 'APPLICATION_SEARCH_LEADS';
export const APPLICATION_SEARCH_LEADS_IDLE = 'APPLICATION_SEARCH_LEADS_IDLE';
export const APPLICATION_SEARCH_LEADS_RESPONSE = 'APPLICATION_SEARCH_LEADS_RESPONSE';
export const APPLICATION_LINK_LEAD = 'APPLICATION_LINK_LEAD';
export const APPLICATION_LINK_LEAD_RESPONSE = 'APPLICATION_LINK_LEAD_RESPONSE';
export const APPLICATION_SELECT_LEAD_LINK = 'APPLICATION_SELECT_LEAD_LINK';
export const APPLICATION_DISMISS_AUTO_SUGGEST_LINK_LEAD = 'APPLICATION_DISMISS_AUTO_SUGGEST_LINK_LEAD';
// Applications
export const APPLICATIONS_EDIT = 'APPLICATIONS_EDIT';
export const APPLICATIONS_EDIT_RESPONSE = 'APPLICATIONS_EDIT_RESPONSE';
export const APPLICATIONS_SEND_FLIGHT_DETAILS = 'APPLICATIONS_SEND_FLIGHT_DETAILS';
export const APPLICATIONS_SEND_FLIGHT_DETAILS_RESPONSE = 'APPLICATIONS_SEND_FLIGHT_DETAILS_RESPONSE';
export const APPLICATIONS_ADD_INVOICE_TEMPLATE = 'APPLICATIONS_ADD_INVOICE_TEMPLATE';
export const APPLICATIONS_UPDATE_INVOICE_TEMPLATE_NAME = 'APPLICATIONS_UPDATE_INVOICE_TEMPLATE_NAME';
export const APPLICATIONS_FILTER = 'APPLICATIONS_FILTER';
export const APPLICATIONS_FILTER_ARCHIVE = 'APPLICATIONS_FILTER_ARCHIVE';
export const APPLICATION_TRANSFER_OWNERSHIP = 'APPLICATION_TRANSFER_OWNERSHIP';
export const APPLICATION_TRANSFER_OWNERSHIP_RESPONSE = 'APPLICATION_TRANSFER_OWNERSHIP_RESPONSE';
// School
export const SCHOOL_GET = 'SCHOOL_GET';
export const SCHOOL_GET_RESPONSE = 'SCHOOL_GET_RESPONSE';
export const SCHOOL_SELECT = 'SCHOOL_SELECT';
export const SCHOOL_RESET = 'SCHOOL_RESET';
export const SCHOOL_EDIT = 'SCHOOL_EDIT';
export const SCHOOL_ADDRESS_AUTOCOMPLETE = 'SCHOOL_ADDRESS_AUTOCOMPLETE';
export const SCHOOL_EDIT_RESPONSE = 'SCHOOL_EDIT_RESPONSE';
export const SCHOOL_GET_STATS = 'SCHOOL_GET_STATS';
export const SCHOOL_GET_STATS_RESPONSE = 'SCHOOL_GET_STATS_RESPONSE';
// Invoice
export const INVOICE_UPDATE_ROW = 'INVOICE_UPDATE_ROW';
export const INVOICE_VOID_PAYMENT = 'INVOICE_VOID_PAYMENT';
export const INVOICE_VOID_PAYMENT_RESPONSE = 'INVOICE_VOID_PAYMENT_RESPONSE';
export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const GET_INVOICE_BY_ID_RESPONSE = 'GET_INVOICE_BY_ID_RESPONSE';
// Notes
export const NOTES_ADD_RESPONSE = 'NOTES_ADD_RESPONSE';
export const NOTES_DELETE_RESPONSE = 'NOTES_DELETE_RESPONSE';
// Pipeline
export const PIPELINE_VIEW_MODE = 'PIPELINE_VIEW_MODE';
// Payment
export const PAYMENT_SELECT_OPTION = 'PAYMENT_SELECT_OPTION';
export const PAYMENT_GET_PROVIDERS = 'PAYMENT_GET_PROVIDERS';
export const PAYMENT_GET_PROVIDERS_RESPONSE = 'PAYMENT_GET_PROVIDERS_RESPONSE';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';
export const PAYMENT_UPDATE_RESPONSE = 'PAYMENT_UPDATE_RESPONSE';
export const PAYMENT_UPDATE_AMOUNT = 'PAYMENT_UPDATE_AMOUNT';
export const PAYMENT_UPDATE_DATE = 'PAYMENT_UPDATE_DATE';
export const PAYMENT_DELETE = 'PAYMENT_DELETE';
export const PAYMENT_DELETE_IDLE = 'PAYMENT_DELETE_IDLE';
export const PAYMENT_DELETE_RESPONSE = 'PAYMENT_DELETE_RESPONSE';
// Shares
export const SHARE_APPLICATION = 'SHARE_APPLICATION';
export const SHARE_APPLICATION_RESPONSE = 'SHARE_APPLICATION_RESPONSE';
export const RESEND_SHARE = 'RESEND_SHARE';
export const RESEND_SHARE_RESPONSE = 'RESEND_SHARE_RESPONSE';
export const REVOKE_SHARE = 'REVOKE_SHARE';
export const REVOKE_SHARE_RESPONSE = 'REVOKE_SHARE_RESPONSE';
export const RESET_SHARE_STATE = 'RESET_SHARE_STATE';
export const SHARE_INVOICE = 'SHARE_INVOICE';
export const SHARE_INVOICE_RESPONSE = 'SHARE_INVOICE_RESPONSE';
export const RESET_SHARE_INVOICE_STATE = 'RESET_SHARE_INVOICE_STATE';
