import { ENROLLER_RECRUIT } from 'permissions/constants/ProductLevels';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ReactComponent as DashboardIcon } from 'images/icons/Material/dashboard.svg';
import { ReactComponent as CampaignIcon } from 'images/icons/Material/campaign.svg';
import { ReactComponent as SchoolIcon } from 'images/icons/Material/school.svg';
import { ReactComponent as AgentIcon } from 'images/icons/Material/support_agent.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/Material/settings.svg';
import {
  PERMISSION_VIEW_APPLICATION_SETTINGS,
  PERMISSION_VIEW_DASHBOARD,
  PERMISSION_VIEW_FINANCIAL_SETTINGS,
  PERMISSION_VIEW_INSTITUTION_AGENTS,
  PERMISSION_VIEW_INSTITUTION_SETTINGS,
  PERMISSION_VIEW_INSTITUTION_USERS,
  PERMISSION_VIEW_LEAD,
  ROLE_TYPE_AGENT,
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_ENROLLER_ADMIN,
  ROLE_TYPE_INSTITUTION_ADMIN,
  ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF,
  ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
  ROLE_TYPE_INSTITUTION_STAFF,
  ROLE_TYPE_PARENT,
  ROLE_TYPE_SHARED_APPLICANT,
} from 'permissions/constants/Roles';
import { getConfig } from 'utils/config';

const { marketplaceUrl } = getConfig();

export const routes = [
  {
    title: 'Workflows',
    url: 'management/workflows',
    mainNav: true,
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_ENROLLER_ADMIN],
    mobileIcon: DashboardIcon,
    requiresSchool: false,
  },
  {
    title: 'Dashboard',
    url: 'dashboard',
    mainNav: true,
    allowedFor: 'permission',
    allowedRoleTypes: [PERMISSION_VIEW_DASHBOARD],
    mobileIcon: DashboardIcon,
    disallowedProducts: [ENROLLER_RECRUIT],
    requiresSchool: true,
  },
  {
    title: 'Marketplace',
    url: marketplaceUrl,
    mainNav: true,
    external: true,
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_AGENT, ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF],
    mobileIcon: CampaignIcon,
    requiresSchool: true,
  },
  {
    title: 'Enrolments',
    url: '/enrolments',
    mainNav: true,
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_AGENT],
    mobileIcon: DashboardIcon,
    requiresSchool: true,
  },
  {
    title: 'Shared Enrolments',
    url: '/shared-enrolments',
    mainNav: true,
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_SHARED_APPLICANT],
    mobileIcon: DashboardIcon,
  },
  {
    title: 'Students',
    url: 'studentsummary',
    mainNav: true,
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
    mobileIcon: SchoolIcon,
    children: [
      {
        label: 'Enquiries',
        url: 'enquiries',
        allowedFor: 'permission',
        allowedRoleTypes: [PERMISSION_VIEW_LEAD],
      },
      {
        label: 'Enrolments',
        url: 'pipeline',
        allowedFor: 'role',
        allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
      },
      {
        label: 'Awaiting arrival',
        url: 'studentsummary?studentStatus=Impending',
        allowedFor: 'role',
        allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
        disallowedProducts: [ENROLLER_RECRUIT],
      },
      {
        label: 'Current students',
        url: 'studentsummary?studentStatus=Current',
        allowedFor: 'role',
        allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
        disallowedProducts: [ENROLLER_RECRUIT],
      },
      {
        label: 'Alumni students',
        url: 'studentsummary?studentStatus=Alumni',
        allowedFor: 'role',
        allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
        disallowedProducts: [ENROLLER_RECRUIT],
      },
      {
        label: 'All enrolled students',
        url: 'studentsummary?studentStatus=All',
        allowedFor: 'role',
        allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
        disallowedProducts: [ENROLLER_RECRUIT],
      },
      {
        label: 'Archived students',
        url: 'studentsummary?studentStatus=Archived',
        allowedFor: 'role',
        allowedRoleTypes: [ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF],
        allowedProducts: [ENROLLER_RECRUIT],
      },
    ],
  },
  {
    title: 'Enrolment',
    url: 'pipeline',
    mainNav: true,
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_APPLICANT, ROLE_TYPE_PARENT],
    mobileIcon: SchoolIcon,
    requiresSchool: true,
  },
  {
    title: 'Agents',
    url: 'settings/agents',
    mainNav: true,
    allowedFor: 'permission',
    allowedRoleTypes: [PERMISSION_VIEW_INSTITUTION_AGENTS],
    mobileIcon: AgentIcon,
    requiresSchool: true,
  },
  {
    title: 'Create a new lead',
    url: 'leads/add',
    mainNav: false,
  },
  {
    title: 'Edit lead details',
    url: 'leads/edit/:id',
    mainNav: false,
  },
  {
    title: 'Settings',
    url: 'settings',
    mainNav: true,
    hide: true,
    children: [
      {
        label: 'User Settings',
        url: 'settings/permissions',
        allowedFor: 'permission',
        allowedRoleTypes: [PERMISSION_VIEW_INSTITUTION_USERS],
        disallowedProducts: [ENROLLER_RECRUIT],
      },
      {
        label: 'School Settings',
        url: 'settings/school',
        allowedFor: 'permission',
        allowedRoleTypes: [PERMISSION_VIEW_INSTITUTION_SETTINGS],
      },
      {
        label: 'Application Settings',
        url: 'settings/application',
        allowedFor: 'permission',
        allowedRoleTypes: [PERMISSION_VIEW_APPLICATION_SETTINGS],
      },
      {
        label: 'Financial Settings',
        url: 'settings/financial',
        allowedFor: 'permission',
        allowedRoleTypes: [PERMISSION_VIEW_FINANCIAL_SETTINGS],
        disallowedProducts: [ENROLLER_RECRUIT],
      },
    ],
    allowedFor: 'role',
    allowedRoleTypes: [ROLE_TYPE_INSTITUTION_ADMIN],
    mobileIcon: SettingsIcon,
  },
  {
    title: 'Help Center',
    titleIcon: HelpIcon,
    mobileIcon: HelpIcon,
    url: 'https://help.enroller.co.nz/',
    mainNav: true,
    external: true,
    alignRight: true,
    allowedFor: 'role',
    allowedRoleTypes: [
      ROLE_TYPE_APPLICANT,
      ROLE_TYPE_AGENT,
      ROLE_TYPE_PARENT,
      ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
      ROLE_TYPE_INSTITUTION_STAFF,
      ROLE_TYPE_INSTITUTION_ADMIN,
    ],
  },
  {
    title: 'Profile',
    url: '/profile',
    mainNav: true,
    hideOnMobile: true,
    alignRight: true,
    allowedFor: 'role',
    allowedRoleTypes: [
      ROLE_TYPE_ENROLLER_ADMIN,
      ROLE_TYPE_APPLICANT,
      ROLE_TYPE_AGENT,
      ROLE_TYPE_PARENT,
      ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
      ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF,
      ROLE_TYPE_INSTITUTION_STAFF,
      ROLE_TYPE_INSTITUTION_ADMIN,
    ],
  },
];

export const mainNav = routes.filter((route) => route.mainNav);
