import { combineReducers } from 'redux';
import { AUTO_LOGOUT } from 'app/constants/ActionTypes';
import { errorsReducer } from 'app/reducers/errors';
import { userReducer } from 'app/reducers/user';
import { schoolReducer } from 'app/reducers/school';
import { pipelineReducer } from 'app/reducers/pipeline';
import { formsReducer } from 'app/reducers/forms';
import { valuesReducer } from 'app/reducers/values';
import { agentsReducer } from 'app/reducers/agents';
import { institutionUsersReducer } from 'app/reducers/institutionUsers';
import { applicationReducer } from 'app/reducers/application';
import { applicationSettingsReducer } from 'app/reducers/applicationSettings';
import { uploadsReducer } from 'app/reducers/uploads';
import { reviewReducer } from 'app/reducers/review';
import { generalReducer } from 'app/reducers/general';
import { invoiceReducer } from 'app/reducers/invoice';
import { notesReducer } from 'app/reducers/notes';
import { paymentReducer } from 'app/reducers/payment';
import { financialReducer } from 'app/reducers/financial';
import { sharesReducer } from 'app/reducers/shares';
import { enquiriesReducer } from 'app/reducers/enquiries';

const nopersist = function (state = {} as Record<string, any>, action) {
  switch (action.type) {
    default:
      return state;
  }
};

const appReducer = () =>
  combineReducers({
    agents: agentsReducer,
    application: applicationReducer,
    applicationSettings: applicationSettingsReducer,
    enquiries: enquiriesReducer,
    errors: errorsReducer,
    financial: financialReducer,
    forms: formsReducer,
    general: generalReducer,
    invoice: invoiceReducer,
    nopersist,
    notes: notesReducer,
    payment: paymentReducer,
    institutionUsers: institutionUsersReducer,
    pipeline: pipelineReducer,
    review: reviewReducer,
    school: schoolReducer,
    shares: sharesReducer,
    uploads: uploadsReducer,
    user: userReducer,
    values: valuesReducer,
  });

const sharedAppReducer = () =>
  combineReducers({
    application: applicationReducer,
    errors: errorsReducer,
    financial: financialReducer,
    forms: formsReducer,
    general: generalReducer,
    invoice: invoiceReducer,
    pipeline: pipelineReducer,
    nopersist,
    payment: paymentReducer,
    school: schoolReducer,
    shares: sharesReducer,
    uploads: uploadsReducer,
    user: userReducer,
    values: valuesReducer,
  });

export const rootReducer = () => (state, action) => {
  if (action.type === AUTO_LOGOUT) {
    state = {
      school: {
        ...state.school,
      },
    };

    if (typeof window !== 'undefined') {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    }
  }

  return appReducer()(state, action);
};

export const sharedRootReducer = () => (state, action) => {
  return sharedAppReducer()(state, action);
};
