import { Particulars } from '../particulars/Particulars';
import { DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export const ApplicationParticulars = ({ onToggle }) => {
  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Particulars
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Particulars onToggle={onToggle} />
      </DialogContent>
    </>
  );
};
