import * as React from 'react';

/**
 * Shows a Post Submission Message on a
 * Step if it's the last step in a sequence
 * of steps for a user group
 *
 * @param props
 */
const HTMLContent = (props) => <div className="parsedmessage" dangerouslySetInnerHTML={{ __html: props.message }} />;

export default React.memo(HTMLContent);
