import { Industry, Programme } from './MultiLevelMultiSelectField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Divider, List, ListItemText, ListSubheader, ListItemButton, ListItem, ListItemIcon } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface PrimaryPanelProps {
  industryOptions: Industry[];
  selectedProgrammeValue: Programme['value'] | null;
  selectedIndustryValue: Industry['value'] | null;
  query: string;
  onSelect: (industry: Industry['value'] | null, programme: Programme['value'] | null) => void;
}

export const PrimaryPanel = ({ industryOptions, selectedProgrammeValue, selectedIndustryValue, query, onSelect }: PrimaryPanelProps) => {
  const filteredIndustryOptions = useMemo(
    () =>
      industryOptions
        .filter((industry) => industry.children.length > 0)
        .map((industry) => ({
          ...industry,
          children: industry.children.filter((programme) => programme.label.toLowerCase().includes(query.toLowerCase())),
        })),
    [industryOptions, query],
  );

  return (
    <Box flex="1">
      <List>
        {/* All immediate children of Lists must be list items, and all mapped children must have keys
        so we can't map nested programmes of industries to empty fragments */}
        {filteredIndustryOptions.reduce((industryListItems, industry, index) => {
          industryListItems.push(
            <ListSubheader key={industry.value} sx={{ fontWeight: 'bold' }}>
              {industry.label}
            </ListSubheader>,
          );
          industryListItems.push(
            ...industry.children.map((programme) => (
              <ListItem disablePadding>
                <ListItemButton
                  key={programme.value}
                  onClick={() => onSelect(industry.value, programme.value)}
                  selected={selectedIndustryValue === industry.value && selectedProgrammeValue === programme.value}
                >
                  <ListItemText>{programme.label}</ListItemText>
                  <ChevronRightIcon />
                </ListItemButton>
              </ListItem>
            )),
          );
          if (index < filteredIndustryOptions.length - 2) {
            industryListItems.push(<Divider key={`${industry.value}-divider`} sx={{ mb: 2 }} aria-hidden component="li" />);
          }
          return industryListItems;
        }, [] as ReactNode[])}

        {query && !Boolean(filteredIndustryOptions.length) && (
          <ListItem>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <SearchIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontWeight: 'bold', color: 'inherit' }}>No search results for {query}</ListItemText>
          </ListItem>
        )}
      </List>
    </Box>
  );
};
