import * as types from 'app/constants/ActionTypes';

export const reviewInitialState = {
  approvalStatus: 'default',
  setInterviewTimesStatus: 'default',
  skipInterviewStatus: 'default',
  interviewNotesStatus: 'default',
  createOfferStatus: 'default',
  reviewOfferStatus: 'default',
  confirmPaymentStatus: 'default',
  sendDocumentsStatus: 'default',
  reviewVisaStatus: 'default',
  confirmationStatus: 'default',
  sendFlightAndArrivalDetailsStatus: 'default',
  withdrawStatus: 'default',
  skipVisaStatus: 'default',
  moveToOfferStatus: 'default',
  showSkipVisaDialog: false,
  showMoveToOfferDialog: false,
  showMoveToEnrolledDialog: false,
  showReviseConfirmedOfferDialog: false,
  showReviseConfirmedOfferStatus: 'default',
};

export const reviewReducer = (state = reviewInitialState, action) => {
  switch (action.type) {
    case types.APPLICATIONS_SEND_FLIGHT_DETAILS:
      return {
        ...state,
        sendFlightAndArrivalDetailsStatus: 'loading',
      };
    case types.APPLICATIONS_SEND_FLIGHT_DETAILS_RESPONSE:
      return {
        ...state,
        sendFlightAndArrivalDetailsStatus: action.payload.success ? 'success' : 'error',
      };
    default:
      return {
        ...state,
      };
  }
};
