import React from 'react';
import { useAppSelector } from 'app/hooks';
import styled from 'styled-components';
import LangContext from 'app/LangContext';
import { getConfig } from 'utils/config';

const Translation = styled.span`
  margin: 0;
  font-weight: 300;
  color: ${(props) => props.theme.dove};
  font-size: 1.4rem;
  line-height: 1.8rem;

  span {
    font-size: inherit;
  }
`;

const Undefined = styled.span`
  color: darkorange;
`;

export const T = (props) => {
  const { name, className, ...params } = props;
  const locale = useAppSelector((state) => state.user.locale);
  const { environment } = getConfig();

  return (
    <LangContext.Consumer>
      {(context) => {
        if (locale === 'en') {
          return <></>;
        }

        const translation = context.lang.get && context.lang.get(name, params);
        let translatedElement = <></>;

        if ((environment === 'development' || environment === 'local') && ((!translation && translation !== '') || translation === name)) {
          translatedElement = (
            <Undefined>
              {name} is undefined for <strong>{locale}</strong>
            </Undefined>
          );
        } else if (translation) {
          translatedElement = <span className={className} dangerouslySetInnerHTML={{ __html: translation }} />;
        }

        return (
          <Translation data-locale={locale} lang={locale}>
            {translatedElement}
          </Translation>
        );
      }}
    </LangContext.Consumer>
  );
};
