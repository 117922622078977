import { WorkflowFormBuilder } from '../../components/Workflow/WorkflowFormBuilder';

export const StepForm = ({ heading, previousStepUrl, stepActionUrl, translationKey, workflowActionUrl, showPostSubmissionMessage }) => {
  return (
    <>
      <WorkflowFormBuilder
        fieldsUrl={stepActionUrl}
        previousStepUrl={previousStepUrl}
        showPostSubmissionMessage={showPostSubmissionMessage}
        workflowEndpoint={workflowActionUrl}
        heading={heading}
        headingTranslationKey={translationKey}
      />
    </>
  );
};
