import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components';
import { format, isValid, parseISO } from 'date-fns';
import { convertRFCtoISO } from 'utils/date';
import { DATE_DASH_FORMAT, DATE_WITH_WEEKDAY_FORMAT } from 'app/constants/DateFormats';
import HasAccess from 'permissions/HasAccess';
import { ReactComponent as NoteIcon } from 'images/icons/Material/note.svg';
import { ReactComponent as PhoneIcon } from 'images/icons/Material/phone.svg';
import { ReactComponent as EmailIcon } from 'images/icons/Material/email.svg';
import { ReactComponent as AlarmIcon } from 'images/icons/Material/alarm.svg';
import { ReactComponent as EditIcon } from 'images/icons/Material/edit.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/Material/delete.svg';
import { ReactComponent as DoneIcon } from 'images/icons/Material/done.svg';
import { ReactComponent as MoreIcon } from 'images/icons/Material/more.svg';
import { getReminderStatus } from 'utils/getReminderStatus';
import { NOTE_LABELS } from 'notes/constants/NoteLabels';
import { ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_STAFF } from 'permissions/constants/Roles';
import {
  Avatar,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { getErrorMessage } from 'utils/errors';
import { useStatelessPut } from 'hooks/useStatelessPut';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { ApplicantRouteParams } from '../components/Workflow/Workflow';
import { useAppSelector } from '../app/hooks';
import { useStatelessDelete } from '../hooks/useStatelessDelete';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  // @ts-expect-error TS(2339): Property 'status' does not exist on type 'ThemedSt... Remove this comment to see the full error message
  opacity: ${(props: any) => (props.status === 'deleting' ? '0.5' : '1')};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: ${(props) => props.theme.mercury};
  z-index: 2;

  svg {
    fill: #fff;
    width: 1.6rem;
    height: 1.6rem;
  }

  @media ${(props) => props.theme.md} {
    width: 4rem;
    height: 4rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

interface EditNoteFormValues {
  task?: string;
  reminder?: Date;
  content: string;
}

const NoteContents = ({ editing, withTask, withReminderDate, task, reminder, content, id, handleEdit, heading, leadId, recipient }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const formDefaultValues: EditNoteFormValues = { content };
  if (withTask) formDefaultValues.task = task;
  if (withReminderDate) formDefaultValues.reminder = reminder;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditNoteFormValues>({
    defaultValues: formDefaultValues,
  });
  const putNote = useStatelessPut<ResponseEnvelope<any>>(`/schools/${schoolSlug}/${recipient}/${leadId}/notes/${id}`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsUpdating(true);
      const response = await putNote(data);
      setIsUpdating(false);
      showSuccessMessage(response.message);
      handleEdit();
    } catch (error) {
      setIsUpdating(false);
      showErrorMessage(getErrorMessage(error));
    }
  });

  if (editing) {
    return (
      <form onSubmit={onSubmit}>
        {withTask && (
          <>
            <FormControl fullWidth error={!!errors.content}>
              <FormLabel id="taskLabel">Label</FormLabel>
              <Controller
                control={control}
                name="task"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Select size="small" aria-labelledby="taskLabel" value={value} onChange={onChange} error={!!errors.content}>
                      {NOTE_LABELS.map((noteLabel) => {
                        return (
                          <MenuItem key={noteLabel.value} value={noteLabel.value}>
                            {noteLabel.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  );
                }}
              />
            </FormControl>
            {errors.task && <Typography color="error">{errors.task.message}</Typography>}
          </>
        )}
        {withReminderDate && (
          <>
            <FormControl fullWidth error={!!errors.reminder}>
              <FormLabel id="reminderLabel">Label</FormLabel>
              <Controller
                control={control}
                name="reminder"
                render={({ field: { value, onChange } }) => {
                  return (
                    <DatePicker
                      aria-labelledby="reminderLabel"
                      value={value}
                      minDate={new Date()}
                      slotProps={{ textField: { size: 'small', error: !!errors.reminder } }}
                      onChange={(newValue) => {
                        if (isValid(newValue)) {
                          onChange(format(newValue!, DATE_DASH_FORMAT));
                        }
                      }}
                    />
                  );
                }}
              />
            </FormControl>
            {errors.reminder && <Typography color="error">{errors.reminder.message}</Typography>}
          </>
        )}
        <FormControl fullWidth error={!!errors.content}>
          <Controller
            name="content"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <TextField multiline rows={3} value={value} onChange={onChange} error={!!errors.content} placeholder="Add new note here..." />
            )}
          />
        </FormControl>
        {errors.content && <Typography color="error">{errors.content.message}</Typography>}
        <DialogActions>
          <LoadingButton variant="contained" type="submit" loading={isUpdating}>
            Save
          </LoadingButton>
          <Button variant="contained" disabled={isUpdating} onClick={handleEdit}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }

  if (task === 'Enquiry') {
    const intro = heading ? (
      <Typography>
        Enquiry about <strong>{heading}</strong>:
      </Typography>
    ) : null;

    return (
      <>
        {intro}
        <p>{content}</p>
      </>
    );
  }

  return (
    <>
      {content.split('\n').map((it, i) => (
        <p key={'x' + i}>{it}</p>
      ))}
    </>
  );
};

export const NewNote = ({
  id,
  task,
  withTask = false,
  reminder,
  withReminderDate = false,
  content,
  heading,
  editable,
  completed_at: completedAt,
  created_at: createdAt,
  status = '',
  name = 'System',
  onUpdate,
  recipient,
  recipientId,
}) => {
  const theme = useTheme();
  const { slug: schoolSlug } = useParams() as ApplicantRouteParams;
  const schoolColor = useAppSelector((state) => state.school.color);
  const [editing, setEditing] = useState(false);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const postCompleteNote = useStatelessPut<ResponseEnvelope<any>>(`/schools/${schoolSlug}/${recipient}/${recipientId}/notes/${id}`);
  const deleteNote = useStatelessDelete<ResponseEnvelope<any>>(`/schools/${schoolSlug}/${recipient}/${recipientId}/notes/${id}`);

  const renderIcon = () => {
    const icons = {
      Call: PhoneIcon,
      Email: EmailIcon,
    };
    const Icon = icons[task] ? icons[task] : NoteIcon;

    return <Icon />;
  };

  const renderTags = () => {
    const { color } = getReminderStatus({
      reminder,
    });
    const reminderDate = format(parseISO(convertRFCtoISO(reminder)), DATE_WITH_WEEKDAY_FORMAT);

    return (
      <>
        {completedAt ? (
          <Tooltip title="Completed date" key={1}>
            <Chip icon={<DoneIcon />} label={format(parseISO(completedAt), DATE_WITH_WEEKDAY_FORMAT)} color="primary" size="small" />
          </Tooltip>
        ) : (
          <></>
        )}
        {reminder ? (
          <Tooltip title="Reminder date" key={2}>
            <Chip
              icon={<AlarmIcon />}
              label={reminderDate}
              // @ts-expect-error TS(2769): No overload matches this call.
              color={color}
              size="small"
            />
          </Tooltip>
        ) : (
          <></>
        )}
        {task ? <Chip label={task} size="small" key={3} /> : <></>}
      </>
    );
  };

  const handleToggleCompleteDialog = () => setShowCompleteDialog(!showCompleteDialog);

  const handleToggleDeleteDialog = () => setShowDeleteDialog(!showDeleteDialog);

  const handleEdit = () => {
    setEditing(!editing);
    onUpdate();
  };

  const handleComplete = () => {
    postCompleteNote({ content, completed_at: new Date(Date.now()) }).then(() => onUpdate());
    handleToggleCompleteDialog();
  };

  const handleDelete = () => {
    deleteNote().then(() => onUpdate());
    handleToggleDeleteDialog();
  };

  const formattedDate = format(parseISO(createdAt), DATE_WITH_WEEKDAY_FORMAT);
  const isSystemGenerated = !name;

  const avatarBgColor = isSystemGenerated ? schoolColor : theme.palette.primary.main;
  const avatarColor = '#fff';
  const avatarSx = { backgroundColor: avatarBgColor, color: avatarColor };
  const avatarContent = isSystemGenerated ? (
    <SettingsIcon sx={{ color: '#ffffff' }} />
  ) : (
    name
      .split(' ')
      .map((word) => word[0].toUpperCase())
      .join('')
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const withClose = (fn, ...args) => {
    handleClose();
    fn(...args);
  };

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper status={status}>
      <Icon>{renderIcon()}</Icon>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ p: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1} mb={2}>
            <Avatar sx={avatarSx}>{avatarContent}</Avatar>
            {name && <Typography>{name}</Typography>}
            {formattedDate && <Typography variant="subtitle2">{formattedDate}</Typography>}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            {renderTags()}
            {editable && (
              <HasAccess for="role" name={[ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN]}>
                <IconButton onClick={handleClick}>
                  <MoreIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  {reminder && !completedAt && (
                    <MenuItem value="complete" disabled={status === 'completing'} onClick={() => withClose(handleToggleCompleteDialog)}>
                      <ListItemIcon>
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText>Mark Complete</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem value="edit" disabled={status === 'deleting'} onClick={() => withClose(() => setEditing(true))}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                  <MenuItem value="delete" disabled={status === 'deleting'} onClick={() => withClose(handleToggleDeleteDialog)}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </Menu>
              </HasAccess>
            )}
          </Stack>
        </Stack>
        <NoteContents
          {...{
            editing,
            withTask,
            withReminderDate,
            task,
            reminder,
            content,
            id,
            handleEdit,
            heading,
            leadId: recipientId,
            recipient,
          }}
        />
        <Dialog open={showDeleteDialog} onClose={handleToggleDeleteDialog}>
          <DialogTitle variant="h4" component="h2">
            Are you sure you want to delete this note?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={handleToggleDeleteDialog}>No</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showCompleteDialog} onClose={handleToggleCompleteDialog}>
          <DialogTitle variant="h4" component="h2">
            Are you sure you want to mark this task as complete?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleComplete}>Yes</Button>
            <Button onClick={handleToggleCompleteDialog}>No</Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Wrapper>
  );
};
