import { ReactComponent as StripeIcon } from 'images/payment/stripe-powered-by.svg';
import { Stack, Typography } from '@mui/material';

const StripePaymentOption = () => (
  <Stack direction="row" alignItems="center" width="fit-content" gap={2}>
    <Typography width="fit-content">I am paying from Stripe</Typography>
    <StripeIcon width={90} />
  </Stack>
);

export default StripePaymentOption;
