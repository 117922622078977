import { Component } from 'react';
import { Reminders } from 'enquiries/reminders/Reminders';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

class RemindersDialog extends Component {
  componentDidUpdate(prevProps) {
    if (
      // @ts-expect-error TS(2339): Property 'status' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.status !== prevProps.status &&
      // @ts-expect-error TS(2339): Property 'status' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.status === 'success'
    ) {
      // @ts-expect-error TS(2339): Property 'onToggle' does not exist on type 'Readon... Remove this comment to see the full error message
      setTimeout(this.props.onToggle, 1000);
    }
  }

  render() {
    return (
      // @ts-expect-error TS(2339): Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
      <Dialog open={this.props.active} onClose={this.props.onToggle}>
        <DialogContent>
          {/* @ts-expect-error TS(2739): Type '{ withTitle: true; alt: true; active: any; }... Remove this comment to see the full error message */}
          <Reminders withTitle alt active={this.props.active} />
        </DialogContent>
        <DialogActions>
          {/* @ts-expect-error TS(2339): Property 'onToggle' does not exist on type 'Readon... Remove this comment to see the full error message */}
          <Button onClick={this.props.onToggle}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RemindersDialog;
