import { useState } from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import styled from 'styled-components';
import classnames from 'classnames';
import Upload from 'components/FileUpload/Upload';
import { T } from 'components/Translation/Translation';
import { UPLOAD_MAX_SIZE } from 'app/constants/env';
import UploadIcon from '@mui/icons-material/Upload';
import theme from 'config/theme';
import 'components/FileUpload/dropzone.css';
import 'components/FileUpload/UploadContent.css';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const Container = styled.section`
  width: 100%;
  height: 50%;
  margin: 3.8rem 0 4.2rem 0;
`;

const UploadLabel = styled.label<{ $error: boolean }>`
  color: ${(props) => (props.$error ? theme.error : 'inherit')};
`;

const UploadError = styled.span`
  color: ${theme.error};

  > span {
    font-size: 1.3rem;
  }
`;

const UploadWrapper = styled.section<{ $error: boolean }>`
  width: 100%;
  margin-top: 1.8rem;
  border: 1px solid ${(props) => (props.$error ? theme.error : '#e5e5e5')};
  border-radius: 2px;
  background-color: #fafafa;
`;

const UploadList = styled.div`
  width: 100%;
`;

interface FileUploadProps {
  accept?: Accept;
  allowMultipleFiles?: boolean;
  directLink?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  maxFiles?: number;
  name: string;
  required?: boolean;
  translation?: string;
  versioned?: boolean;
  value: any[];
  onChange: (files: any[]) => void;
}

export const FileUpload = ({
  accept,
  allowMultipleFiles = false,
  directLink = false,
  disabled = false,
  error = false,
  label,
  maxFiles = 99,
  name,
  required = false,
  translation,
  versioned = false,
  value = [],
  onChange,
}: FileUploadProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [fileIndex, setFileIndex] = useState(-1);

  const handleFileRemove = (index) => {
    setShowConfirm(true);
    setFileIndex(index);
  };

  const renderFileList = () => {
    if (!value.length) {
      return <></>;
    }

    const uploads = value.map((file, index) => {
      return (
        <Upload
          directLink={directLink}
          key={`file-${index}`}
          index={index}
          name={file.name}
          preview={file.preview}
          size={file.size}
          type={file.type}
          versioned={versioned}
          canRemove={!disabled}
          onRemove={handleFileRemove}
        />
      );
    });

    return <UploadList>{uploads}</UploadList>;
  };

  const handleClose = () => {
    setShowConfirm(false);
    setFileIndex(-1);
  };

  const handleConfirmFileRemove = () => {
    const files = value.slice();
    files.splice(fileIndex, 1);
    onChange(files);
    setShowConfirm(false);
    setFileIndex(-1);
  };

  const handleFileDrop = (accepted) => {
    const newFileList = [];

    const existingFiles = value.slice();

    for (let i = 0; i < accepted.length; i++) {
      const file = Object.assign(accepted[i], {
        preview: URL.createObjectURL(accepted[i]),
      });

      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      if (!newFileList.includes(file)) {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        newFileList.push(file);
      }
    }

    onChange(existingFiles.concat(newFileList));
  };

  const contentClasses = classnames({
    uploadcontent: true,
    'uploadedcontent--pure': value.length === 0,
  });

  const isUploadDisabled = (!allowMultipleFiles && value.length === 1) || value.length >= maxFiles || disabled;

  const hasError = Boolean(error);
  return (
    <Container>
      {!!label ? (
        <UploadLabel $error={hasError} htmlFor="dropzone">
          {label}
          {required ? '*' : null} <small>(Max file size 10MB)</small>
        </UploadLabel>
      ) : (
        <></>
      )}
      <p>
        <T name={translation} />
      </p>
      <UploadWrapper $error={hasError}>
        <Dropzone multiple={allowMultipleFiles} accept={accept} disabled={isUploadDisabled} maxSize={UPLOAD_MAX_SIZE} onDrop={handleFileDrop}>
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
            <div
              {...getRootProps({
                className: 'dropzone',
              })}
            >
              <input {...getInputProps()} />
              <div className={contentClasses}>
                <UploadIcon width="60" height="50" className="uploadicon" />
                {isDragAccept && <p className="label">Drag and drop files here or click to upload.</p>}
                {isDragReject && <p className="forbidden">This file type is not allowed.</p>}
                {!isDragActive && <p className="label">Drag and drop files here or click to upload.</p>}
              </div>
            </div>
          )}
        </Dropzone>
      </UploadWrapper>
      {renderFileList()}
      {hasError && <UploadError>{error}</UploadError>}
      <Dialog open={showConfirm} onClose={handleClose}>
        <DialogTitle variant="h4" component="h2">
          Are you sure you want to remove this file?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmFileRemove}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
