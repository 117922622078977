import * as types from 'app/constants/ActionTypes';
import { APPLICATION_STATUS_ENROLLED, OWNER_ISD } from 'app/constants/ApplicationStatuses';

export const pipelineInitialState = {
  status: 'loading',
  searchStatus: 'default',
  filter: OWNER_ISD,
  archiveFilter: APPLICATION_STATUS_ENROLLED,
  total: 0,
  page: 0,
  lastPage: 0,
  perPage: 15,
  downloadFormStatus: 'default',
  viewMode: 'card',
  applications: [],
  isArchivedPipeline: false,
};

export const pipelineReducer = (state = pipelineInitialState, action) => {
  switch (action.type) {
    case types.APPLICATIONS_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case types.APPLICATIONS_FILTER_ARCHIVE:
      return {
        ...state,
        archiveFilter: action.filter,
      };
    case types.APPLICATION_ARCHIVE_RESPONSE:
      if (action.payload.success) {
        let archivedApplications = state.applications.slice();

        archivedApplications = archivedApplications.map((application) => {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          if (application.id === action.payload.application.id) {
            // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
            application = action.payload.application;
          }

          return application;
        });

        return {
          ...state,
          applications: archivedApplications,
        };
      }

      return {
        ...state,
      };
    case types.PIPELINE_VIEW_MODE:
      return {
        ...state,
        viewMode: action.mode,
      };
    default:
      return {
        ...state,
      };
  }
};
