import { Card, Grid, Typography } from '@mui/material';
import Loader from 'components/Loader';

const Server = () => (
  <Grid container justifyContent="center" my={2}>
    <Card className="nojs__message">
      <Typography variant="h5">JavaScript is required</Typography>
      <Typography>Please enable JavaScript to use Enroller.</Typography>
    </Card>
    <div className="nojs__loader">
      <Loader />
    </div>
  </Grid>
);

export default Server;
