import { format } from 'date-fns';
import spacetime from 'spacetime';
import { DATE_WITH_WEEKDAY_AND_TIME_FORMAT } from 'app/constants/DateFormats';

export function convertTZ(date, from = '', to = '') {
  if (!date) return '';

  const fromDate = spacetime(date, from || spacetime.now().timezone().name);
  const toDate = fromDate.goto(to || spacetime.now().timezone().name);

  return format(new Date(toDate.year(), toDate.month(), toDate.date(), toDate.hour(), toDate.minute()), DATE_WITH_WEEKDAY_AND_TIME_FORMAT);
}
