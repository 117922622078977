import { format, parseISO } from 'date-fns';
import * as types from 'app/constants/ActionTypes';
import { decimal } from 'currency/currency';
import { FEE_STRUCTURE_ITEMS } from 'app/constants/env';
import { DATE_DASH_FORMAT, DATE_SLASH_FORMAT } from 'app/constants/DateFormats';

// eslint-disable-next-line prefer-spread
const defaultItems = Array.apply(null, Array(FEE_STRUCTURE_ITEMS)).map((_, index) => {
  return {
    id: `new-${index}`,
    description: '',
    quantity: '',
    amount: '',
  };
});

export const invoiceInitialState = {
  applicationId: null,
  updateStatus: 'default',
  addPaymentStatus: 'default',
  selectedInvoiceTemplateIndex: null,
  selectedInvoiceTemplateId: null,
  id: null,
  invoiceNumber: '',
  date: '',
  dueDate: '',
  notes: null,
  total: null,
  tax: null,
  items: defaultItems,
  invoiceStatus: null,
  paymentAmount: null,
  paymentDate: new Date(),
  payments: [],
  receiptCreateStatus: 'default',
  studentName: '',
  fetchStatus: 'default',
  shareInvoiceStatus: 'default',
  deletePaymentStatus: 'default',
  updatedAt: new Date().getTime(),
  showAccountCode: null,
  student: {} as Record<string, any>,
  xero_id: null,
  xero_url: null,
};

export const invoiceReducer = (state = invoiceInitialState, action) => {
  switch (action.type) {
    case types.GET_INVOICE_BY_ID:
      return {
        ...state,
        fetchStatus: 'loading',
      };
    case types.GET_INVOICE_BY_ID_RESPONSE:
      if (action.payload.success) {
        const items = action.payload.invoice.items.map((item) => {
          return {
            ...item,
            amount: decimal(item.amount, action.payload.currencyCode, action.payload.locale),
          };
        });

        return {
          ...state,
          applicationId: action.payload.invoice.application,
          id: action.payload.invoice.id,
          invoiceNumber: action.payload.invoice.invoice_number,
          date: format(parseISO(action.payload.invoice.created_at), DATE_SLASH_FORMAT),
          dueDate: action.payload.invoice.due_date ? format(parseISO(action.payload.invoice.due_date), DATE_DASH_FORMAT) : '',
          notes: action.payload.invoice.notes,
          tax: action.payload.invoice.tax,
          total: action.payload.invoice.total,
          invoiceStatus: action.payload.invoice.status,
          payments: action.payload.invoice.payments ? action.payload.invoice.payments : [],
          items,
          studentName: `${action.payload.invoice.student.first_name} ${action.payload.invoice.student.last_name}`,
          fetchStatus: 'default',
          xero_id: action.payload.invoice.xero_id,
          xero_url: action.payload.invoice.xero_url,
          updatedAt: new Date().getTime(),
        };
      }

      if (
        !action.payload.success &&
        action.payload.error &&
        action.payload.error.status &&
        action.payload.error.status.code &&
        action.payload.error.status.code === 404
      ) {
        return {
          ...state,
          fetchStatus: 'error',
        };
      }

      return {
        ...state,
      };
    case types.INVOICE_UPDATE_ROW:
      if (action.section === 'offer') {
        const updatedOfferItems = state.items.slice();

        updatedOfferItems[action.index][action.field] = action.value;

        return {
          ...state,
          items: updatedOfferItems,
          updatedAt: new Date().getTime(),
        };
      }

      return {
        ...state,
      };
    case types.PAYMENT_UPDATE_AMOUNT:
      return {
        ...state,
        paymentAmount: action.value,
      };
    case types.PAYMENT_UPDATE_DATE:
      return {
        ...state,
        paymentDate: action.date,
      };
    case types.INVOICE_VOID_PAYMENT_RESPONSE:
      if (action.payload.success) {
        const invoice = action.payload.invoice;

        return {
          ...state,
          payments: invoice.payments,
        };
      }

      return {
        ...state,
      };
    case types.SHARE_INVOICE:
      return {
        ...state,
        shareInvoiceStatus: 'loading',
      };
    case types.SHARE_INVOICE_RESPONSE:
      return {
        ...state,
        shareInvoiceStatus: action.payload.success ? 'success' : 'error',
      };
    case types.RESET_SHARE_INVOICE_STATE:
      return {
        ...state,
        shareInvoiceStatus: 'default',
      };
    case types.PAYMENT_DELETE:
      return {
        ...state,
        deletePaymentStatus: 'deleting',
      };
    case types.PAYMENT_DELETE_IDLE:
      return {
        ...state,
        deletePaymentStatus: 'idle',
      };
    case types.PAYMENT_DELETE_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          deletePaymentStatus: 'success',
          payments: action.payload.invoice.payments ? action.payload.invoice.payments : state.payments.slice(),
        };
      }

      return {
        ...state,
        deletePaymentStatus: 'default',
      };
    default:
      return {
        ...state,
      };
  }
};
