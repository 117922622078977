import { useEffect, useRef, useState } from 'react';
import Loader from 'components/Loader';
import { asyncLoad } from 'utils/asyncLoad';
import theme from 'config/theme';
import { PAYMENT_STATUS_CREATED } from 'invoices/InvoiceStatuses';
import { getConfig } from 'utils/config';
import { useMountEffect } from 'hooks/useMountEffect';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { Box } from '@mui/material';

interface FlywireConfig {
  env: 'production' | 'demo';
  destination: any;
  amount: number;
  country: any;
  en_invoice_id: string;
  student_first_name: string;
  student_last_name: string;
  locale: string;
  theme: {
    brandColor: string;
    footer: false;
    chat: false;
  };
  provider: 'Enroller';
  read_only: string;
  callback_url: string;
  callback_id: string;
}

export const Flywire = ({
  invoiceId,
  invoiceNumber,
  sharedPayment,
  slug,
  selectedPaymentProviderId,
  amountInCents,
  headers,
  destination,
  country,
  locale,
  workflowConfigurations,
  backURL,
}) => {
  const wrapper = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState<FlywireConfig | null>(null);
  const applicantFirstName = useAppSelector((state) => state.invoice.student.first_name);
  const applicantLastName = useAppSelector((state) => state.invoice.student.last_name);
  const { environment, apiUrl } = getConfig();

  useMountEffect(() => {
    if (invoiceId && invoiceNumber) {
      const action = sharedPayment ? 'shared-payments' : 'payments';
      const endpoint = `${apiUrl}/schools/${slug}/invoices/${invoiceId}/${action}`;
      const data = {
        payment_provider_id: selectedPaymentProviderId,
        amount: amountInCents,
        status: PAYMENT_STATUS_CREATED,
      };
      const params = {
        method: 'post',
        headers: { ...headers, 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      };

      const readonlyFields = ['en_invoice_id'];

      const configObj: Partial<FlywireConfig> = {
        env: environment === 'production' ? 'production' : 'demo',
        destination,
        amount: amountInCents,
        country,
        en_invoice_id: invoiceNumber,
        student_first_name: applicantFirstName || '',
        student_last_name: applicantLastName || '',
        locale,
        theme: {
          brandColor: theme.primary,
          footer: false,
          chat: false,
        },
        provider: 'Enroller',
      };

      if (workflowConfigurations.length) {
        workflowConfigurations.forEach((field) => {
          configObj[field.key] = field.value;

          if (field.readonly) {
            readonlyFields.push(field.key);
          }
        });
      }

      configObj.read_only = readonlyFields.join(',');

      fetch(endpoint, params)
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject(new Error(response.statusText));
          }

          return response.json();
        })
        .then((response) => {
          configObj.callback_url = `${apiUrl}/schools/${slug}/payments/${response.created_payment_id}/webhook`;
          configObj.callback_id = response.created_payment_id;

          return asyncLoad('https://payment.flywire.com/assets/js/flywire.js');
        })
        .then(() => {
          setConfiguration(configObj as FlywireConfig);
        })
        .catch((error) => {
          console.error('Request failed', error);
          navigate(backURL);
        });
    }
  });

  useEffect(() => {
    if (configuration && wrapper.current) {
      const node = wrapper.current.lastChild;

      while (node && node.hasChildNodes()) {
        node.removeChild(node.lastChild!);
      }

      window.flywire.Payment.render(configuration, '#flywireContainer');
    }
  }, [configuration]);

  return (
    <Box ref={wrapper} id="flywireContainer">
      <Loader center />
    </Box>
  );
};
