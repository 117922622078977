import * as types from 'app/constants/ActionTypes';

export const errorsInitialState = {
  errorMessage: null,
  errors: null,
};

export const errorsReducer = (state = errorsInitialState, action) => {
  switch (action.type) {
    case types.ERROR_SHOW: {
      const toUpdate = { ...state };

      toUpdate.errorMessage = action.errorMessage;

      if (action.errors) {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        const newErrors = { ...state.errors };

        Object.keys(action.errors).forEach((field) => {
          newErrors[field] = action.errors[field][0];
        });

        toUpdate.errors = newErrors;
      }

      return {
        ...state,
        ...toUpdate,
      };
    }
    case types.ERROR_HIDE:
      return {
        ...state,
        errorMessage: null,
      };
    case types.USER_REGISTER:
    case types.INSTITUTION_USERS_ADD:
    case types.INSTITUTION_USERS_EDIT:
    case types.AGENTS_ADD:
      return {
        ...errorsInitialState,
      };
    default:
      return {
        ...state,
      };
  }
};
