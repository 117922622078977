import { useMemo, useState } from 'react';
import { UsePostOptions, useStatelessPost } from 'hooks/useStatelessPost';

export const usePost = <T, D = unknown>(url: string, options?: UsePostOptions): [boolean, (data?: D, params?: URLSearchParams) => Promise<T>] => {
  const [loading, setIsLoading] = useState(false);
  const post = useStatelessPost<T>(url, options);

  return [
    loading,
    useMemo(
      () => async (data?: unknown, params?: URLSearchParams) => {
        try {
          setIsLoading(true);
          const response = await post(data, params);
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      },
      [post],
    ),
  ];
};
