import File from 'components/DocStack/File';
import { Stack, Typography } from '@mui/material';

export const DocStack = ({
  compact = false,
  fileList = [] as any[],
  showTitle = false,
  title = 'Documents',
  withBackground = false,
  withLinks = false,
  withPreview = false,
}) => (
  <>
    {(!compact || (compact && showTitle)) && (
      <Typography variant="h6" component="h4">
        {title}
      </Typography>
    )}
    <Stack component="ul" direction={compact ? 'row' : 'column'} flexWrap="wrap">
      {fileList
        .sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0))
        .filter((file) => file && typeof file === 'object' && file.filename && file.size && file.mime && file.preview)
        .map((file, index) => (
          <File
            key={`file-${file.id || index}`}
            compact={compact}
            withBackground={withBackground}
            withLinks={withLinks}
            withPreview={withPreview}
            directLink={!!file.directLink}
            name={file.filename}
            size={file.size}
            mime={file.mime}
            preview={file.preview}
            createdDate={file.created_at}
          />
        ))}
    </Stack>
  </>
);

export default DocStack;
