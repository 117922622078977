import { LoadingButton } from '@mui/lab';
import {
  ACTION_DECLINE,
  ACTION_EXPORT,
  ACTION_LINK,
  ACTION_NEXT,
  ACTION_REENROL,
  ACTION_SHARE_INVOICE,
  ACTION_SKIP_TO_STEP,
  ACTION_WITHDRAW,
  POS_LEFT,
  POS_RIGHT,
  POS_STEPLIST,
} from 'workflow/actions/constants/Actions';
import { Withdraw } from './Withdraw';
import { SkipToStep } from './SkipToStep';
import Export from './Export';
import { Decline } from './Decline';
import ShareInvoice from './ShareInvoice';
import HasAccess from 'permissions/HasAccess';
import { ReEnrol } from './ReEnrol';
import { FormBuilderAction } from '../../FormBuilder/types/FormBuilderAction';
import { LinkAction } from './LinkAction';

interface ActionProps {
  action: FormBuilderAction;
  isFormLoading?: boolean;
  setActionId?: (action) => void;
  workflowEndpoint: string;
  submitWithoutValidation?: () => void;
}

const Action = ({ action, isFormLoading, submitWithoutValidation, workflowEndpoint, setActionId }: ActionProps) => {
  let actionColor;
  let actionVariant;

  if (action.type === ACTION_LINK && action.position === POS_STEPLIST) {
    actionColor = 'primary';
    actionVariant = 'outlined';
  } else {
    switch (action.style) {
      case 'primary':
        actionColor = 'primary';
        actionVariant = 'contained';
        break;
      case 'secondary':
        actionColor = 'secondary';
        actionVariant = 'outlined';
        break;
      case 'tertiary':
        actionColor = 'primary';
        actionVariant = 'outlined';
        break;
    }
  }

  switch (action.type) {
    case ACTION_NEXT: {
      const saveButton = () => (
        <LoadingButton variant={actionVariant} color={actionColor} loading={isFormLoading} onClick={submitWithoutValidation}>
          Save for later
        </LoadingButton>
      );
      return (
        <>
          {/* Add 'Save' action before a `next` action with position Right */}
          {action.position === POS_RIGHT && saveButton()}
          <LoadingButton
            variant={actionVariant}
            color={actionColor}
            type="submit"
            loading={isFormLoading}
            disabled={action.disabled}
            onClick={() => setActionId?.(action.id)}
          >
            {action.label}
          </LoadingButton>
          {/* Add 'Save' action after a `next` action with position Left */}
          {action.position === POS_LEFT && saveButton()}
        </>
      );
    }
    case ACTION_WITHDRAW:
      return (
        <Withdraw
          actionId={action.id}
          disabled={action.disabled}
          workflowEndpoint={workflowEndpoint}
          label={action.label}
          variant={actionVariant}
          color={actionColor}
        />
      );
    case ACTION_SKIP_TO_STEP:
      const { show_dialog: shouldShowDialog, dialog_label: dialogLabel } = action.frontendConfig || {};
      return (
        <SkipToStep
          actionId={action.id}
          disabled={action.disabled}
          workflowEndpoint={workflowEndpoint}
          label={action.label}
          description={action.description}
          shouldShowDialog={shouldShowDialog}
          dialogLabel={dialogLabel}
          variant={actionVariant}
          color={actionColor}
        />
      );
    case ACTION_EXPORT:
      return <Export actionId={action.id} disabled={action.disabled} workflowEndpoint={workflowEndpoint} variant={actionVariant} color={actionColor} />;
    case ACTION_DECLINE:
      return (
        <Decline
          actionId={action.id}
          disabled={action.disabled}
          label={action.label}
          workflowEndpoint={workflowEndpoint}
          variant={actionVariant}
          color={actionColor}
        />
      );
    case ACTION_SHARE_INVOICE:
      return <ShareInvoice disabled={action.disabled} label={action.label} variant={actionVariant} color={actionColor} />;
    case ACTION_REENROL:
      return (
        <ReEnrol
          actionId={action.id}
          disabled={action.disabled}
          workflowEndpoint={workflowEndpoint}
          label={action.label}
          variant={actionVariant}
          color={actionColor}
        />
      );
    case ACTION_LINK:
      return <LinkAction action={action} color={actionColor} variant={actionVariant} />;
    default:
      return <></>;
  }
};

interface WorkflowActionListProps extends Omit<ActionProps, 'action'> {
  actions: FormBuilderAction[];
}

export const WorkflowActionList = ({ actions, isFormLoading, setActionId, workflowEndpoint, submitWithoutValidation }: WorkflowActionListProps) => {
  return (
    <>
      {actions.map((action: FormBuilderAction) => (
        <HasAccess for="group" name={[action.userType]} key={action.id}>
          <Action {...{ action, isFormLoading, setActionId, workflowEndpoint, submitWithoutValidation }} />
        </HasAccess>
      ))}
    </>
  );
};
