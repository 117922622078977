import interceptor from 'rest/interceptor';
import Logger from 'app/api/common/Logger';
import { getConfig } from 'utils/config';

const logger = new Logger();

const failureLogger = interceptor({
  response: function (response, config) {
    const { environment } = getConfig();
    const log = (config || {}).logger.logtype || logger.warning;

    response = response || {};

    response.request = response.request || {};
    response.request.method = response.request.method || 'GET';
    response.status = response.status || { text: response.error };

    if (environment !== 'local' && environment !== 'development' && response.status && response.status.code > config.code) {
      log.apply(config.logger, [
        'Request error: ' + response.request.method + ' ' + response.request.path + ' ' + (response.status.code || '~') + ' ' + response.status.text,
        {
          extra: {
            request: {
              path: response.request.path,
              method: response.request.method,
              canceled: response.request.canceled,
              params: response.request.params,
            },
            response: {
              entity: response.entity,
              status: response.status,
            },
          },
        },
      ]);
    }

    return response;
  },
});

export default failureLogger;
