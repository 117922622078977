import { get } from 'lodash';

export const newMultiOptions = (options, accommodationTypes, formFields): { label: string; value: string }[] => {
  return options.flatMap((option) => {
    switch (option.source) {
      case 'default':
        return {
          label: option.label || option.value,
          value: option.value,
        };
      case 'api_json': {
        const field = formFields[option.value];

        if (field) {
          if (option.path) {
            return get(field.value, option.path);
          }

          return field.value;
        }

        return null;
      }
      case 'accommodation_types':
        if (accommodationTypes) {
          return accommodationTypes.map((sourceOption) => {
            return sourceOption.name
              ? {
                  label: sourceOption.name,
                  value: sourceOption.name,
                }
              : null;
          });
        }

        return null;
      default:
        return null;
    }
  });
};
