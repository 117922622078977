export const deepMerge = (a: Record<string, any>, b: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const key of new Set([...Object.keys(a), ...Object.keys(b)])) {
    // structuredClone doesn't support FormData so we need to return it as is
    // This is only used for multipart forms.
    if (a[key] instanceof FormData || b[key] instanceof FormData) {
      result[key] = a[key] instanceof FormData ? a[key] : b[key];
    } else if (a[key]?.constructor === Object && b[key]?.constructor === Object) {
      result[key] = deepMerge(a[key], b[key]);
    } else {
      result[key] = structuredClone(b[key] !== undefined ? b[key] : a[key]);
    }
  }

  return result;
};
