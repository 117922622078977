import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const api: any = {};
const { apiUrl } = getConfig();

const shareAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/share/{user}`,
});

api.POST = shareAPI.wrap(defaultRequest, {
  method: 'POST',
});

api.PATCH = shareAPI.wrap(defaultRequest, {
  method: 'PATCH',
});

api.DELETE = shareAPI.wrap(defaultRequest, {
  method: 'DELETE',
  path: `${apiUrl}/schools/{slug}/share/{application}/{user}`,
});

export default api;
