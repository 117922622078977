import React from 'react';
import { Box, Typography } from '@mui/material';

export const EnquiryHeaderENZ = (props) => (
  <Box
    sx={{
      p: 1,
      minHeight: '5rem',
      backgroundColor: '#0c1e2e',
    }}
  >
    <img src="/images/origins/logo-enz-light.svg" alt="enz logo" />
    <Typography color="white">{props.name}</Typography>
  </Box>
);
