import ReactGA from 'react-ga4';

export const trackDocumentDownload = function (filePreview) {
  ReactGA.event({
    category: 'Application documents',
    action: 'Download file',
    label: filePreview,
  });
};

export const trackDocumentUpload = function (documentsLength) {
  ReactGA.event({
    category: 'Application documents',
    action: 'Uploaded files',
    label: 'Upload',
    value: documentsLength,
  });
};

export const trackDocumentDelete = function () {
  ReactGA.event({
    category: 'Application documents',
    action: 'Deleted file',
    label: 'Delete',
  });
};
