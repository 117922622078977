import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const api: any = {};
const { apiUrl } = getConfig();

const agentsAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/users/{?type}{&active}{?action}{id}`,
});

// GET|HEAD  | api/schools/{school}/agents/{lead}       | schools.agents.show
// GET|HEAD  | api/schools/{school}/agents              | schools.agents.index
api.GET = agentsAPI.wrap(defaultRequest, {
  method: 'GET',
});

// POST      | api/schools/{school}/agents              | schools.agents.store
api.POST = agentsAPI.wrap(defaultRequest, {
  method: 'POST',
});

// PUT|PATCH | api/schools/{school}/agents/{agent}       | schools.agents.update
api.PUT = agentsAPI.wrap(defaultRequest, {
  method: 'PUT',
});

api.DELETE = agentsAPI.wrap(defaultRequest, {
  method: 'DELETE',
});

export default api;
