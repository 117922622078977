import React from 'react';
import { useAppSelector } from 'app/hooks';
import styled from 'styled-components';

const Total = styled.small`
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
`;

export const Count = ({ children }) => {
  const schoolColor = useAppSelector((state) => state.school.color);

  return <Total color={schoolColor}>({children})</Total>;
};
