import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { BusinessToggles } from 'businessToggles/BusinessToggles';
import { ProfileAvatar } from 'components/Profile/ProfileAvatar';
import { MobileMenuHeader } from 'components/MobileMenu/MobileMenuHeader';
import HasAccess from 'permissions/HasAccess';
import { ProductsAccess } from 'permissions/ProductsAccess';
import HasFeature from 'businessToggles/HasFeature';
import { FLAG_LANGUAGE_ASSIST } from 'businessToggles/Flags';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ListItemToggle = ({ title, slug, nav, mobileIcon, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItemButton onClick={handleToggle}>
        <ListItemIcon>{mobileIcon}</ListItemIcon>
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen}>
        <List component="div" disablePadding>
          {nav.map((nav, index) => (
            <ListItemButton key={`nav-${index}`} component={Link} to={`/${slug}/${nav.url}`} onClick={onClose} sx={{ pl: 6 }}>
              <ListItemText primary={nav.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const MobileMenu = ({ hideLogin = false, mainNav, localeChooser }) => {
  const theme = useTheme();
  const { user, isAuthenticated, logout, isLoading } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const schoolSlug = useAppSelector((state) => state.school.slug);
  const userName = `${user?.userData.firstName} ${user?.userData.lastName}`;
  const userEmail = user?.email;
  const hideMobileMenu = useMediaQuery(theme.breakpoints.up('lg'));

  const handleMenuToggle = () => setIsOpen(!isOpen);

  const MobileMenuNavItem = ({ nav, index }) => {
    let mobileIcon;

    if (nav.mobileIcon) {
      const MobileIcon = nav.mobileIcon;
      mobileIcon = <MobileIcon />;
    }

    // Collapsible submenu
    if (nav.children && nav.children.length) {
      return <ListItemToggle key={`nav-${index}`} title={nav.title} slug={schoolSlug} nav={nav.children} mobileIcon={mobileIcon} onClose={handleMenuToggle} />;
    }

    // External link
    if (nav.external) {
      return (
        <ListItemButton key={`nav-${index}`} component="a" href={nav.url} target="_blank" rel="noreferrer">
          <ListItemIcon>{mobileIcon}</ListItemIcon>
          <ListItemText primary={nav.title} />
        </ListItemButton>
      );
    }

    // Default menu item
    return (
      <ListItemButton key={`nav-${index}`} component={Link} to={`/${schoolSlug}/${nav.url}`} onClick={handleMenuToggle}>
        <ListItemIcon>{mobileIcon}</ListItemIcon>
        <ListItemText primary={nav.title} />
      </ListItemButton>
    );
  };

  const renderNavItems = () => {
    let mainNavItems = mainNav || [];

    if (!isAuthenticated && !hideLogin) {
      mainNavItems = [
        {
          title: 'Login',
          url: 'login',
        },
      ];
    }

    return mainNavItems
      .filter((n) => !n.hideOnMobile)
      .map((nav, index) => {
        const navItem = <MobileMenuNavItem key={`nav-${index}`} nav={nav} index={index} />;

        if (nav.url !== 'login') {
          return (
            <HasAccess key={`nav-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
              <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
                {navItem}
              </ProductsAccess>
            </HasAccess>
          );
        }

        return navItem;
      });
  };

  return (
    <>
      {!hideMobileMenu && (
        <IconButton onClick={handleMenuToggle}>
          <MenuIcon />
        </IconButton>
      )}
      <Drawer anchor="right" open={isOpen} onClose={handleMenuToggle}>
        <Box>
          <MobileMenuHeader onClose={handleMenuToggle} />
          <Stack direction="row" width="100%" p={1} pl={2} alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <ProfileAvatar />
              {isAuthenticated && (
                <Stack>
                  <Typography variant="subtitle1" fontSize="14px" fontWeight="700">
                    {userName}
                  </Typography>
                  <Typography variant="subtitle2" fontSize="12px">
                    {userEmail}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <BusinessToggles>
              <HasFeature name={[FLAG_LANGUAGE_ASSIST]}>{localeChooser}</HasFeature>
            </BusinessToggles>
          </Stack>
          <List
            component="nav"
            sx={{
              width: '320px',
              borderTop: 'solid 1px #c9c6c6',
              borderBottom: 'solid 1px #c9c6c6',
              mb: 2,
            }}
          >
            {renderNavItems()}
          </List>
          {isAuthenticated && (
            <LoadingButton
              variant="outlined"
              loading={isLoading}
              onClick={() => {
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
              sx={{ ml: 2 }}
            >
              Logout
            </LoadingButton>
          )}
        </Box>
      </Drawer>
    </>
  );
};
