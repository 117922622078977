import { App } from 'app/App';
import { AppProvider } from 'app/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from 'auth0/Auth0ProviderWithNavigate';
import { CountriesProvider } from 'app/CountriesContext';
import { VersionCheckerModal } from 'app/VersionCheckerModal';
import { PageTitleProvider } from 'app/PageTitleContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <PageTitleProvider>
        <Auth0ProviderWithNavigate>
          <AppProvider>
            <CountriesProvider>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </CountriesProvider>
          </AppProvider>
          <VersionCheckerModal />
        </Auth0ProviderWithNavigate>
      </PageTitleProvider>
    </BrowserRouter>
  );
};
