import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import applyAPI from 'app/api/applyAPI';
import { getConfig } from '../../utils/config';

export function filter(filter) {
  return {
    type: ActionTypes.APPLICATIONS_FILTER,
    filter,
  };
}

export const resetSearchForLeadsByApplicant = () => (dispatch) =>
  dispatch({
    type: ActionTypes.APPLICATION_SEARCH_LEADS_IDLE,
  });

export function searchForLeadsByApplicant(id, findFirst, slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.APPLICATION_SEARCH_LEADS,
    });

    applyAPI
      .GET({
        params: {
          slug,
          id,
          type: 'suggested-leads',
        },
      })
      .then((response) => {
        if (response.status.code === 200 || response.status.code === 201) {
          dispatch({
            type: ActionTypes.APPLICATION_SEARCH_LEADS_RESPONSE,
            payload: {
              success: true,
              leads: response.entity.data,
              findFirst,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.APPLICATION_SEARCH_LEADS_RESPONSE,
            payload: {
              success: false,
            },
          });

          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        ErrorActions.show(error)(dispatch);
      });
  };
}

export function linkLeadWithApplication(applicationId, leadId, slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.APPLICATION_LINK_LEAD,
    });

    applyAPI
      .PUT({
        params: {
          slug,
          id: applicationId,
          type: 'lead',
        },
        entity: {
          lead_id: leadId,
        },
      })
      .then((response) => {
        if (response.status.code === 200 || response.status.code === 201) {
          dispatch({
            type: ActionTypes.APPLICATION_LINK_LEAD_RESPONSE,
            payload: {
              success: true,
              application: response.entity.data,
              message: response.entity.message,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.APPLICATION_LINK_LEAD_RESPONSE,
            payload: {
              success: false,
            },
          });

          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        ErrorActions.show(error)(dispatch);
      });
  };
}

export function selectLeadToLink(id) {
  return {
    type: ActionTypes.APPLICATION_SELECT_LEAD_LINK,
    id,
  };
}

export function transferOwnership(applicantId, loadApplicant, agent, slug) {
  return function (dispatch) {
    const { apiUrl } = getConfig();

    dispatch({
      type: ActionTypes.APPLICATION_TRANSFER_OWNERSHIP,
    });

    applyAPI
      .PUT({
        path: `${apiUrl}/schools/{slug}/applicants{/id}{/type}`,
        params: {
          slug,
          id: applicantId,
          type: 'owner',
        },
        entity: {
          agent_id: agent.id,
        },
      })
      .then((response) => {
        if (response.status.code === 200 || response.status.code === 201) {
          loadApplicant();

          dispatch({
            type: ActionTypes.APPLICATION_TRANSFER_OWNERSHIP_RESPONSE,
            payload: {
              success: true,
              message: response.entity.message,
              applicationCreatedBy: response.entity.data.created_by,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.APPLICATION_TRANSFER_OWNERSHIP_RESPONSE,
            payload: {
              success: false,
            },
          });

          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.APPLICATION_TRANSFER_OWNERSHIP_RESPONSE,
          payload: {
            success: false,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}
