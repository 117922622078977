import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer } from 'react';
import { AsyncStatus } from 'types/AsyncStatus';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { ApiGetPermissions } from 'types/api/ApiPermissions';
import { Typography } from '@mui/material';
import Loader from 'components/Loader';
import { useAuth0 } from '@auth0/auth0-react';

type PermissionAction =
  | { type: 'start permission read' }
  | {
      type: 'finish permission read';
      permissions: string[];
      roles: string[];
      groups: string[];
    }
  | { type: 'fail permission read'; error: unknown };
type PermissionState = {
  status: AsyncStatus;
  permissions: string[];
  roles: string[];
  groups: string[];
};
interface PermissionContextData {
  hasPermission: (permissions: string[]) => boolean;
  hasRole: (permissions: string[]) => boolean;
  hasGroup: (permissions: string[]) => boolean;
}

export const PermissionContext = createContext<PermissionContextData | undefined>(undefined);

const permissionReducer = (state: PermissionState, action: PermissionAction): PermissionState => {
  switch (action.type) {
    case 'start permission read':
      return { ...state, status: 'pending' };
    case 'finish permission read':
      return {
        status: 'success',
        permissions: action.permissions,
        roles: action.roles,
        groups: action.groups,
      };
    case 'fail permission read':
      console.error(action.error);
      return {
        status: 'error',
        permissions: [],
        roles: [],
        groups: [],
      };
  }
};

export const PermissionProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(permissionReducer, {
    status: 'idle',
    permissions: [],
    roles: [],
    groups: [],
  });
  const { isLoading, isAuthenticated } = useAuth0();
  const getPermissions = useStatelessGet<ResponseEnvelope<ApiGetPermissions>>('/user-permissions');

  const loadPermissions = useCallback(async () => {
    // if Auth0 is loading don't try to request permissions
    if (isLoading) return;

    // if not authenticated then set permissions to empty for public routes
    if (!isAuthenticated) {
      dispatch({
        type: 'finish permission read',
        permissions: [],
        roles: [],
        groups: [],
      });
      return;
    }

    dispatch({ type: 'start permission read' });

    try {
      const response = await getPermissions();
      dispatch({
        type: 'finish permission read',
        permissions: response.data.permissions,
        roles: response.data.roles,
        groups: response.data.groups,
      });
    } catch (error) {
      dispatch({ type: 'fail permission read', error });
    }
  }, [getPermissions, isAuthenticated, isLoading]);

  const hasPermission = useCallback(
    (permissions: string[]) => {
      return permissions.some((permission) => state.permissions.includes(permission));
    },
    [state.permissions],
  );

  const hasRole = useCallback(
    (roles: string[]) => {
      return roles.some((role) => state.roles.includes(role));
    },
    [state.roles],
  );

  const hasGroup = useCallback(
    (groups: string[]) => {
      return groups.some((group) => state.groups.includes(group));
    },
    [state.groups],
  );

  useEffect(() => {
    loadPermissions();
  }, [loadPermissions]);

  if (state.status === 'error') {
    return <Typography color="error">Could not load permissions</Typography>;
  }

  if (state.status === 'pending' || isLoading) {
    return <Loader center />;
  }

  return <PermissionContext.Provider value={{ hasPermission, hasRole, hasGroup }}>{children}</PermissionContext.Provider>;
};

export const usePermissions = () => {
  const context = useContext(PermissionContext);

  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }

  return context;
};
