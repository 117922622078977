import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HasAccess from '../permissions/HasAccess';
import { PERMISSION_ADD_NOTES } from '../permissions/constants/Roles';
import { useCallback, useEffect, useState } from 'react';
import { AddNoteDialog } from '../notes/AddNoteDialog';
import { useParams } from 'react-router-dom';
import { ApplicantRouteParams } from '../components/Workflow/Workflow';
import EmptyState from '../components/Profile/EmptyState';
import { useGet } from '../hooks/useGet';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import Loader from '../components/Loader';
import { useNotificationMessages } from '../hooks/useNotificationMessages';
import { getErrorMessage } from '../utils/errors';
import { NewNote } from '../notes/NewNote';

const NotesList = ({ notes, onUpdate, recipient, recipientId }) => {
  if (!notes.length) return <EmptyState nounSingular="note" nounPlural="notes" />;

  return (
    <Stack direction="column" alignItems="center" sx={{ width: '100%' }}>
      {notes.map((note, index) => (
        <NewNote key={note.id} {...note} {...{ index, onUpdate, recipient, recipientId }} />
      ))}
    </Stack>
  );
};

export const ApplicationNotes = ({ onToggle, recipient, recipientId, dialogTitle }) => {
  const { slug: schoolSlug } = useParams() as ApplicantRouteParams;
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, getNotes] = useGet<ResponseEnvelope<any>>(`/schools/${schoolSlug}/${recipient}/${recipientId}/notes`);
  const { showErrorMessage } = useNotificationMessages();

  const fetchNotes = useCallback(async () => {
    try {
      const response = await getNotes();
      setNotes(response.data);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getNotes, showErrorMessage]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            {dialogTitle}
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {loading ? <Loader center /> : <NotesList {...{ onUpdate: fetchNotes, notes, recipient, recipientId }} />}
        <AddNoteDialog
          recipient={recipient}
          id={recipientId}
          active={showAddNoteDialog}
          onToggle={() => {
            setShowAddNoteDialog(false);
          }}
          withReminderDate
          withTask
        />
        <DialogActions>
          <HasAccess for="permission" name={[PERMISSION_ADD_NOTES]}>
            <Button onClick={() => setShowAddNoteDialog(true)}>Add note</Button>
          </HasAccess>
          <Button variant="contained" onClick={onToggle}>
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};
