import { createSelector } from 'reselect';
import { asc, desc } from 'utils/sort';
import { getReminderStatus } from 'utils/getReminderStatus';

const enquiriesSelector = (state, props) => {
  if (props && Object.prototype.hasOwnProperty.call(props, 'isInactive')) {
    if (props.isInactive) {
      return state.enquiries.enquiries.filter((enquiry) => {
        return !enquiry.status;
      });
    } else {
      return state.enquiries.enquiries.filter((enquiry) => {
        return enquiry.status;
      });
    }
  }

  return state.enquiries.enquiries;
};

const sortField = (state) => state.enquiries.sortField;
const sortOrder = (state) => state.enquiries.sortOrder;

/**
 * Sorted enquiries
 * @param  {Function} enquiriesSelector Function that returns all enquiries
 * @param  {String}   sortField         Field to sort by
 * @param  {String}   sortOrder         Sort order: asc|desc
 * @return {Array}                      Sorted array of Enquiries
 */
export const sortedEnquiries = createSelector(enquiriesSelector, sortField, sortOrder, (enquiries, sortField, sortOrder) => {
  const sortFunc = sortOrder === 'asc' ? asc(sortField) : desc(sortField);

  return enquiries.sort(sortFunc);
});

/**
 * Get all due or overdue Enquiries
 * @param  {Function} sortedEnquiries   Function that returns all enquiries
 * @return {Array}                      Enquiries with due or overdue statuses
 */
export const reminders = createSelector(sortedEnquiries, (enquiries) => {
  return enquiries
    .filter((enquiry) => {
      return !!enquiry.lead_state;
    })
    .map((enquiry) => {
      const reminderStatus = getReminderStatus({
        reminder: enquiry.lead_state.due_since,
        status: enquiry.lead_state.label,
      });

      return {
        id: enquiry.id,
        name: `${enquiry.first_name} ${enquiry.last_name}`,
        role: enquiry.role_type,
        due: reminderStatus.label,
        status: reminderStatus.status,
        statusColour: reminderStatus.color,
      };
    });
});
