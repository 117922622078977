import { differenceInDays, formatDistance, parseISO } from 'date-fns';
import theme from 'config/theme';
import { DUE_DAYS, OVERDUE_DAYS } from 'app/constants/Thresholds';

export default function getApplicationStatus(permanence) {
  if (!permanence) {
    return {
      label: '-',
      color: '#9E9E9E',
    };
  }

  const diff = differenceInDays(new Date(), parseISO(permanence));
  let color = '#9E9E9E';
  let label = formatDistance(new Date(), parseISO(permanence));

  if (diff >= OVERDUE_DAYS) {
    color = theme.overdue;
    label = formatDistance(new Date(), parseISO(permanence));
    label = label === 'a day' ? '1 day' : label;
  } else if (diff > DUE_DAYS && diff < OVERDUE_DAYS) {
    color = theme.due;
    label = formatDistance(new Date(), parseISO(permanence));
    label = label === 'a day' ? '1 day' : label;
  } else if (diff === 0) {
    label = 'today';
  }

  return {
    label,
    color,
  };
}
