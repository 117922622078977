import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Currency } from 'currency/components/Currency';
import { Link, Stack, Typography } from '@mui/material';

export const BankTransfer = ({
  amount,
  invoiceNumber,
  account_name,
  account_number,
  branch_number,
  bank_name,
  bank_account_address,
  swift_code,
  sharedPayment,
  backURL,
}) => {
  return (
    <Stack>
      <Typography className="type--leadin">
        Please pay{' '}
        <strong>
          <Currency amount={amount} />
        </strong>{' '}
        in to our school bank account below:
      </Typography>
      <Typography>
        It is very important that you include the invoice number <strong>{invoiceNumber}</strong> as a reference when you make the payment.
      </Typography>
      <Stack my={2} gap={1}>
        <Typography>
          <strong>Account name: </strong>
          {account_name}
        </Typography>
        <Typography>
          <strong>Account number: </strong>
          {account_number}
        </Typography>
        {branch_number && (
          <Typography>
            <strong>Branch number: </strong>
            {branch_number}
          </Typography>
        )}
        <Typography>
          <strong>Bank name: </strong>
          {bank_name}
        </Typography>
        <Typography>
          <strong>Bank account address: </strong>
          {bank_account_address}
        </Typography>
        <Typography>
          <strong>SWIFT code: </strong>
          {swift_code}
        </Typography>
      </Stack>
      <Typography>Once we have confirmed payment, we will issue you with the documents that are required for the Visa application.</Typography>
      <Typography>Thank you.</Typography>
      {sharedPayment && (
        <Link href={backURL} sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <ArrowBackIosIcon color="primary" sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }} /> Back
        </Link>
      )}
    </Stack>
  );
};
