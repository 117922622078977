import { createContext, useContext, useState, useEffect } from 'react';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { Country } from 'app/constants/countries';

const CountriesContext = createContext<Country[]>([]);

export const CountriesProvider = ({ children }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const getCountries = useStatelessGet<ResponseEnvelope<Country[]>>(`/countries`, {
    skipAuth: true,
  });

  const sortNameAlphabetic = (a: Country, b: Country) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

  useEffect(() => {
    getCountries().then((response) => {
      setCountries(response.data.sort(sortNameAlphabetic));
    });
  }, [getCountries]);

  return <CountriesContext.Provider value={countries}>{children}</CountriesContext.Provider>;
};

export const useCountries = () => {
  const context = useContext(CountriesContext);

  if (context === undefined) {
    throw new Error('useCountries was used outside of its Provider');
  }
  return context;
};
