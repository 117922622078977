import * as types from 'app/constants/ActionTypes';

export const agentsInitialState = {
  agents: [],
  agentsStatus: 'default',
  agentsTotal: 0,
  agentsEditStatus: 'default',
  agentsFetchStatus: 'default',
  agentsEditMessage: null,
  agentsDeleteStatus: 'default',
  agentsDeleteMessage: null,
  agentsEnableStatus: 'default',
  agentsSearchStatus: 'default',
  agentsSortField: 'name',
  agentsSortOrder: 'asc',
  agentsAgentDetails: null,
  agentsCurrentName: '',
  // Invitations
  invitations: [],
  invitationsStatus: 'default',
  invitationsTotal: 0,
  invitationsSortField: 'name',
  invitationsSortOrder: 'asc',
  invitationsAgentDetails: null,
  invitationsCurrentName: null,
};

export const agentsReducer = (state = agentsInitialState, action) => {
  switch (action.type) {
    case types.AGENTS_GET:
      return {
        ...state,
        agentsEditStatus: 'default',
        agentsDeleteStatus: 'default',
        agentsEnableStatus: 'default',
        agentsEditMessage: null,
        agentsStatus: 'loading',
      };
    case types.AGENTS_GET_RESPONSE: {
      const agents = action.payload.agents;

      return {
        ...state,
        agentsStatus: 'default',
        agents,
      };
    }
    case types.INVITATIONS_GET:
      return {
        ...state,
        invitationsStatus: 'loading',
      };
    case types.INVITATIONS_GET_RESPONSE:
      return {
        ...state,
        invitationsStatus: 'default',
        invitations: action.payload.invitations,
      };
    case types.INVITATIONS_EXPIRE:
      return {
        ...state,
        expireInvitationStatus: 'loading',
      };
    case types.INVITATIONS_EXPIRE_RESPONSE: {
      const expireInvitationState = { ...state };

      if (action.payload.success) {
        // @ts-expect-error TS(2339): Property 'expireInvitationStatus' does not exist o... Remove this comment to see the full error message
        expireInvitationState.expireInvitationStatus = 'success';
      } else {
        // @ts-expect-error TS(2339): Property 'expireInvitationStatus' does not exist o... Remove this comment to see the full error message
        expireInvitationState.expireInvitationStatus = 'error';
      }

      return {
        ...expireInvitationState,
      };
    }
    case types.AGENTS_ADD:
      return {
        ...state,
        sendInvitationStatus: 'loading',
      };
    case types.AGENTS_ADD_RESPONSE: {
      const addAgentState = { ...state };

      if (action.payload.success) {
        // @ts-expect-error TS(2339): Property 'sendInvitationStatus' does not exist on ... Remove this comment to see the full error message
        addAgentState.sendInvitationStatus = 'success';
        // @ts-expect-error TS(2339): Property 'sendInvitationMessage' does not exist on... Remove this comment to see the full error message
        addAgentState.sendInvitationMessage = action.payload.message;
      } else {
        // @ts-expect-error TS(2339): Property 'sendInvitationStatus' does not exist on ... Remove this comment to see the full error message
        addAgentState.sendInvitationStatus = 'error';
        // @ts-expect-error TS(2339): Property 'sendInvitationMessage' does not exist on... Remove this comment to see the full error message
        addAgentState.sendInvitationMessage = 'Error';
      }

      return {
        ...addAgentState,
      };
    }
    case types.INVITATIONS_SORT:
      return {
        ...state,
        invitationsSortField: action.field,
        invitationsSortOrder: action.order,
      };
    default:
      return {
        ...state,
      };
  }
};
