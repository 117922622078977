import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Params, Route, Routes, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import { FetchSchool } from 'app/FetchSchool';
import { useIntercom } from 'hooks/analytics/useIntercom';

const SharedInvoice = React.lazy(() => import(/* webpackChunkName: 'sharedinvoice' */ 'views/Shared/SharedInvoice'));
const SharedInvoicePayment = React.lazy(() => import(/* webpackChunkName: 'sharedinvoice' */ 'views/Shared/SharedInvoicePayment'));

const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

export interface SharedInvoiceRouteParams extends Params {
  slug: string;
  id: string;
}

const SharedSchoolRoutes = () => {
  return (
    <FetchSchool>
      <Routes>
        <Route path="student-invoice/:id" element={<SharedInvoice />} />
        <Route path="student-invoice/:id/payment" element={<SharedInvoicePayment />} />
      </Routes>
    </FetchSchool>
  );
};

export const SharedApp = () => {
  const location = useLocation();
  useIntercom();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Flex key="flex">
      <React.Suspense fallback={<Loader center />}>
        <Routes location={location}>
          <Route path=":slug/*" element={<SharedSchoolRoutes />} />
        </Routes>
      </React.Suspense>
    </Flex>
  );
};

export default SharedApp;
