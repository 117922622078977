import { ReactElement, useContext, useEffect, useMemo } from 'react';
import TextField from '../FormBuilder/fields/TextField';
import CheckboxField from '../FormBuilder/fields/CheckboxField';
import CheckboxsetField from '../FormBuilder/fields/CheckboxsetField';
import SchoolSubjectsSelectorField from '../FormBuilder/fields/SchoolSubjectsSelectorField';
import FileField from '../FormBuilder/fields/FileField';
import MultiLevelMultiSelectField from '../FormBuilder/fields/MultiLevelMultiSelectField/MultiLevelMultiSelectField';
import MegaSelectionMenuField from '../FormBuilder/fields/MegaSelectionMenuField';
import NumberField from '../FormBuilder/fields/NumberField';
import DatePickerField from '../FormBuilder/fields/DatePickerField';
import EmailField from '../FormBuilder/fields/EmailField';
import StandardPhoneNumberField from '../FormBuilder/fields/StandardPhoneNumberField';
import TextareaField from '../FormBuilder/fields/TextareaField';
import ExternalDropdownField from '../FormBuilder/fields/ExternalDropdownField';
import DropdownField from '../FormBuilder/fields/DropdownField';
import RadioField from '../FormBuilder/fields/RadioField';
import DocumentsField from '../FormBuilder/fields/DocumentsField';
import { getValidationRules } from './fields/getValidationRules';
import { useAppSelector } from 'app/hooks';
import { useCountries } from 'app/CountriesContext';
import { Control, FieldError, useController, UseFormTrigger, useWatch } from 'react-hook-form';
import { newMultiOptions } from 'forms/consumer/utils/newMultiOptions';
import CountryAndTimezoneField from '../FormBuilder/fields/CountryAndTimezoneField';
import PhoneNumberField from '../FormBuilder/fields/PhoneNumberField';
import CountryField from '../FormBuilder/fields/CountryField';
import LocalTimeField from '../FormBuilder/fields/LocalTimeField';
import SelectLocalTimeField from '../FormBuilder/fields/SelectLocalTimeField';
import { slugify } from 'utils/slugify';
import { convertTZ } from 'utils/getLocalTime';
import spacetime from 'spacetime';
import { Alert, Divider, Typography } from '@mui/material';
import TemplateDisplayField from '../FormBuilder/fields/TemplateDisplayField';
import TimeField from '../FormBuilder/fields/TimeField';
import MonthAndYearField from '../FormBuilder/fields/MonthAndYearField';
import StartAndEndDateField from '../FormBuilder/fields/StartAndEndDateField';
import ConditionsCheckboxsetField from '../FormBuilder/fields/ConditionsCheckboxsetField';
import PasswordField from '../FormBuilder/fields/PasswordField';
import AutocompleteField from '../FormBuilder/fields/AutocompleteField';
import ContentField from '../FormBuilder/fields/ContentField';
import TimezoneField from '../FormBuilder/fields/TimezoneField';
import ApplicantDetailsField from '../FormBuilder/fields/ApplicantDetailsField';
import ApiJsonField from '../FormBuilder/fields/ApiJsonField';
import InvoiceField from '../FormBuilder/fields/InvoiceField';
import CreateInvoiceField from '../FormBuilder/fields/CreateInvoiceField';
import AccountSummaryField from '../FormBuilder/fields/AccountSummaryField';
import { useApplication } from '../../contexts/ApplicationContext';
import PaymentOptionsField from '../FormBuilder/fields/PaymentOptionsField';
import PaymentField from '../FormBuilder/fields/PaymentField';
import CustomFieldsPlaceholderField from '../FormBuilder/fields/CustomFieldsPlaceholderField';
import StepListField from '../FormBuilder/fields/StepListField';
import StepSummaryField from '../FormBuilder/fields/StepSummaryField';
import { FormBuilderField } from './types/FormBuilderField';
import { format } from 'date-fns';
import { DATE_DASH_FORMAT } from '../../app/constants/DateFormats';
import { evaluateDisplayConditions } from './utils/evaluateDisplayConditions';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from '../../utils/user';
import { useApplicant } from '../../contexts/ApplicantContext';
import { ApiFormBuilderField } from './types/api/ApiFormField';
import LangContext from '../../app/LangContext';
import { ReviewStepField } from './fields/ReviewStepField';
import TabsField from './fields/TabsField';
import PlacesAutocompleteField from './fields/PlacesAutocompleteField';
import ToggleButtonField from './fields/ToggleButtonField';
import ChipsBoxField from './fields/ChipsBoxField';

const getDatepickerProps = (options: any[]) => {
  const hasOptions = options.length;
  const min = hasOptions && options[0] ? options[0] : '';
  const minPeriod = hasOptions && options[1] ? options[1] : '';
  const minDirection = hasOptions && options[2] ? options[2] : '';
  const max = hasOptions && options[3] ? options[3] : '';
  const maxPeriod = hasOptions && options[4] ? options[4] : '';
  const maxDirection = hasOptions && options[5] ? options[5] : '';
  const message = hasOptions && options[6] ? options[6] : null;
  const messageComparison = hasOptions && options[7] ? options[7] : null;
  const messageAmount = hasOptions && options[8] ? parseInt(options[8], 10) : null;
  const messagePeriod = hasOptions && options[9] ? options[9] : null;
  const messageIsAfter = hasOptions && options[10] ? options[10] === 'after' : false;

  return {
    min,
    minPeriod,
    minDirection,
    max,
    maxPeriod,
    maxDirection,
    message,
    messageComparison,
    messageAmount,
    messagePeriod,
    messageIsAfter,
  };
};

const getLocalTimeLabel = (option: string[], fieldsMap: Map<string, ApiFormBuilderField>, name: string): ReactElement => {
  const field = fieldsMap.get(name)!;

  if (!option || !option.length) {
    return <></>;
  }

  const date = option[0] ? format(option[0], DATE_DASH_FORMAT) : '';
  const time = option[1];
  const timezone = option[2];
  const countryAndTimezone = fieldsMap.get(field.options?.[0])?.value;
  const studentTZ = countryAndTimezone ? countryAndTimezone[1] : '';

  return (
    <>
      {convertTZ(`${date} ${time}`, timezone, studentTZ)}
      <Typography>({studentTZ || spacetime.now().timezone().name})</Typography>
    </>
  );
};

const RenderedFormField = ({
  field,
  baseFieldProps,
  formValues,
  countries,
  multiOptions,
  fieldsMap,
  fields,
  schoolTimezone,
  label,
  control,
  validationRules,
  disabled,
  readOnly,
  fieldValue,
  onChange,
}) => {
  switch (field.type) {
    case 'checkbox':
      return <CheckboxField {...baseFieldProps} />;
    case 'text':
      return <TextField {...baseFieldProps} />;
    case 'number':
      return <NumberField {...baseFieldProps} min={field.options?.[0]} max={field.options?.[1]} />;
    case 'datepicker': {
      const datePickerProps = getDatepickerProps(field.options!);
      return <DatePickerField {...baseFieldProps} {...datePickerProps} />;
    }
    case 'email':
      return <EmailField {...baseFieldProps} />;
    case 'standardphonenumber': {
      const countryFieldId = field.options?.[0];
      const country = Array.isArray(formValues[countryFieldId]) ? formValues[countryFieldId][0] : formValues[countryFieldId];
      const defaultCountry = country ? countries.find((c) => c.id === country)?.iso_3166_2 : '';
      return <StandardPhoneNumberField {...baseFieldProps} defaultCountry={defaultCountry} />;
    }
    case 'textarea':
      return <TextareaField {...baseFieldProps} />;
    case 'externaldropdown':
      return <ExternalDropdownField {...baseFieldProps} sourceUrl={field.options?.[0]} />;
    case 'dropdown': {
      return <DropdownField {...baseFieldProps} options={multiOptions} />;
    }
    case 'checkboxset': {
      return <CheckboxsetField {...baseFieldProps} options={multiOptions} />;
    }
    case 'radiogroup': {
      return <RadioField {...baseFieldProps} options={multiOptions} direction={field.options[1]} />;
    }
    case 'reviewstep':
      return <ReviewStepField step={field.options[0]} control={control} value={fieldValue} onChange={onChange} />;
    case 'schoolsubjectsselector': {
      return <SchoolSubjectsSelectorField {...baseFieldProps} options={multiOptions} />;
    }
    case 'file': {
      return (
        <FileField
          {...baseFieldProps}
          id={field.id}
          allowMultiple={field.options?.[0]}
          acceptedMimeTypes={field.options?.[1]}
          displayWidth={field.displayWidth}
        />
      );
    }
    case 'multilevelmultiselect': {
      return <MultiLevelMultiSelectField {...baseFieldProps} sourceUrl={field.options?.[0]} />;
    }
    case 'megaselectionmenu': {
      return <MegaSelectionMenuField {...baseFieldProps} sourceUrl={field.options?.[0]} limitation={field.options?.[1]} />;
    }
    case 'documents':
      return <DocumentsField label={label} options={field.options || []} />;
    case 'countryandtimezone':
      return <CountryAndTimezoneField {...baseFieldProps} />;
    case 'tel':
      return <PhoneNumberField {...baseFieldProps} />;
    case 'country':
      return <CountryField {...baseFieldProps} />;
    case 'localtime': {
      let studentTimezone = '';
      const referenceId = field.options?.[0];
      if (referenceId && fieldsMap.has(referenceId) && fieldsMap.get(referenceId)!.value?.length) {
        studentTimezone = fieldsMap.get(referenceId)!.value![1];
      }
      return <LocalTimeField {...baseFieldProps} {...{ schoolTimezone, studentTimezone }} />;
    }
    case 'selectlocaltime': {
      const referencedFields: string[] =
        field.options?.filter((option) => {
          if (!fieldsMap.has(option)) return false;
          const opt = fieldsMap.get(option)!;
          return Boolean(opt.slug && opt.value?.length && !opt.value?.includes(null));
        }) || [];
      const options = referencedFields.map((option) => {
        const opt = fieldsMap.get(option)!;
        const formValue = [...opt.value!];
        if (formValue[0] !== null && (formValue[0] as any) instanceof Date) {
          formValue[0] = format(formValue[0], DATE_DASH_FORMAT);
        }
        return {
          key: slugify(formValue.join('-')),
          label: getLocalTimeLabel(opt.value as any, fieldsMap, option),
          value: `${formValue}`,
        };
      });
      return <SelectLocalTimeField {...baseFieldProps} options={options} />;
    }
    case 'templatedisplay': {
      return <TemplateDisplayField fields={fields} fieldsMap={fieldsMap} control={control} mapperGroup={field.options?.[0]} />;
    }
    case 'time':
      return <TimeField {...baseFieldProps} />;
    case 'timezone':
      return <TimezoneField {...baseFieldProps} />;
    case 'monthyear':
      return <MonthAndYearField {...baseFieldProps} />;
    case 'startandenddate':
      return <StartAndEndDateField {...baseFieldProps} />;
    case 'conditionscheckboxset':
      return <ConditionsCheckboxsetField {...baseFieldProps} options={multiOptions} />;
    case 'password':
      return <PasswordField {...baseFieldProps} />;
    case 'autocomplete':
      return <AutocompleteField {...baseFieldProps} options={multiOptions} />;
    case 'content':
      return <ContentField value={field.value || field.options?.[0]} />;
    case 'applicantdetails':
      return <ApplicantDetailsField />;
    case 'apijson':
      return <ApiJsonField {...baseFieldProps} />;
    case 'invoice':
      return <InvoiceField label={field.label} canAddPayments={field.options?.[1]} invoiceId={fieldsMap.get(field.options?.[0])!.value!} />;
    case 'createinvoice':
      return (
        <CreateInvoiceField
          control={control}
          validationRules={validationRules}
          label={label}
          name={field.slug}
          disabled={disabled}
          invoiceId={field.options?.[0]}
          readOnly={readOnly}
        />
      );
    case 'accountsummary':
      return (
        <AccountSummaryField
          control={control}
          validationRules={validationRules}
          label={label}
          name={field.slug}
          disabled={disabled}
          readOnly={readOnly}
          invoiceId={fieldsMap.get(field.options?.[0])!.value!}
        />
      );
    case 'paymentoptions':
      return <PaymentOptionsField value={fieldValue} onChange={onChange} invoiceId={fieldsMap.get(field.options?.[0])!.value!} readOnly={readOnly} />;
    case 'payment': {
      const paymentOptionFieldId = field.options?.[0];
      const invoiceFieldId = fieldsMap.get(paymentOptionFieldId)!.options?.[0];
      const invoiceId = fieldsMap.get(invoiceFieldId)!.value!;
      const selectedPaymentMethodId = fieldsMap.get(paymentOptionFieldId)!.value!;

      return <PaymentField invoiceId={invoiceId} selectedPaymentMethodId={selectedPaymentMethodId} />;
    }
    case 'customfieldsplaceholder':
      return <CustomFieldsPlaceholderField control={control} label={label} disabled={disabled} validationRules={validationRules} readOnly={readOnly} />;
    case 'steplist':
      return <StepListField />;
    case 'separator':
      return <Divider data-cy="separator" sx={{ mb: 5 }} />;
    case 'stepsummary':
      // TODO: remove filter when data and workflow management no longer sends `null`s in options
      return <StepSummaryField steps={field.options?.filter((f) => f !== null) || []} control={control} />;
    case 'tabs':
      const tabQuantityFieldSlug = field.options?.[0];
      const tabQuantity = formValues[tabQuantityFieldSlug] ?? 0;
      return <TabsField {...baseFieldProps} tabQuantity={tabQuantity} tabsLabel={field.options?.[1]} />;
    case 'placesautocomplete':
      return <PlacesAutocompleteField {...baseFieldProps} />;
    case 'togglebutton':
      return <ToggleButtonField {...baseFieldProps} />;
    case 'infobox':
      return (
        <Alert data-cy="infobox" variant="outlined" severity={field.options[1]}>
          <div
            dangerouslySetInnerHTML={{
              __html: field.options[0],
            }}
          ></div>
        </Alert>
      );
    case 'chipsbox':
      return <ChipsBoxField {...baseFieldProps} />;
    default:
      return <></>;
  }
};

interface NewFieldProps {
  field: FormBuilderField;
  fields: FormBuilderField[];
  control: Control;
  disabled?: boolean;
  readOnly?: boolean;
  value?: any;
  trigger?: UseFormTrigger<Record<string, any>>;
  fieldWrapper?: (children: ReactElement) => ReactElement;
}

export const FormField = ({ field, control, fields, disabled = false, readOnly = false, value, trigger, fieldWrapper }: NewFieldProps): ReactElement => {
  const accommodationTypes = useAppSelector((state) => state.school.accommodation_types);
  const schoolTimezone = useAppSelector((state) => state.school.timezone);
  const countries = useCountries();
  const { content } = useContext(LangContext);
  const formValues: Record<string, any> = useWatch({ control });
  // TODO: move this
  const {
    state: { application },
  } = useApplication();
  const {
    state: { applicant },
  } = useApplicant();
  const { user } = useAuth0<AuthUser>();
  const school = useAppSelector((state) => state.school);

  const fieldsMap = useMemo(() => {
    const map = new Map<string, ApiFormBuilderField>();
    application?.workflow.stages.forEach((stage) => {
      stage.steps.forEach((step) => {
        step.fields.forEach((f) => map.set(f.id, f));
      });
    });

    return map;
  }, [application?.workflow.stages]);

  let multiOptions: { label: string; value: string }[] = [];
  if (['autocomplete', 'dropdown', 'checkboxset', 'conditionscheckboxset', 'schoolsubjectsselector'].includes(field.type)) {
    multiOptions = newMultiOptions(field.options, accommodationTypes, formValues);
  }

  if (field.type === 'radiogroup' && Array.isArray(field.options[0])) {
    multiOptions = newMultiOptions(field.options[0], accommodationTypes, formValues);
  } else if (field.type === 'radiogroup') {
    multiOptions = newMultiOptions(field.options, accommodationTypes, formValues);
  }

  let shouldDisplay = false;
  let conditionsError: string | false = false;
  try {
    shouldDisplay = evaluateDisplayConditions(field.slug, field.conditions, user, applicant, fieldsMap, formValues, school);
  } catch (error) {
    conditionsError = `${error}`;
  }

  // set disabled flag to remove from form submission
  field.disabled = !shouldDisplay || readOnly;

  const applicationFieldMessagesRules = field.applicationFieldMessages.map((fieldMessage) => ({
    type: 'changed',
    message: fieldMessage.message,
  }));

  const validationRules = field.disabled
    ? {}
    : getValidationRules(fieldsMap, [...field.validationRules, ...applicationFieldMessagesRules], field.label, field.value);

  // If applicationFieldMessages has a value, it means that we the user is filling a step that has previously been reviewed,
  // so we need to trigger validation errors on page load.
  useEffect(() => {
    if (field.applicationFieldMessages.length) {
      trigger?.(field.slug);
    }
  }, [field, trigger]);

  const {
    field: { value: controlledValue, onChange },
    formState: { errors },
  } = useController({
    name: field.slug,
    control,
    rules: validationRules,
    disabled: disabled || !shouldDisplay,
  });
  if (conditionsError) return <Typography color="error">{conditionsError}</Typography>;
  if (!shouldDisplay) return <></>;

  // If the value prop is set, we assume the component is uncontrolled.
  const fieldValue = value ?? controlledValue;
  const label = content(field.translationKey, {}, field.label);
  const baseFieldProps = {
    label,
    name: field.slug,
    disabled,
    readOnly,
    value: fieldValue,
    onChange,
    error: errors[field.slug] as FieldError,
  };

  const renderedField = (
    <RenderedFormField
      {...{
        field,
        baseFieldProps,
        formValues,
        countries,
        multiOptions,
        fieldsMap,
        fields,
        schoolTimezone,
        label,
        control,
        validationRules,
        disabled,
        readOnly,
        fieldValue,
        onChange,
      }}
    />
  );

  if (fieldWrapper) return fieldWrapper(renderedField);
  return <>{renderedField}</>;
};
