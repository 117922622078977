import * as React from 'react';
import styled from 'styled-components';
import content from 'utils/content';

const Inherit = styled.span`
  color: inherit;
  font-size: inherit;
  font-weight: inherit;

  a {
    color: ${(props) => props.theme.primary};
  }
`;

// hard to type Content properly for children and random props to pass to content()
interface ContentProps {
  [key: string]: any;
}

export const Content = ({ name, children, ...params }: ContentProps) => {
  const label = content(name, params);

  if (!label) {
    return <>{children}</>;
  }

  return <Inherit dangerouslySetInnerHTML={{ __html: label }} />;
};

export default Content;
