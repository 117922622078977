import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const api: any = {};
const { apiUrl } = getConfig();

const invitationsAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/invitations/{?active}`,
});

api.byToken = {
  GET: client.wrap(template).wrap(defaultRequest, {
    path: `${apiUrl}/invitations/{token}`,
    method: 'GET',
  }),
};

api.GET = invitationsAPI.wrap(defaultRequest, {
  method: 'GET',
});

api.POST = invitationsAPI.wrap(defaultRequest, {
  method: 'POST',
});

api.DELETE = invitationsAPI.wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/invitations/{invitation}/expire`,
  method: 'DELETE',
});

export default api;
