import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'app/PrivateRoute';
import HasAccess from 'permissions/HasAccess';
import { PERMISSION_CREATE_LEAD, PERMISSION_VIEW_LEAD } from 'permissions/constants/Roles';
import React from 'react';

const Enquiries = React.lazy(() => import(/* webpackChunkName: 'leads' */ 'views/Enquiries/Enquiries'));
const AddEnquiry = React.lazy(() => import(/* webpackChunkName: 'leads' */ 'views/Enquiries/AddEnquiry'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: 'errorpage' */ 'views/Error/ErrorPage'));

export const SchoolEnquiriesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HasAccess for="permission" name={[PERMISSION_VIEW_LEAD]} fallback={<ErrorPage />}>
              <Enquiries />
            </HasAccess>
          </PrivateRoute>
        }
      />
      <Route
        path="inactive"
        element={
          <PrivateRoute>
            <HasAccess for="permission" name={[PERMISSION_VIEW_LEAD]} fallback={<ErrorPage />}>
              <Enquiries />
            </HasAccess>
          </PrivateRoute>
        }
      />
      <Route
        path="add"
        element={
          <PrivateRoute>
            <HasAccess for="permission" name={[PERMISSION_CREATE_LEAD]} fallback={<ErrorPage />}>
              <AddEnquiry />
            </HasAccess>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
