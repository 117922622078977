import { useCallback, useEffect, useState } from 'react';
import invoiceDueDateOptions from 'utils/invoiceDueDateOptions';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Control, useController, useFormState } from 'react-hook-form';
import { Box, FormControl, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useGet } from 'hooks/useGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { cloneDeep } from 'lodash';
import { defaultTemplate } from 'app/reducers/financial';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { TemplateOptionsList } from '../../../invoices/TemplateOptionsList';
import { InvoiceTable } from '../../../invoices/InvoiceTable';
import { format, parse, parseISO } from 'date-fns';
import { DATE_DASH_FORMAT } from '../../../app/constants/DateFormats';
import { defaultTaxRateNz } from 'invoices/taxRates';

const reorder = (list: unknown[], startIndex: number, endIndex: number): unknown[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface CreateInvoiceFieldProps {
  control: Control;
  name: string;
  label: string;
  disabled: boolean;
  validationRules: ControllerProps['rules'];
  readOnly: boolean;
  invoiceId: string | null;
}

export const CreateInvoiceField = ({ control, invoiceId, name, label, disabled, validationRules, readOnly }: CreateInvoiceFieldProps) => {
  const { errors } = useFormState({ control });
  const [dueDateDropdown, setDueDateDropdown] = useState<string>('');
  const [invoice, setInvoice] = useState<any>();
  const [templates, setTemplates] = useState<Map<string, any>>(new Map());
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [invoiceLoading, getInvoice] = useGet<ResponseEnvelope<any>>(`/schools/${schoolSlug}/invoices/${invoiceId}`);
  const [templateLoading, getInvoiceTemplates] = useGet<ResponseEnvelope<any>>(`/schools/${schoolSlug}/invoice-templates`);
  const { showErrorMessage } = useNotificationMessages();
  const { field } = useController({
    control,
    name,
    rules: validationRules,
    disabled,
  });

  const fetchInvoice = useCallback(async () => {
    try {
      const response = await getInvoice();
      const dueDate = response.data.due_date ? parseISO(response.data.due_date) : null;
      if (dueDate) {
        const dueDateFormatted = format(dueDate, DATE_DASH_FORMAT);
        const isDropdownDate = invoiceDueDateOptions.find((item) => item.value === dueDateFormatted);
        setDueDateDropdown(isDropdownDate?.value || (dueDate ? 'custom' : ''));
      }
      field.onChange({ ...response.data, due_date: dueDate });
      setInvoice({ ...response.data, due_date: dueDate });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [field, getInvoice, showErrorMessage]);

  const fetchTemplates = useCallback(async () => {
    try {
      const response = await getInvoiceTemplates();
      const map = new Map<string, any>();
      response.data.forEach((template) => map.set(template.id, template));
      setTemplates(map);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getInvoiceTemplates, showErrorMessage]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    if (invoice !== undefined) return;

    if (invoiceId) {
      fetchInvoice();
    } else {
      const blankInvoice = {
        ...cloneDeep(defaultTemplate),
        invoice_number: '',
        due_date: null,
        notes: '',
        xero_url: '',
        payments: [],
      };
      setInvoice(blankInvoice);
      field.onChange(blankInvoice);
    }
  }, [fetchInvoice, field, invoice, invoiceId]);

  const onChangeDescription = (newValue: string, index: number) => {
    const items = cloneDeep(field.value.items);
    items[index].description = newValue;
    field.onChange({ ...field.value, items });
  };

  const onChangeAmount = (newValue: string, index: number) => {
    const items = cloneDeep(field.value.items);
    items[index].amount = newValue;
    field.onChange({ ...field.value, items });
  };

  const onChangeQuantity = (newValue: number, index: number) => {
    const items = cloneDeep(field.value.items);
    items[index].quantity = Math.max(newValue, 0);
    field.onChange({ ...field.value, items });
  };

  const onChangeTaxRate = (newValue: number, index: number) => {
    const items = cloneDeep(field.value.items);
    items[index].tax_rate = newValue;
    field.onChange({ ...field.value, items });
  };

  const onChangeXeroCode = (newValue: string, index: number) => {
    const items = cloneDeep(field.value.items);
    items[index].xero_id = newValue;
    field.onChange({ ...field.value, items });
  };

  const onRemoveRow = (index: number) => {
    const items = cloneDeep(field.value.items);
    items.splice(index, 1);
    field.onChange({ ...field.value, items });
  };

  const onChangeRowOrder = (oldIndex: number, newIndex: number) => {
    const items = reorder(cloneDeep(field.value.items), oldIndex, newIndex);
    field.onChange({ ...field.value, items });
  };

  const onAddRow = () => {
    const items = cloneDeep(field.value.items);
    items.push({
      id: `new-${field.value.items.length}`,
      description: '',
      amount: '',
      tax_rate: defaultTaxRateNz,
    });
    field.onChange({ ...field.value, items });
  };

  const onChangeTemplate = (templateId: string) => {
    if (templateId && !templates.has(templateId)) {
      console.error(`Template ${templateId} not found in ${JSON.stringify(templates.keys())}`);
    }

    let template = defaultTemplate;

    if (templateId) {
      template = templates.get(templateId);
    }

    field.onChange({ ...field.value, items: cloneDeep(template.items) });
  };

  if (invoice === undefined || invoiceLoading || templateLoading) {
    return <Loader small />;
  }

  return (
    <Box data-cy-field-type="create-invoice">
      <FormLabel>{label}</FormLabel>
      <FormControl fullWidth size="small" data-cy-field-type="create-invoice--due-date">
        <FormLabel id={`${name}-due-date-label`}>Invoice due date</FormLabel>
        <Select
          displayEmpty
          sx={{ mb: 2 }}
          size="small"
          value={dueDateDropdown}
          error={!!errors[name]}
          labelId={`${name}-due-date-label`}
          disabled={disabled}
          onChange={(event) => {
            setDueDateDropdown(event.target.value);

            if (event.target.value !== 'custom') {
              field.onChange({
                ...field.value,
                due_date: parse(event.target.value, DATE_DASH_FORMAT, new Date()),
              });
            }
          }}
          readOnly={readOnly}
        >
          <MenuItem value="">
            <em>Select option</em>
          </MenuItem>
          {invoiceDueDateOptions.map((option) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.label || option.value}
              </MenuItem>
            );
          })}
        </Select>
        {dueDateDropdown === 'custom' && (
          <DatePicker
            aria-labelledby={`${name}-due-date-label`}
            value={field.value.due_date}
            readOnly={disabled || readOnly}
            slotProps={{ textField: { size: 'small', error: !!errors[name] } }}
            onChange={(newValue) => {
              field.onChange({ ...field.value, due_date: newValue });
            }}
          />
        )}
      </FormControl>
      <FormControl fullWidth size="small" data-cy-field-type="create-invoice--invoice-number" sx={{ mb: 2 }}>
        <FormLabel id={`${name}-invoice-number-label`}>Invoice number</FormLabel>
        <TextField
          size="small"
          aria-labelledby={`${name}-invoice-number-label`}
          value={field.value.invoice_number}
          error={!!errors[name]}
          disabled={disabled}
          onChange={(event) => {
            field.onChange({
              ...field.value,
              invoice_number: event.target.value,
            });
          }}
          inputProps={{ readOnly }}
        />
      </FormControl>
      <TemplateOptionsList templates={templates} disabled={disabled} onChange={onChangeTemplate} onAddRow={onAddRow} />
      <InvoiceTable
        section="offer"
        disabled={disabled}
        invoice={field.value}
        onChangeDescription={onChangeDescription}
        onChangeAmount={onChangeAmount}
        onChangeQuantity={onChangeQuantity}
        onChangeTaxRate={onChangeTaxRate}
        onChangeXeroCode={onChangeXeroCode}
        onRemoveRow={onRemoveRow}
        onChangeRowOrder={onChangeRowOrder}
        onAddRow={onAddRow}
        readOnly={readOnly}
      />
      <FormControl fullWidth size="small" data-cy-field-type="create-invoice--invoice-notes">
        <FormLabel id={`${name}-invoice-notes-label`}>Invoice notes</FormLabel>
        <TextField
          size="small"
          aria-labelledby={`${name}-invoice-notes-label`}
          multiline
          rows={5}
          value={field.value.notes}
          error={!!errors[name]}
          disabled={disabled}
          onChange={(event) => {
            field.onChange({ ...field.value, notes: event.target.value });
          }}
          inputProps={{ readOnly }}
        />
      </FormControl>
      {errors[name] ? <Typography color="error">{errors[name]!.message as string}</Typography> : <br />}
    </Box>
  );
};

// for React.lazy
export default CreateInvoiceField;
