/**
 * Check if a date is in the past
 * @param  {Date} expiry Date
 * @return {Boolean}
 */
export function isExpired(expiry) {
  const expiryDate = new Date(expiry);

  return Date.now() > expiryDate.getTime();
}
