import * as types from 'app/constants/ActionTypes';

export const uploadsInitialState = {
  files: {},
};

export const uploadsReducer = (state = uploadsInitialState, action) => {
  switch (action.type) {
    case types.SCHOOL_EDIT_RESPONSE:
    case types.APPLICATIONS_EDIT_RESPONSE:
    case types.SCHOOL_GET_RESPONSE: {
      const schoolUploads = { ...state.files };
      const { school } = action.payload;

      if (school && school.logo) {
        // @ts-expect-error TS(2339): Property 'school_edit_logo' does not exist on type... Remove this comment to see the full error message
        schoolUploads.school_edit_logo = [school.logo];
      }

      if (school && school.signature) {
        // @ts-expect-error TS(2339): Property 'school_edit_signature' does not exist on... Remove this comment to see the full error message
        schoolUploads.school_edit_signature = [school.signature];
      }

      if (school && school.application_settings && school.application_settings.terms_and_conditions) {
        // @ts-expect-error TS(2339): Property 'applications_edit_terms_and_conditions' ... Remove this comment to see the full error message
        schoolUploads.applications_edit_terms_and_conditions = [school.application_settings.terms_and_conditions];
      } else {
        // @ts-expect-error TS(2339): Property 'applications_edit_terms_and_conditions' ... Remove this comment to see the full error message
        schoolUploads.applications_edit_terms_and_conditions = null;
      }

      return {
        ...state,
        files: schoolUploads,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
