import { getConfig } from 'utils/config';

const { enrolmentsUrl } = getConfig();
const host = typeof window !== 'undefined' ? enrolmentsUrl || 'enroller.app' : 'enroller.app';

export const generalInitialState = { host };

export const generalReducer = (state = generalInitialState) => {
  return {
    ...state,
  };
};
