const theme = {
  primary: '#3ead47',
  accent: '#D00202',
  overdue: '#F90000',
  due: '#FF991A',
  red: '#D00202',
  tundora: '#4A4A4A',
  silver: '#FAFAFA',
  dove: '#666666',
  mercury: '#E7E7E7',
  black: '#000000',
  error: '#de3226',
  fontPrimary: '"Helvetica", sans-serif',
  xs: '(min-width: 320px)',
  sm: '(min-width: 480px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  easeStandard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  easeSharp: 'cubic-bezier(0.4, 0.0, 0.6, 1)',
  easeDeceleration: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  easeAcceleration: 'cubic-bezier(0.4, 0.0, 1, 1)',
};

export default theme;
