import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'app/PrivateRoute';
import HasAccess from 'permissions/HasAccess';
import {
  PERMISSION_ADD_INSTITUTION_USERS,
  PERMISSION_EDIT_INSTITUTION_USERS,
  PERMISSION_INVITE_INSTITUTION_AGENTS,
  PERMISSION_VIEW_APPLICATION_SETTINGS,
  PERMISSION_VIEW_FINANCIAL_SETTINGS,
  PERMISSION_VIEW_INSTITUTION_AGENTS,
  PERMISSION_VIEW_INSTITUTION_SETTINGS,
  PERMISSION_VIEW_INSTITUTION_USERS,
} from 'permissions/constants/Roles';
import ProductsAccess from 'permissions/ProductsAccess';
import { ENROLLER_RECRUIT } from 'permissions/constants/ProductLevels';
import React from 'react';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

const Agents = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/Agents'));
const AddAgent = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/Agents/AddAgent'));
const InstitutionUsers = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/InstitutionUsers'));
const AddUser = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/AddUser'));
const EditUser = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/EditUser'));
const EditSchool = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/EditSchool'));
const EditApplications = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/Application/Application'));
const Financial = React.lazy(() => import(/* webpackChunkName: 'settings' */ 'views/Settings/Financial/Financial'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: 'errorpage' */ 'views/Error/ErrorPage'));

export interface SchoolUserSettingsRouteParams extends SchoolRouteParams {
  id: string;
}

export interface SchoolInvoiceSettingsRouteParams extends SchoolRouteParams {
  id: string;
  duplicateId: string;
}

export const SchoolSettingsRoutes = () => (
  <Routes>
    <Route
      path="agents"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_VIEW_INSTITUTION_AGENTS]} fallback={<ErrorPage />}>
            <Agents />
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="agents/add"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_INVITE_INSTITUTION_AGENTS]} fallback={<ErrorPage />}>
            <AddAgent />
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="permissions"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_VIEW_INSTITUTION_USERS]} fallback={<ErrorPage />}>
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              <InstitutionUsers />
            </ProductsAccess>
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="permissions/add"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_ADD_INSTITUTION_USERS]} fallback={<ErrorPage />}>
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              <AddUser />
            </ProductsAccess>
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="permissions/edit/:id"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_EDIT_INSTITUTION_USERS]} fallback={<ErrorPage />}>
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              <EditUser />
            </ProductsAccess>
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="school"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_VIEW_INSTITUTION_SETTINGS]} fallback={<ErrorPage />}>
            <EditSchool />
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="application"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_VIEW_APPLICATION_SETTINGS]} fallback={<ErrorPage />}>
            <EditApplications />
          </HasAccess>
        </PrivateRoute>
      }
    />
    <Route
      path="financial/*"
      element={
        <PrivateRoute>
          <HasAccess for="permission" name={[PERMISSION_VIEW_FINANCIAL_SETTINGS]} fallback={<ErrorPage />}>
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              <Financial />
            </ProductsAccess>
          </HasAccess>
        </PrivateRoute>
      }
    />
  </Routes>
);
