import Loader from 'components/Loader';
import StageScroller from 'views/Steps/StageScroller';
import ApplicantPipelineStageColumn from 'applicants/ApplicantPipelineStageColumn';
import ApplicantPipelineCompleteApplications from 'components/ProgressColumn/ApplicantPipelineCompleteApplications';
import EmptyState from 'components/Profile/EmptyState';
import { APPLICATION_STATUS_ENROLLED } from 'app/constants/ApplicationStatuses';
import { useApplicant } from 'contexts/ApplicantContext';
import { useParams } from 'react-router-dom';
import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export const FilteredApplicantPipeline = ({ filters, applicationData, loading }) => {
  const {
    state: { applicant },
  } = useApplicant();
  const { applicant: applicantId } = useParams() as SchoolWorkflowRouteParams;

  const renderColumns = (stages) =>
    stages.map((stage) => (
      <ApplicantPipelineStageColumn
        key={stage.id}
        id={stage.id}
        name={stage.name}
        nameTranslationKey={stage.name_translation_key}
        totalApplications={stage.total_applications_count}
        applications={stage.applications || []}
      />
    ));

  const renderApplications = () => {
    const stages = applicationData?.data || [];

    if (filters.status && filters.status === APPLICATION_STATUS_ENROLLED) {
      return <ApplicantPipelineCompleteApplications stages={stages} />;
    }

    const allApplications =
      (stages.length &&
        stages.reduce((a, b) => {
          return {
            applications: a.applications && a.applications.concat(b.applications),
          };
        }).applications) ||
      [];

    if (!allApplications.length) {
      return <EmptyState nounSingular="application" nounPlural="applications" />;
    }

    return <StageScroller>{renderColumns(stages)}</StageScroller>;
  };

  return (
    <Grid container justifyContent="center" pt={2}>
      <Grid lg={12} xl={10}>
        {loading || (!applicantId && !applicant) ? (
          <Stack flex="1" alignItems="center">
            <Loader center />
          </Stack>
        ) : (
          renderApplications()
        )}
      </Grid>
    </Grid>
  );
};
