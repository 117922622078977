import { Auth0ContextInterface } from '@auth0/auth0-react';

let _getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'] | null = null;
let _isAuthenticated: Auth0ContextInterface['isAuthenticated'];
let _user: Auth0ContextInterface['user'];

/**
 * TODO: remove this workaround for getting jwt token for restful-react
 */
export const authBypass = {
  getAccessTokenSilently: () => (_getAccessTokenSilently ? _getAccessTokenSilently() : null),
  setAccessTokenSilently: (getAccessTokenSilently) => (_getAccessTokenSilently = getAccessTokenSilently),
  getUser: () => _user,
  setUser: (user) => (_user = user),
  getIsAuthenticated: () => _isAuthenticated,
  setIsAuthenticated: (isAuthenticated) => (_isAuthenticated = isAuthenticated),
};
