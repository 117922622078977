import { Box } from '@mui/material';
import { useAppBarHeight } from 'hooks/useAppBarHeight';
import { PropsWithChildren } from 'react';

/**
 * This should probably be the first rendered child in the layout outlet to work correctly
 */
export const StickyPageHeader = ({ children }: PropsWithChildren) => {
  const appBarHeight = useAppBarHeight();
  return (
    <Box position="sticky" top={`${appBarHeight}px`} pt={3} width="100vw" bgcolor="background.paper" border="1px solid" borderColor="divider" zIndex={1}>
      {children}
    </Box>
  );
};
