import { useState } from 'react';
import { Currency } from 'currency/components/Currency';
import Loader from 'components/Loader';
import { loadStripe, StripeCardElementOptions } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { PAYMENT_STATUS_CREATED } from 'invoices/InvoiceStatuses';
import { ReactComponent as PersonIcon } from 'images/icons/Material/person.svg';
import { ReactComponent as EmailIcon } from 'images/icons/Material/email.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as StripeIcon } from 'images/payment/stripe-powered-by.svg';
import { Controller, useForm } from 'react-hook-form';
import { email } from 'forms/consumer/validators';
import { useMountEffect } from 'hooks/useMountEffect';
import { getConfig } from '../../utils/config';
import { FormControl, FormLabel, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState<Error | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentResult, setPaymentResult] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { name: '', email: '', card: false } });

  const theme = useTheme();

  const CARD_OPTIONS: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
      },
      invalid: {
        color: theme.palette.error.main,
      },
    },
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!stripe || !elements) {
      setPaymentError(new Error('Unable to initiate Stripe Payment system'));
      return;
    }

    setProcessing(true);

    const payload = await stripe.confirmCardPayment(props.clientKey, {
      payment_method: {
        // @ts-expect-error TS(2322): Type 'StripeCardElement | null' is not assignable ... Remove this comment to see the full error message
        card: elements.getElement(CardElement),
        billing_details: {
          name: data.name,
          email: data.email,
        },
      },
    });

    setProcessing(false);

    if (payload.error) {
      // @ts-expect-error TS(2345): Argument of type 'StripeError' is not assignable t... Remove this comment to see the full error message
      setPaymentError(payload.error);
    } else {
      if (payload.paymentIntent.status !== 'succeeded') {
        // @ts-expect-error TS(2345): Argument of type '"processing" | "canceled" | "req... Remove this comment to see the full error message
        setPaymentError(payload.paymentIntent.status);
      } else {
        // @ts-expect-error TS(2345): Argument of type '"succeeded"' is not assignable t... Remove this comment to see the full error message
        setPaymentResult(payload.paymentIntent.status);
      }
    }
  });

  if (paymentResult) {
    return (
      <>
        <CheckCircleIcon sx={{ width: 80, height: 80 }} color="primary" />
        <Typography>Payment successful</Typography>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={1} width="fit-content">
        <Typography variant="h6">Pay with card</Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Name is required',
          }}
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth required>
              <FormLabel id="nameLabel" required>
                Name
              </FormLabel>
              <TextField
                size="small"
                aria-labelledby="nameLabel"
                InputProps={{
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    </>
                  ),
                }}
                placeholder="Name"
                required
                value={value}
                onChange={onChange}
              />
            </FormControl>
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            validate: {
              valid: (val) => (email(val) ? true : 'Email is not valid'),
            },
          }}
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth required>
              <FormLabel id="emailLabel" required>
                Email
              </FormLabel>
              <TextField
                size="small"
                InputProps={{
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    </>
                  ),
                }}
                type="email"
                placeholder="Email Address"
                required
                value={value}
                onChange={onChange}
              />
            </FormControl>
          )}
        />
        <Controller
          name="card"
          control={control}
          rules={{
            required: 'Card is required',
          }}
          render={({ field: { onChange } }) => (
            <CardElement
              options={CARD_OPTIONS}
              onChange={(e) => {
                // @ts-expect-error TS(2345): Argument of type '{ type: "validation_error"; code... Remove this comment to see the full error message
                setPaymentError(e.error);
                onChange(e.complete);
              }}
            />
          )}
        />
        <LoadingButton loading={processing} disabled={!stripe || processing} variant="contained" sx={{ width: 'fit-content' }} onClick={onSubmit}>
          Pay <Currency amount={props.amount} />
        </LoadingButton>
        <StripeIcon height={20} width="fit-content" />
      </Stack>

      {/* form errors */}
      {Object.values(errors).map((err, idx) => (
        <Stack direction="row" gap={1} alignItems="center" color="error.main" key={idx}>
          <ErrorOutlineIcon />
          {err.message}
        </Stack>
      ))}
      {/* payment errors */}
      {paymentError && (
        <Stack direction="row" gap={1} alignItems="center" color="error.main">
          <ErrorOutlineIcon />
          {paymentError.message}
        </Stack>
      )}
    </form>
  );
};

const StripeFormWrapper = (props) => {
  const [pubKey, setPubKey] = useState('');
  const [clientKey, setClientKey] = useState('');
  const [stripeAccount, setStripeAccount] = useState('');
  const { apiUrl } = getConfig();

  useMountEffect(() => {
    let isCancelled = false;

    if (props.invoiceId && !pubKey && !clientKey) {
      const action = props.sharedPayment ? 'shared-payments' : 'payments';
      const endpoint = `${apiUrl}/schools/${props.slug}/invoices/${props.invoiceId}/${action}`;
      const data = {
        payment_provider_id: props.selectedPaymentProviderId,
        amount: props.amountInCents,
        status: PAYMENT_STATUS_CREATED,
      };
      const params = {
        method: 'post',
        headers: { ...props.headers, 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      };

      fetch(endpoint, params)
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject(new Error(response.statusText));
          }

          return Promise.resolve(response);
        })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (!isCancelled) {
            setPubKey(response.stripe_publishable_key);
            setClientKey(response.intent.client_secret);
            setStripeAccount(response.stripe_account);
          }
        });
    }

    return () => {
      isCancelled = true;
    };
  });

  if (clientKey && pubKey && stripeAccount) {
    const stripePromise = loadStripe(pubKey, { stripeAccount });
    return (
      <Stack mt={2}>
        <Elements stripe={stripePromise}>
          <CheckoutForm {...props} clientKey={clientKey} />
        </Elements>
      </Stack>
    );
  }

  return <Loader center />;
};

const Stripe = (props) => (
  <Stack>
    <Typography>
      Amount: <Currency amount={props.amount} />
    </Typography>
    {props.invoiceNumber && <Typography>Invoice Number: {props.invoiceNumber}</Typography>}
    <StripeFormWrapper {...props} />
  </Stack>
);

export default Stripe;
