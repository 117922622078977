import { useEffect } from 'react';
import { ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF } from 'permissions/constants/Roles';
import { useAuth0 } from '@auth0/auth0-react';
import { loginConfig } from 'auth0/login-config';
import Loader from 'components/Loader';
import { getConfig } from 'utils/config';

export const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const { marketplaceUrl } = getConfig();

  useEffect(() => {
    const login = () => loginWithRedirect(loginConfig(window.location.pathname));

    if (!isAuthenticated) {
      login();
    }
  }, [loginWithRedirect, isAuthenticated]);

  if (!isAuthenticated || !user) {
    return <Loader />;
  }

  const isMarketingOnlyStaff = user.userData.roles.find((r) => r === ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF);

  if (isMarketingOnlyStaff) {
    window.location.href = marketplaceUrl;
    return <></>;
  }

  return children;
};
