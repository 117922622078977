import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3ead47;
  border-radius: 100%;
  padding: 0.3rem;
  color: white;
  width: 3.2rem;
  height: 3.2rem;
  font-weight: bold;
  font-size: 20px;
`;

export const ProfileAvatar = () => {
  const { user, isAuthenticated } = useAuth0();

  // SU?
  const firstName = user?.userData.firstName || 'S';
  const lastName = user?.userData.lastName || 'U';

  if (!isAuthenticated) {
    return <></>;
  }

  return <IconWrapper data-cy="profile-card">{firstName[0].toUpperCase() + lastName[0].toUpperCase()}</IconWrapper>;
};
