import { createContext } from 'react';

interface LangContextData {
  lang: { get?: (n, p) => string };
  content: (key: string, params: Record<string, string>, fallback: string) => string;
}

const LangContext = createContext<LangContextData>({
  lang: {},
  content: (key, params, fallback) => fallback,
});

LangContext.displayName = 'LangContext';

export default LangContext;
