import rest from 'rest';
import errorCode from 'rest/interceptor/errorCode';
import pathPrefix from 'rest/interceptor/pathPrefix';
import retry from 'rest/interceptor/retry';
import mime from 'rest/interceptor/mime';
import params from 'rest/interceptor/params';
import failureLogger from 'app/api/common/failureLogger';
import auth from 'app/api/common/authInterceptor';
import logger from 'app/api/common/defaultLogger';
import { API_ADDRESS } from 'app/constants/env';

const client = rest
  .wrap(mime, {
    mime: 'application/json',
  })
  .wrap(params, {})
  .wrap(pathPrefix, {
    prefix: API_ADDRESS,
  })
  .wrap(retry, {
    initial: 1000,
    max: 10000,
  })
  .wrap(auth)
  .wrap(errorCode)
  .wrap(failureLogger, {
    logger,
    logtype: logger.error,
    code: 404,
  });

export default client;
