import { Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { branding } from 'branding';
import { AppHeader } from './AppHeader';
import { useAppBarHeight } from 'hooks/useAppBarHeight';

export const AppLayout = () => {
  const appBarHeight = useAppBarHeight();
  return (
    <Stack direction="column" height="100vh" width="100%" style={{ overflowY: 'scroll' }}>
      <Box position="sticky" top="0" zIndex={1}>
        <AppHeader />
      </Box>
      <Stack flexGrow="1" mt={`${appBarHeight}px`}>
        <Outlet />
      </Stack>
      {branding.footer}
    </Stack>
  );
};
