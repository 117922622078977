import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { Application } from 'types/Application';
import { AsyncStatus } from 'types/AsyncStatus';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useStatelessPut } from 'hooks/useStatelessPut';
import { useParams } from 'react-router-dom';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';

type ApplicationAction =
  | { type: 'start application update'; updates: Partial<Application> }
  | { type: 'finish application update'; application: Application }
  | { type: 'fail application update'; error: unknown }
  | { type: 'start application read' }
  | { type: 'finish application read'; application: Application }
  | { type: 'fail application read'; error: unknown };
type ApplicationState = {
  status: AsyncStatus;
  application: Application | null;
};
interface ApplicationContextData {
  state: ApplicationState;
  updateApplication: (data: Application) => Promise<void>;
  loadApplication: () => Promise<void>;
}

export const ApplicationContext = createContext<ApplicationContextData | undefined>(undefined);

const applicationReducer = (state: ApplicationState, action: ApplicationAction): ApplicationState => {
  switch (action.type) {
    case 'start application read':
    case 'start application update':
      return { ...state, status: 'pending' };
    case 'finish application read':
    case 'finish application update':
      return {
        status: 'success',
        application: action.application,
      };
    case 'fail application read':
    case 'fail application update':
      console.error(action.error);
      return {
        status: 'error',
        application: null,
      };
  }
};

interface ApplicationProviderProps {
  id?: string;
}

export const ApplicationProvider = ({ id, children }: PropsWithChildren<ApplicationProviderProps>) => {
  const { application: paramApplicationId, slug: schoolSlug } = useParams() as ApplicantRouteParams;
  const [state, dispatch] = useReducer(applicationReducer, {
    status: 'idle',
    application: null,
  });
  // this is because Applicant and School User routes behave differently
  const applicationId = paramApplicationId || id;
  const fetchApplication = useStatelessGet<ResponseEnvelope<Application>>(`/schools/${schoolSlug}/applications/${applicationId}`);
  const modifyApplication = useStatelessPut<ResponseEnvelope<Application>>(`/schools/${schoolSlug}/applications/${applicationId}`);

  const loadApplication = useCallback(async () => {
    dispatch({ type: 'start application read' });

    try {
      const response = await fetchApplication();
      dispatch({
        type: 'finish application read',
        application: response.data,
      });
    } catch (error) {
      dispatch({ type: 'fail application read', error });
    }
  }, [fetchApplication]);

  const updateApplication = useCallback(
    async (data: Application) => {
      dispatch({ type: 'start application update', updates: data });

      try {
        const response = await modifyApplication(data);
        dispatch({
          type: 'finish application update',
          application: response.data,
        });
      } catch (error) {
        dispatch({ type: 'fail application update', error });
      }
    },
    [modifyApplication],
  );

  useEffect(() => {
    // only try load an application when have an id
    if (applicationId && state.status === 'idle') {
      loadApplication();
    }
  }, [applicationId, loadApplication, state.status]);

  const contextValue = useMemo(() => ({ state, updateApplication, loadApplication }), [loadApplication, state, updateApplication]);

  return <ApplicationContext.Provider value={contextValue}>{children}</ApplicationContext.Provider>;
};

export const useApplication = () => {
  const context = useContext(ApplicationContext);

  if (context === undefined) {
    throw new Error('useApplication must be used within a ApplicationProvider');
  }

  return context;
};
