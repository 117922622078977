import { isEmpty, isDate } from 'lodash';

/**
 * Check if values are equal
 * @param referenceValue
 * @param compareTo
 * @returns {boolean}
 */
export const equal = (referenceValue, compareTo) => {
  return referenceValue === compareTo;
};

/**
 * Check if values are not equal
 * @param referenceValue
 * @param compareTo
 * @returns {boolean}
 */
export const notequal = (referenceValue, compareTo) => {
  return !equal(referenceValue, compareTo);
};

/**
 * Check if referenceValue is greater than compareTo
 * @param referenceValue
 * @param compareTo
 * @returns {boolean}
 */
export const greaterthan = (referenceValue, compareTo) => {
  return referenceValue !== '' && parseInt(referenceValue, 10) > parseInt(compareTo, 10);
};

/**
 * Check if referenceValue is less than compareTo
 * @param referenceValue
 * @param compareTo
 * @returns {boolean}
 */
export const lessthan = (referenceValue, compareTo) => {
  return referenceValue !== '' && parseInt(referenceValue, 10) < parseInt(compareTo, 10);
};

/**
 * Check if referenceValue contains compareTo
 * @param referenceValue
 * @param compareTo
 * @returns {boolean}
 */
export const contains = (referenceValue, compareTo) => {
  return referenceValue ? referenceValue.includes(compareTo) : false;
};

/**
 * * Check if referenceValue does not contain compareTo
 * @param referenceValue
 * @param compareTo
 * @returns {boolean}
 */
export const doesnotcontain = (referenceValue, compareTo) => {
  return !contains(referenceValue, compareTo);
};

/**
 * Check if referenceValue is empty
 * @param referenceValue
 * @returns {boolean}
 */
export const empty = (referenceValue) => {
  if (referenceValue === null || referenceValue === '') {
    return true;
  } else {
    switch (typeof referenceValue) {
      case 'undefined':
        return true;
      case 'boolean':
        return !referenceValue;
      case 'number':
        return false;
    }

    if (Object.prototype.hasOwnProperty.call(referenceValue, 'length')) {
      return !referenceValue.length;
    } else if (isDate(referenceValue)) {
      return false;
    }

    return isEmpty(referenceValue);
  }
};

/**
 * Check if referenceValue is not empty
 * @param referenceValue
 * @returns {*}
 */
export const notempty = (referenceValue) => {
  return !empty(referenceValue);
};

/**
 * Check if referenceValue is true (or truthy)
 * Used for checking checkbox field value === true
 * @param referenceValue
 * @returns {boolean}
 */
export const checked = (referenceValue) => {
  return !!referenceValue;
};

/**
 * Check if referenceValue is false (or falsey)
 * Used for checking checkbox field value === false
 * @param referenceValue
 * @returns {boolean}
 */
export const notchecked = (referenceValue) => {
  return !checked(referenceValue);
};
