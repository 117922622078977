import { useParams } from 'react-router-dom';
import { useWorkflow } from 'contexts/WorkflowContext';
import { T } from 'components/Translation/Translation';
import Content from 'components/Translation/Content';
import HasAccess from 'permissions/HasAccess';
import { useApplication } from 'contexts/ApplicationContext';
import { WorkflowStepRouteParams } from '../../Workflow/WorkflowApplicationLayout';
import { Link, List, ListItem, Typography } from '@mui/material';
import Loader from '../../Loader';
import EditIcon from '@mui/icons-material/Edit';

export const StepListField = () => {
  const {
    state: { application },
  } = useApplication();
  const {
    state: { workflow },
  } = useWorkflow();
  const { step: stepId, stage: stageId, slug, applicant: applicantId, application: applicationId } = useParams() as WorkflowStepRouteParams;

  const renderSteps = () => {
    const stage = workflow!.stages.find((stage) => stage.slug === stageId);

    if (!stage || !stage.steps.length) {
      return <></>;
    }

    const canEdit = application?.current_stage_id === stage.id;
    const label = canEdit ? 'Edit details' : 'View details';

    return stage.steps
      .filter((step) => step.slug !== stepId)
      .map((step, index) => {
        const number = index + 1;
        const link = `/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}/stages/${stage.slug}/steps/${step.slug}`;

        return (
          <HasAccess for="group" name={[step.assign_to]} key={step.id}>
            <ListItem sx={{ px: 0 }} data-cy={`stepListItem-${index}`}>
              <Typography variant="body2">
                Step {number}: <Content name={step.name_translation_key}>{step.name}</Content>{' '}
                <Link href={link} color="primary.dark">
                  {label} {canEdit && <EditIcon color="primary" sx={{ height: 20, width: 20 }} />}
                </Link>
              </Typography>
              <T name={step.name_translation_key} />
            </ListItem>
          </HasAccess>
        );
      });
  };

  if (!workflow) return <Loader />;

  return <List data-cy-field-type="step-list">{renderSteps()}</List>;
};

// for React.lazy
export default StepListField;
