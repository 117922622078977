import { FormBuilderField } from '../types/FormBuilderField';
import { ApiFormBuilderField } from '../types/api/ApiFormField';

export const convertApiFieldsToBuilderFields = (newFields: ApiFormBuilderField[]): FormBuilderField[] => {
  return newFields.map((field) => {
    let options = field.options;
    let value = field.value !== null ? field.value : field.default_value;
    if (value === undefined) value = '';

    // special logic for createinvoice which stores the invoiceId as the value,
    // but requires a payload to create an invoice when submitting an action
    if (['createinvoice'].includes(field.field_type.name)) {
      options = [field.value];
      value = '';
    }

    return {
      id: field.id,
      value,
      label: field.label,
      slug: field.slug,
      type: field.field_type.name,
      options,
      disabled: false,
      conditions: field.conditions,
      validationRules: field.validation_rules,
      applicationFieldMessages: field.application_field_messages ?? [],
      translationKey: field.translation_key,
      displayWidth: field.display_width,
    };
  });
};
