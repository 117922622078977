import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import schoolAPI from 'app/api/schoolAPI';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

/**
 * Change current school
 * @param  {Object} school  New school
 * @return {Function}       Action creator
 */
export function selectSchool(school) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.SCHOOL_SELECT,
      school,
    });
  };
}

/**
 * Reset current school
 * @return {Function}       Action creator
 */
export function resetSchool() {
  return {
    type: ActionTypes.SCHOOL_RESET,
  };
}

export function getSchool(slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.SCHOOL_GET,
    });

    schoolAPI
      .GET({
        path: `${apiUrl}/schools/{slug}`,
        params: {
          slug,
        },
      })
      .then((response) => {
        if (response.entity.data) {
          dispatch({
            type: ActionTypes.SCHOOL_GET_RESPONSE,
            payload: {
              success: true,
              school: response.entity.data,
            },
          });
        } else {
          // request cancelled, swallow error as new request will be in-flight
          dispatch({
            type: ActionTypes.SCHOOL_GET_RESPONSE,
            payload: {
              success: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.SCHOOL_GET_RESPONSE,
          payload: {
            success: false,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}

export function getDashboardStats(slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.SCHOOL_GET_STATS,
    });

    schoolAPI
      .GET({
        path: `${apiUrl}/schools/{slug}/school-stats`,
        params: {
          slug,
        },
      })
      .then((response) => {
        if (response.status.code === 200) {
          dispatch({
            type: ActionTypes.SCHOOL_GET_STATS_RESPONSE,
            payload: {
              success: true,
              stats: response.entity.data,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.SCHOOL_GET_STATS_RESPONSE,
          payload: {
            success: false,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}
