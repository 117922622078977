import React from 'react';
import { useAppSelector } from 'app/hooks';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import {
  GROUP_TYPE_INSTITUTION,
  ROLE_TYPE_AGENT,
  ROLE_TYPE_PARENT,
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF,
  ROLE_TYPE_SHARED_APPLICANT,
  ROLE_TYPE_ENROLLER_ADMIN,
} from 'permissions/constants/Roles';
import HasAccess from 'permissions/HasAccess';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'components/Loader';
import { getConfig } from 'utils/config';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { AuthUser } from 'utils/user';

export const PublicOnlyRoute = ({ children }) => {
  const location = useLocation();
  const params = useParams() as SchoolRouteParams;
  const { marketplaceUrl } = getConfig();
  const slug = useAppSelector((state) => state.school.slug);

  const { isAuthenticated, isLoading, user } = useAuth0<AuthUser>();

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated || !user) {
    return children;
  }

  const state = {
    from: location,
  };

  // Use slug from store if no slug is in URL (i.e. generic login)
  const schoolSlug = params.slug || slug;
  let redirectIfLoggedIn = '/my-schools';

  if (user.userData.roles.includes(ROLE_TYPE_ENROLLER_ADMIN)) {
    redirectIfLoggedIn = '/management/workflows';
  }

  if (user.userData.schoolSlugs.length === 1) {
    redirectIfLoggedIn = `/${user.userData.schoolSlugs[0]}/login`;
  }

  // Agents logged in go to Marketplace
  if (user.userData.roles.find((r) => r === ROLE_TYPE_AGENT || r === ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF)) {
    window.location.href = marketplaceUrl;
    return <></>;
  }

  if (user.userData.roles.includes(ROLE_TYPE_SHARED_APPLICANT)) {
    // If the shared user only has one shared application, just take them directly to it
    if (user.userData.sharedUserApplications.length === 1) {
      const sharedApplication = user.userData.sharedUserApplications[0];
      redirectIfLoggedIn = `/${sharedApplication.school.slug}/enrolment-application/applicant/${sharedApplication.applicant_id}/application/${sharedApplication.id}/stages`;
    } else {
      redirectIfLoggedIn = '/shared-enrolments';
    }
  }

  return (
    <>
      {location.search && <Navigate state={state} to={location.search.replace('?back=', '')} />}
      {isAuthenticated && !schoolSlug ? (
        <Navigate state={state} to={redirectIfLoggedIn} />
      ) : (
        <>
          <HasAccess for="group" name={[GROUP_TYPE_INSTITUTION]}>
            <Navigate state={state} to={`/${schoolSlug}/dashboard`} />
          </HasAccess>
          <HasAccess for="role" name={[ROLE_TYPE_AGENT, ROLE_TYPE_PARENT, ROLE_TYPE_APPLICANT]}>
            <Navigate state={state} to={`/${schoolSlug}/pipeline`} />
          </HasAccess>
        </>
      )}
    </>
  );
};
