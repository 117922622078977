import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Box, Chip, FormLabel, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { FormControl, FormControlState } from '@mui/base/FormControl';
import Loader from 'components/Loader';
import scrollTo from 'utils/scrollTo';
import getElementOffset from 'utils/getElementOffset';
import { SecondaryPanel } from './SecondaryPanel';
import { useMountEffect } from 'hooks/useMountEffect';
import { PrimaryPanel } from './PrimaryPanel';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { useGet } from 'hooks/useGet';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';

export interface ProgrammeIntake {
  value: string;
  label: string;
  provider: string;
  providerName?: string;
  disabled?: boolean;
}

export interface Programme {
  value: string;
  label: string;
  children: ProgrammeIntake[];
  minSelectedChildrenIfChildSelected?: number;
  maxSelectedChildrenIfChildSelected?: number;
}

export interface Industry {
  value: string;
  label: string;
  children: Programme[];
  maxSelectedChildren: number;
}

export interface SelectedOption {
  industry: Industry['value'];
  programme: Programme['value'];
  intake: ProgrammeIntake['value'];
  name: Programme['label'];
  details: ProgrammeIntake['label'];
  provider?: ProgrammeIntake['provider'];
  providerName?: ProgrammeIntake['providerName'];
}

interface MultiLevelMultiSelectFieldProps {
  label: string;
  disabled: boolean;
  value: object;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  sourceUrl: string;
}

export const MultiLevelMultiSelectField = ({ label, disabled, value, onChange, error, name, readOnly, sourceUrl }: MultiLevelMultiSelectFieldProps) => {
  const internalValue = useMemo(() => value || {}, [value]);
  const [industryOptions, setIndustryOptions] = useState<Industry[]>([]);
  const [failedToGetIndustryOptions, setFailedToGetIndustryOptions] = useState(false);

  const fieldElement = useRef<HTMLDivElement | null>(null);
  const tagsContainer = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedIndustryValue, setSelectedIndustryValue] = useState<string | null>(null);
  const [selectedProgrammeValue, setSelectedProgrammeValue] = useState<string | null>(null);
  const showSecondLevel = selectedIndustryValue !== null && selectedProgrammeValue !== null;

  // activeRef used to get state value in event listener
  const activeRef = useRef(active);
  activeRef.current = active;

  const handleChange = (key: string, selectedOption: SelectedOption | null, unselectValues = true, internalValue, onChange) => {
    const fieldValues = { ...internalValue };

    if (unselectValues && (!selectedOption || fieldValues[key])) {
      delete fieldValues[key];
    } else if (selectedOption === null || (fieldValues[key] && fieldValues[key].intake === selectedOption.intake)) {
      return;
    } else {
      fieldValues[key] = selectedOption;
    }

    onChange(Object.keys(fieldValues).length > 0 ? fieldValues : null);
  };

  const handleBlur = (e) => {
    if (
      activeRef.current &&
      fieldElement.current &&
      tagsContainer.current &&
      !fieldElement.current.contains(e.target) &&
      !tagsContainer.current.contains(e.target)
    ) {
      setActive(false);
      setSelectedIndustryValue(null);
      setSelectedProgrammeValue(null);
    }
  };

  useMountEffect(() => {
    window.addEventListener('mousedown', handleBlur);
    window.addEventListener('touchend', handleBlur);

    return () => {
      window.removeEventListener('mousedown', handleBlur);
      window.removeEventListener('touchend', handleBlur);
    };
  });

  const selectedOptions: SelectedOption[] = industryOptions.flatMap((industry) =>
    industry.children.flatMap((programme) =>
      programme.children
        .filter((intake) => Boolean(internalValue[intake.value]))
        .map((intake) => {
          const selectedOption: SelectedOption = {
            industry: industry.label,
            programme: programme.value,
            intake: intake.value,
            name: programme.label,
            details: intake.label,
            provider: intake.provider,
          };
          if (intake.providerName) {
            selectedOption.providerName = intake.providerName;
          }
          return selectedOption;
        }),
    ),
  );

  const handleSelectProgramme = (industryValue: string | null, programmeValue: string | null) => {
    setSelectedIndustryValue(industryValue);
    setSelectedProgrammeValue(programmeValue);

    setTimeout(() => {
      scrollTo(getElementOffset(tagsContainer.current).top - 100, null, 500);
    }, 300);
  };

  const programmeBelowMinSelectedChildren = (): boolean => {
    if (!Boolean(industryOptions.length)) return false;
    const selectedProgrammeValues = selectedOptions.map((v) => v.programme);
    const selectedProgrammes = industryOptions.flatMap((industry) => industry.children.filter((p) => selectedProgrammeValues.includes(p.value)));

    for (const p of selectedProgrammes) {
      if (!Object.prototype.hasOwnProperty.call(p, 'minSelectedChildrenIfChildSelected')) continue;
      const minSelectedChildren = p.minSelectedChildrenIfChildSelected!;
      const selectedChildren = selectedOptions.filter((v) => v.programme === p.value).length;
      if (selectedChildren < minSelectedChildren) return true;
    }
    return false;
  };
  const isProgrammeBelowMinSelectedIntakes = programmeBelowMinSelectedChildren();

  const handleSearch = (query: string) => {
    setActive(true);
    setQuery(query);
  };

  const handleActivate = (event) => {
    if (readOnly) return;
    const scrollIntoViewWithOffset = (offset: number) => {
      window.scrollTo({
        behavior: 'smooth',
        top: event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
      });
    };

    // Make sure select panel is in view but the input field isn't off-screen
    // This may not be needed when using a MUI Popup
    scrollIntoViewWithOffset(140);
    setActive(true);
  };

  const { showErrorMessage } = useNotificationMessages();
  const theme = useTheme();
  const [isLoading, getIndustryOptions] = useGet<Industry[]>(sourceUrl, { useApiUrl: false });
  const { slug } = useParams() as SchoolRouteParams;
  const sourceParams = useMemo(() => new URLSearchParams({ school: slug }), [slug]);

  const fetchOptions = useCallback(async () => {
    try {
      const response = await getIndustryOptions(sourceParams);
      setIndustryOptions(response);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
      setFailedToGetIndustryOptions(true);
    }
  }, [getIndustryOptions, showErrorMessage, sourceParams]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const selectedOptionChipLabel = (option: SelectedOption) =>
    `${option.industry} - ${option.name} - ${option.details}${option.providerName ? ` at ${option.providerName}` : ''}`;

  return (
    <>
      <FormControl data-cy-field-type="multi-level-multi-select">
        {({ focused }: FormControlState) => (
          <Fragment>
            <FormLabel id={`${name}-label`}>{label}</FormLabel>
            {isLoading && <Loader small center />}
            {failedToGetIndustryOptions && <Typography color="error">Could not load options</Typography>}
            {!isLoading && !failedToGetIndustryOptions && (
              <>
                <TextField
                  fullWidth
                  aria-labelledby={`${name}-label`}
                  placeholder="Search..."
                  size="small"
                  value={query}
                  disabled={disabled}
                  onChange={(event) => handleSearch(event.target.value)}
                  onFocus={handleActivate}
                  className={active ? 'Mui-focused' : undefined}
                  inputProps={{ readOnly }}
                  InputProps={{
                    className: active ? 'Mui-focused' : undefined,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                        {JSON.stringify(internalValue) !== '{}' && (
                          <Chip label={`${selectedOptions.length} selected`} size="small" sx={{ mr: 1 }} onDelete={() => onChange({})} />
                        )}
                      </>
                    ),
                    endAdornment: (
                      <>
                        {query.length > 0 && (
                          <Tooltip title="Clear search">
                            <IconButton onClick={() => handleSearch('')}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <IconButton onClick={() => setActive(!active)}>
                          {active && <ExpandMoreIcon />}
                          {!active && <ExpandLessIcon />}
                        </IconButton>
                      </>
                    ),
                  }}
                />
                <Stack ref={tagsContainer} mb={1} mt={1.5} spacing={1} justifyContent="left">
                  {selectedOptions.map((selectedOption) => (
                    <Box key={selectedOption.intake}>
                      <Chip
                        icon={<CheckCircleIcon color="primary" />}
                        deleteIcon={
                          <Tooltip title="Delete">
                            <DeleteOutlinedIcon />
                          </Tooltip>
                        }
                        onDelete={() => handleChange(selectedOption.intake, null, true, internalValue, onChange)}
                        sx={{
                          userSelect: 'inherit',
                          bgcolor: active ? `${theme.palette.primary.light}AA` : 'inherit',
                          height: '100%',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                          '& .MuiChip-deleteIcon': {
                            color: theme.palette.error.main,
                            '&:hover': {
                              color: theme.palette.error.dark,
                            },
                          },
                          p: 0.5,
                        }}
                        label={
                          <Stack direction="column">
                            {selectedOptionChipLabel(selectedOption)
                              ?.split('\n')
                              .map((l) => <Typography key={l}>{l}</Typography>)}
                          </Stack>
                        }
                        data-cy="multi-level-chip"
                      />
                    </Box>
                  ))}
                </Stack>
                {isProgrammeBelowMinSelectedIntakes && <Typography color="error">Not enough options selected.</Typography>}
                {active && (
                  <Paper
                    ref={fieldElement}
                    sx={{
                      borderRadius: theme.spacing(1),
                      boxShadow: `0px 8px 8px -4px ${theme.palette.shadows.light}, 0px 20px 24px -4px ${theme.palette.shadows.dark}`,
                      border: `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <Box
                      data-secondlevel={showSecondLevel}
                      display="flex"
                      sx={{
                        width: {
                          xs: '200%',
                          md: '100%',
                        },
                        transform: {
                          xs: `translateX(${showSecondLevel ? '-50%' : '0%'})`,
                          md: 'translateX(0%);',
                        },
                        transition: '0.35s transform cubic-bezier(0.4, 0, 0.2, 1)',
                      }}
                    >
                      <PrimaryPanel
                        industryOptions={industryOptions}
                        selectedIndustryValue={selectedIndustryValue}
                        selectedProgrammeValue={selectedProgrammeValue}
                        query={query}
                        onSelect={handleSelectProgramme}
                      />
                      <SecondaryPanel
                        industryOptions={industryOptions}
                        selectedIndustryValue={selectedIndustryValue}
                        selectedProgrammeValue={selectedProgrammeValue}
                        selectedOptions={selectedOptions}
                        onChange={(intake, itemValue) => handleChange(intake, itemValue, true, internalValue, onChange)}
                        onSelect={handleSelectProgramme}
                      />
                    </Box>
                  </Paper>
                )}
              </>
            )}
          </Fragment>
        )}
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default MultiLevelMultiSelectField;
