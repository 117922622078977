const TIMEZONES = [
  {
    code: 'AF',
    country: 'Afghanistan',
    name: 'Asia/Kabul',
    offset: 'UTC +04:30',
  },
  {
    code: 'AX',
    country: 'Aland Islands',
    name: 'Europe/Mariehamn',
    offset: 'UTC +02:00',
  },
  {
    code: 'AL',
    country: 'Albania',
    name: 'Europe/Tirane',
    offset: 'UTC +01:00',
  },
  {
    code: 'DZ',
    country: 'Algeria',
    name: 'Africa/Algiers',
    offset: 'UTC +01:00',
  },
  {
    code: 'AS',
    country: 'American Samoa',
    name: 'Pacific/Pago Pago',
    offset: 'UTC -11:00',
  },
  {
    code: 'AD',
    country: 'Andorra',
    name: 'Europe/Andorra',
    offset: 'UTC +01:00',
  },
  {
    code: 'AO',
    country: 'Angola',
    name: 'Africa/Luanda',
    offset: 'UTC +01:00',
  },
  {
    code: 'AI',
    country: 'Anguilla',
    name: 'America/Anguilla',
    offset: 'UTC -04:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Casey',
    offset: 'UTC +11:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Davis',
    offset: 'UTC +07:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/DumontDUrville',
    offset: 'UTC +10:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Mawson',
    offset: 'UTC +05:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/McMurdo',
    offset: 'UTC +13:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Palmer',
    offset: 'UTC -03:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Rothera',
    offset: 'UTC -03:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Syowa',
    offset: 'UTC +03:00',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Troll',
    offset: 'UTC',
  },
  {
    code: 'AQ',
    country: 'Antarctica',
    name: 'Antarctica/Vostok',
    offset: 'UTC +06:00',
  },
  {
    code: 'AG',
    country: 'Antigua and Barbuda',
    name: 'America/Antigua',
    offset: 'UTC -04:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Buenos Aires',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Catamarca',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Cordoba',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Jujuy',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/La Rioja',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Mendoza',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Rio Gallegos',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Salta',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/San Juan',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/San Luis',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Tucuman',
    offset: 'UTC -03:00',
  },
  {
    code: 'AR',
    country: 'Argentina',
    name: 'America/Argentina/Ushuaia',
    offset: 'UTC -03:00',
  },
  {
    code: 'AM',
    country: 'Armenia',
    name: 'Asia/Yerevan',
    offset: 'UTC +04:00',
  },
  {
    code: 'AW',
    country: 'Aruba',
    name: 'America/Aruba',
    offset: 'UTC -04:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Antarctica/Macquarie',
    offset: 'UTC +11:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Adelaide',
    offset: 'UTC +10:30',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Brisbane',
    offset: 'UTC +10:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Broken Hill',
    offset: 'UTC +10:30',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Currie',
    offset: 'UTC +11:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Darwin',
    offset: 'UTC +09:30',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Eucla',
    offset: 'UTC +08:45',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Hobart',
    offset: 'UTC +11:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Lindeman',
    offset: 'UTC +10:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Lord Howe',
    offset: 'UTC +11:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Melbourne',
    offset: 'UTC +11:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Perth',
    offset: 'UTC +08:00',
  },
  {
    code: 'AU',
    country: 'Australia',
    name: 'Australia/Sydney',
    offset: 'UTC +11:00',
  },
  {
    code: 'AT',
    country: 'Austria',
    name: 'Europe/Vienna',
    offset: 'UTC +01:00',
  },
  {
    code: 'AZ',
    country: 'Azerbaijan',
    name: 'Asia/Baku',
    offset: 'UTC +04:00',
  },
  {
    code: 'BS',
    country: 'Bahamas',
    name: 'America/Nassau',
    offset: 'UTC -05:00',
  },
  {
    code: 'BH',
    country: 'Bahrain',
    name: 'Asia/Bahrain',
    offset: 'UTC +04:00',
  },
  {
    code: 'BD',
    country: 'Bangladesh',
    name: 'Asia/Dhaka',
    offset: 'UTC +06:00',
  },
  {
    code: 'BB',
    country: 'Barbados',
    name: 'America/Barbados',
    offset: 'UTC -04:00',
  },
  {
    code: 'BY',
    country: 'Belarus',
    name: 'Europe/Minsk',
    offset: 'UTC +03:00',
  },
  {
    code: 'BE',
    country: 'Belgium',
    name: 'Europe/Brussels',
    offset: 'UTC +01:00',
  },
  {
    code: 'BZ',
    country: 'Belize',
    name: 'America/Belize',
    offset: 'UTC -06:00',
  },
  {
    code: 'BJ',
    country: 'Benin',
    name: 'Africa/Porto-Novo',
    offset: 'UTC +01:00',
  },
  {
    code: 'BM',
    country: 'Bermuda',
    name: 'Atlantic/Bermuda',
    offset: 'UTC -04:00',
  },
  {
    code: 'BT',
    country: 'Bhutan',
    name: 'Asia/Thimphu',
    offset: 'UTC +06:00',
  },
  {
    code: 'BO',
    country: 'Bolivia',
    name: 'America/La Paz',
    offset: 'UTC -04:00',
  },
  {
    code: 'BQ',
    country: 'Bonaire, Saint Eustatius and Saba',
    name: 'America/Kralendijk',
    offset: 'UTC -04:00',
  },
  {
    code: 'BA',
    country: 'Bosnia and Herzegovina',
    name: 'Europe/Sarajevo',
    offset: 'UTC +01:00',
  },
  {
    code: 'BW',
    country: 'Botswana',
    name: 'Africa/Gaborone',
    offset: 'UTC +02:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Araguaina',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Bahia',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Belem',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Boa Vista',
    offset: 'UTC -04:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Campo Grande',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Cuiaba',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Eirunepe',
    offset: 'UTC -05:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Fortaleza',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Maceio',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Manaus',
    offset: 'UTC -04:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Noronha',
    offset: 'UTC -02:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Porto Velho',
    offset: 'UTC -04:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Recife',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Rio Branco',
    offset: 'UTC -05:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Santarem',
    offset: 'UTC -03:00',
  },
  {
    code: 'BR',
    country: 'Brazil',
    name: 'America/Sao Paulo',
    offset: 'UTC -02:00',
  },
  {
    code: 'IO',
    country: 'British Indian Ocean Territory',
    name: 'Indian/Chagos',
    offset: 'UTC +06:00',
  },
  {
    code: 'VG',
    country: 'British Virgin Islands',
    name: 'America/Tortola',
    offset: 'UTC -04:00',
  },
  {
    code: 'BN',
    country: 'Brunei',
    name: 'Asia/Brunei',
    offset: 'UTC +08:00',
  },
  {
    code: 'BG',
    country: 'Bulgaria',
    name: 'Europe/Sofia',
    offset: 'UTC +02:00',
  },
  {
    code: 'BF',
    country: 'Burkina Faso',
    name: 'Africa/Ouagadougou',
    offset: 'UTC',
  },
  {
    code: 'BI',
    country: 'Burundi',
    name: 'Africa/Bujumbura',
    offset: 'UTC +02:00',
  },
  {
    code: 'KH',
    country: 'Cambodia',
    name: 'Asia/Phnom Penh',
    offset: 'UTC +07:00',
  },
  {
    code: 'CM',
    country: 'Cameroon',
    name: 'Africa/Douala',
    offset: 'UTC +01:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Atikokan',
    offset: 'UTC -05:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Blanc-Sablon',
    offset: 'UTC -04:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Cambridge Bay',
    offset: 'UTC -07:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Creston',
    offset: 'UTC -07:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Dawson',
    offset: 'UTC -08:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Dawson Creek',
    offset: 'UTC -07:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Edmonton',
    offset: 'UTC -07:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Fort Nelson',
    offset: 'UTC -07:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Glace Bay',
    offset: 'UTC -04:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Goose Bay',
    offset: 'UTC -04:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Halifax',
    offset: 'UTC -04:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Inuvik',
    offset: 'UTC -07:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Iqaluit',
    offset: 'UTC -05:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Moncton',
    offset: 'UTC -04:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Nipigon',
    offset: 'UTC -05:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Pangnirtung',
    offset: 'UTC -05:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Rainy River',
    offset: 'UTC -06:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Rankin Inlet',
    offset: 'UTC -06:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Regina',
    offset: 'UTC -06:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Resolute',
    offset: 'UTC -06:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/St Johns',
    offset: 'UTC -03:30',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Swift Current',
    offset: 'UTC -06:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Thunder Bay',
    offset: 'UTC -05:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Toronto',
    offset: 'UTC -05:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Vancouver',
    offset: 'UTC -08:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Whitehorse',
    offset: 'UTC -08:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Winnipeg',
    offset: 'UTC -06:00',
  },
  {
    code: 'CA',
    country: 'Canada',
    name: 'America/Yellowknife',
    offset: 'UTC -07:00',
  },
  {
    code: 'CV',
    country: 'Cape Verde',
    name: 'Atlantic/Cape Verde',
    offset: 'UTC -01:00',
  },
  {
    code: 'KY',
    country: 'Cayman Islands',
    name: 'America/Cayman',
    offset: 'UTC -05:00',
  },
  {
    code: 'CF',
    country: 'Central African Republic',
    name: 'Africa/Bangui',
    offset: 'UTC +01:00',
  },
  {
    code: 'TD',
    country: 'Chad',
    name: 'Africa/Ndjamena',
    offset: 'UTC +01:00',
  },
  {
    code: 'CL',
    country: 'Chile',
    name: 'America/Punta Arenas',
    offset: 'UTC -03:00',
  },
  {
    code: 'CL',
    country: 'Chile',
    name: 'America/Santiago',
    offset: 'UTC -03:00',
  },
  {
    code: 'CL',
    country: 'Chile',
    name: 'Pacific/Easter',
    offset: 'UTC -05:00',
  },
  {
    code: 'CN',
    country: 'China',
    name: 'Asia/Shanghai',
    offset: 'UTC +08:00',
  },
  {
    code: 'CN',
    country: 'China',
    name: 'Asia/Urumqi',
    offset: 'UTC +06:00',
  },
  {
    code: 'CX',
    country: 'Christmas Island',
    name: 'Indian/Christmas',
    offset: 'UTC +07:00',
  },
  {
    code: 'CC',
    country: 'Cocos Islands',
    name: 'Indian/Cocos',
    offset: 'UTC +06:30',
  },
  {
    code: 'CO',
    country: 'Colombia',
    name: 'America/Bogota',
    offset: 'UTC -05:00',
  },
  {
    code: 'KM',
    country: 'Comoros',
    name: 'Indian/Comoro',
    offset: 'UTC +03:00',
  },
  {
    code: 'CK',
    country: 'Cook Islands',
    name: 'Pacific/Rarotonga',
    offset: 'UTC -10:00',
  },
  {
    code: 'CR',
    country: 'Costa Rica',
    name: 'America/Costa Rica',
    offset: 'UTC -06:00',
  },
  {
    code: 'HR',
    country: 'Croatia',
    name: 'Europe/Zagreb',
    offset: 'UTC +01:00',
  },
  {
    code: 'CU',
    country: 'Cuba',
    name: 'America/Havana',
    offset: 'UTC -05:00',
  },
  {
    code: 'CW',
    country: 'Curaçao',
    name: 'America/Curacao',
    offset: 'UTC -04:00',
  },
  {
    code: 'CY',
    country: 'Cyprus',
    name: 'Asia/Famagusta',
    offset: 'UTC +02:00',
  },
  {
    code: 'CY',
    country: 'Cyprus',
    name: 'Asia/Nicosia',
    offset: 'UTC +02:00',
  },
  {
    code: 'CZ',
    country: 'Czech Republic',
    name: 'Europe/Prague',
    offset: 'UTC +01:00',
  },
  {
    code: 'CD',
    country: 'Democratic Republic of the Congo',
    name: 'Africa/Kinshasa',
    offset: 'UTC +01:00',
  },
  {
    code: 'CD',
    country: 'Democratic Republic of the Congo',
    name: 'Africa/Lubumbashi',
    offset: 'UTC +02:00',
  },
  {
    code: 'DK',
    country: 'Denmark',
    name: 'Europe/Copenhagen',
    offset: 'UTC +01:00',
  },
  {
    code: 'DJ',
    country: 'Djibouti',
    name: 'Africa/Djibouti',
    offset: 'UTC +03:00',
  },
  {
    code: 'DM',
    country: 'Dominica',
    name: 'America/Dominica',
    offset: 'UTC -04:00',
  },
  {
    code: 'DO',
    country: 'Dominican Republic',
    name: 'America/Santo Domingo',
    offset: 'UTC -04:00',
  },
  {
    code: 'TL',
    country: 'East Timor',
    name: 'Asia/Dili',
    offset: 'UTC +09:00',
  },
  {
    code: 'EC',
    country: 'Ecuador',
    name: 'America/Guayaquil',
    offset: 'UTC -05:00',
  },
  {
    code: 'EC',
    country: 'Ecuador',
    name: 'Pacific/Galapagos',
    offset: 'UTC -06:00',
  },
  {
    code: 'EG',
    country: 'Egypt',
    name: 'Africa/Cairo',
    offset: 'UTC +02:00',
  },
  {
    code: 'SV',
    country: 'El Salvador',
    name: 'America/El Salvador',
    offset: 'UTC -06:00',
  },
  {
    code: 'GQ',
    country: 'Equatorial Guinea',
    name: 'Africa/Malabo',
    offset: 'UTC +01:00',
  },
  {
    code: 'ER',
    country: 'Eritrea',
    name: 'Africa/Asmara',
    offset: 'UTC +03:00',
  },
  {
    code: 'EE',
    country: 'Estonia',
    name: 'Europe/Tallinn',
    offset: 'UTC +02:00',
  },
  {
    code: 'ET',
    country: 'Ethiopia',
    name: 'Africa/Addis Ababa',
    offset: 'UTC +03:00',
  },
  {
    code: 'FK',
    country: 'Falkland Islands',
    name: 'Atlantic/Stanley',
    offset: 'UTC -03:00',
  },
  {
    code: 'FO',
    country: 'Faroe Islands',
    name: 'Atlantic/Faroe',
    offset: 'UTC',
  },
  {
    code: 'FJ',
    country: 'Fiji',
    name: 'Pacific/Fiji',
    offset: 'UTC +13:00',
  },
  {
    code: 'FI',
    country: 'Finland',
    name: 'Europe/Helsinki',
    offset: 'UTC +02:00',
  },
  {
    code: 'FR',
    country: 'France',
    name: 'Europe/Paris',
    offset: 'UTC +01:00',
  },
  {
    code: 'GF',
    country: 'French Guiana',
    name: 'America/Cayenne',
    offset: 'UTC -03:00',
  },
  {
    code: 'PF',
    country: 'French Polynesia',
    name: 'Pacific/Gambier',
    offset: 'UTC -09:00',
  },
  {
    code: 'PF',
    country: 'French Polynesia',
    name: 'Pacific/Marquesas',
    offset: 'UTC -09:30',
  },
  {
    code: 'PF',
    country: 'French Polynesia',
    name: 'Pacific/Tahiti',
    offset: 'UTC -10:00',
  },
  {
    code: 'TF',
    country: 'French Southern Territories',
    name: 'Indian/Kerguelen',
    offset: 'UTC +05:00',
  },
  {
    code: 'GA',
    country: 'Gabon',
    name: 'Africa/Libreville',
    offset: 'UTC +01:00',
  },
  {
    code: 'GM',
    country: 'Gambia',
    name: 'Africa/Banjul',
    offset: 'UTC',
  },
  {
    code: 'GE',
    country: 'Georgia',
    name: 'Asia/Tbilisi',
    offset: 'UTC +04:00',
  },
  {
    code: 'DE',
    country: 'Germany',
    name: 'Europe/Berlin',
    offset: 'UTC +01:00',
  },
  {
    code: 'DE',
    country: 'Germany',
    name: 'Europe/Busingen',
    offset: 'UTC +01:00',
  },
  {
    code: 'GH',
    country: 'Ghana',
    name: 'Africa/Accra',
    offset: 'UTC',
  },
  {
    code: 'GI',
    country: 'Gibraltar',
    name: 'Europe/Gibraltar',
    offset: 'UTC +01:00',
  },
  {
    code: 'GR',
    country: 'Greece',
    name: 'Europe/Athens',
    offset: 'UTC +02:00',
  },
  {
    code: 'GL',
    country: 'Greenland',
    name: 'America/Danmarkshavn',
    offset: 'UTC',
  },
  {
    code: 'GL',
    country: 'Greenland',
    name: 'America/Godthab',
    offset: 'UTC -03:00',
  },
  {
    code: 'GL',
    country: 'Greenland',
    name: 'America/Scoresbysund',
    offset: 'UTC -01:00',
  },
  {
    code: 'GL',
    country: 'Greenland',
    name: 'America/Thule',
    offset: 'UTC -04:00',
  },
  {
    code: 'GD',
    country: 'Grenada',
    name: 'America/Grenada',
    offset: 'UTC -04:00',
  },
  {
    code: 'GP',
    country: 'Guadeloupe',
    name: 'America/Guadeloupe',
    offset: 'UTC -04:00',
  },
  {
    code: 'GU',
    country: 'Guam',
    name: 'Pacific/Guam',
    offset: 'UTC +10:00',
  },
  {
    code: 'GT',
    country: 'Guatemala',
    name: 'America/Guatemala',
    offset: 'UTC -06:00',
  },
  {
    code: 'GG',
    country: 'Guernsey',
    name: 'Europe/Guernsey',
    offset: 'UTC',
  },
  {
    code: 'GN',
    country: 'Guinea',
    name: 'Africa/Conakry',
    offset: 'UTC',
  },
  {
    code: 'GW',
    country: 'Guinea-Bissau',
    name: 'Africa/Bissau',
    offset: 'UTC',
  },
  {
    code: 'GY',
    country: 'Guyana',
    name: 'America/Guyana',
    offset: 'UTC -04:00',
  },
  {
    code: 'HT',
    country: 'Haiti',
    name: 'America/Port-au-Prince',
    offset: 'UTC -05:00',
  },
  {
    code: 'HN',
    country: 'Honduras',
    name: 'America/Tegucigalpa',
    offset: 'UTC -06:00',
  },
  {
    code: 'HK',
    country: 'Hong Kong',
    name: 'Asia/Hong Kong',
    offset: 'UTC +08:00',
  },
  {
    code: 'HU',
    country: 'Hungary',
    name: 'Europe/Budapest',
    offset: 'UTC +01:00',
  },
  {
    code: 'IS',
    country: 'Iceland',
    name: 'Atlantic/Reykjavik',
    offset: 'UTC',
  },
  {
    code: 'IN',
    country: 'India',
    name: 'Asia/Kolkata',
    offset: 'UTC +05:30',
  },
  {
    code: 'ID',
    country: 'Indonesia',
    name: 'Asia/Jakarta',
    offset: 'UTC +07:00',
  },
  {
    code: 'ID',
    country: 'Indonesia',
    name: 'Asia/Jayapura',
    offset: 'UTC +09:00',
  },
  {
    code: 'ID',
    country: 'Indonesia',
    name: 'Asia/Makassar',
    offset: 'UTC +08:00',
  },
  {
    code: 'ID',
    country: 'Indonesia',
    name: 'Asia/Pontianak',
    offset: 'UTC +07:00',
  },
  {
    code: 'IR',
    country: 'Iran',
    name: 'Asia/Tehran',
    offset: 'UTC +03:30',
  },
  {
    code: 'IQ',
    country: 'Iraq',
    name: 'Asia/Baghdad',
    offset: 'UTC +03:00',
  },
  {
    code: 'IE',
    country: 'Ireland',
    name: 'Europe/Dublin',
    offset: 'UTC',
  },
  {
    code: 'IM',
    country: 'Isle of Man',
    name: 'Europe/Isle of Man',
    offset: 'UTC',
  },
  {
    code: 'IL',
    country: 'Israel',
    name: 'Asia/Jerusalem',
    offset: 'UTC +02:00',
  },
  {
    code: 'IT',
    country: 'Italy',
    name: 'Europe/Rome',
    offset: 'UTC +01:00',
  },
  {
    code: 'CI',
    country: 'Ivory Coast',
    name: 'Africa/Abidjan',
    offset: 'UTC',
  },
  {
    code: 'JM',
    country: 'Jamaica',
    name: 'America/Jamaica',
    offset: 'UTC -05:00',
  },
  {
    code: 'JP',
    country: 'Japan',
    name: 'Asia/Tokyo',
    offset: 'UTC +09:00',
  },
  {
    code: 'JE',
    country: 'Jersey',
    name: 'Europe/Jersey',
    offset: 'UTC',
  },
  {
    code: 'JO',
    country: 'Jordan',
    name: 'Asia/Amman',
    offset: 'UTC +02:00',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    name: 'Asia/Almaty',
    offset: 'UTC +06:00',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    name: 'Asia/Aqtau',
    offset: 'UTC +05:00',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    name: 'Asia/Aqtobe',
    offset: 'UTC +05:00',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    name: 'Asia/Atyrau',
    offset: 'UTC +05:00',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    name: 'Asia/Oral',
    offset: 'UTC +05:00',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    name: 'Asia/Qyzylorda',
    offset: 'UTC +06:00',
  },
  {
    code: 'KE',
    country: 'Kenya',
    name: 'Africa/Nairobi',
    offset: 'UTC +03:00',
  },
  {
    code: 'KI',
    country: 'Kiribati',
    name: 'Pacific/Enderbury',
    offset: 'UTC +13:00',
  },
  {
    code: 'KI',
    country: 'Kiribati',
    name: 'Pacific/Kiritimati',
    offset: 'UTC +14:00',
  },
  {
    code: 'KI',
    country: 'Kiribati',
    name: 'Pacific/Tarawa',
    offset: 'UTC +12:00',
  },
  {
    code: 'KW',
    country: 'Kuwait',
    name: 'Asia/Kuwait',
    offset: 'UTC +03:00',
  },
  {
    code: 'KG',
    country: 'Kyrgyzstan',
    name: 'Asia/Bishkek',
    offset: 'UTC +06:00',
  },
  {
    code: 'LA',
    country: 'Laos',
    name: 'Asia/Vientiane',
    offset: 'UTC +07:00',
  },
  {
    code: 'LV',
    country: 'Latvia',
    name: 'Europe/Riga',
    offset: 'UTC +02:00',
  },
  {
    code: 'LB',
    country: 'Lebanon',
    name: 'Asia/Beirut',
    offset: 'UTC +02:00',
  },
  {
    code: 'LS',
    country: 'Lesotho',
    name: 'Africa/Maseru',
    offset: 'UTC +02:00',
  },
  {
    code: 'LR',
    country: 'Liberia',
    name: 'Africa/Monrovia',
    offset: 'UTC',
  },
  {
    code: 'LY',
    country: 'Libya',
    name: 'Africa/Tripoli',
    offset: 'UTC +02:00',
  },
  {
    code: 'LI',
    country: 'Liechtenstein',
    name: 'Europe/Vaduz',
    offset: 'UTC +01:00',
  },
  {
    code: 'LT',
    country: 'Lithuania',
    name: 'Europe/Vilnius',
    offset: 'UTC +02:00',
  },
  {
    code: 'LU',
    country: 'Luxembourg',
    name: 'Europe/Luxembourg',
    offset: 'UTC +01:00',
  },
  {
    code: 'MO',
    country: 'Macao',
    name: 'Asia/Macau',
    offset: 'UTC +08:00',
  },
  {
    code: 'MK',
    country: 'Macedonia',
    name: 'Europe/Skopje',
    offset: 'UTC +01:00',
  },
  {
    code: 'MG',
    country: 'Madagascar',
    name: 'Indian/Antananarivo',
    offset: 'UTC +03:00',
  },
  {
    code: 'MW',
    country: 'Malawi',
    name: 'Africa/Blantyre',
    offset: 'UTC +02:00',
  },
  {
    code: 'MY',
    country: 'Malaysia',
    name: 'Asia/Kuala Lumpur',
    offset: 'UTC +08:00',
  },
  {
    code: 'MY',
    country: 'Malaysia',
    name: 'Asia/Kuching',
    offset: 'UTC +08:00',
  },
  {
    code: 'MV',
    country: 'Maldives',
    name: 'Indian/Maldives',
    offset: 'UTC +05:00',
  },
  {
    code: 'ML',
    country: 'Mali',
    name: 'Africa/Bamako',
    offset: 'UTC',
  },
  {
    code: 'MT',
    country: 'Malta',
    name: 'Europe/Malta',
    offset: 'UTC +01:00',
  },
  {
    code: 'MH',
    country: 'Marshall Islands',
    name: 'Pacific/Kwajalein',
    offset: 'UTC +12:00',
  },
  {
    code: 'MH',
    country: 'Marshall Islands',
    name: 'Pacific/Majuro',
    offset: 'UTC +12:00',
  },
  {
    code: 'MQ',
    country: 'Martinique',
    name: 'America/Martinique',
    offset: 'UTC -04:00',
  },
  {
    code: 'MR',
    country: 'Mauritania',
    name: 'Africa/Nouakchott',
    offset: 'UTC',
  },
  {
    code: 'MU',
    country: 'Mauritius',
    name: 'Indian/Mauritius',
    offset: 'UTC +04:00',
  },
  {
    code: 'YT',
    country: 'Mayotte',
    name: 'Indian/Mayotte',
    offset: 'UTC +03:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Bahia Banderas',
    offset: 'UTC -06:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Cancun',
    offset: 'UTC -05:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Chihuahua',
    offset: 'UTC -07:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Hermosillo',
    offset: 'UTC -07:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Matamoros',
    offset: 'UTC -06:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Mazatlan',
    offset: 'UTC -07:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Merida',
    offset: 'UTC -06:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Mexico City',
    offset: 'UTC -06:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Monterrey',
    offset: 'UTC -06:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Ojinaga',
    offset: 'UTC -07:00',
  },
  {
    code: 'MX',
    country: 'Mexico',
    name: 'America/Tijuana',
    offset: 'UTC -08:00',
  },
  {
    code: 'FM',
    country: 'Micronesia',
    name: 'Pacific/Chuuk',
    offset: 'UTC +10:00',
  },
  {
    code: 'FM',
    country: 'Micronesia',
    name: 'Pacific/Kosrae',
    offset: 'UTC +11:00',
  },
  {
    code: 'FM',
    country: 'Micronesia',
    name: 'Pacific/Pohnpei',
    offset: 'UTC +11:00',
  },
  {
    code: 'MD',
    country: 'Moldova',
    name: 'Europe/Chisinau',
    offset: 'UTC +02:00',
  },
  {
    code: 'MC',
    country: 'Monaco',
    name: 'Europe/Monaco',
    offset: 'UTC +01:00',
  },
  {
    code: 'MN',
    country: 'Mongolia',
    name: 'Asia/Choibalsan',
    offset: 'UTC +08:00',
  },
  {
    code: 'MN',
    country: 'Mongolia',
    name: 'Asia/Hovd',
    offset: 'UTC +07:00',
  },
  {
    code: 'MN',
    country: 'Mongolia',
    name: 'Asia/Ulaanbaatar',
    offset: 'UTC +08:00',
  },
  {
    code: 'ME',
    country: 'Montenegro',
    name: 'Europe/Podgorica',
    offset: 'UTC +01:00',
  },
  {
    code: 'MS',
    country: 'Montserrat',
    name: 'America/Montserrat',
    offset: 'UTC -04:00',
  },
  {
    code: 'MA',
    country: 'Morocco',
    name: 'Africa/Casablanca',
    offset: 'UTC',
  },
  {
    code: 'MZ',
    country: 'Mozambique',
    name: 'Africa/Maputo',
    offset: 'UTC +02:00',
  },
  {
    code: 'MM',
    country: 'Myanmar',
    name: 'Asia/Yangon',
    offset: 'UTC +06:30',
  },
  {
    code: 'NA',
    country: 'Namibia',
    name: 'Africa/Windhoek',
    offset: 'UTC +02:00',
  },
  {
    code: 'NR',
    country: 'Nauru',
    name: 'Pacific/Nauru',
    offset: 'UTC +12:00',
  },
  {
    code: 'NP',
    country: 'Nepal',
    name: 'Asia/Kathmandu',
    offset: 'UTC +05:45',
  },
  {
    code: 'NL',
    country: 'Netherlands',
    name: 'Europe/Amsterdam',
    offset: 'UTC +01:00',
  },
  {
    code: 'NC',
    country: 'New Caledonia',
    name: 'Pacific/Noumea',
    offset: 'UTC +11:00',
  },
  {
    code: 'NZ',
    country: 'New Zealand',
    name: 'Pacific/Auckland',
    offset: 'UTC +13:00',
  },
  {
    code: 'NZ',
    country: 'New Zealand',
    name: 'Pacific/Chatham',
    offset: 'UTC +13:45',
  },
  {
    code: 'NI',
    country: 'Nicaragua',
    name: 'America/Managua',
    offset: 'UTC -06:00',
  },
  {
    code: 'NE',
    country: 'Niger',
    name: 'Africa/Niamey',
    offset: 'UTC +01:00',
  },
  {
    code: 'NG',
    country: 'Nigeria',
    name: 'Africa/Lagos',
    offset: 'UTC +01:00',
  },
  {
    code: 'NU',
    country: 'Niue',
    name: 'Pacific/Niue',
    offset: 'UTC -11:00',
  },
  {
    code: 'NF',
    country: 'Norfolk Island',
    name: 'Pacific/Norfolk',
    offset: 'UTC +11:00',
  },
  {
    code: 'KP',
    country: 'North Korea',
    name: 'Asia/Pyongyang',
    offset: 'UTC +08:30',
  },
  {
    code: 'MP',
    country: 'Northern Mariana Islands',
    name: 'Pacific/Saipan',
    offset: 'UTC +10:00',
  },
  {
    code: 'NO',
    country: 'Norway',
    name: 'Europe/Oslo',
    offset: 'UTC +01:00',
  },
  {
    code: 'OM',
    country: 'Oman',
    name: 'Asia/Muscat',
    offset: 'UTC +04:00',
  },
  {
    code: 'PK',
    country: 'Pakistan',
    name: 'Asia/Karachi',
    offset: 'UTC +05:00',
  },
  {
    code: 'PW',
    country: 'Palau',
    name: 'Pacific/Palau',
    offset: 'UTC +09:00',
  },
  {
    code: 'PS',
    country: 'Palestinian Territory',
    name: 'Asia/Gaza',
    offset: 'UTC +02:00',
  },
  {
    code: 'PS',
    country: 'Palestinian Territory',
    name: 'Asia/Hebron',
    offset: 'UTC +02:00',
  },
  {
    code: 'PA',
    country: 'Panama',
    name: 'America/Panama',
    offset: 'UTC -05:00',
  },
  {
    code: 'PG',
    country: 'Papua New Guinea',
    name: 'Pacific/Bougainville',
    offset: 'UTC +11:00',
  },
  {
    code: 'PG',
    country: 'Papua New Guinea',
    name: 'Pacific/Port Moresby',
    offset: 'UTC +10:00',
  },
  {
    code: 'PY',
    country: 'Paraguay',
    name: 'America/Asuncion',
    offset: 'UTC -03:00',
  },
  {
    code: 'PE',
    country: 'Peru',
    name: 'America/Lima',
    offset: 'UTC -05:00',
  },
  {
    code: 'PH',
    country: 'Philippines',
    name: 'Asia/Manila',
    offset: 'UTC +08:00',
  },
  {
    code: 'PN',
    country: 'Pitcairn',
    name: 'Pacific/Pitcairn',
    offset: 'UTC -08:00',
  },
  {
    code: 'PL',
    country: 'Poland',
    name: 'Europe/Warsaw',
    offset: 'UTC +01:00',
  },
  {
    code: 'PT',
    country: 'Portugal',
    name: 'Atlantic/Azores',
    offset: 'UTC -01:00',
  },
  {
    code: 'PT',
    country: 'Portugal',
    name: 'Atlantic/Madeira',
    offset: 'UTC',
  },
  {
    code: 'PT',
    country: 'Portugal',
    name: 'Europe/Lisbon',
    offset: 'UTC',
  },
  {
    code: 'PR',
    country: 'Puerto Rico',
    name: 'America/Puerto Rico',
    offset: 'UTC -04:00',
  },
  {
    code: 'QA',
    country: 'Qatar',
    name: 'Asia/Qatar',
    offset: 'UTC +04:00',
  },
  {
    code: 'CG',
    country: 'Republic of the Congo',
    name: 'Africa/Brazzaville',
    offset: 'UTC +01:00',
  },
  {
    code: 'RE',
    country: 'Reunion',
    name: 'Indian/Reunion',
    offset: 'UTC +04:00',
  },
  {
    code: 'RO',
    country: 'Romania',
    name: 'Europe/Bucharest',
    offset: 'UTC +02:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Anadyr',
    offset: 'UTC +12:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Barnaul',
    offset: 'UTC +07:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Chita',
    offset: 'UTC +09:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Irkutsk',
    offset: 'UTC +08:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Kamchatka',
    offset: 'UTC +12:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Khandyga',
    offset: 'UTC +09:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Krasnoyarsk',
    offset: 'UTC +07:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Magadan',
    offset: 'UTC +11:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Novokuznetsk',
    offset: 'UTC +07:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Novosibirsk',
    offset: 'UTC +07:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Omsk',
    offset: 'UTC +06:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Sakhalin',
    offset: 'UTC +11:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Srednekolymsk',
    offset: 'UTC +11:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Tomsk',
    offset: 'UTC +07:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Ust-Nera',
    offset: 'UTC +10:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Vladivostok',
    offset: 'UTC +10:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Yakutsk',
    offset: 'UTC +09:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Asia/Yekaterinburg',
    offset: 'UTC +05:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Astrakhan',
    offset: 'UTC +04:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Kaliningrad',
    offset: 'UTC +02:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Kirov',
    offset: 'UTC +03:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Moscow',
    offset: 'UTC +03:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Samara',
    offset: 'UTC +04:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Saratov',
    offset: 'UTC +04:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Simferopol',
    offset: 'UTC +03:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Ulyanovsk',
    offset: 'UTC +04:00',
  },
  {
    code: 'RU',
    country: 'Russian Federation',
    name: 'Europe/Volgograd',
    offset: 'UTC +03:00',
  },
  {
    code: 'RW',
    country: 'Rwanda',
    name: 'Africa/Kigali',
    offset: 'UTC +02:00',
  },
  {
    code: 'BL',
    country: 'Saint Barthélemy',
    name: 'America/St Barthelemy',
    offset: 'UTC -04:00',
  },
  {
    code: 'SH',
    country: 'Saint Helena',
    name: 'Atlantic/St Helena',
    offset: 'UTC',
  },
  {
    code: 'KN',
    country: 'Saint Kitts and Nevis',
    name: 'America/St Kitts',
    offset: 'UTC -04:00',
  },
  {
    code: 'LC',
    country: 'Saint Lucia',
    name: 'America/St Lucia',
    offset: 'UTC -04:00',
  },
  {
    code: 'MF',
    country: 'Saint Martin',
    name: 'America/Marigot',
    offset: 'UTC -04:00',
  },
  {
    code: 'PM',
    country: 'Saint Pierre and Miquelon',
    name: 'America/Miquelon',
    offset: 'UTC -03:00',
  },
  {
    code: 'VC',
    country: 'Saint Vincent and the Grenadines',
    name: 'America/St Vincent',
    offset: 'UTC -04:00',
  },
  {
    code: 'WS',
    country: 'Samoa',
    name: 'Pacific/Apia',
    offset: 'UTC +14:00',
  },
  {
    code: 'SM',
    country: 'San Marino',
    name: 'Europe/San Marino',
    offset: 'UTC +01:00',
  },
  {
    code: 'ST',
    country: 'Sao Tome and Principe',
    name: 'Africa/Sao Tome',
    offset: 'UTC',
  },
  {
    code: 'SA',
    country: 'Saudi Arabia',
    name: 'Asia/Riyadh',
    offset: 'UTC +03:00',
  },
  {
    code: 'SN',
    country: 'Senegal',
    name: 'Africa/Dakar',
    offset: 'UTC',
  },
  {
    code: 'RS',
    country: 'Serbia',
    name: 'Europe/Belgrade',
    offset: 'UTC +01:00',
  },
  {
    code: 'SC',
    country: 'Seychelles',
    name: 'Indian/Mahe',
    offset: 'UTC +04:00',
  },
  {
    code: 'SL',
    country: 'Sierra Leone',
    name: 'Africa/Freetown',
    offset: 'UTC',
  },
  {
    code: 'SG',
    country: 'Singapore',
    name: 'Asia/Singapore',
    offset: 'UTC +08:00',
  },
  {
    code: 'SX',
    country: 'Sint Maarten',
    name: 'America/Lower Princes',
    offset: 'UTC -04:00',
  },
  {
    code: 'SK',
    country: 'Slovakia',
    name: 'Europe/Bratislava',
    offset: 'UTC +01:00',
  },
  {
    code: 'SI',
    country: 'Slovenia',
    name: 'Europe/Ljubljana',
    offset: 'UTC +01:00',
  },
  {
    code: 'SB',
    country: 'Solomon Islands',
    name: 'Pacific/Guadalcanal',
    offset: 'UTC +11:00',
  },
  {
    code: 'SO',
    country: 'Somalia',
    name: 'Africa/Mogadishu',
    offset: 'UTC +03:00',
  },
  {
    code: 'ZA',
    country: 'South Africa',
    name: 'Africa/Johannesburg',
    offset: 'UTC +02:00',
  },
  {
    code: 'GS',
    country: 'South Georgia and the South Sandwich Islands',
    name: 'Atlantic/South Georgia',
    offset: 'UTC -02:00',
  },
  {
    code: 'KR',
    country: 'South Korea',
    name: 'Asia/Seoul',
    offset: 'UTC +09:00',
  },
  {
    code: 'SS',
    country: 'South Sudan',
    name: 'Africa/Juba',
    offset: 'UTC +03:00',
  },
  {
    code: 'ES',
    country: 'Spain',
    name: 'Africa/Ceuta',
    offset: 'UTC +01:00',
  },
  {
    code: 'ES',
    country: 'Spain',
    name: 'Atlantic/Canary',
    offset: 'UTC',
  },
  {
    code: 'ES',
    country: 'Spain',
    name: 'Europe/Madrid',
    offset: 'UTC +01:00',
  },
  {
    code: 'LK',
    country: 'Sri Lanka',
    name: 'Asia/Colombo',
    offset: 'UTC +05:30',
  },
  {
    code: 'SD',
    country: 'Sudan',
    name: 'Africa/Khartoum',
    offset: 'UTC +02:00',
  },
  {
    code: 'SR',
    country: 'Suricountry',
    name: 'America/Paramaribo',
    offset: 'UTC -03:00',
  },
  {
    code: 'SJ',
    country: 'Svalbard and Jan Mayen',
    name: 'Arctic/Longyearbyen',
    offset: 'UTC +01:00',
  },
  {
    code: 'SZ',
    country: 'Swaziland',
    name: 'Africa/Mbabane',
    offset: 'UTC +02:00',
  },
  {
    code: 'SE',
    country: 'Sweden',
    name: 'Europe/Stockholm',
    offset: 'UTC +01:00',
  },
  {
    code: 'CH',
    country: 'Switzerland',
    name: 'Europe/Zurich',
    offset: 'UTC +01:00',
  },
  {
    code: 'SY',
    country: 'Syria',
    name: 'Asia/Damascus',
    offset: 'UTC +02:00',
  },
  {
    code: 'TW',
    country: 'Taiwan',
    name: 'Asia/Taipei',
    offset: 'UTC +08:00',
  },
  {
    code: 'TJ',
    country: 'Tajikistan',
    name: 'Asia/Dushanbe',
    offset: 'UTC +05:00',
  },
  {
    code: 'TZ',
    country: 'Tanzania',
    name: 'Africa/Dar es Salaam',
    offset: 'UTC +03:00',
  },
  {
    code: 'TH',
    country: 'Thailand',
    name: 'Asia/Bangkok',
    offset: 'UTC +07:00',
  },
  {
    code: 'TG',
    country: 'Togo',
    name: 'Africa/Lome',
    offset: 'UTC',
  },
  {
    code: 'TK',
    country: 'Tokelau',
    name: 'Pacific/Fakaofo',
    offset: 'UTC +13:00',
  },
  {
    code: 'TO',
    country: 'Tonga',
    name: 'Pacific/Tongatapu',
    offset: 'UTC +13:00',
  },
  {
    code: 'TT',
    country: 'Trinidad and Tobago',
    name: 'America/Port of Spain',
    offset: 'UTC -04:00',
  },
  {
    code: 'TN',
    country: 'Tunisia',
    name: 'Africa/Tunis',
    offset: 'UTC +01:00',
  },
  {
    code: 'TR',
    country: 'Turkey',
    name: 'Europe/Istanbul',
    offset: 'UTC +03:00',
  },
  {
    code: 'TM',
    country: 'Turkmenistan',
    name: 'Asia/Ashgabat',
    offset: 'UTC +05:00',
  },
  {
    code: 'TC',
    country: 'Turks and Caicos Islands',
    name: 'America/Grand Turk',
    offset: 'UTC -05:00',
  },
  {
    code: 'TV',
    country: 'Tuvalu',
    name: 'Pacific/Funafuti',
    offset: 'UTC +12:00',
  },
  {
    code: 'VI',
    country: 'U.S. Virgin Islands',
    name: 'America/St Thomas',
    offset: 'UTC -04:00',
  },
  {
    code: 'UG',
    country: 'Uganda',
    name: 'Africa/Kampala',
    offset: 'UTC +03:00',
  },
  {
    code: 'UA',
    country: 'Ukraine',
    name: 'Europe/Kiev',
    offset: 'UTC +02:00',
  },
  {
    code: 'UA',
    country: 'Ukraine',
    name: 'Europe/Uzhgorod',
    offset: 'UTC +02:00',
  },
  {
    code: 'UA',
    country: 'Ukraine',
    name: 'Europe/Zaporozhye',
    offset: 'UTC +02:00',
  },
  {
    code: 'AE',
    country: 'United Arab Emirates',
    name: 'Asia/Dubai',
    offset: 'UTC +04:00',
  },
  {
    code: 'GB',
    country: 'United Kingdom',
    name: 'Europe/London',
    offset: 'UTC',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Adak',
    offset: 'UTC -10:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Anchorage',
    offset: 'UTC -09:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Boise',
    offset: 'UTC -07:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Chicago',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Denver',
    offset: 'UTC -07:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Detroit',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Indianapolis',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Knox',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Marengo',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Petersburg',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Tell City',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Vevay',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Vincennes',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Indiana/Winamac',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Juneau',
    offset: 'UTC -09:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Kentucky/Louisville',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Kentucky/Monticello',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Los Angeles',
    offset: 'UTC -08:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Menominee',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Metlakatla',
    offset: 'UTC -09:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/New York',
    offset: 'UTC -05:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Nome',
    offset: 'UTC -09:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/North Dakota/Beulah',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/North Dakota/Center',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/North Dakota/New Salem',
    offset: 'UTC -06:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Phoenix',
    offset: 'UTC -07:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Sitka',
    offset: 'UTC -09:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'America/Yakutat',
    offset: 'UTC -09:00',
  },
  {
    code: 'US',
    country: 'United States',
    name: 'Pacific/Honolulu',
    offset: 'UTC -10:00',
  },
  {
    code: 'UM',
    country: 'United States Minor Outlying Islands',
    name: 'Pacific/Midway',
    offset: 'UTC -11:00',
  },
  {
    code: 'UM',
    country: 'United States Minor Outlying Islands',
    name: 'Pacific/Wake',
    offset: 'UTC +12:00',
  },
  {
    code: 'UY',
    country: 'Uruguay',
    name: 'America/Montevideo',
    offset: 'UTC -03:00',
  },
  {
    code: 'UZ',
    country: 'Uzbekistan',
    name: 'Asia/Samarkand',
    offset: 'UTC +05:00',
  },
  {
    code: 'UZ',
    country: 'Uzbekistan',
    name: 'Asia/Tashkent',
    offset: 'UTC +05:00',
  },
  {
    code: 'VU',
    country: 'Vanuatu',
    name: 'Pacific/Efate',
    offset: 'UTC +11:00',
  },
  {
    code: 'VA',
    country: 'Vatican',
    name: 'Europe/Vatican',
    offset: 'UTC +01:00',
  },
  {
    code: 'VE',
    country: 'Venezuela',
    name: 'America/Caracas',
    offset: 'UTC -04:00',
  },
  {
    code: 'VN',
    country: 'Viet Nam',
    name: 'Asia/Ho Chi Minh',
    offset: 'UTC +07:00',
  },
  {
    code: 'WF',
    country: 'Wallis and Futuna',
    name: 'Pacific/Wallis',
    offset: 'UTC +12:00',
  },
  {
    code: 'EH',
    country: 'Western Sahara',
    name: 'Africa/El Aaiun',
    offset: 'UTC',
  },
  {
    code: 'YE',
    country: 'Yemen',
    name: 'Asia/Aden',
    offset: 'UTC +03:00',
  },
  {
    code: 'ZM',
    country: 'Zambia',
    name: 'Africa/Lusaka',
    offset: 'UTC +02:00',
  },
  {
    code: 'ZW',
    country: 'Zimbabwe',
    name: 'Africa/Harare',
    offset: 'UTC +02:00',
  },
];

export default TIMEZONES;
