import { ApiFormBuilderValidationRule } from 'components/Workflow/WorkflowFormBuilder';
import { evaluateValidationConditional } from '../utils/evaluateValidationConditional';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { email } from '../../../forms/consumer/validators';
import { ApiFormBuilderField } from '../types/api/ApiFormField';
import { isEqual } from 'lodash';

const addRhfValidationRule = (
  rules: ControllerProps['rules'],
  rule: ApiFormBuilderValidationRule,
  fieldName: string,
  oldValue?: any,
): ControllerProps['rules'] => {
  if (!rules) rules = {};

  switch (rule.type) {
    case 'required':
      rules.required = `${fieldName} is required`;
      break;
    case 'min':
      rules.min = {
        value: rule.value!,
        message: `A minimum of ${rule.value} is required for ${fieldName}`,
      };
      break;
    case 'max':
      rules.max = {
        value: rule.value!,
        message: `A maximum of ${rule.value} is required for ${fieldName}`,
      };
      break;
    case 'email':
      rules.validate = {
        ...(rules.validate as unknown as Record<string, () => boolean>),
        email: (val) => (email(val) ? true : 'Email is not valid'),
      };
      break;
    case 'equal':
      rules.validate = {
        ...(rules.validate as unknown as Record<string, () => boolean>),
        equal: (val) => (val === rule.value ? true : `${fieldName} does not match ${rule.value}`),
      };
      break;
    case 'regex':
      rules.pattern = {
        value: new RegExp(rule.value! as unknown as string),
        message: `${fieldName} does not match pattern`,
      };
      break;
    // Rule type used to show an error when a step has been reviewed and has changes
    case 'changed':
      rules.validate = {
        ...(rules.validate as unknown as Record<string, () => boolean>),
        changed: (val) => {
          return !isEqual(oldValue, val) ? true : rule.message;
        },
      };
      break;
  }

  return rules;
};

export const getValidationRules = (
  fieldMap: Map<string, ApiFormBuilderField>,
  rules: ApiFormBuilderValidationRule[],
  fieldName: string,
  oldValue?: any,
): ControllerProps['rules'] => {
  return rules.reduce(
    (returnedRules, rule) => {
      // default to 'always' occurrence
      if (
        !rule.occurrence ||
        rule.occurrence === 'always' ||
        evaluateValidationConditional(fieldMap, rule.occurrenceCondition!, rule.occurrenceValue!, rule.occurrenceReference!)
      ) {
        return addRhfValidationRule(returnedRules, rule, fieldName, oldValue);
      }
      return returnedRules;
    },
    {} as ControllerProps['rules'],
  );
};
