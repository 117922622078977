import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const api: any = {};
const { apiUrl } = getConfig();

const userAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/auth/login?school={slug}`,
});

api.POST = userAPI.wrap(defaultRequest, {
  method: 'POST',
});

api.PUT = userAPI.wrap(defaultRequest, {
  method: 'PUT',
});

export default api;
