import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loader from 'components/Loader';
import { LinkEnquiriesTable } from 'enquiries/LinkEnquiriesTable';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { linkLeadWithApplication, resetSearchForLeadsByApplicant } from 'app/actions/applicationActions';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

export const LinkWithEnquiryDialog = ({ onToggle, applicationId, active }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.application.leadSearchStatus);
  const selectedEnquiryId = useAppSelector((state) => state.application.selectedLeadId);

  useEffect(() => {
    if (status === 'success') {
      onToggle();
      dispatch(resetSearchForLeadsByApplicant());
    }
  }, [dispatch, status, onToggle]);

  const handleLinkEnquiry = () => {
    dispatch(linkLeadWithApplication(applicationId, selectedEnquiryId, schoolSlug));
  };

  const renderLoading = () => {
    return <Loader center />;
  };

  const renderResults = () => {
    const isLoading = status === 'loading';
    const isDisabled = isLoading || !selectedEnquiryId;

    return (
      <form action="" method="post" className="animated animated--onload anim__slide--upquick" onSubmit={handleLinkEnquiry}>
        <LinkEnquiriesTable />
        <DialogActions>
          <LoadingButton variant="contained" type="submit" loading={isLoading} disabled={isDisabled}>
            Link applicant
          </LoadingButton>
          <Button variant="contained" onClick={onToggle}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  };

  return (
    // @ts-expect-error TS(2322): Type '"800px"' is not assignable to type 'false | ... Remove this comment to see the full error message
    <Dialog fullWidth maxWidth="800px" open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Search for an Enquiry to link this applicant profile to
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            disabled={status === 'searching'}
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      {status === 'searching' ? renderLoading() : renderResults()}
    </Dialog>
  );
};
