import fileDownload from 'js-file-download';
import { AppContext } from 'app/AppContext';
import { fileSize } from 'utils/fileSize';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { getConfig } from '../../utils/config';
import { useContext } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { GradientIcon } from 'components/GradientIcon/GradientIcon';

export const Upload = ({ canRemove, directLink = false, index, name, onRemove, preview, size, type, versioned }) => {
  const appContext = useContext(AppContext);
  const { apiUrl } = getConfig();
  const theme = useTheme();

  const handleRemove = (index, e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove(index);
  };

  const handleDownload = () => {
    const endpoint = `${apiUrl}/schools/${preview}`;
    const params = {
      method: 'get',
      headers: appContext.headers,
      responseType: 'blob',
    };

    fetch(endpoint, params)
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(new Error(response.statusText));
        }
        return Promise.resolve(response);
      })
      .then((response) => response.blob())
      .then((blob) => fileDownload(blob, name))
      .catch(appContext.operations.onError);
  };

  const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'image/webp'].includes(type);

  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={theme.spacing(1)}
        sx={{ mb: 0.5, p: 2 }}
      >
        <Stack width="100%" direction="row" spacing={2} pb={1}>
          <GradientIcon color="primary" IconComponent={isImage ? PhotoOutlinedIcon : DescriptionOutlinedIcon} />
          <Stack width="100%" m={1}>
            <Typography>{name}</Typography>
            <Typography variant="subtitle2" color="secondary" fontSize="small">
              Uploaded - {fileSize(size, 2)}
            </Typography>
          </Stack>
          <Box flex="1" />
          <CheckCircleIcon color="success" />
        </Stack>
      </Stack>
      <Stack direction="row">
        <Tooltip title={`View file`}>
          <IconButton
            href={directLink ? preview : '#'}
            target={directLink ? '_blank' : '_self'}
            rel="noopener noreferrer"
            onClick={() => {
              if (directLink) return false;
              handleDownload();
            }}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
        {canRemove && (
          <Tooltip title={`Remove file`}>
            <IconButton aria-label="Remove file" onClick={(event) => handleRemove(index, event)} color="error">
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};

export default Upload;
