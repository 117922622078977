import { getConfig } from './config';
import { AuthUser, getUserId } from './user';

interface IntercomData {
  api_base: string;
  app_id: string;
  user_id: string;
  user_type: string;
  name: string;
  email: string;
  created_at: string;
  school: string;
  current_application_id?: string;
}

export const initIntercom = () => {
  const { environment, intercomAppId } = getConfig();

  if (environment === 'local') {
    console.info(`Skipping Intercom initialisation in local environment`);
    return;
  }
  if (!intercomAppId) return;

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: intercomAppId!,
  });
};

export const getIntercomData = (user: AuthUser, schoolName: string, intercomAppId: string, applicationId?: string) => {
  const intercomData: IntercomData = {
    api_base: 'https://api-iam.intercom.io',
    app_id: intercomAppId,
    user_id: getUserId(user),
    user_type: user.userData.roles[0],
    name: `${user.userData.firstName} ${user.userData.lastName}`,
    email: user.email,
    created_at: user.userData.createdAt,
    school: schoolName,
  };

  if (applicationId) {
    intercomData.current_application_id = applicationId;
  }

  return intercomData;
};
