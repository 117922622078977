import React from 'react';
import styled from 'styled-components';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { Button } from '@mui/material';

const Empty = styled.div`
  text-align: center;
  padding-bottom: 2rem;
`;

const Icon = styled(AnnouncementIcon)`
  height: 10rem;
  width: 10rem;
  fill: #e5e5e5;
`;

const Content = styled.p`
  margin-bottom: 1.8rem;
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: 300;
`;

const EmptyState = (props) => {
  const hasAction = props.onAction && typeof props.onAction === 'function';

  return (
    <Empty>
      <Icon />
      <Content>It looks like there are no {props.nounPlural} to show.</Content>
      {hasAction ? <Button onClick={props.onAction}>{props.buttonLabel || `Add a ${props.nounSingular}`}</Button> : <></>}
    </Empty>
  );
};

export default EmptyState;
