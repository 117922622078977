import { TicketCourse } from 'components/Ticket/TicketCourse';
import EmptyState from 'components/Profile/EmptyState';
import { Box, Stack } from '@mui/material';

export const ApplicantPipelineCompleteApplications = ({ stages }) => {
  const renderApplications = () => {
    const combined =
      stages &&
      stages.length &&
      stages.reduce((a, b) => {
        return {
          applications: a.applications.concat(b.applications),
        };
      }).applications;

    if (!combined.length) {
      return (
        <Stack justifyContent="center">
          <EmptyState nounSingular="application" nounPlural="applications" />
        </Stack>
      );
    }

    const applications = combined.map((application) => {
      return (
        <Stack key={application.id}>
          <TicketCourse
            course={application.application_label}
            action="Enrolled"
            permanence={application.updated_at}
            delay={Math.floor(Math.random() * (480 - 220) + 220)}
            application={application}
            isShared={application.shared_users && !!application.shared_users.length}
          />
        </Stack>
      );
    });

    return <Box sx={{ p: 1, backgroundColor: (theme) => theme.palette.grey[200] }}>{applications}</Box>;
  };

  return <Stack>{renderApplications()}</Stack>;
};

export default ApplicantPipelineCompleteApplications;
