import { Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface GradientIconProps {
  color: 'primary' | 'secondary' | 'info' | 'warning' | 'error';
  IconComponent: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
}

export const GradientIcon = ({ color, IconComponent }: GradientIconProps) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    flexShrink="0"
    borderRadius="24px"
    bgcolor={(theme) => `${theme.palette[color].light}33`}
    sx={{ width: '48px', height: '48px' }}
  >
    <Stack
      alignItems="center"
      justifyContent="center"
      flexShrink="0"
      borderRadius="24px"
      bgcolor={(theme) => `${theme.palette[color].light}33`}
      sx={{ width: '36px', height: '36px' }}
    >
      <IconComponent sx={{ color: (theme) => theme.palette[color].dark }} />
    </Stack>
  </Stack>
);
