import { Box } from '@mui/material';
import { Invoice } from 'invoices/Invoice';

interface InvoiceFieldProps {
  label: string;
  canAddPayments: boolean;
  invoiceId: string;
}

// TODO: rebuild without redux
export const InvoiceField = ({ label, canAddPayments, invoiceId }: InvoiceFieldProps) => (
  <Box data-cy-field-type="invoice">
    <Invoice invoiceId={invoiceId} canAddPayments={canAddPayments} heading={label} asStatement />
  </Box>
);

// for React.lazy
export default InvoiceField;
