import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers';

import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { usePermissions } from 'contexts/PermissionContext';
import { useCountries } from 'app/CountriesContext';
import { usePost } from 'hooks/usePost';

import { DATE_DASH_FORMAT } from 'app/constants/DateFormats';
import { ROLE_TYPE_APPLICANT } from 'permissions/constants/Roles';
import { AuthUser } from 'utils/user';
import validators from 'utils/validators';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { Applicant } from 'types/Applicant';

import { useWorkflow } from 'contexts/WorkflowContext';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import Loader from '../components/Loader';

const genders = ['Male', 'Female', 'Diverse'];

interface CreateApplicantFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  gender: 'Male' | 'Female' | 'Diverse' | '';
  email: string;
  dateOfBirth: Date | null;
  nationality: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export const CreateApplicant = () => {
  const navigate = useNavigate();
  const { user } = useAuth0<AuthUser>();
  const { hasRole } = usePermissions();
  const { slug } = useParams() as SchoolRouteParams;
  const countries = useCountries();
  const {
    state: { workflow },
  } = useWorkflow();

  const [isSubmitting, saveApplicant] = usePost<ResponseEnvelope<Applicant>>(`/schools/${slug}/applicants`);
  const { showSuccessMessage, showErrorMessage } = useNotificationMessages();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateApplicantFormValues>({
    defaultValues: {
      firstName: hasRole([ROLE_TYPE_APPLICANT]) ? user!.userData.firstName : '',
      middleName: '',
      lastName: hasRole([ROLE_TYPE_APPLICANT]) ? user!.userData.lastName : '',
      preferredName: '',
      gender: '',
      email: '',
      dateOfBirth: null,
      nationality: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
    },
  });

  const onSubmit = handleSubmit(
    async ({
      firstName,
      middleName,
      lastName,
      preferredName,
      gender,
      email,
      dateOfBirth,
      nationality,
      addressLine1,
      addressLine2,
      city,
      state,
      postcode,
      country,
    }) => {
      const applicantData = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        preferred_name: preferredName,
        gender,
        email,
        date_of_birth: format(dateOfBirth as Date, DATE_DASH_FORMAT),
        nationality_id: Number(nationality),
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city,
        state,
        postcode,
        country_id: Number(country),
        // Create new application assigned to newly created applicant
        create_application: true,
      };

      try {
        const response = await saveApplicant(applicantData);
        showSuccessMessage(response.message);
        navigate(`/${slug}/enrolment-application/applicant/${response.data.id}/application/${response.data.applications[0].id}/stages`);
      } catch (error: any) {
        showErrorMessage(error.message);
      }
    },
  );

  if (!workflow) return <Loader />;

  return (
    <Stack>
      <Container maxWidth="md" sx={{ py: 2 }}>
        <Card sx={{ p: 3, maxWidth: '100%' }}>
          <Typography variant="h4">Create a new applicant</Typography>
          <Divider sx={{ my: 2 }} />
          <form onSubmit={onSubmit}>
            <Stack spacing={0.5}>
              <Typography>Please enter the details of the applying student.</Typography>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: 'First name is required',
                  maxLength: {
                    value: 200,
                    message: 'First name is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.firstName}>
                      <FormLabel id="firstNameLabel">First name</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="firstNameLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.firstName}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.firstName ? errors.firstName.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="middleName"
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Middle name is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth error={!!errors.middleName}>
                      <FormLabel id="middleNameLabel">Middle name</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="middleNameLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.middleName}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.middleName ? errors.middleName.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Last name is required',
                  maxLength: {
                    value: 200,
                    message: 'Last name is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.lastName}>
                      <FormLabel id="lastNameLabel">Family name</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="lastNameLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.lastName}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.lastName ? errors.lastName.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="preferredName"
                control={control}
                rules={{
                  required: 'Preferred name is required',
                  maxLength: {
                    value: 200,
                    message: 'Preferred name is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.preferredName}>
                      <FormLabel id="preferredNameLabel">Preferred name / known as</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="preferredNameLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.preferredName}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.preferredName ? errors.preferredName.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: 'Gender is required',
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.gender}>
                      <FormLabel id="genderLabel">Gender</FormLabel>
                      <RadioGroup value={value} onChange={onChange} aria-labelledby="genderLabel">
                        {genders.map((val) => {
                          return <FormControlLabel key={`createApplicantGenderRadio-${val}`} value={val} control={<Radio />} label={val} />;
                        })}
                      </RadioGroup>
                    </FormControl>
                    <Typography color="error">{errors.gender ? errors.gender.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required',
                  maxLength: {
                    value: 200,
                    message: 'Email is too long',
                  },
                  validate: (value) => (validators.email(value) ? true : 'Email address is invalid'),
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.email}>
                      <FormLabel id="emailLabel">Student email address</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="emailLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.email}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.email ? errors.email.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{
                  required: 'Date of birth is required',
                  validate: validators.dateOfBirth,
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.dateOfBirth}>
                      <FormLabel id="dateOfBirthLabel">Date of birth</FormLabel>
                      <DatePicker
                        aria-labelledby="dateOfBirthLabel"
                        disableFuture
                        value={value}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors.dateOfBirth,
                          },
                        }}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.dateOfBirth ? errors.dateOfBirth.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="nationality"
                control={control}
                rules={{
                  required: 'Nationality is required',
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth error={!!errors.nationality}>
                      <FormLabel id="nationalityLabel">Nationality (as shown on passport)</FormLabel>
                      <Select
                        size="small"
                        aria-labelledby="nationalityLabel"
                        value={value}
                        onChange={onChange}
                        error={!!errors.nationality}
                        disabled={isSubmitting}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: '30vh' },
                          },
                        }}
                      >
                        {countries.map(({ id, name }) => {
                          return (
                            <MenuItem key={`createApplicantNationality-select-${id}`} value={id}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Typography color="error">{errors.nationality ? errors.nationality.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="addressLine1"
                control={control}
                rules={{
                  required: 'Address (Line 1) is required',
                  maxLength: {
                    value: 200,
                    message: 'Address (Line 1) is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.addressLine1}>
                      <FormLabel id="addressLine1Label">Address (Line 1)</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="addressLine1Label"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.addressLine1}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.addressLine1 ? errors.addressLine1.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="addressLine2"
                control={control}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Address (Line 2) is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth error={!!errors.addressLine2}>
                      <FormLabel id="addressLine2Label">Address (Line 2)</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="addressLine2Label"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.addressLine2}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.addressLine2 ? errors.addressLine2.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'City / town is required',
                  maxLength: {
                    value: 200,
                    message: 'City / town is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.city}>
                      <FormLabel id="cityLabel">City / town</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="cityLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.city}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.city ? errors.city.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="state"
                control={control}
                rules={{
                  required: 'State / province / region is required',
                  maxLength: {
                    value: 200,
                    message: 'State / province / region is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.state}>
                      <FormLabel id="stateLabel">State / province / region</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="stateLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.state}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.state ? errors.state.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="postcode"
                control={control}
                rules={{
                  required: 'Postcode / ZIP is required',
                  maxLength: {
                    value: 20,
                    message: 'Postcode / ZIP is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth required error={!!errors.postcode}>
                      <FormLabel id="postcodeLabel">Postcode / ZIP</FormLabel>
                      <TextField
                        size="small"
                        aria-labelledby="postcodeLabel"
                        value={value || ''}
                        onChange={onChange}
                        error={!!errors.postcode}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    <Typography color="error">{errors.postcode ? errors.postcode.message : <br />}</Typography>
                  </>
                )}
              />
              <Controller
                name="country"
                control={control}
                rules={{
                  required: 'Country is required',
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControl fullWidth error={!!errors.country}>
                      <FormLabel id="countryLabel">Country</FormLabel>
                      <Select
                        size="small"
                        aria-labelledby="countryLabel"
                        value={value}
                        onChange={onChange}
                        error={!!errors.country}
                        disabled={isSubmitting}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: '30vh' },
                          },
                        }}
                      >
                        {countries.map(({ id, name }) => {
                          return (
                            <MenuItem key={`createApplicantCountry-select-${id}`} value={id}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Typography color="error">{errors.country ? errors.country.message : <br />}</Typography>
                  </>
                )}
              />
            </Stack>
            <Stack direction="row" spacing={2} pt={3}>
              <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                Submit
              </LoadingButton>
              <Button disabled={isSubmitting} onClick={() => navigate(-1)} color="warning">
                Cancel
              </Button>
            </Stack>
          </form>
        </Card>
      </Container>
    </Stack>
  );
};

// for React.lazy
export default CreateApplicant;
