import { DefaultPaymentOption } from 'payment/components/options/DefaultOption';
import { BankTransfer } from 'payment/components/BankTransfer';
import FlywireOption from 'payment/components/options/FlywireOption';
import { Flywire } from 'payment/components/Flywire';
import Stripe from 'payment/components/Stripe';
import StripeOption from 'payment/components/options/StripeOption';
import { ReactComponent as StripeIcon } from 'images/payment/stripe.svg';
import { ReactComponent as BankIcon } from 'images/icons/Material/bank.svg';
import { ReactComponent as FlywireIcon } from 'images/payment/flywire-small.svg';
import { ReactComponent as FlywireIconLarge } from 'images/payment/flywire.svg';

const paymentProviderMap = {
  bank: {
    optionComponent: DefaultPaymentOption,
    paymentComponent: BankTransfer,
    iconSmall: BankIcon,
    iconLarge: BankIcon,
    canDeletePayments: true,
  },
  flywire: {
    optionComponent: FlywireOption,
    paymentComponent: Flywire,
    iconSmall: FlywireIcon,
    iconLarge: FlywireIconLarge,
    canDeletePayments: false,
  },
  xero: {
    // Xero should never be a choosable payment provider by the user
    // Therefore no option and payment components should be present
    optionComponent: null,
    paymentComponent: null,
    iconSmall: BankIcon,
    iconLarge: BankIcon,
    canDeletePayments: false,
  },
  stripe: {
    optionComponent: StripeOption,
    paymentComponent: Stripe,
    iconSmall: StripeIcon,
    iconLarge: StripeIcon,
    canDeletePayments: false,
  },
};

export default paymentProviderMap;
