import { Fragment, useState, useEffect, useCallback } from 'react';
import { Box, Chip, FormLabel, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FormControl, FormControlState } from '@mui/base/FormControl';
import Loader from 'components/Loader';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { NestedDropdown } from 'mui-nested-menu';
import { useGet } from 'hooks/useGet';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

const responseItemsToMenuItems = (items, setSelected, parents) =>
  items.map((item) => {
    if (item.value) {
      return {
        label: item.label,
        callback: () => setSelected({ label: item.label, value: item.label, parents }),
      };
    } else if (item.children) {
      return {
        label: item.label,
        items: responseItemsToMenuItems(item.children, setSelected, [...parents, item.label]),
      };
    }
    console.error('Did not find value or children field on MenuItemType node');
    return undefined;
  });

const responseToMenuItems = (res, setSelected, rootLabel) => ({
  label: rootLabel,
  items: responseItemsToMenuItems(res, setSelected, []),
});

interface MenuOption {
  label: string;
  value?: string;
  children?: MenuOption[];
}

interface MegaSelectionMenuFieldProps {
  label: string;
  disabled: boolean;
  value: any[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  sourceUrl: string;
  limitation?: number;
}

const MegaSelectionMenuField = ({
  label,
  disabled,
  value,
  onChange,
  error,
  name,
  readOnly,
  sourceUrl,
  limitation = Number.MAX_VALUE,
}: MegaSelectionMenuFieldProps) => {
  const [loading, getOptions] = useGet<MenuOption[]>(sourceUrl, {
    useApiUrl: false,
    skipAuth: true,
  });
  const [options, setOptions] = useState<MenuOption[]>([]);
  const { showErrorMessage } = useNotificationMessages();

  if (!sourceUrl) {
    showErrorMessage('External API source URL is required of an MultiLevelDropdown');
  }

  const fetchOptions = useCallback(async () => {
    try {
      const response = await getOptions();
      setOptions(response);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getOptions, showErrorMessage]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handleChange = (value, onChange, newValue) => {
    if (value.length >= limitation) return;
    onChange([...value, newValue]);
  };

  const theme = useTheme();

  if (loading || !options.length) return <Loader small />;

  return (
    <>
      <FormControl data-cy-field-type="mega-selection-menu">
        {({ focused }: FormControlState) => (
          <Fragment>
            <FormLabel id={`${name}-label`}>{label}</FormLabel>
            <Box mb={1}>
              <NestedDropdown
                menuItemsData={responseToMenuItems(options, (newValue) => handleChange(value, onChange, newValue), 'Select from options...')}
                MenuProps={{ elevation: 3 }}
                ButtonProps={{
                  variant: 'outlined',
                  disabled: value.length >= limitation || disabled || readOnly,
                }}
              />
            </Box>
            <Box aria-labelledby={`${name}-label`}>
              {value.map?.((item) => (
                <Chip
                  icon={<CheckCircleIcon color="success" />}
                  deleteIcon={
                    <Tooltip title="Delete">
                      <DeleteOutlinedIcon />
                    </Tooltip>
                  }
                  sx={{
                    userSelect: 'inherit',
                    bgcolor: focused ? `${theme.palette.primary.light}AA` : 'inherit',
                    height: '100%',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },
                    '& .MuiChip-deleteIcon': {
                      color: theme.palette.error.main,
                      '&:hover': {
                        color: theme.palette.error.dark,
                      },
                    },
                    p: 0.5,
                  }}
                  data-cy="mega-selection-chip"
                  key={item.value}
                  onDelete={() => {
                    onChange(value.filter((i) => i.value !== item.value));
                  }}
                  label={
                    <Stack direction="column" px={1}>
                      {item.parents.map((p) => (
                        <Typography display="inline" key={p}>
                          {p}
                        </Typography>
                      ))}
                      <Typography>{item.label}</Typography>
                    </Stack>
                  }
                />
              ))}
            </Box>
          </Fragment>
        )}
      </FormControl>
      <Typography color="error">{error ? error!.message : <br />}</Typography>
    </>
  );
};

// need default export for React.lazy()
export default MegaSelectionMenuField;
