import { parsePhoneNumber, AsYouType, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { useCountries } from 'app/CountriesContext';
import { FieldError } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Avatar, FormControl, FormLabel, IconButton, InputAdornment, ListItemText, Menu, MenuItem, TextField, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import { useMemo, useState } from 'react';
import { interleaveMenuDivider } from 'utils/interleaveDivider';

interface StandardPhoneNumberFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  /* ISO 3166-2 code */
  defaultCountry?: string;
}

const StandardPhoneNumberField = ({ label, disabled, value, onChange, error, name, defaultCountry, readOnly }: StandardPhoneNumberFieldProps) => {
  const [countryCode, setCountryCode] = useState<string>(defaultCountry || '');
  const [internalValue, setInternalValue] = useState<string>(value || (defaultCountry ? getCountryCallingCode(defaultCountry as any) : ''));

  const countries = useCountries();
  const countryNamesByCode = useMemo(() => countries.reduce((acc, country) => acc.set(country.iso_3166_2, country.name), new Map()), [countries]);

  const countryOptions = useMemo(
    () =>
      getCountries()
        .map((countryCode) => ({ countryName: countryNamesByCode.get(countryCode), countryCode }))
        .filter((option) => option.countryName)
        .sort((a, b) => a.countryName.toLowerCase().localeCompare(b.countryName.toLowerCase())),
    [countryNamesByCode],
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickFlagAvatar = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const selectCountry = (countryCode) => {
    handleClose();
    setCountryCode(countryCode);
    setInternalValue(`+${getCountryCallingCode(countryCode)}`);
  };

  const onClear = () => {
    setInternalValue('');
    setCountryCode(defaultCountry || '');
    onChange('');
  };

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="standard-phone-number">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <TextField
          size="small"
          type="text"
          aria-labelledby={`${name}-label`}
          value={internalValue}
          error={!!error}
          placeholder="Enter phone number (including country code)"
          inputProps={{ readOnly }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  id={`${name}-standard-phone-number-country-code-selector`}
                  aria-controls={open ? 'school-selector-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup
                  src={`/images/flag-icons/flags/4x3/${countryCode.toLowerCase()}.svg`}
                  sx={{ width: 26, height: 26, fontSize: '16px', cursor: 'pointer' }}
                  onClick={handleClickFlagAvatar}
                >
                  <PhoneIcon sx={{ fontSize: '16px' }} />
                </Avatar>
                <Menu
                  id={`${name}-standard-phone-number-menu`}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ 'aria-labelledby': `${name}-standard-phone-number-country-code-selector` }}
                >
                  {interleaveMenuDivider(
                    countryOptions.map((countryOption, index) => (
                      <MenuItem key={countryOption.countryCode} onClick={() => selectCountry(countryOption.countryCode)} tabIndex={index}>
                        <ListItemText>{countryOption.countryName}</ListItemText>
                      </MenuItem>
                    )),
                  )}
                </Menu>
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton onClick={onClear} sx={{ visibility: internalValue ? 'visible' : 'hidden' }}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          disabled={disabled}
          onChange={(event) => {
            const newValue = event.target.value;
            if (!newValue) return onClear();
            const prefixedValue = internalValue === '' && !defaultCountry ? `+${newValue}` : newValue;
            const formattedPartial = new AsYouType((defaultCountry as any) || undefined).input(prefixedValue);
            try {
              const parsedPhoneNumber = parsePhoneNumber(formattedPartial);
              const formatInternational = parsedPhoneNumber.formatInternational();
              setInternalValue(formatInternational);
              setCountryCode(parsedPhoneNumber?.country || '');
              onChange(formatInternational);
            } catch (e) {
              console.warn(e);
              setInternalValue(formattedPartial);
              setCountryCode('');
            }
          }}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default StandardPhoneNumberField;
