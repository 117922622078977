import { ReactComponent as BankIcon } from 'images/icons/Material/bank.svg';
import { ReactComponent as FlywireLogo } from 'images/payment/flywire.svg';
import { ReactComponent as MastercardIcon } from 'images/payment/mastercard.svg';
import { ReactComponent as VisaIcon } from 'images/payment/visa.svg';
import { ReactComponent as AlipayIcon } from 'images/payment/alipay.svg';
import { ReactComponent as EpayIcon } from 'images/payment/epay.svg';
import { ReactComponent as PaypalIcon } from 'images/payment/paypal.svg';
import { ReactComponent as UnionpayIcon } from 'images/payment/unionpay.svg';
import { Stack, Typography } from '@mui/material';

const FlywirePaymentOption = (props) => (
  <Stack gap={1}>
    <Typography>{props.onlyOption ? 'Flywire is our trusted International Payment Provider' : 'I am paying from overseas'}</Typography>
    <Stack direction="row" gap={2}>
      <BankIcon height={24} width={24} />
      <MastercardIcon height={24} width={24} />
      <VisaIcon height={24} width={24} />
      <UnionpayIcon height={24} width={24} />
      <PaypalIcon height={24} width={24} />
      <AlipayIcon height={24} width={24} />
      <EpayIcon height={24} width={24} />
    </Stack>
    <Stack direction="row" gap={1}>
      <Typography variant="body2">Powered by</Typography> <FlywireLogo height={24} width={40} />
    </Stack>
  </Stack>
);

export default FlywirePaymentOption;
