import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser, getUserId } from '../../utils/user';
import { useEffect } from 'react';
import { getConfig } from '../../utils/config';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export const useGoogleAnalytics = () => {
  const { user } = useAuth0<AuthUser>();
  const { environment, googleAnalyticsId } = getConfig();
  const location = useLocation();

  useEffect(() => {
    if (environment === 'local' || !user || !googleAnalyticsId) {
      return;
    }

    const userId = getUserId(user);
    ReactGA.set({
      userId,
      dimension3: userId,
      dimension4: user.userData.roles[0],
    });
  }, [environment, googleAnalyticsId, user]);

  useEffect(() => {
    if (environment === 'local' || !googleAnalyticsId) {
      return;
    }

    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [environment, googleAnalyticsId, location]);
};
