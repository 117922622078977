import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';

import Country from 'components/Country/Country';
import Loader from 'components/Loader';

import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { slugify } from 'utils/slugify';

import { useApplicant } from 'contexts/ApplicantContext';
import { useCountries } from 'app/CountriesContext';
import { useGet } from 'hooks/useGet';
import { usePut } from 'hooks/usePut';
import { useNotificationMessages } from 'hooks/useNotificationMessages';

import { STUDENT_STATUS_ALUMNI, STUDENT_STATUS_CURRENT, STUDENT_STATUS_IMPENDING, STUDENT_STATUSES_COLORS } from 'app/constants/StudentStatuses';
import { APPLICATION_STATUS_ENROLLED, APPLICATION_STATUS_LABELS } from 'app/constants/ApplicationStatuses';
import { DATE_DASH_FORMAT, DATE_SLASH_FORMAT } from 'app/constants/DateFormats';
import { ResponseEnvelope } from 'types/ResponseEnvelope';

const statuses = [
  {
    key: STUDENT_STATUS_IMPENDING,
    label: 'Awaiting Arrival',
  },
  {
    key: STUDENT_STATUS_CURRENT,
    label: 'Current Student',
  },
  {
    key: STUDENT_STATUS_ALUMNI,
    label: 'Alumni Student',
  },
];

const exitoptions = ['Enrolment complete', 'Medical', 'Change of visa status', 'Financial reasons', 'Discipline', 'Family reasons', 'Pandemic', 'Other'];

const EDUCATION_STATUS_TRANSFER_NZ = 'Transfer to another institution in NZ';
const EDUCATION_STATUS_TRANSFER_INTERNATIONAL = 'Transfer to another international institution';
const EDUCATION_STATUS_NO_PROGRAMME = 'No future study programme indicated';
const EDUCATION_STATUS_OTHER = 'Other';

const educationStatuses = [EDUCATION_STATUS_TRANSFER_NZ, EDUCATION_STATUS_TRANSFER_INTERNATIONAL, EDUCATION_STATUS_NO_PROGRAMME, EDUCATION_STATUS_OTHER];

interface ApiEditStatusFormApplication {
  id: string;
  course: string;
  start_date: string;
  end_date: string;
  status: string;
}

interface EditStatusFormApplication {
  id: string;
  courseName: string;
  startDate: Date;
  endDate: Date;
  status: string;
}

interface ApplicantStudentStatusDetails {
  student_status: string;
  exit_reason?: string;
  exit_reason_other?: string;
  destination_type?: string;
  country_id?: number;
  destination_institution?: string;
  applications: ApiEditStatusFormApplication[];
}

interface EditStatusFormValues {
  status: string;
  exitReason?: string;
  exitReasonOther?: string;
  educationStatus?: string;
  educationStatusOther?: string;
  countryId?: number;
  institution?: string;
  applications: EditStatusFormApplication[];
}

const EditStatusForm = ({ statusData, onUpdate, onToggle, isSubmitting }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const [institutionsURL, setInstitutionsURL] = useState('');
  const [institutions, setInstitutions] = useState<string[]>([]);

  const countries = useCountries();

  // RHF-related logic
  const getValuesFromData = ({
    student_status: status,
    exit_reason: exitReason,
    exit_reason_other: exitReasonOther,
    destination_type: educationStatus,
    country_id: countryId,
    destination_institution: institution,
    applications: applicationsData,
  }: ApplicantStudentStatusDetails) => {
    const applications = applicationsData.map(({ id, status, start_date, end_date, course }) => ({
      id,
      status,
      startDate: new Date(start_date ?? ''),
      endDate: new Date(end_date ?? ''),
      courseName: course,
    }));
    return {
      status,
      exitReason: exitReason ?? '',
      exitReasonOther,
      educationStatus,
      countryId,
      institution,
      applications,
    };
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditStatusFormValues>({
    defaultValues: getValuesFromData(statusData),
  });

  const { fields: applications } = useFieldArray({
    control,
    name: 'applications',
    keyName: 'key',
  });

  const validateDate = (date) => {
    if (!isValid(date)) {
      return 'Date is invalid';
    }

    return true;
  };

  const onSubmit = handleSubmit(({ status, exitReason, exitReasonOther, educationStatus, countryId, institution, educationStatusOther, applications }) => {
    const applicationsData: ApiEditStatusFormApplication[] = applications.map(({ id, courseName, startDate, endDate, status }) => {
      const formattedStartDate = format(startDate, DATE_DASH_FORMAT);
      const formattedEndDate = format(endDate, DATE_DASH_FORMAT);

      return {
        id,
        course: courseName,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        status,
      };
    });
    const studentStatusDetails: ApplicantStudentStatusDetails = {
      student_status: status,
      exit_reason: exitReason,
      exit_reason_other: exitReasonOther,
      destination_type: educationStatus,
      country_id: countryId,
      destination_institution: educationStatus === EDUCATION_STATUS_OTHER ? educationStatusOther : institution,
      applications: applicationsData,
    };

    onUpdate(studentStatusDetails);
  });

  const statusWatch = watch('status');
  const exitReasonWatch = watch('exitReason');
  const educationStatusWatch = watch('educationStatus');
  const countryWatch = watch('countryId');

  const [institutionsLoading, getInstitutions] = useGet<string[]>(institutionsURL);

  useEffect(() => {
    if (institutionsURL) {
      getInstitutions().then((res) => {
        setInstitutions(res);

        if (res.length !== 0) {
          // Reset institution field after changing the institutions list
          setValue('institution', '');
        }
      });
    }
  }, [getInstitutions, setValue, institutionsURL]);

  useEffect(() => {
    if (educationStatusWatch !== EDUCATION_STATUS_TRANSFER_NZ && countryWatch === null) return;

    if (educationStatusWatch === EDUCATION_STATUS_TRANSFER_NZ) {
      // If the student is transferring to another NZ institution, we need to show the list
      // of NZ institutions
      setInstitutionsURL('/countries/554/institutions');
    } else {
      setInstitutionsURL(`/countries/${countryWatch}/institutions`);
    }
  }, [countryWatch, educationStatusWatch, setValue]);

  const hasReenrolled = statusData.applications.find((a) => a.status === APPLICATION_STATUS_ENROLLED);

  // Used for conditional rendering of fields
  const showAlumniFields = statusWatch === STUDENT_STATUS_ALUMNI;
  const showExitReasonOther = exitReasonWatch === 'Other';
  const showCountry = educationStatusWatch && educationStatusWatch !== EDUCATION_STATUS_TRANSFER_NZ;
  const showInstitution = educationStatusWatch !== EDUCATION_STATUS_OTHER;
  const showEducationStatusOther = educationStatusWatch === EDUCATION_STATUS_OTHER;

  if (!hasReenrolled) {
    return <Typography>The student has not enrolled yet.</Typography>;
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={5}>
        <Stack>
          <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 3 }}>
            Applicant Status
          </Typography>
          <Controller
            control={control}
            name="status"
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <Stack direction="row" gap={3}>
                    {statuses.map((s) => {
                      const statusColor = STUDENT_STATUSES_COLORS[s.key];
                      const active = value === s.key;
                      return (
                        <Chip
                          key={s.key}
                          sx={{
                            px: '24px',
                            py: '8px',
                            height: 'auto',
                            borderRadius: '50px',
                            fontSize: '16px',
                            borderColor: active ? statusColor : '#cacaca',
                            color: active ? statusColor : '#cacaca',
                            backgroundColor: 'transparent',
                            '&:hover': {
                              color: statusColor,
                              borderColor: statusColor,
                              bgcolor: 'transparent',
                            },
                            '&::before': {
                              content: "''",
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              backgroundColor: active ? statusColor : 'transparent',
                              opacity: 0.2,
                              borderRadius: '50px',
                            },
                          }}
                          variant="outlined"
                          size="medium"
                          onClick={() => onChange(s.key)}
                          label={s.label}
                        />
                      );
                    })}
                  </Stack>
                </FormControl>
              );
            }}
          />
        </Stack>
        {showAlumniFields && (
          <Stack gap={1}>
            <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 3 }}>
              Alumni details
            </Typography>
            <Controller
              control={control}
              name="exitReason"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl>
                    <FormLabel id="exitReasonLabel" sx={{ mb: 1 }}>
                      Exit reason
                    </FormLabel>
                    <Select size="small" aria-labelledby="exitReasonLabel" value={value} onChange={onChange} error={!!errors.exitReason}>
                      <MenuItem value="">Select option</MenuItem>
                      {exitoptions.map((option) => {
                        const valueSlug = slugify(option);
                        return (
                          <MenuItem key={`exitOption-${valueSlug}`} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                );
              }}
            />

            {showExitReasonOther && (
              <Controller
                name="exitReasonOther"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControl>
                    <FormLabel sx={{ mb: 1 }} id="exitReasonOtherLabel">
                      Other reason
                    </FormLabel>
                    <TextField aria-labelledby="exitReasonOtherLabel" multiline rows={3} value={value} onChange={onChange} />
                  </FormControl>
                )}
              />
            )}
            <Controller
              control={control}
              name="educationStatus"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl>
                    <FormLabel id="educationStatusLabel" sx={{ mb: 1 }}>
                      Education status
                    </FormLabel>
                    <Select size="small" aria-labelledby="educationStatusLabel" value={value} onChange={onChange}>
                      <MenuItem value="">Select option</MenuItem>
                      {educationStatuses.map((option) => {
                        const valueSlug = slugify(option);
                        return (
                          <MenuItem key={`educationStatus-${valueSlug}`} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                );
              }}
            />
            {showCountry && (
              <Controller
                name="countryId"
                control={control}
                rules={{
                  required: 'Country is required',
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth error={!!errors.countryId}>
                    <FormLabel id="countryIdLabel" sx={{ mb: 1 }}>
                      Country
                    </FormLabel>
                    <Select size="small" aria-labelledby="countryIdLabel" value={value} onChange={onChange} error={!!errors.countryId}>
                      {countries
                        .filter(({ id }) => id !== 554)
                        .map(({ id, name }) => {
                          return (
                            <MenuItem key={`editEnquiryCountry-select-${id}`} value={id}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {errors.countryId && <Typography color="error">{errors.countryId.message}</Typography>}
                  </FormControl>
                )}
              />
            )}

            {showInstitution &&
              (institutionsLoading ? (
                <Loader center small />
              ) : (
                <Controller
                  control={control}
                  name="institution"
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <FormLabel id="institutionLabel">Institution</FormLabel>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        disablePortal
                        aria-labelledby="institutionLabel"
                        size="small"
                        options={institutions}
                        value={value}
                        renderInput={(params) => {
                          return <TextField {...params} placeholder="Select option" />;
                        }}
                        onChange={(_, values) => onChange(values)}
                      />
                    </FormControl>
                  )}
                />
              ))}
            {showEducationStatusOther && (
              <Controller
                control={control}
                name="educationStatusOther"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel id="educationStatusOtherLabel">Other - Provide further details</FormLabel>
                      <TextField size="small" aria-labelledby="educationStatusOtherLabel" multiline rows={3} value={value} onChange={onChange} />
                    </FormControl>
                  );
                }}
              />
            )}
          </Stack>
        )}

        <Stack gap={1}>
          <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 3 }}>
            Study periods
          </Typography>
          {applications.map(({ courseName, startDate, endDate, status }, index) => {
            const slugCourseName = slugify(courseName || `Unnamed course ${index}`);
            return (
              <Accordion key={slugCourseName}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                    <Stack direction="column">
                      <Typography>{courseName}</Typography>
                      <Typography variant="body2">
                        {format(new Date(startDate), DATE_SLASH_FORMAT + ' ')} - {format(new Date(endDate), ' ' + DATE_SLASH_FORMAT)}
                      </Typography>
                    </Stack>
                    <Chip
                      label={APPLICATION_STATUS_LABELS[status]}
                      size="small"
                      sx={{
                        color: '#fff',
                        bgcolor: status === APPLICATION_STATUS_ENROLLED ? primaryColor : '#a5a5a5',
                        mr: 2,
                        py: 1,
                      }}
                    />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Controller
                    control={control}
                    name={`applications.${index}.courseName`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <FormLabel id={`applicationCourseName-${index}`}>Course name</FormLabel>
                          <TextField size="small" aria-labelledby={`applicationCourseName-${index}`} value={value} onChange={onChange} />
                        </FormControl>
                      );
                    }}
                  />
                  <Stack direction="row" gap={1}>
                    <Controller
                      control={control}
                      name={`applications.${index}.startDate`}
                      rules={{
                        validate: validateDate,
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <>
                            <FormControl fullWidth>
                              <FormLabel id={`applicationStartDate-${index}`}>Start date</FormLabel>
                              <DatePicker
                                aria-labelledby={`applicationStartDate-${index}`}
                                value={value}
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    error: !!errors.applications?.[index]?.startDate,
                                  },
                                }}
                                onChange={onChange}
                              />
                            </FormControl>
                            <Typography color="error">{errors.applications?.[index]?.startDate && errors.applications?.[index]?.startDate?.message}</Typography>
                          </>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name={`applications.${index}.endDate`}
                      rules={{
                        validate: validateDate,
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <>
                            <FormControl fullWidth>
                              <FormLabel id={`applicationEndDate-${index}`}>Start date</FormLabel>
                              <DatePicker
                                aria-labelledby={`applicationEndDate-${index}`}
                                value={value}
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    error: !!errors.applications?.[index]?.endDate,
                                  },
                                }}
                                onChange={onChange}
                              />
                            </FormControl>
                            <Typography color="error">{errors.applications?.[index]?.endDate && errors.applications?.[index]?.endDate?.message}</Typography>
                          </>
                        );
                      }}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit" color="primary">
            Save
          </LoadingButton>
          <Button onClick={onToggle} color="warning">
            Close
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export const EditStatus = ({ onUpdate, onToggle }) => {
  const { slug } = useParams() as SchoolRouteParams;
  const {
    state: { applicant },
  } = useApplicant();

  const [statusData, setStatusData] = useState<ApplicantStudentStatusDetails>();

  const sourceUrl = `/schools/${slug}/applicants/${applicant?.id}/student-status`;
  const [isLoading, getStudentStatusDetails] = useGet<ResponseEnvelope<ApplicantStudentStatusDetails>>(sourceUrl);
  const [isSubmitting, updateStudentStatus] = usePut<ResponseEnvelope<ApplicantStudentStatusDetails>>(sourceUrl);
  const { showSuccessMessage, showErrorMessage } = useNotificationMessages();

  const handleUpdate = async (studentStatusDetails: ApplicantStudentStatusDetails) => {
    try {
      const response = await updateStudentStatus(studentStatusDetails);
      onUpdate();
      showSuccessMessage(response.message);
    } catch (error: any) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    try {
      getStudentStatusDetails().then((res: any) => {
        setStatusData(res.data);
      });
    } catch (error: any) {
      showErrorMessage(error.message);
    }
  }, [getStudentStatusDetails, showErrorMessage]);

  if (!applicant || isLoading) {
    return <Loader center />;
  }

  return (
    <>
      <Stack direction="row">
        <Typography variant="h5" component="h2" sx={{ mr: 3, mb: 2 }}>
          <span>{applicant.first_name}</span> <span>{applicant.last_name}</span>
        </Typography>
        {applicant.nationality && <Country code={applicant.nationality.iso_3166_2} name={applicant.nationality.name} />}
      </Stack>
      {statusData && <EditStatusForm onUpdate={handleUpdate} isSubmitting={isSubmitting} onToggle={onToggle} statusData={statusData} />}
    </>
  );
};
