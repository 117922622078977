import React from 'react';
import { FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface DropdownFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: string; value: string }[];
}

export const DropdownField = ({ label, disabled, value, onChange, error, name, options, readOnly }: DropdownFieldProps) => {
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="dropdown">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Select
          displayEmpty
          sx={{ mb: 2 }}
          size="small"
          value={value}
          name={name}
          error={!!error}
          labelId={`${name}-label`}
          disabled={disabled}
          onChange={(event) => onChange(event.target.value)}
          readOnly={readOnly}
        >
          <MenuItem value="">
            <em>Select option</em>
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.label || option.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default DropdownField;
