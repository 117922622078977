import * as React from 'react';
import classnames from 'classnames';
import { useState } from 'react';

export const StageScroller = ({ children }) => {
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [scrolledToStart, setScrolledToStart] = useState(true);

  const handleScroll = (e) => {
    const isAtEnd = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;
    const isAtStart = e.target.scrollLeft === 0;

    setScrolledToEnd(isAtEnd);
    setScrolledToStart(isAtStart);
  };

  const scrollClasses = classnames({
    column__holder: true,
    'column__holder--end': scrolledToEnd,
    'column__holder--start': scrolledToStart,
  });

  return (
    <div className={scrollClasses}>
      <div className="column__wrapper" onScroll={handleScroll}>
        <div className="columncontainer">{children}</div>
      </div>
    </div>
  );
};

export default StageScroller;
