const getElementOffset = (DOMNode) => {
  const docElem = document.documentElement;
  let box = { top: 0, left: 0, height: 0, width: 0 };

  // If we don't have gBCR, just use 0,0 rather than error
  // BlackBerry 5, iOS 3 (original iPhone)
  if (typeof DOMNode.getBoundingClientRect !== 'undefined') {
    box = DOMNode.getBoundingClientRect();
  }

  return {
    top: box.top + window.pageYOffset - docElem.clientTop,
    left: box.left + window.pageXOffset - docElem.clientLeft,
    height: box.height,
    width: box.width,
  };
};

export default getElementOffset;
