import { useMemo } from 'react';
import { Autocomplete, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface AutocompleteFieldProps {
  label: string;
  disabled: boolean;
  value: string[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: string; value: string }[];
}

export const AutocompleteField = ({ label, disabled, value, onChange, error, name, readOnly, options }: AutocompleteFieldProps) => {
  const opts = useMemo(() => options.map((o) => o.value), [options]);

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="autocomplete">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Autocomplete
          multiple
          disableClearable
          disablePortal
          disabled={disabled}
          aria-labelledby={`${name}-label`}
          size="small"
          options={opts}
          value={value}
          renderInput={(params) => {
            return <TextField {...params} error={!!error} placeholder="Select option" />;
          }}
          onChange={(_, v) => onChange(v)}
          readOnly={readOnly}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default AutocompleteField;
