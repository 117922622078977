import interceptor from 'rest/interceptor';

const formDataInterceptor = interceptor({
  request: function (request) {
    request.headers = request.headers || {};
    request.headers['Content-Type'] = 'multipart/form-data';
    request.headers.Accept = 'application/json';
    return request;
  },
});

export default formDataInterceptor;
