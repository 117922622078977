import { useAuth0 } from '@auth0/auth0-react';
import { deepMerge } from 'utils/deepMerge';
import { getConfig } from 'utils/config';
import { loginConfig } from 'auth0/login-config';
import { getErrorMessage } from 'utils/errors';
import { useMemo, useRef } from 'react';

export interface UseDeleteOptions {
  useApiUrl?: boolean;
  headers?: HeadersInit;
}

// Static objects for default parameters to prevent re-renders
const emptyObject = {};
const defaultOptions = { useApiUrl: true, headers: emptyObject };

export const useStatelessDelete = <T>(url: string, options: UseDeleteOptions = {}) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const ref = useRef({ options });

  const _delete = useMemo(() => {
    const { useApiUrl, headers } = deepMerge(defaultOptions, ref.current.options);
    const { apiUrl } = getConfig();
    const fetchUrl = useApiUrl ? `${apiUrl}${url}` : url;
    const validResponseStatuses = [200, 201, 204];

    return async (): Promise<T> => {
      try {
        const token = await getAccessTokenSilently();
        const config: RequestInit = deepMerge(
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            method: 'DELETE',
          },
          { headers },
        );

        return fetch(fetchUrl, config).then(async (resp) => {
          let body;

          if (resp.headers.get('content-type') === 'application/json') {
            body = await resp.json();
          } else {
            body = await resp.text();
          }

          if (!validResponseStatuses.includes(resp.status)) {
            throw new Error(getErrorMessage(body));
          }

          return body;
        });
      } catch {
        await loginWithRedirect(loginConfig(window.location.pathname));
        throw new Error('Not logged in. Redirecting...');
      }
    };
  }, [getAccessTokenSilently, loginWithRedirect, url]);

  return _delete;
};
