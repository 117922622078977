import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';

interface NumberFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
  error?: FieldError;
  readOnly: boolean;
  name: string;
  min: number;
  max: number;
}

export const NumberField = ({ label, disabled, value, onChange, error, name, readOnly, min = -Infinity, max = Infinity }: NumberFieldProps) => {
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="number">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <TextField
          size="small"
          type="number"
          aria-labelledby={`${name}-label`}
          value={value}
          error={!!error}
          inputProps={{ min, max, readOnly, 'data-testid': 'form-builder-field-number' }}
          disabled={disabled}
          name={name}
          onChange={(event) => onChange(event.target.value)}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default NumberField;
