import { EnquiryHeaderENZ } from 'enquiries/EnquiryHeaderENZ';
import { ReactComponent as ENZLogoDark } from 'images/origins/logo-enz-dark.svg';
import { ReactComponent as ENZLogoLight } from 'images/origins/logo-enz-light.svg';

const CUSTOM_ORIGIN_MAP = {
  enz: {
    header: EnquiryHeaderENZ,
    tabColor: '#22a2a2',
    lightText: true,
    LogoDark: ENZLogoDark,
    LogoLight: ENZLogoLight,
  },
};

export default CUSTOM_ORIGIN_MAP;
