import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const LoaderContainer = styled.div<{ $small: boolean; $center: boolean; $contained: boolean }>`
  ${(props) => (!props.$center ? 'float: left;' : '')}
  margin: ${(props) => (props.$center ? '0 auto' : '0')};
  width: ${(props) => (props.$small || props.$contained ? '3rem' : '6rem')};
  height: ${(props) => (props.$small || props.$contained ? '3rem' : '6rem')};
  padding-top: ${(props) => (props.$small ? '0.9rem' : '0')};
  flex: 1;
`;

interface LoaderProps {
  small?: boolean;
  center?: boolean;
  contained?: boolean;
}

export const Loader = ({ small = false, center = false, contained = false }: LoaderProps) => {
  const progressSize = small || contained ? '2rem' : '4rem';

  return (
    <LoaderContainer $small={small} $center={center} $contained={contained}>
      <CircularProgress size={progressSize} variant="indeterminate" />
    </LoaderContainer>
  );
};

export default Loader;
