import React from 'react';
import { format, parseISO } from 'date-fns';
import { DATE_SLASH_FORMAT } from 'app/constants/DateFormats';
import Placeholder from 'forms/consumer/components/Placeholder';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

interface ApplicantsTableProps {
  applicants?: any[];
  loading?: boolean;
  onRowClick?: (id: any) => void;
}

export const ApplicantsTable = ({ applicants = [], loading = false, onRowClick }: ApplicantsTableProps) => {
  const renderTableHeader = () => {
    if (!applicants) {
      return <></>;
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell>First name</TableCell>
          <TableCell>Last name</TableCell>
          <TableCell>Date of birth</TableCell>
          <TableCell>Email address</TableCell>
          <TableCell>Created</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const renderTableRows = (): any => {
    if (loading) {
      // eslint-disable-next-line prefer-spread
      return Array.apply(null, Array(10)).map((r, i) => (
        <TableRow key={`row-${i}`}>
          {/* eslint-disable-next-line prefer-spread */}
          {Array.apply(null, Array(3)).map((c, i) => (
            <TableCell key={`column-${i}`}>
              <Placeholder animate fieldType="profilelong" />
            </TableCell>
          ))}
        </TableRow>
      ));
    }

    if (applicants && applicants.length) {
      return applicants.map((applicant, index) => {
        return (
          <TableRow
            key={`row-${index}`}
            sx={{
              cursor: 'pointer',
              ':hover': { backgroundColor: (theme) => theme.palette.grey[200] },
            }}
            onClick={onRowClick ? () => onRowClick(applicant.id) : undefined}
          >
            <TableCell>{applicant.first_name}</TableCell>
            <TableCell>{applicant.last_name}</TableCell>
            <TableCell>{format(parseISO(applicant.date_of_birth), DATE_SLASH_FORMAT)}</TableCell>
            <TableCell>{applicant.email}</TableCell>
            <TableCell>{format(parseISO(applicant.created_at), DATE_SLASH_FORMAT)}</TableCell>
          </TableRow>
        );
      });
    }

    return <></>;
  };

  return (
    <Table>
      {renderTableHeader()}
      <TableBody>{renderTableRows()}</TableBody>
    </Table>
  );
};
