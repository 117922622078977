import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface CheckboxFieldProps {
  label: string;
  disabled: boolean;
  value: boolean;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  readOnly: boolean;
}

export const CheckboxField = ({ label, disabled, value, onChange, error, readOnly }: CheckboxFieldProps) => {
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="checkbox" sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}>
        <FormControlLabel
          control={<Checkbox disabled={disabled} value="on" checked={value} onChange={(e) => onChange(e.target.checked)} />}
          label={
            <Typography
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            ></Typography>
          }
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default CheckboxField;
