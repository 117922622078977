export const APPLICATION_STATUS_INPROGRESS = 'in-progress';
export const APPLICATION_STATUS_WITHDRAWN = 'withdrawn';
export const APPLICATION_STATUS_ENROLLED = 'enrolled';
export const APPLICATION_STATUS_ALL = 'all';

export const APPLICATION_STATUS_LABELS = {
  [APPLICATION_STATUS_INPROGRESS]: 'In Progress',
  [APPLICATION_STATUS_WITHDRAWN]: 'Withdrawn',
  [APPLICATION_STATUS_ENROLLED]: 'Enrolled',
  [APPLICATION_STATUS_ALL]: 'All',
};

export const OWNER_ISD = 'isd';
