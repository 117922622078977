/**
 * Convert amount to a formatted currency
 * value based on the Institution's currency code
 * and user's current locale
 * @param amount
 * @param currencyCode
 * @param locale
 * @param style
 */
const currency = (amount, currencyCode, locale): string => {
  const number = amount || 0;
  const currency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
  return currency.format(number);
};

/**
 * Convert a currency amount to a decimal
 * @param amount
 * @param currencyCode
 * @param locale
 */
export const decimal = (amount, currencyCode, locale): number => {
  const number = amount || 0;
  const currency = new Intl.NumberFormat(locale, {
    style: 'decimal',
    currency: currencyCode,
  }).formatToParts(number);

  const formatted = currency
    .filter((part) => part.type !== 'group' && part.type !== 'literal')
    .map((part) => part.value)
    .join('');

  return parseFloat(formatted);
};

export default currency;
