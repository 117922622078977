import { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import currency, { decimal } from 'currency/currency';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { ReceiptLineItem } from 'types/InvoiceReceipt';
import { getReceivedTotal } from '../invoices/invoiceUtils';
import { ReceivedItemInput } from './ReceivedItemInput';

interface ReceiptItemsProps {
  isDisabled: boolean;
  onChangeAmount: (index: number, amount: number) => void;
  items: ReceiptLineItem[];
  receiptTotal: number;
  payments: any[];
}

export const ReceiptItems = ({ isDisabled, onChangeAmount, items, receiptTotal, payments }: ReceiptItemsProps) => {
  const locale = useAppSelector((state) => state.user.locale);
  const currencyCode = useAppSelector((state) => state.school.currency_code);
  const [receiptCreated, setReceiptCreated] = useState(false);

  const receivedTotal = getReceivedTotal(payments, currencyCode, locale);
  const receiptCanBeCreated = items.length > 0 && receiptTotal === receivedTotal;

  const theme = useTheme();

  useEffect(() => {
    if (!receiptCanBeCreated || receiptCreated) return;
    setReceiptCreated(true);
    items.forEach((item, index) => {
      onChangeAmount(index, decimal(item.paid_amount || item.total, currencyCode, locale));
    });
  }, [currencyCode, items, locale, onChangeAmount, receiptCanBeCreated, receiptCreated]);

  const total = currency(receiptTotal, currencyCode, locale);

  return (
    <Box overflow={{ xs: 'auto', md: 'hidden' }}>
      <Typography variant="h5" component="h3" sx={{ mb: 1 }}>
        Receipt Details
      </Typography>
      <Typography variant="body1" fontWeight="300" mb={3}>
        Please review / edit the &quot;Received&quot; amounts in the table below.
      </Typography>

      <Table
        data-cy="account-summary-receipt-items"
        sx={{
          tableLayout: 'fixed',
          minWidth: { xs: '74.5rem', md: 'auto' },
        }}
      >
        <TableHead>
          <TableRow sx={{ height: theme.spacing(4), fontWeight: 700 }}>
            <TableCell width="40%">Description</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Received</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={item.id || `item-${index}`}>
              <TableCell>{item.description}</TableCell>
              <TableCell>{parseFloat(item.quantity).toFixed(2)}</TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell>{item.total}</TableCell>
              <TableCell sx={{}}>
                <Tooltip title="Received">
                  <ReceivedItemInput
                    item={item}
                    isDisabled={isDisabled}
                    onChange={(newValue: number) => {
                      onChangeAmount(index, newValue);
                    }}
                    // onBlur={() => {
                    //   if (!row.paid_amount) return;
                    //   const formattedValue = decimal(row.paid_amount, currencyCode, locale);
                    //   onChange(formattedValue || 0);
                    // }}
                    error={!receiptCanBeCreated}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell>
              <Stack direction="row" alignItems="center">
                {!receiptCanBeCreated && <ErrorIcon fontSize="small" color="error" sx={{ mr: 1 }} />}
                <Typography fontWeight="bold">Total</Typography>
              </Stack>
            </TableCell>
            <TableCell sx={{ fontSize: 'large' }}>{total}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {!receiptCanBeCreated && (
        <Typography color="error" textAlign="right">
          The total does not match the total of all received payments.
        </Typography>
      )}
    </Box>
  );
};
