import Logger from 'app/api/common/Logger';

// Logger
const logger = new Logger({
  tags: {
    module: 'enroller',
  },
});

export default logger;
