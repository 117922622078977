import { useContext, useState } from 'react';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import Country from 'components/Country/Country';
import { FLAG_LANGUAGE_ASSIST } from 'businessToggles/Flags';
import HasFeature from 'businessToggles/HasFeature';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import LangContext from 'app/LangContext';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useMountEffect } from 'hooks/useMountEffect';
import { setLocale } from 'app/actions/userActions';

export const LocaleMenu = ({ localeLabel, localeImg }) => {
  const langContext = useContext(LangContext);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleSelectLocale = (label, locale, img, e) => {
    e.preventDefault();
    dispatch(setLocale(locale, label, img, langContext.lang));
    handleClose();
  };

  useMountEffect(() => {
    if (user.locale) {
      dispatch(setLocale(user.locale, user.localeLabel, user.localeImg, langContext.lang));
    }
  });

  return (
    <HasFeature name={[FLAG_LANGUAGE_ASSIST]}>
      <Stack direction="row" onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <Country name={localeLabel} code={localeImg} hideCode>
          <Typography>{localeLabel}</Typography>
        </Country>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Stack>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={(e) => handleSelectLocale('EN', 'en', 'gb', e)}>
          <Country name="EN" code="gb" hideCode>
            <Typography variant="subtitle2" color="secondary">
              EN
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelectLocale('CN', 'zh-CN', 'cn', e)}>
          <Country name="CN" code="cn" hideCode>
            <Typography variant="subtitle2" color="secondary">
              CN
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelectLocale('JP', 'ja', 'jp', e)}>
          <Country name="JP" code="jp" hideCode>
            <Typography variant="subtitle2" color="secondary">
              JP
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelectLocale('KO', 'ko', 'kr', e)}>
          <Country name="KO" code="kr" hideCode>
            <Typography variant="subtitle2" color="secondary">
              KO
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelectLocale('TH', 'th', 'th', e)}>
          <Country name="TH" code="th" hideCode>
            <Typography variant="subtitle2" color="secondary">
              TH
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelectLocale('VN', 'vi', 'vn', e)}>
          <Country name="VN" code="vn" hideCode>
            <Typography variant="subtitle2" color="secondary">
              VN
            </Typography>
          </Country>
        </MenuItem>
      </Menu>
    </HasFeature>
  );
};
