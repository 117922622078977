import { Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from 'utils/config';

export const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { auth0Domain, auth0ClientId, auth0CallbackUrl, auth0Audience } = getConfig();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || '/dashboard');
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: auth0CallbackUrl,
        scope: 'openid profile email offline_access read:current_user',
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
};
