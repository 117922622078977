import { MenuItem, Select, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkflowStepRouteParams } from './WorkflowApplicationLayout';
import { useApplication } from '../../contexts/ApplicationContext';
import makeLinkToStep from '../../workflow/utils/makeLinkToStep';
import { getApplicationUrlForCurrentParty } from './utils/getApplicationUrlForCurrentParty';

export const CollapsedStepsAndStages = () => {
  const { slug: schoolSlug, applicant: applicantId, application: applicationId, stage: stageSlug, step: stepSlug } = useParams() as WorkflowStepRouteParams;
  const {
    state: { application },
  } = useApplication();
  const navigate = useNavigate();
  const stages = application?.workflow.stages || [];
  const currentStage = stages.find((stage) => stage.slug === stageSlug);
  const steps = currentStage?.steps || [];
  const currentStep = steps.find((step) => step.slug === stepSlug);

  const navigateToStage = (stageSlugToNavigate: string) => {
    if (!application) return;
    const url = getApplicationUrlForCurrentParty(application, stageSlugToNavigate, schoolSlug, applicantId);
    if (url) navigate(url);
  };

  const navigateToStep = (stepToNavigate: string) => {
    const url = makeLinkToStep(schoolSlug, applicantId, applicationId, stageSlug, stepToNavigate);
    navigate(url);
  };

  return (
    <Stack direction="column" sx={{ my: 2 }} spacing={1}>
      <Select size="small" value={currentStage?.slug} onChange={(event) => navigateToStage(event.target.value)}>
        {stages.map((stage) => (
          <MenuItem key={stage.slug} value={stage.slug}>
            {stage.name}
          </MenuItem>
        ))}
      </Select>
      <Select size="small" value={currentStep?.slug} onChange={(event) => navigateToStep(event.target.value)}>
        {steps.map((step) => (
          <MenuItem key={step.slug} value={step.slug}>
            {step.name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
