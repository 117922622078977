import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';
import Grid from '@mui/material/Unstable_Grid2';
import { ApplicationProvider } from 'contexts/ApplicationContext';
import { StickyPageHeader } from 'views/Settings/StickyPageHeader';
import { useApplicant } from 'contexts/ApplicantContext';
import { useState } from 'react';
import { PERMISSION_LINK_APPLICATION_TO_LEAD, PERMISSION_SHARE_APPLICATION, ROLE_TYPE_SHARED_APPLICANT } from 'permissions/constants/Roles';
import { Application } from '../../types/Application';
import HasFeature from '../../businessToggles/HasFeature';
import { FLAG_ENQUIRE_NOW } from '../../businessToggles/Flags';
import HasAccess from '../../permissions/HasAccess';
import LinkWithEnquiryButton from '../../enquiries/LinkWithEnquiryButton';
import { LinkWithEnquiryDialog } from '../../enquiries/LinkWithEnquiryDialog';
import getCurrentStep from '../../workflow/utils/getCurrentStep';
import { ShareApplicationButton } from '../../sharedApplication/ShareApplicationButton';
import { ApplicantSummary } from './ApplicantSummary';
import { Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { NewWorkflowActions } from './actions/NewWorkflowActions';
import { convertApiActionsToBuilderActions } from '../FormBuilder/utils/convertApiActionsToBuilderActions';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissions } from '../../contexts/PermissionContext';
import { WorkflowStepRouteParams } from './WorkflowApplicationLayout';
import { CollapsedStepsAndStages } from './CollapsedStepsAndStages';
import { getApplicationUrlForCurrentParty } from './utils/getApplicationUrlForCurrentParty';

export interface ApplicantRouteParams extends SchoolWorkflowRouteParams {
  application: string;
}

interface WorkflowHeaderProps {
  application?: Application | null;
}

export const WorkflowHeader = ({ application = null }: WorkflowHeaderProps) => {
  const {
    state: { applicant },
  } = useApplicant();
  const { slug: schoolSlug, stage: stageSlug } = useParams() as WorkflowStepRouteParams;
  const theme = useTheme();
  const { hasRole } = usePermissions();
  const navigate = useNavigate();
  const [showLinkWithLeadDialog, setShowLinkWithLeadDialog] = useState(false);
  const currentStep = application ? getCurrentStep(application.workflow, application.current_stage_id, application.current_step_id) : null;
  // this is the current stage displayed
  const currentStage = application?.workflow.stages.find((stage) => stage.slug === stageSlug) || null;
  // this is the current stage the application process is up to
  const currentApplicationStateStage = application?.workflow.stages.find((stage) => stage.id === application?.current_stage_id) || null;
  const isMediumOrAbove = useMediaQuery(theme.breakpoints.up('md'));

  if (!applicant) return <></>;

  const handleToggleLinkWithLeadDialog = () => {
    setShowLinkWithLeadDialog(!showLinkWithLeadDialog);
  };

  const navigateToStage = (stageSlugToNavigate: string) => {
    if (!application) return;
    const url = getApplicationUrlForCurrentParty(application, stageSlugToNavigate, schoolSlug, applicant.id);
    if (url) navigate(url);
  };

  return (
    <ApplicationProvider>
      <StickyPageHeader>
        <Grid container justifyContent="center" px={2}>
          <Grid lg={12} xl={10}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" spacing={1} mb={2}>
              <ApplicantSummary application={application} />
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                {!!application && !application.lead_id && (
                  <HasFeature name={[FLAG_ENQUIRE_NOW]}>
                    <HasAccess for="permission" name={[PERMISSION_LINK_APPLICATION_TO_LEAD]}>
                      <LinkWithEnquiryButton
                        showAutoSuggestEnquiry
                        hasEnquiry={application && !!application.lead_id}
                        applicationId={application.id}
                        onClick={handleToggleLinkWithLeadDialog}
                      />
                      <LinkWithEnquiryDialog applicationId={application.id} active={showLinkWithLeadDialog} onToggle={handleToggleLinkWithLeadDialog} />
                    </HasAccess>
                  </HasFeature>
                )}
                {!!application && currentStep && currentStep.shareable && (
                  <HasAccess for="permission" name={[PERMISSION_SHARE_APPLICATION]}>
                    <ShareApplicationButton />
                  </HasAccess>
                )}
                {!!application && !hasRole([ROLE_TYPE_SHARED_APPLICANT]) && (
                  <NewWorkflowActions
                    actions={convertApiActionsToBuilderActions(application.workflow.actions)}
                    workflowEndpoint={`/schools/${schoolSlug}/applications/${application.id}`}
                  />
                )}
              </Stack>
            </Stack>
            {isMediumOrAbove && !!application && currentStage && (
              <Tabs value={currentStage.slug} centered onChange={(_, newStage) => navigateToStage(newStage)}>
                {application.workflow.stages.map((stage) => (
                  <Tab
                    key={stage.slug}
                    label={stage.name}
                    value={stage.slug}
                    disabled={stage.order > (currentApplicationStateStage?.order || 0)}
                    sx={{ px: 8, fontSize: '1.1em' }}
                  />
                ))}
              </Tabs>
            )}
            {!isMediumOrAbove && !!application && currentStage && <CollapsedStepsAndStages />}
          </Grid>
        </Grid>
      </StickyPageHeader>
    </ApplicationProvider>
  );
};
