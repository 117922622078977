export const ROLE_TYPE_ENROLLER_ADMIN = 'enroller-admin';
export const ROLE_TYPE_APPLICANT = 'applicant';
export const ROLE_TYPE_AGENT = 'agent';
export const ROLE_TYPE_PARENT = 'parent';
export const ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF = 'institutional-read-only-staff';
export const ROLE_TYPE_INSTITUTION_STAFF = 'institutional-staff';
export const ROLE_TYPE_INSTITUTION_ADMIN = 'institutional-admin';
export const ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF = 'institutional-marketing-only-staff';
export const ROLE_TYPE_SHARED_APPLICANT = 'shared-applicant';

export const GROUP_TYPE_INSTITUTION = 'institutional-users';
export const GROUP_TYPE_NON_INSTITUTION = 'non-institutional-users';
export enum GroupType {
  Institution = 'institutional-users',
  NonInstitution = 'non-institutional-users',
  AllRegistered = 'registered-users',
}

export const PERMISSION_VIEW_PIPELINE = 'view-pipeline';
export const PERMISSION_VIEW_DASHBOARD = 'view-dashboard';
export const PERMISSION_VIEW_INSTITUTION_SETTINGS = 'view-institution-settings';
export const PERMISSION_EDIT_INSTITUTION_SETTINGS = 'edit-institution-settings';
export const PERMISSION_VIEW_INSTITUTION_USERS = 'view-institution-users';
export const PERMISSION_ADD_INSTITUTION_USERS = 'add-institution-users';
export const PERMISSION_VIEW_INSTITUTION_AGENTS = 'view-institution-agents';
export const PERMISSION_INVITE_INSTITUTION_AGENTS = 'invite-institution-agents';
export const PERMISSION_VIEW_APPLICATION_SETTINGS = 'view-application-settings';
export const PERMISSION_EDIT_APPLICATION_SETTINGS = 'edit-application-settings';
export const PERMISSION_VIEW_FINANCIAL_SETTINGS = 'view-financial-settings';
export const PERMISSION_EDIT_INVOICE_TEMPLATES = 'edit-invoice-templates';
export const PERMISSION_ADD_INVOICE_TEMPLATES = 'add-invoice-templates';
export const PERMISSION_CREATE_INVOICE = 'create-invoice';
export const PERMISSION_CREATE_LEAD = 'create-lead';
export const PERMISSION_VIEW_LEAD = 'view-lead';
export const PERMISSION_EDIT_LEAD = 'edit-lead';
export const PERMISSION_LINK_APPLICATION_TO_LEAD = 'link-application-to-lead';
export const PERMISSION_SHARE_APPLICATION = 'share-application';
export const PERMISSION_SELECT_LANGUAGE = 'select-language';
export const PERMISSION_TRANSFER_OWNERSHIP = 'transfer-ownership';
export const PERMISSION_UPLOAD_DOCUMENTS = 'upload-documents';
export const PERMISSION_ADD_NOTES = 'add-notes';
export const PERMISSION_EDIT_INSTITUTION_AGENTS = 'edit-institution-agents';
export const PERMISSION_EDIT_INSTITUTION_USERS = 'edit-institution-users';
export const PERMISSION_IMPORT_APPLICATION = 'import-application';
