export const NOTES_GET = 'NOTES_GET';
export const NOTES_GET_RESPONSE = 'NOTES_GET_RESPONSE';
export const NOTES_ADD = 'NOTES_ADD';
export const NOTES_ADD_RESPONSE = 'NOTES_ADD_RESPONSE';
export const NOTES_IDLE = 'NOTES_IDLE';
export const NOTES_EDIT = 'NOTES_EDIT';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const NOTES_UPDATE_RESPONSE = 'NOTES_UPDATE_RESPONSE';
export const NOTES_DELETE = 'NOTES_DELETE';
export const NOTES_DELETE_RESPONSE = 'NOTES_DELETE_RESPONSE';
export const TASK_COMPLETE = 'TASK_COMPLETE';
export const TASK_COMPLETE_RESPONSE = 'TASK_COMPLETE_RESPONSE';
