import makeLinkToStep from '../../../workflow/utils/makeLinkToStep';
import { Application } from '../../../types/Application';

export const getApplicationUrlForCurrentParty = (
  application: Application,
  stageSlugToNavigate: string,
  schoolSlug: string,
  applicantId: string,
): string | null => {
  if (!application) return null;
  const firstStepInStageId = application.current_accessible_step_ids[stageSlugToNavigate];
  const stage = application.workflow.stages.find((s) => s.slug === stageSlugToNavigate);
  if (!stage) return null;
  const firstStepInStage = stage.steps.find((s) => s.id === firstStepInStageId);
  if (!firstStepInStage) return null;

  return makeLinkToStep(schoolSlug, applicantId, application.id, stageSlugToNavigate, firstStepInStage.slug);
};
