import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import schoolAPI from 'app/api/schoolAPI';
import { getConfig } from '../utils/config';

export function selectPaymentOption(slug) {
  return {
    type: ActionTypes.PAYMENT_SELECT_OPTION,
    slug,
  };
}

export function getPaymentProviders(applicationId, slug) {
  return function (dispatch) {
    const { apiUrl } = getConfig();

    dispatch({
      type: ActionTypes.PAYMENT_GET_PROVIDERS,
    });

    schoolAPI
      .GET({
        path: `${apiUrl}/schools/{slug}/applications/{application}/payment-providers`,
        params: {
          slug,
          application: applicationId,
        },
      })
      .then((response) => {
        if (response.status.code === 200) {
          dispatch({
            type: ActionTypes.PAYMENT_GET_PROVIDERS_RESPONSE,
            payload: {
              success: true,
              paymentProviders: response.entity.data,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.PAYMENT_GET_PROVIDERS_RESPONSE,
            payload: {
              success: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.PAYMENT_GET_PROVIDERS_RESPONSE,
          payload: {
            success: false,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}
