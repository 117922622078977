import { format, isValid } from 'date-fns';
import { DATE_DASH_FORMAT, TIME_FORMAT } from '../../../app/constants/DateFormats';
import { ApiFormBuilderStepSubmission } from '../types/api/ApiWorkflowStepSubmission';
import { FormBuilderField } from '../../FormBuilder/types/FormBuilderField';

export const formatStepData = (formData: Record<string, string | Date | null | any>, fieldsMap: Map<string, FormBuilderField>) => {
  let stepData: ApiFormBuilderStepSubmission['step_data'] = [];

  Object.entries(formData).forEach(([fieldKey, fieldData]) => {
    const field = fieldsMap.get(fieldKey)!;
    let dataValue = fieldData;

    // Condition to skip form fields within a customfieldsplaceholder field which
    // have field values but don't have a field entry in the DB.
    // Check for undefined and disabled to filter out fields not displayed or filled out
    if (field && field.value !== undefined && !field.disabled && !['content'].includes(field.type)) {
      // convert string from textarea into JSON object
      if (field.type === 'apijson') dataValue = JSON.parse(fieldData);
      // strip timezone from date
      if (field.type === 'createinvoice' && fieldData.due_date) {
        dataValue = {
          ...fieldData,
          due_date: format(fieldData.due_date, DATE_DASH_FORMAT),
        };
      }
      // strip timezone from date
      if (field.type === 'localtime' && fieldData.length) {
        dataValue = [...fieldData];
        if (fieldData[0] instanceof Date) {
          dataValue[0] = format(fieldData[0], DATE_DASH_FORMAT);
        }
        if (fieldData[1] instanceof Date) {
          dataValue[1] = isValid(fieldData[1]) ? format(fieldData[1], TIME_FORMAT) : null;
        }
      }
      // strip timezone from startandenddate
      if (field.type === 'startandenddate' && fieldData.length) {
        dataValue = [...fieldData];
        if (fieldData[0] instanceof Date) {
          dataValue[0] = format(fieldData[0], DATE_DASH_FORMAT);
        }
        if (fieldData[1] instanceof Date) {
          dataValue[1] = format(fieldData[1], DATE_DASH_FORMAT);
        }
      }

      if (field.type === 'datepicker') {
        dataValue = isValid(fieldData) ? format(fieldData, DATE_DASH_FORMAT) : null;
      }

      if (field.type === 'time') {
        dataValue = isValid(fieldData) ? format(fieldData, TIME_FORMAT) : null;
      }

      if (field.type === 'reviewstep') {
        const newReviewComments = {};
        fieldData.fields.forEach((value, key) => {
          newReviewComments[key] = value;
        });

        // If there are no comments, we keep the status as unreviewed, otherwise reviewing
        const hasComments = fieldData.fields.entries().length;
        dataValue = {
          status: hasComments ? 'reviewing' : 'unreviewed',
          fields: newReviewComments,
        };
      }

      stepData.push({
        id: field.id,
        value: dataValue,
      });
    }
  });

  return stepData;
};
