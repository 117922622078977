import React from 'react';
import { useWorkflow } from 'contexts/WorkflowContext';
import { FormField } from 'components/FormBuilder/FormField';
import { Control } from 'react-hook-form';
import { Typography } from '@mui/material';
import Loader from '../../Loader';
import { FormBuilderField } from '../types/FormBuilderField';
import { convertApiFieldsToBuilderFields } from '../utils/convertApiFieldsToBuilderFields';
import { ApiFormBuilderField } from '../types/api/ApiFormField';
import { getValuesFromFormData } from '../FormBuilder';
import { useApplication } from '../../../contexts/ApplicationContext';

interface TemplateDisplayFieldProps {
  control: Control;
  mapperGroup: string;
  fields: FormBuilderField[];
  fieldsMap: Map<string, ApiFormBuilderField>;
}

export const TemplateDisplayField = ({ fields, fieldsMap, control, mapperGroup }: TemplateDisplayFieldProps) => {
  const {
    state: { workflow },
  } = useWorkflow();
  const {
    state: { application },
  } = useApplication();

  if (!workflow) return <Loader />;

  const group = workflow.mapper_groups.find((mg) => mg.id === mapperGroup);
  let fieldsToRender = [<></>];

  if (group && group.fields) {
    fieldsToRender = group.fields.map((groupField) => {
      const apiField = fieldsMap.get(groupField.id);

      if (!apiField) {
        const message = `No field ${groupField.id} found for TemplateDisplayField mapper group ${group.id}`;
        console.error(message);
        return <Typography color="error">{message}</Typography>;
      }
      const field = convertApiFieldsToBuilderFields([apiField])[0];
      const value = getValuesFromFormData([field], application?.custom_questions || {}, application?.custom_question_answers || {})[field.slug];

      return <FormField key={field.slug} field={field} value={value} control={control} fields={fields} readOnly />;
    });
  }

  return <div data-cy-field-type="template-display">{fieldsToRender}</div>;
};

// for React.lazy
export default TemplateDisplayField;
