import { useMemo } from 'react';
import { Autocomplete, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import Placeholder from 'forms/consumer/components/Placeholder';
import { useCountries } from 'app/CountriesContext';
import { Country } from 'app/constants/countries';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface CountryFieldProps {
  label: string;
  disabled: boolean;
  value: number;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const CountryField = ({ label, disabled, value, onChange, error, readOnly, name }: CountryFieldProps) => {
  const countries = useCountries();
  const countryList = useMemo(() => countries.map((c) => c.id), [countries]);
  const countryMap: Map<number, Country> = useMemo(() => {
    const map = new Map<number, Country>();
    countries.forEach((country) => {
      map.set(country.id, country);
    });
    return map;
  }, [countries]);

  if (countries.length === 0) {
    return (
      <FormControl fullWidth>
        <FormLabel>{label}</FormLabel>
        <Placeholder fieldType="profile" animate />
      </FormControl>
    );
  }

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="country">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Autocomplete
          disableClearable
          disablePortal
          aria-labelledby={`${name}-label`}
          size="small"
          options={countryList}
          disabled={disabled}
          getOptionLabel={(o) => countryMap.get(o)!.name}
          value={value}
          renderInput={(params) => {
            const paramsAndProps = {
              ...params,
              inputProps: { ...params.inputProps, name },
            };
            return <TextField {...paramsAndProps} error={!!error} placeholder="Select country" />;
          }}
          renderTags={() => <></>}
          onChange={(_, values) => onChange(values)}
          readOnly={readOnly}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default CountryField;
