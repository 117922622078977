import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

type SkipStepFormValues = {
  reason: string;
};

interface SkipStepDialogProps {
  title: string;
  label: string;
  onSubmit: (data: SkipStepFormValues) => void;
  onToggle: () => void;
  active: boolean;
  loading: boolean;
}

const SkipStepDialog = ({ title, label, onSubmit, onToggle, active, loading }: SkipStepDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SkipStepFormValues>({
    defaultValues: { reason: '' },
  });

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle variant="h4" component="h2">
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            {title || 'Skip this step'}
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <FormControl fullWidth required>
            <FormLabel id="firstNameLabel">{label}</FormLabel>
            <Controller
              control={control}
              rules={{
                required: `${label} is required`,
              }}
              name="reason"
              render={({ field }) => {
                return <TextField {...field} error={!!errors.reason} />;
              }}
            />
          </FormControl>
          {errors.reason && <Typography color="error">{errors.reason.message}</Typography>}
          <DialogActions>
            <LoadingButton variant="contained" type="submit" loading={loading} disabled={loading}>
              Submit
            </LoadingButton>

            <Button variant="contained" onClick={onToggle}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SkipStepDialog;
