import ReactGA from 'react-ga4';
import { getConfig } from './config';

export const initGoogleAnalytics = () => {
  const { environment, googleAnalyticsId } = getConfig();

  if (environment === 'local') {
    console.info(`Skipping GA initialisation in local environment`);
    return;
  }
  if (!googleAnalyticsId) return;

  ReactGA.initialize(googleAnalyticsId, {
    gaOptions: {
      userId: 'anonymous',
    },
  });
};
