export const INVOICE_STATUS_DRAFT = 'draft';
export const INVOICE_STATUS_APPROVED = 'approved';
export const INVOICE_STATUS_VOIDED = 'voided';

export const PAYMENT_STATUS_CREATED = 'created';
export const PAYMENT_STATUS_VOIDED = 'voided';
export const PAYMENT_STATUS_INITIATED = 'initiated';
export const PAYMENT_STATUS_RECEIVED = 'received';
export const PAYMENT_STATUS_ADJUSTMENT = 'adjustment';
export const PAYMENT_STATUS_COMPLETE = 'complete';
export const PAYMENT_STATUS_CANCELLED = 'cancelled';
export const PAYMENT_STATUS_FAILED = 'failed';
export const PAYMENT_STATUS_OVERPAID = 'overpaid';
