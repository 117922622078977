import { memo } from 'react';
import Content from 'components/Translation/Content';
import { T } from 'components/Translation/Translation';
import { Tag } from 'components/Tag/Tag';
import { Stack, Typography } from '@mui/material';

const StageColumns = (props) => {
  return props.stages.map((stage, index) => (
    <Stack mr={2} mt={4} key={`stage-${index}`}>
      <Tag title={stage.name} translationKey={stage.name_translation_key} />
      <Typography>
        <Content name={stage.description_translation_key}>{stage.description}</Content>
      </Typography>
      <Typography>
        <T name={stage.description_translation_key} />
      </Typography>
    </Stack>
  ));
};

export default memo(StageColumns);
