import { useMemo, useState } from 'react';
import { UseGetOptions, useStatelessGet } from 'hooks/useStatelessGet';

export const useGet = <T>(url: string, options?: UseGetOptions): [boolean, (params?: URLSearchParams) => Promise<T>] => {
  const [loading, setIsLoading] = useState(false);
  const get = useStatelessGet<T>(url, options);

  return [
    loading,
    useMemo(
      () => async (params?: URLSearchParams) => {
        try {
          setIsLoading(true);
          const response = await get(params);
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      },
      [get],
    ),
  ];
};
