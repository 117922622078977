import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import agentsAPI from 'app/api/agentsAPI';
import invitationsAPI from 'app/api/invitationsAPI';

export function getAgents(slug, active = 0) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.AGENTS_GET,
    });

    const params = {
      slug,
      type: 'Agent',
      active,
    };

    agentsAPI
      .GET({
        params,
      })
      .then((response) => {
        if (response.status.code === 200) {
          dispatch({
            type: ActionTypes.AGENTS_GET_RESPONSE,
            payload: {
              success: true,
              agents: response.entity.data,
            },
          });
        } else {
          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        ErrorActions.show(error)(dispatch);
      });
  };
}

export function sendInvitation(values, slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.AGENTS_ADD,
    });

    // invitations
    invitationsAPI
      .POST({
        entity: values,
        params: {
          slug,
        },
      })
      .then((response) => {
        if (response.status.code === 200 || response.status.code === 201) {
          dispatch({
            type: ActionTypes.AGENTS_ADD_RESPONSE,
            payload: {
              success: true,
              message: response.entity.message,
            },
          });
        } else {
          ErrorActions.show(response)(dispatch);
        }
      })
      .catch((error) => {
        ErrorActions.show(error)(dispatch);
      });
  };
}
