export const ACTION_WITHDRAW = 'withdraw';
export const ACTION_NEXT = 'next';
export const ACTION_SKIP_TO_STEP = 'skip-to-step';
export const ACTION_EXPORT = 'export';
export const ACTION_SAVE = 'save';
export const ACTION_DECLINE = 'decline';
export const ACTION_SHARE_INVOICE = 'share-invoice';
export const ACTION_LINK = 'link';
export const ACTION_REENROL = 're-enrol';

// Appears on the left (relevant for both step and workflow actions)
export const POS_LEFT = 'left';
// Appears on the right (relevant for both step and workflow actions)
export const POS_RIGHT = 'right';
// Appears in the pipeline, on the previous stage (relevant for step actions)
export const POS_PREVIOUS_STAGE = 'previous-stage';
// Appears in the pipeline (relevant for step actions)
export const POS_PIPELINE = 'pipeline';
export const POS_STEPLIST = 'step-list';
