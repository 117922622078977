import { useState } from 'react';
import { useApplication } from 'contexts/ApplicationContext';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { slugify } from 'utils/slugify';
import { useStatelessPost } from 'hooks/useStatelessPost';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { ApiWorkflowPostAction } from 'types/api/ApiWorkflowActions';

const declineReasons = [
  'Language proficiency',
  'I am going to another school',
  'I have not heard anything back',
  'I do not have enough money',
  'My visa has been declined',
  'I have changed my mind about the school',
  'Other',
];
const specifyReasons = ['Other', 'I have changed my mind about the school'];

interface DeclineProps {
  label: string;
  actionId: string;
  workflowEndpoint: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const Decline = ({ label: buttonLabel, actionId, workflowEndpoint, disabled, color, variant }: DeclineProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { decliningReason: '', declineReasonOther: '' },
  });
  const reasonWatch = watch('decliningReason');
  const { slug, applicant: applicantId, application: applicationId } = useParams() as ApplicantRouteParams;
  const actionUrl = `${workflowEndpoint}/actions/${actionId}`;
  const postDeclineAction = useStatelessPost<ResponseEnvelope<ApiWorkflowPostAction>>(actionUrl);
  const { loadApplication } = useApplication();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useNotificationMessages();

  const onSubmit = handleSubmit(async (data) => {
    const declineReason = specifyReasons.includes(reasonWatch) ? data.declineReasonOther : data.decliningReason;

    try {
      setIsSubmitting(true);

      const { success, data, message } = await postDeclineAction({
        reason: declineReason,
      });
      const applicationProfileURL = `/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}`;

      if (success) {
        setShowDialog(false);
        showSuccessMessage(message);
        const nextStageSlug = data.next_step && data.next_step.stage.slug;
        const nextStepSlug = data.next_step && data.next_step.slug;

        if (nextStageSlug && nextStepSlug) {
          navigate(`${applicationProfileURL}/stages/${nextStageSlug}/steps/${nextStepSlug}`);
        } else {
          navigate(applicationProfileURL);
        }

        return loadApplication();
      } else {
        showErrorMessage(message);
      }

      setIsSubmitting(false);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  });

  return (
    <>
      <Button
        variant={variant}
        color={color}
        disabled={disabled}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        {buttonLabel}
      </Button>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Typography variant="h4" component="h2">
              Contact School
            </Typography>
            <IconButton
              aria-label="close"
              title="Close"
              onClick={() => setShowDialog(false)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <Controller
              name="decliningReason"
              control={control}
              rules={{
                required: 'A reason is required',
              }}
              render={({ field: { value, onChange } }) => (
                <FormControl required>
                  <FormLabel id="decliningReason-label">Please select the reason why you have declined this offer</FormLabel>
                  <RadioGroup aria-labelledby="decliningReason-label" value={value} name="decliningReason" onChange={(_, newValue) => onChange(newValue)}>
                    {declineReasons.map((option) => {
                      const valueSlug = slugify(option);

                      return (
                        <FormControlLabel
                          key={`decliningReason-radio-${valueSlug}`}
                          value={option}
                          control={<Radio />}
                          checked={value === option}
                          label={option}
                        />
                      );
                    })}
                  </RadioGroup>
                  {errors.decliningReason ? <Typography color="error">{errors.decliningReason.message}</Typography> : <br />}
                </FormControl>
              )}
            />
            {specifyReasons.includes(reasonWatch) ? (
              <Controller
                name="declineReasonOther"
                control={control}
                rules={{
                  validate: (fieldValue) => {
                    return !(specifyReasons.includes(reasonWatch) && fieldValue.length < 3);
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth required>
                    <FormLabel id="declineReasonOther-label">Please specify why</FormLabel>
                    <TextField
                      multiline
                      rows={3}
                      aria-labelledby="declineReasonOther-label"
                      name="declineReasonOther"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      size="small"
                      error={Boolean(errors.declineReasonOther)}
                    />
                    {errors.declineReasonOther ? <Typography color="error">{errors.declineReasonOther.message}</Typography> : <br />}
                  </FormControl>
                )}
              />
            ) : (
              <></>
            )}
            <DialogActions>
              <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                Submit
              </LoadingButton>
              <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
