import { isEmpty, isDate } from 'lodash';

/**
 * Validate input as required
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
export const required = (value) => {
  switch (typeof value) {
    case 'undefined':
      return false;
    case 'boolean':
      return true;
    case 'string':
      return value !== '';
    case 'number':
      return true;
    case 'object':
      if (isDate(value)) {
        return true;
      }

      return !isEmpty(value);
    default:
      return !!value;
  }
};

/**
 * Validate input as an email address
 * @param  {string}   value    Value of field
 * @return {boolean}           Input is valid
 */
export const email = (value) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );
};

/**
 * Validates input `value` as minimum against `min`
 * @param value
 * @param min
 * @returns {boolean}
 */
export const min = (value, min) => {
  return parseInt(value, 10) >= parseInt(min, 10);
};

/**
 * Validates input `value` as maximum against `max`
 * @param value
 * @param max
 * @returns {boolean}
 */
export const max = (value, max) => {
  return parseInt(value, 10) <= parseInt(max, 10);
};

/**
 * Validations input `value` against custom regex pattern `regex`
 * @param value
 * @param regex
 * @returns {boolean}
 */
export const regex = (value, regex) => {
  const pattern = new RegExp(regex);
  return pattern.test(value);
};

export const equal = (value, match) => {
  return value === match;
};
