// easing functions http://goo.gl/5HLl8
// @ts-expect-error TS(2339): Property 'easeInOutQuad' does not exist on type 'M... Remove this comment to see the full error message
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;

  if (t < 1) {
    return (c / 2) * t * t + b;
  }

  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

// @ts-expect-error TS(2339): Property 'easeInCubic' does not exist on type 'Mat... Remove this comment to see the full error message
Math.easeInCubic = function (t, b, c, d) {
  const tc = (t /= d) * t * t;
  return b + c * tc;
};

// @ts-expect-error TS(2339): Property 'inOutQuintic' does not exist on type 'Ma... Remove this comment to see the full error message
Math.inOutQuintic = function (t, b, c, d) {
  const ts = (t /= d) * t;
  const tc = ts * t;
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
};

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (function () {
  if (typeof window !== 'undefined') {
    return (
      window.requestAnimationFrame ||
      // @ts-expect-error TS(2339): Property 'webkitRequestAnimationFrame' does not ex... Remove this comment to see the full error message
      window.webkitRequestAnimationFrame ||
      // @ts-expect-error TS(2339): Property 'mozRequestAnimationFrame' does not exist... Remove this comment to see the full error message
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  }

  return function () {};
})();

export default function scrollTo(to, callback, duration) {
  function move(amount) {
    document.documentElement.scrollTop = amount;
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  }

  function position() {
    return (
      document.documentElement.scrollTop ||
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  }

  const start = position();
  const change = to - start;
  let currentTime = 0;
  const increment = 20;
  duration = typeof duration === 'undefined' ? 500 : duration;

  const animateScroll = function () {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    // @ts-expect-error TS(2339): Property 'easeInOutQuad' does not exist on type 'M... Remove this comment to see the full error message
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);

    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      if (callback && typeof callback === 'function') {
        // the animation is done so lets callback
        callback();
      }
    }
  };

  animateScroll();
}
