import * as conditionFunctions from '../../../forms/consumer/conditions';

const SOURCE_TYPE_FIELD = 'field';
const SOURCE_TYPE_ROLE = 'role';
const SOURCE_TYPE_INSTITUTION = 'institution';
const SOURCE_TYPE_APPLICANT = 'applicant';
const SOURCE_TYPE_CONTEXT = 'context';

const getReferenceValue = (slug, source, formValues, fieldsMap, reference, user, school, applicant) => {
  let referenceValue;

  switch (source) {
    case SOURCE_TYPE_FIELD: {
      if (!fieldsMap.has(reference)) {
        throw new Error(`Reference field ${reference} not found when checking conditions for ${slug}`);
      }
      const applicationField = fieldsMap.get(reference);
      let currentReferenceValue = formValues[applicationField.slug];
      if (applicationField.field_type.name === 'selectlocaltime' && Array.isArray(currentReferenceValue)) {
        currentReferenceValue = currentReferenceValue[0];
      }
      referenceValue = currentReferenceValue || applicationField.value;
      break;
    }
    case SOURCE_TYPE_ROLE:
      referenceValue = user!.userData.roles[0];
      break;
    case SOURCE_TYPE_INSTITUTION:
      if (reference.includes('properties.')) {
        const institutionProperty = school.properties.find((property) => property.key === reference.replace('properties.', ''));

        if (institutionProperty) {
          referenceValue = institutionProperty.pivot.value;
        }
      } else {
        referenceValue = school[reference];
      }

      break;
    case SOURCE_TYPE_APPLICANT:
      referenceValue = applicant[reference];
      break;
    case SOURCE_TYPE_CONTEXT:
      referenceValue = 'frontend';
      break;
  }

  return referenceValue;
};

const checkCondition = (condition, value, referenceValue, show) => {
  const comparisonFunc = conditionFunctions[condition];
  const meetsCondition = comparisonFunc(referenceValue, value);

  if (meetsCondition) {
    return show;
  }

  return !show;
};

export const evaluateDisplayConditions = (slug, conditions, user, applicant, fieldsMap, formValues, school) => {
  return conditions.every((condition) => {
    const show = condition.show === 'show';
    const referenceValue = getReferenceValue(slug, condition.source, formValues, fieldsMap, condition.reference, user, school, applicant);
    return checkCondition(condition.condition, condition.value, referenceValue, show);
  });
};
