import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { FieldError, ControllerRenderProps } from 'react-hook-form';

interface EmailFieldProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const EmailField = ({ label, disabled, value, onChange, error, name, readOnly }: EmailFieldProps) => {
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="email">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <TextField
          size="small"
          type="email"
          aria-labelledby={`${name}-label`}
          value={value}
          error={!!error}
          disabled={disabled}
          name={name}
          onChange={(event) => onChange(event.target.value)}
          inputProps={{ readOnly }}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default EmailField;
