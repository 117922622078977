import * as React from 'react';

/**
 * This component will render a country flag icon and the country name
 *
 * The "code" prop must be an ISO 3166-1-alpha-2 code (NZ for New Zealand, US for United States)
 * A complete list of country names can be found here: https://www.iso.org/obp/ui/#search
 */
export const Country = ({ children = (<></>) as any, code, hideCode = false, name }) => {
  const countryCode = code ? code.toLowerCase() : '';
  const className = hideCode ? 'country country--small' : 'country';

  return (
    <div className={className} title={name}>
      <div
        className="country__flag"
        style={{
          backgroundImage: `url('/images/flag-icons/flags/4x3/${countryCode}.svg')`,
        }}
      />
      {hideCode ? <></> : <small className="country__code">{countryCode}</small>}
      {children}
    </div>
  );
};

export default Country;
