import { itemsWithTotal } from 'payment/totals';
import {
  INVOICE_STATUS_VOIDED,
  PAYMENT_STATUS_ADJUSTMENT,
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_COMPLETE,
  PAYMENT_STATUS_CREATED,
  PAYMENT_STATUS_OVERPAID,
  PAYMENT_STATUS_VOIDED,
} from 'invoices/InvoiceStatuses';
import currency, { decimal } from 'currency/currency';
import { defaultTaxRateNz } from './taxRates';
import { Invoice, Payment } from 'types/Invoice';

export const getInvoiceTaxAmount = (items, currencyCode, locale) => {
  const getTaxMultiplier = (item) => 100 + (item.tax_rate === undefined ? defaultTaxRateNz : parseFloat(item.tax_rate));
  const getItemTotal = (item) => parseFloat((item.total * (1 - 100 / getTaxMultiplier(item))).toFixed(2));

  return parseFloat(
    itemsWithTotal(items, currencyCode, locale)
      .reduce((total: number, item) => total + getItemTotal(item), 0)
      .toFixed(2),
  );
};

export const getReceiptItems = (items, currencyCode, locale) => {
  if (!items) return [];
  return itemsWithTotal(items, currencyCode, locale).map((item) => ({
    ...item,
    paid_amount: item.paid_amount !== undefined && item.paid_amount !== null ? item.paid_amount : item.total,
  }));
};

export const getInvoiceBalanceDue = (invoice: Invoice, currencyCode, locale) => {
  if (invoice.status === INVOICE_STATUS_VOIDED) {
    return decimal(0, currencyCode, locale);
  }
  const excludedStatuses = [PAYMENT_STATUS_CREATED, PAYMENT_STATUS_VOIDED, PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_OVERPAID];
  const total = invoice.total || 0;
  const completedPayments = invoice.payments.filter((payment) => !excludedStatuses.includes(payment.status));
  const paymentsTotal = completedPayments.reduce((runningTotal, payment) => runningTotal + parseFloat(payment.amount), 0);
  const balanceDue = total - paymentsTotal;
  return decimal(balanceDue, currencyCode, locale);
};

export const getReceiptTotal = (items, currencyCode, locale) => {
  const receiptTotal = items.reduce((accumTotal, item) => {
    const received = item.paid_amount !== undefined && item.paid_amount !== null ? item.paid_amount : item.total;
    return accumTotal + parseFloat(received);
  }, 0);
  return decimal(receiptTotal, currencyCode, locale);
};

export const getReceivedTotal = (payments: Payment[], currencyCode, locale) => {
  if (!payments.length) return 0;
  const validStatuses = [PAYMENT_STATUS_COMPLETE, PAYMENT_STATUS_ADJUSTMENT];
  const completedPayments = payments.filter((payment) => validStatuses.includes(payment.status));
  const receivedTotal = completedPayments.reduce(
    (accumulatedTotal, payment) =>
      payment.status === PAYMENT_STATUS_VOIDED
        ? accumulatedTotal
        : decimal(accumulatedTotal, currencyCode, locale) + decimal(payment.amount, currencyCode, locale),
    0,
  );
  return decimal(receivedTotal, currencyCode, locale);
};

export const getReceiptPaymentsFormatted = (payments: Payment[], currencyCode, locale) => {
  const validStatuses = [PAYMENT_STATUS_COMPLETE, PAYMENT_STATUS_ADJUSTMENT];
  const completedPayments = payments.filter((payment) => validStatuses.includes(payment.status));
  return completedPayments.map((payment) => ({
    ...payment,
    amount: currency(payment.amount, currencyCode, locale),
  }));
};

export const getOverpaymentsFormatted = (payments: Payment[], currencyCode, locale) => {
  const validStatuses = [PAYMENT_STATUS_OVERPAID];
  const overpayments = payments.filter((payment) => validStatuses.includes(payment.status));
  return overpayments.map((payment) => ({
    ...payment,
    amount: currency(payment.amount, currencyCode, locale),
  }));
};
