import { useState } from 'react';
import { ShareInvoiceDialog } from 'invoices/ShareInvoiceDialog';
import { Button, ButtonProps } from '@mui/material';

interface ShareInvoiceProps {
  label: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}
const ShareInvoice = ({ label, disabled, variant, color }: ShareInvoiceProps) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button variant={variant} color={color} type="submit" disabled={disabled} onClick={() => setShowDialog(true)}>
        {label}
      </Button>
      <ShareInvoiceDialog active={showDialog} onToggle={() => setShowDialog(!showDialog)} />
    </>
  );
};

export default ShareInvoice;
