import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from '../../utils/user';
import { getConfig } from '../../utils/config';
import { useEffect } from 'react';
import { setLogRocketIdentity } from '../../utils/logRocket';
import { useAppSelector } from '../../app/hooks';

export const useLogRocket = () => {
  const { user } = useAuth0<AuthUser>();
  const { environment, intercomAppId } = getConfig();
  const schoolName = useAppSelector((state) => state.school.name);

  useEffect(() => {
    if (environment === 'local' || !user || !intercomAppId) {
      return;
    }

    setLogRocketIdentity(user, schoolName);
  }, [environment, intercomAppId, schoolName, user]);
};
