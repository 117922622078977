import { useEffect } from 'react';
import styled from 'styled-components';
import Country from 'components/Country/Country';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useMountEffect } from 'hooks/useMountEffect';
import { searchForLeadsByApplicant } from 'app/actions/applicationActions';
import { Button } from '@mui/material';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

const InlineEnquiry = styled.div`
  display: inline-flex;
`;

const EnquiryName = styled.strong`
  margin: 0 1rem;
  font-size: 1.4rem;
  white-space: nowrap;
`;

export const LinkWithEnquiryButton = ({ preventFetch = false, hasEnquiry, applicationId, onClick, showAutoSuggestEnquiry }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const dispatch = useAppDispatch();
  const selectedEnquiry = useAppSelector((state) =>
    state.application.selectedLeadId ? state.application.leads.find((enquiry) => enquiry.id === state.application.selectedLeadId) : null,
  );
  const name = selectedEnquiry ? `${selectedEnquiry.first_name} ${selectedEnquiry.last_name}` : null;
  const country = selectedEnquiry ? selectedEnquiry.country : null;

  useMountEffect(() => {
    if (!preventFetch && !hasEnquiry && applicationId) {
      dispatch(searchForLeadsByApplicant(applicationId, true, schoolSlug));
    }
  });

  useEffect(() => {
    if (applicationId) {
      dispatch(searchForLeadsByApplicant(applicationId, true, schoolSlug));
    }
  }, [dispatch, applicationId, showAutoSuggestEnquiry, schoolSlug]);

  return (
    <Button variant="outlined" onClick={() => onClick()}>
      Link with enquiry
      {showAutoSuggestEnquiry && name && country ? (
        <InlineEnquiry>
          :{name ? <EnquiryName>{name}</EnquiryName> : null}
          {country ? <Country hideCode name={country.name} code={country.iso_3166_2.toLowerCase()} /> : null}
        </InlineEnquiry>
      ) : null}
    </Button>
  );
};

export default LinkWithEnquiryButton;
