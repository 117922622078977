import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { memo, useEffect } from 'react';
import { getSchool } from 'app/actions/schoolActions';
import { useAuth0 } from '@auth0/auth0-react';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

const FetchSchoolComponent = ({ children }) => {
  const { slug } = useParams() as SchoolRouteParams;
  const dispatch = useAppDispatch();
  const id = useAppSelector((state: any) => state.school.id);
  const status = useAppSelector((state: any) => state.school.status);
  const workflow = useAppSelector((state: any) => state.school.workflow && state.school.workflow.id);
  const stateSlug = useAppSelector((state: any) => state.school.slug);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (status !== 'loading' && (slug !== stateSlug || (!id && status === 'default') || (isAuthenticated && !workflow))) {
      dispatch(getSchool(slug));
    }
  }, [dispatch, stateSlug, id, slug, status, workflow, isAuthenticated]);

  return children;
};

export const FetchSchool = memo(FetchSchoolComponent);
