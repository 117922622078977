import { Enroller } from 'app/Enroller';
import { SharedEnroller } from 'app/SharedEnroller';
import { createRoot } from 'react-dom/client';
import { initSentry } from './utils/sentry';
import { initLogRocket } from './utils/logRocket';
import { initGoogleAnalytics } from './utils/googleAnalytics';
import { initIntercom } from './utils/intercom';

import 'css/docs.css';
import 'css/style.css';

initSentry();
initLogRocket();
initGoogleAnalytics();
initIntercom();

window.addEventListener('DOMContentLoaded', function () {
  const appElement = document.querySelector('.js--app');
  const isShareableApp = window.location.pathname.includes('student-invoice');

  const root = createRoot(appElement!);
  if (!isShareableApp) {
    root.render(<Enroller />);
  } else {
    root.render(<SharedEnroller />);
  }
});
