export const NOTE_LABELS = [
  {
    value: '',
    label: '-',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'Call',
    label: 'Call',
  },
];
