import React from 'react';
import HTMLContent from 'workflow/components/HTMLContent';

const ContentField = ({ value }) => {
  return (
    <div data-cy-field-type="content">
      <HTMLContent message={value} />
    </div>
  );
};

export default ContentField;
