export const asyncLoad = async (src) => {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;

    s.onerror = function (err) {
      // @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
      reject(err, s);
    };

    // @ts-expect-error TS(2339): Property 'onreadystatechange' does not exist on ty... Remove this comment to see the full error message
    s.onload = s.onreadystatechange = function () {
      // @ts-expect-error TS(2339): Property 'readyState' does not exist on type 'HTML... Remove this comment to see the full error message
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      }
    };

    const t = document.getElementsByTagName('script')[0];

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    t.parentElement.insertBefore(s, t);
  });
};
