import { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { FileUpload } from '../../components/FileUpload/FileUpload';
import { usePost } from '../../hooks/usePost';
import { useNotificationMessages } from '../../hooks/useNotificationMessages';
import { getErrorMessage } from '../../utils/errors';
import { ResponseEnvelope } from '../../types/ResponseEnvelope';

interface UploadDocumentsDialogProps {
  onToggle: () => void;
  active: boolean;
  onSuccess: () => void;
  postUrl: string;
  fieldName: string;
  tag?: string;
}

export const UploadDocumentsDialog = ({ onToggle, active, onSuccess, postUrl, fieldName, tag }: UploadDocumentsDialogProps) => {
  const [loading, postFiles] = usePost<ResponseEnvelope<any>>(postUrl, {
    isMultiPartForm: true,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { [fieldName]: [] },
  });
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const uploadFiles = useCallback(
    async (files) => {
      const formData = new FormData();
      files.forEach((file, index) => formData.set(index, file));
      if (tag) formData.set('tag', tag);
      try {
        const response = await postFiles(formData);
        showSuccessMessage(response.message);
        await onSuccess();
        reset();
        onToggle();
      } catch (error) {
        showErrorMessage(getErrorMessage(error));
      }
    },
    [postFiles, showErrorMessage, showSuccessMessage, onSuccess, onToggle, reset, tag],
  );

  const onSubmit = handleSubmit((data) => {
    uploadFiles(data[fieldName]);
  });

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Upload documents
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Controller
            name={fieldName}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth required error={!!errors[fieldName]}>
                <FormLabel id="applicationFileLabel">
                  Please select files to upload <small>(Max file size 10MB)</small>
                </FormLabel>
                <FileUpload error={!!errors[fieldName]} name={fieldName} required value={value} onChange={onChange} />
                {errors[fieldName] && <Typography color="error">{errors[fieldName]?.message}</Typography>}
              </FormControl>
            )}
          />
          <DialogActions>
            <LoadingButton variant="contained" type="submit" loading={loading}>
              Upload
            </LoadingButton>
            <Button onClick={onToggle}>Cancel</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
