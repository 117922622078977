import { useAppSelector } from 'app/hooks';
import { UserMenu } from 'components/Menu/UserMenu';
import styled from 'styled-components';
import EnrollerHeader from './EnrollerHeader';
import defaultLogo from 'images/icons/favicon-180.png';
import enrollerLogo from 'images/logo-enroller.svg';

const LogoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.8rem;
  min-width: 9.6rem;
  padding: 1rem 0;
  text-align: center;
  background-color: #ffffff;
`;

const Logo = styled.img`
  position: relative;
  max-height: 100%;
`;

const SchoolAppBar = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  @media ${(props) => props.theme.lg} {
    overflow: visible;
  }

  @media all and (-ms-high-contrast: none) and (min-width: 1200px), (-ms-high-contrast: active) {
    justify-content: flex-start;
    width: 95%;
  }
`;

const SchoolHeading = styled.h1`
  margin: 0;
  padding: ${(props: any) => (props.multiple ? '0' : '0 2.2rem')};
  line-height: 7.8rem;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 2.4rem;
  max-width: 50rem;

  @media (min-width: 1600px) {
    font-size: 2.8rem;
    line-height: 7.8rem;
  }
`;

const SchoolHeader = styled.div`
  display: flex;
  flex: 1;
  position: fixed;
  left: 0;
  top: 6rem;
  width: 100%;
  box-shadow:
    0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14),
    0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12),
    0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.color};
  transition: background-color 0.3s ease-out;
  will-change: transform, background-color;
  z-index: 6;

  @media print {
    display: none;
  }
`;

export const SharedPageHeader = () => {
  const schoolName = useAppSelector((state) => state.school.name);
  const schoolLogo = useAppSelector((state) => state.school.logo);
  const schoolColor = useAppSelector((state) => state.school.color);
  const logoSrc = schoolLogo || defaultLogo;

  return (
    <>
      <EnrollerHeader>
        <Logo src={enrollerLogo} height="40" />
        <SchoolAppBar>
          <UserMenu hideLogin />
        </SchoolAppBar>
      </EnrollerHeader>
      <SchoolHeader color={schoolColor} className="app__schoolheader">
        <LogoWrapper>
          {typeof window === 'undefined' ? (
            <Logo
              src="{{ $institution ? Storage::disk()~~url($institution~~logo) : `/images/icons/favicon-120.png` }}"
              alt="{{ $institution ? $institution~~name : `Enroller` }}"
              height="57"
            />
          ) : (
            <Logo src={logoSrc} alt={schoolName} height="57" />
          )}
        </LogoWrapper>
        <SchoolHeading>{schoolName}</SchoolHeading>
      </SchoolHeader>
    </>
  );
};
