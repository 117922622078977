import { TextField } from '@mui/material';
import { useState } from 'react';
import { InvoiceLineItem } from 'types/Invoice';

interface ReceivedItemInputProps {
  item: InvoiceLineItem;
  isDisabled: boolean;
  onChange: (newValue: number) => void;
  // onBlur: () => void;
  error: boolean;
}

export const ReceivedItemInput = ({ item, isDisabled, onChange, error }: ReceivedItemInputProps) => {
  const [value, setValue] = useState<string>(`${item.paid_amount}` || '');

  return (
    <TextField
      key={item.id}
      size="small"
      error={error || isNaN(parseFloat(value))}
      placeholder="Received"
      value={value}
      type="text"
      disabled={isDisabled}
      onChange={(event) => {
        setValue(event.target.value);
        const newValue = parseFloat(event.target.value);
        if (isNaN(newValue)) return;
        onChange(newValue);
      }}
      onBlur={() => {
        if (!isNaN(parseFloat(value))) return;
        setValue('0');
        onChange(0);
      }}
      inputProps={{
        pattern: '^-?[0-9.,]*$',
      }}
    />
  );
};
