export function padDate(n) {
  return n < 10 ? '0' + n : n;
}

/**
 * Covert an RFC 2822 date string into ISO8601)
 * @param  {String} RFCDate  RFCDate date string
 * @return {String}          ISO8601 date string (YYYY-MM-DD)
 */
export function convertRFCtoISO(RFCDate) {
  const dateObject = new Date(RFCDate);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth();
  const date = dateObject.getDate();

  return `${year}-${padDate(month + 1)}-${padDate(date)}`;
}
