import { FormControl, FormLabel, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays } from 'date-fns';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface StartAndEndDateFieldProps {
  label: string;
  disabled: boolean;
  value: any[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const StartAndEndDateField = ({ label, disabled, value, onChange, error, name, readOnly }: StartAndEndDateFieldProps) => {
  const tomorrow = addDays(new Date(), 1);

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <FormControl fullWidth size="small" data-cy-field-type="start-and-end-date-picker--start">
        <FormLabel id={`${name}-start-label`}>Start date</FormLabel>
        <DatePicker
          aria-labelledby={`${name}-start-label`}
          value={value[0]}
          maxDate={value[1] || null}
          readOnly={disabled || readOnly}
          slotProps={{
            textField: { size: 'small', error: !!error },
          }}
          onChange={(newValue) => {
            const fieldValues = value.slice();
            fieldValues[0] = newValue;
            onChange(fieldValues);
          }}
        />
      </FormControl>
      <FormControl fullWidth size="small" data-cy-field-type="start-and-end-date-picker--end">
        <FormLabel id={`${name}-end-label`}>End date</FormLabel>
        <DatePicker
          aria-labelledby={`${name}-end-label`}
          value={value[1]}
          minDate={value[0] || tomorrow}
          readOnly={disabled || readOnly}
          slotProps={{
            textField: { size: 'small', error: !!error },
          }}
          onChange={(newValue) => {
            const fieldValues = value.slice();
            fieldValues[1] = newValue;
            onChange(fieldValues);
          }}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default StartAndEndDateField;
