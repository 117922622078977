import { usePermissions } from 'contexts/PermissionContext';

export const HasAccess = ({ children, fallback = <></>, for: permissionFor, name }) => {
  const { hasPermission, hasRole, hasGroup } = usePermissions();

  switch (permissionFor) {
    case 'permission':
      return hasPermission(name) ? children : fallback;
    case 'role':
      return hasRole(name) ? children : fallback;
    case 'group':
      return hasGroup(name) ? children : fallback;
    default:
      return fallback;
  }
};

export default HasAccess;
