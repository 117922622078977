import * as React from 'react';
import { useAppSelector } from 'app/hooks';

const initialState = {
  features: [],
  has: () => {},
};

export const BusinessTogglesContext = React.createContext(initialState);

export const BusinessToggles = ({ children }) => {
  const features = useAppSelector((state) => state.school.features);

  const renderProvider = (value) => {
    return <BusinessTogglesContext.Provider value={value}>{children}</BusinessTogglesContext.Provider>;
  };

  const hasFeature = (context, name) => {
    const feats = context.features;
    const hasNoFeatures = !feats || (feats && !feats.length);

    if (hasNoFeatures) {
      return false;
    }

    if (!(name instanceof Array)) {
      throw new Error(`No features provided. Expected array, but got <${typeof name}>`);
    }

    return name.some((featureName) => feats.some((feature) => feature.name === featureName));
  };

  const context = {
    ...initialState,
    features,
  };

  return renderProvider({
    context,
    has: (name) => {
      return hasFeature(context, name);
    },
  });
};
