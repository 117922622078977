import { Button, ButtonProps, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormBuilderAction } from '../../FormBuilder/types/FormBuilderAction';

const statusIconMap = {
  success: CheckCircleOutlineIcon,
  warning: TimelapseIcon,
  error: ErrorOutlineIcon,
  info: InfoOutlinedIcon,
};

interface LinkActionProps {
  action: FormBuilderAction;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const LinkAction = ({ action, color, variant }: LinkActionProps) => {
  const theme = useTheme();

  const getButtonSx = (active): SxProps<Theme> => {
    if (active) return theme.mixins.activeButton as SxProps<Theme>;
    return {};
  };

  const buttonSx = getButtonSx(action.active);

  let actionIcon = <></>;
  if (action.icon) {
    const Icon = statusIconMap[action.icon];
    actionIcon = <Icon />;
  }

  if (action.tooltip) {
    return (
      <Tooltip
        arrow
        title={<Typography sx={{ p: 1, color: 'white', backgroundColor: theme.palette.grey['600'] }}>{action.tooltip}</Typography>}
        placement="right"
      >
        <Button variant={variant} color={color} component={Link} to={action.destinationUrl!} startIcon={actionIcon} sx={buttonSx} disabled={action.disabled}>
          {action.label}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button variant={variant} color={color} component={Link} to={action.destinationUrl!} startIcon={actionIcon} sx={buttonSx} disabled={action.disabled}>
      {action.label}
    </Button>
  );
};
