import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from '../../utils/user';
import { getConfig } from '../../utils/config';
import { useEffect } from 'react';
import { setSentryUser } from '../../utils/sentry';
import { useAppSelector } from '../../app/hooks';

export const useSentry = () => {
  const { user } = useAuth0<AuthUser>();
  const { environment, sentryDsn } = getConfig();
  const schoolName = useAppSelector((state) => state.school.name);

  useEffect(() => {
    if (environment === 'local' || !user || !sentryDsn) {
      return;
    }

    setSentryUser(user, schoolName);
  }, [environment, schoolName, sentryDsn, user]);
};
