import { TransferOwnershipAgentsTable } from 'profile/transferOwnership/TransferOwnershipAgentsTable';
import { useApplicant } from 'contexts/ApplicantContext';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const TransferOwnershipDialog = ({ onClose }) => {
  const {
    state: { applicant },
    loadApplicant,
  } = useApplicant();

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Transfer ownership
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <p>
          {/* @ts-expect-error TS(2531): Object is possibly 'null'. Here you can */}
          transfer {applicant.first_name} {applicant.last_name}
          &rsquo;s application to an agent&rsquo;s account.
        </p>
        {/* @ts-expect-error TS(2531): Object is possibly 'null'. */}
        {applicant.createdBy && (
          <p>
            Transferring the application will remove it from
            {/* @ts-expect-error TS(2531): Object is possibly 'null'. */}
            {applicant.created_by.first_name} {applicant.created_by.last_name}
            &rsquo;s account.
          </p>
        )}
        <TransferOwnershipAgentsTable
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          applicantId={applicant.id}
          loadApplicant={loadApplicant}
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          applicantOwnerId={applicant.created_by && applicant.created_by.id}
        />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};
