import { Button, Stack, useTheme } from '@mui/material';

interface TabsFieldProps {
  disabled: boolean;
  value: number;
  onChange: (value: number) => void;
  name: string;
  tabQuantity: number;
  tabsLabel: string;
}

export const TabsField = ({ disabled, value, onChange, tabQuantity, tabsLabel }: TabsFieldProps) => {
  const theme = useTheme();

  return (
    <>
      <Stack
        data-cy-field-type="tabs"
        direction="row"
        justifyContent="space-between"
        gap={1}
        p={1}
        sx={{ backgroundColor: (theme) => theme.palette.grey[50], borderRadius: '9px' }}
      >
        {Array.from({ length: tabQuantity }).map((_, index) => {
          const buttonIsActive = value === index + 1;
          return (
            <Button
              key={index}
              variant="outlined"
              color={buttonIsActive ? 'primary' : 'secondary'}
              fullWidth
              disabled={disabled}
              sx={buttonIsActive ? theme.mixins.activeButton : { backgroundColor: theme.palette.primary.contrastText }}
              onClick={() => onChange(index + 1)}
            >
              {tabsLabel} {index + 1}
            </Button>
          );
        })}
      </Stack>
    </>
  );
};

export default TabsField;
