import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Stack } from '@mui/material';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormBuilder } from '../components/FormBuilder/FormBuilder';
import { convertApiFieldsToBuilderFields } from '../components/FormBuilder/utils/convertApiFieldsToBuilderFields';
import { FormBuilderField } from '../components/FormBuilder/types/FormBuilderField';
import { formatStepData } from '../components/Workflow/utils/formatStepData';

const FormText = styled.div`
  padding-left: 0;
  font-size: 16px;
  @media ${(props) => props.theme.md} {
    padding-left: 2.5rem;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media ${(props) => props.theme.md} {
    flex-direction: row;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.label`
  font-weight: bolder;
`;

const Content = styled.div`
  margin-left: 0;
  margin-top: 10px;
  width: 100%;
  @media ${(props) => props.theme.md} {
    margin-left: 5px;
    width: 70%;
  }
`;

const FormContainer = styled.div`
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 11px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const SideMenu = ({ content, onToggle }) => {
  const { slug, application } = useParams() as ApplicantRouteParams;
  const [field, setField] = useState([]);
  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (!content || content.length < 1) return;

    setField(content[0].status === true ? content[0].fields : []);
    setTitle(content[0].label);
    setSelected(content[0].id);
  }, [content]);

  const handleMenuClick = (id) => {
    setSelected(id);
    setField(content.find((item) => item.id === id).fields);
    setTitle(content.find((item) => item.id === id).label);
  };

  const preSubmit = (data) => {
    return {
      editable_fields: formatStepData(data, fieldsMap),
    };
  };

  const fields = convertApiFieldsToBuilderFields(field);
  const fieldsMap = new Map<string, FormBuilderField>();
  fields.map((field) => fieldsMap.set(field.slug, field));

  return (
    <Main>
      <Menu>
        {content.map((item) => {
          return (
            <Button
              key={item.id}
              disabled={!item.status}
              onClick={() => handleMenuClick(item.id)}
              sx={{
                backgroundColor: item.id === selected && item.status ? '#3ead47' : 'white',
                color: item.id === selected && item.status ? 'white' : '#3ead47',
              }}
            >
              {item.label}
            </Button>
          );
        })}
      </Menu>
      <Content>
        <FormText>
          <Title>{title}</Title>
          <FormContainer>
            {field.length > 0 && (
              <FormBuilder
                fieldsMap={fieldsMap}
                submitUrl={`/schools/${slug}/applications/${application}/editable-fields`}
                preSubmit={preSubmit}
                method="PUT"
                formActions={(loading) => (
                  <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 1 }}>
                    <Button onClick={onToggle}>Close</Button>
                    <LoadingButton variant="contained" loading={loading} type="submit">
                      Save
                    </LoadingButton>
                  </Stack>
                )}
              />
            )}
          </FormContainer>
        </FormText>
      </Content>
    </Main>
  );
};

export default SideMenu;
