import { FormControl, FormLabel, Typography, TextField, Stack, Chip } from '@mui/material';
import { useState } from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface ChipsBoxFieldProps {
  label: string;
  disabled: boolean;
  value: string[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const ChipsBoxField = ({ label, disabled, value, onChange, error, name, readOnly }: ChipsBoxFieldProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      onChange([...value, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleItemDelete = (index) => {
    const newValue = value.slice();
    newValue.splice(index, 1);
    onChange(newValue);
  };
  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="chipsbox">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Stack direction="row" width="100%" gap={4}>
          <TextField
            size="small"
            aria-labelledby={`${name}-label`}
            value={inputValue}
            error={!!error}
            disabled={disabled}
            name={name}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyDown={handleKeyDown}
            inputProps={{ readOnly }}
            sx={{ flex: 1 }}
          />
          <Stack direction="row" gap={1} sx={{ flex: 1 }} flexWrap="wrap">
            {value &&
              value.map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  size="small"
                  disabled={disabled}
                  sx={{ '& .MuiChip-deleteIcon': { display: readOnly ? 'none' : 'block' } }}
                  onDelete={() => handleItemDelete(index)}
                />
              ))}
          </Stack>
        </Stack>
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default ChipsBoxField;
