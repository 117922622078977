import { useEffect, useState } from 'react';
import { schoolXeroAPI } from 'app/api/schoolAPI';
import { InvoiceTableManual } from 'invoices/InvoiceTableManual';
import { InvoiceTableXero } from 'invoices/InvoiceTableXero';
import Loader from 'components/Loader';
import { useMountEffect } from 'hooks/useMountEffect';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

interface InvoiceTableProps {
  asStatement?: boolean;
  disabled: boolean;
  section?: string;
  canAddPayments?: boolean;
  onDeletePayment?: () => void;
  id?: string;
  invoice: any;
  onChangeDescription?: (newValue: string, index: number) => void;
  onChangeAmount?: (newValue: string, index: number) => void;
  onChangeQuantity?: (newValue: number, index: number) => void;
  onChangeTaxRate?: (newValue: number, index: number) => void;
  onChangeXeroCode?: (newValue: string, index: number) => void;
  onRemoveRow?: (index: number) => void;
  onChangeRowOrder?: (oldIndex: number, newIndex: number) => void;
  onAddRow?: () => void;
  readOnly?: boolean;
}

export const InvoiceTable = ({
  asStatement = false,
  disabled,
  canAddPayments = false,
  onDeletePayment = () => {},
  invoice,
  onChangeDescription = () => {},
  onChangeAmount = () => {},
  onChangeQuantity = () => {},
  onChangeTaxRate = () => {},
  onChangeXeroCode = () => {},
  onRemoveRow = () => {},
  onChangeRowOrder = () => {},
  onAddRow = () => {},
  readOnly,
}: InvoiceTableProps) => {
  const [loading, setLoading] = useState(true);
  const [accountCodes, setAccountCodes] = useState<{
    [key: string]: {
      code: string;
      name: string;
      tax_rate: string;
    };
  }>({});
  const [showAccountCode, setShowAccountCode] = useState(false);
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  const getXeroConnectionAndAccountCodes = async () => {
    try {
      const schoolXeroApiStatus = await schoolXeroAPI.GET({
        params: {
          slug: schoolSlug,
          query: 'connection',
        },
      });
      const isConnected = schoolXeroApiStatus.status.code === 200 && schoolXeroApiStatus.entity.data.active;

      if (!isConnected) {
        setAccountCodes({});
        setShowAccountCode(false);
        setLoading(false);

        return;
      }
    } catch {
      throw new Error('Could not fetch school Xero status');
    }

    try {
      const schoolXeroApiCodes = await schoolXeroAPI.GET({
        params: {
          slug: schoolSlug,
          query: 'accounts',
        },
      });

      if (schoolXeroApiCodes.status.code === 200) {
        setAccountCodes({
          ...accountCodes,
          ...schoolXeroApiCodes.entity.data.reduce((acc, cur) => ({ ...acc, [cur.code]: cur }), {}),
        });
        setShowAccountCode(true);
      } else {
        setAccountCodes({});
        setShowAccountCode(false);
      }

      setLoading(false);
    } catch {
      throw new Error('Could not fetch school Xero codes');
    }
  };

  useEffect(() => {
    setAccountCodes((acCodes) => {
      return {
        ...invoice.items
          .filter((i) => i.account_code)
          .reduce(
            (acc, cur) => ({
              ...acc,
              [cur.account_code]: {
                name: cur.account_code,
                tax_rate: cur.tax_rate,
              },
            }),
            {},
          ),
        ...acCodes,
      };
    });
  }, [invoice.items]);

  useMountEffect(() => {
    getXeroConnectionAndAccountCodes().catch(() => {
      setAccountCodes({});
      setShowAccountCode(false);
      setLoading(false);
    });
  });

  // do not allow manual changing of values if Xero handles tax etc
  const xeroHandlesAccounts = invoice.xero_id || showAccountCode;

  if (loading) {
    return <Loader />;
  }

  if (xeroHandlesAccounts) {
    return (
      <InvoiceTableXero
        {...{
          asStatement,
          disabled: !!(disabled || readOnly),
          canAddPayments,
          onDeletePayment,
          accountCodes,
          invoice,
          onChangeDescription,
          onChangeAmount,
          onChangeQuantity,
          onChangeTaxRate,
          onChangeXeroCode,
          onRemoveRow,
          onChangeRowOrder,
          onAddRow,
        }}
      />
    );
  }

  return (
    <InvoiceTableManual
      {...{
        asStatement,
        disabled: !!(disabled || readOnly),
        canAddPayments,
        onDeletePayment,
        invoice,
        onChangeDescription,
        onChangeAmount,
        onChangeQuantity,
        onChangeTaxRate,
        onRemoveRow,
        onChangeRowOrder,
        onAddRow,
      }}
    />
  );
};
