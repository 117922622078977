import * as types from 'app/constants/ActionTypes';

export const sharesInitialState = {
  createShareStatus: 'default',
  resendShareStatus: 'default',
  revokeShareStatus: 'default',
  loginStatus: 'default',
};

export const sharesReducer = (state = sharesInitialState, action) => {
  switch (action.type) {
    case types.SHARE_APPLICATION:
      return {
        ...state,
        createShareStatus: 'loading',
      };
    case types.SHARE_APPLICATION_RESPONSE:
      return {
        ...state,
        createShareStatus: action.payload.success ? 'success' : 'error',
      };
    case types.RESEND_SHARE:
      return {
        ...state,
        resendShareStatus: 'loading',
      };
    case types.RESEND_SHARE_RESPONSE:
      return {
        ...state,
        resendShareStatus: 'default',
      };
    case types.REVOKE_SHARE:
      return {
        ...state,
        revokeShareStatus: 'loading',
      };
    case types.REVOKE_SHARE_RESPONSE:
      return {
        ...state,
        revokeShareStatus: 'default',
        createShareStatus: 'default',
      };
    case types.RESET_SHARE_STATE:
      return {
        ...state,
        createShareStatus: 'default',
      };
    default:
      return {
        ...state,
      };
  }
};
