import React from 'react';
import { useAppSelector } from 'app/hooks';
import styled from 'styled-components';
import classnames from 'classnames';
import { Reminder } from 'enquiries/reminders/Reminder';
import HasAccess from 'permissions/HasAccess';
import { reminders as remindersSelector } from 'enquiries/enquiriesSelector';
import { PERMISSION_VIEW_LEAD } from 'permissions/constants/Roles';
import 'enquiries/reminders/reminders.css';
import { Button, List } from '@mui/material';

const ReminderList = styled.section`
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 3rem;
  ${(props: any) => (props.mobile ? 'margin-top: 3.2rem' : '')};
`;

export const Reminders = ({ withTitle = false, active = false, alt = false }) => {
  const reminders = useAppSelector((state) => remindersSelector(state, {}));
  const slug = useAppSelector((state) => state.school.slug);

  const renderTitle = () => {
    if (withTitle) {
      return <h3 className="type--intro type--flat">Reminders</h3>;
    }

    return <></>;
  };

  const renderReminders = () => {
    if (!reminders.length) {
      return <></>;
    }

    return reminders.map((reminder, index) => {
      return (
        <Reminder
          key={`reminder-${index}`}
          name={reminder.name}
          role={reminder.role}
          due={reminder.due}
          status={reminder.status}
          statusColour={reminder.statusColour}
          delay={index * 80}
          link={`/${slug}/enquiry/${reminder.id}`}
        />
      );
    });
  };

  const reminderListClasses = classnames({
    'reminderlist ': true,
    'reminderlist--active': active,
    'reminderlist--alt': alt,
  });

  return (
    <ReminderList className={reminderListClasses}>
      <ListHeader>
        {renderTitle()}
        <HasAccess for="permission" name={[PERMISSION_VIEW_LEAD]}>
          <Button href="../enquiries" sx={{ textTransform: 'uppercase', fontSize: '1.5rem', lineHeight: '1.7rem' }}>
            See all enquiries
          </Button>
        </HasAccess>
      </ListHeader>
      <List>{renderReminders()}</List>
    </ReminderList>
  );
};
