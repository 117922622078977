/**
 * Make a link to a step with school slug,
 * application id, stage slug & step slug
 * @param slug
 * @param applicant
 * @param application
 * @param stage
 * @param step
 * @returns {string}
 */
const makeLinkToStep = (slug, applicant, application, stage, step) => {
  const url = [slug, 'enrolment-application', 'applicant', applicant, 'application', application, 'stages', stage, 'steps', step].join('/');

  return `/${url}`;
};

export default makeLinkToStep;
