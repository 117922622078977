import { ApiFormBuilderCustomQuestionAnswers } from '../types/api/ApiCustomQuestionAnswers';
import { FormBuilderField } from '../../FormBuilder/types/FormBuilderField';

export const getCustomQuestionAnswers = (
  formData: Record<string, string | Date | null | any>,
  fieldsMap: Map<string, FormBuilderField>,
  questions: Record<string, string>,
) => {
  let customQuestionAnswers: ApiFormBuilderCustomQuestionAnswers[] = [];

  Object.entries(formData).forEach(([fieldKey, fieldData]) => {
    const field = fieldsMap.get(fieldKey)!;

    // Condition to skip form fields within a customfieldsplaceholder field which
    // have field values but don't have a field entry in the DB.
    if (!field) {
      const questionName = questions[fieldKey];
      if (questionName) {
        customQuestionAnswers.push({
          question: questionName,
          answer: fieldData,
        });
      }
    }
  });

  return customQuestionAnswers;
};
