import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getConfig } from '../utils/config';

interface PageTitleContextFunctions {
  setPageTitle: (title: string) => void;
  getPageTitle: () => string;
}

const PageTitleContext = createContext<PageTitleContextFunctions | undefined>(undefined);

export const PageTitleProvider = ({ children }: PropsWithChildren) => {
  const { pageTitle: titleByline } = getConfig();
  const location = useLocation();
  const [title, setTitle] = useState(titleByline);

  const getPageTitle = useCallback(() => title, [title]);
  const setPageTitle = useCallback(
    (pageTitle: string) => {
      setTitle(() => `${pageTitle} | ${titleByline}`);
    },
    [titleByline],
  );

  // default to byline on navigation. Individual components should call `setPageTitle` once they have loaded
  useEffect(() => {
    setTitle(titleByline);
  }, [titleByline, location.pathname]);

  useEffect(() => {
    document.getElementsByTagName('title')[0].innerHTML = title;
  }, [title]);

  return <PageTitleContext.Provider value={{ setPageTitle, getPageTitle }}>{children}</PageTitleContext.Provider>;
};

export const usePageTitle = () => {
  const context = useContext(PageTitleContext);

  if (context === undefined) {
    throw new Error('usePageTitle must be used within a PageTitleProvider');
  }

  return context;
};
