import React from 'react';
import { GROUP_TYPE_INSTITUTION } from 'permissions/constants/Roles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { slugify } from 'utils/slugify';

import { useForm, Controller } from 'react-hook-form';
import { usePermissions } from 'contexts/PermissionContext';

type WithdrawApplicantFormValues = {
  reason: string;
  sendEmailNotifications: boolean;
  other?: string;
};

interface WithdrawApplicantDialogProps {
  isSubmitting: boolean;
  onToggle: () => void;
  applicationUserType?: string;
  onWithdraw: (data: { reason: string; skip_email_notifications: boolean }) => void;
  active: boolean;
  name: string;
}

export const WithdrawApplicantDialog = ({ isSubmitting, onToggle, applicationUserType, onWithdraw, active, name }: WithdrawApplicantDialogProps) => {
  const { hasGroup } = usePermissions();
  // Check user type and permissions to handle different form options
  const userIsSchoolStaff = hasGroup([GROUP_TYPE_INSTITUTION]);
  const userType = applicationUserType ? applicationUserType.toLowerCase() : 'applicant/agent';

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<WithdrawApplicantFormValues>({
    defaultValues: { reason: '', sendEmailNotifications: true, other: '' },
  });

  const reasonWatch = watch('reason');
  const sendEmailNotificationsWatch = watch('sendEmailNotifications');

  const onSubmit = (data: WithdrawApplicantFormValues) => {
    let reason = data.reason;

    if (data.reason === 'Other' && data.other) {
      reason = data.other;
    }

    onWithdraw({
      reason,
      skip_email_notifications: !data.sendEmailNotifications,
    });
  };

  const getRadioOptions = () => {
    if (userIsSchoolStaff) {
      return [
        'Ineligible',
        'English level',
        'Behavioural reasons',
        'Studying elsewhere',
        'No response from student/agent',
        'Visa declined',
        'Financial reasons',
        'Other',
      ];
    } else {
      return [
        'The student has chosen another school',
        'I have not heard anything back from the school',
        "The student's visa has been declined",
        'The student has changed their mind about the school',
        'Other',
      ];
    }
  };

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            {`Withdraw ${name || 'student'}`}
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" justifyContent="space-between" sx={{ width: '100%', mb: 5 }}>
            <Typography variant="h2" sx={{ fontSize: 18, mt: 3, mb: 1 }}>
              Select the reason for withdrawing the applicant:
            </Typography>

            <Controller
              name="reason"
              control={control}
              rules={{ required: 'Reason is required' }}
              render={({ field: { value, onChange } }) => (
                <FormControl error={!!errors.reason}>
                  <FormLabel sx={{ mb: 1 }}>Reason</FormLabel>
                  <RadioGroup value={value} onChange={onChange}>
                    {getRadioOptions().map((option) => {
                      const valueSlug = slugify(option);

                      return <FormControlLabel key={`withdrawReason-radio-${valueSlug}`} value={option} control={<Radio />} label={option} />;
                    })}
                  </RadioGroup>
                  {errors.reason && <Typography color="error">{errors.reason.message}</Typography>}
                </FormControl>
              )}
            />

            {reasonWatch === 'Other' && (
              <Controller
                name="other"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <FormControl error={!!errors.other}>
                    <FormLabel sx={{ mb: 1 }}>Please specify why</FormLabel>
                    <TextField multiline rows={3} value={value} onChange={onChange} error={!!errors.other} />
                    {errors.other && <Typography color="error">{errors.other.message}</Typography>}
                  </FormControl>
                )}
              />
            )}

            <Typography variant="h2" sx={{ fontSize: 18, mt: 3, mb: 1 }}>
              {`Send an email to the ${userIsSchoolStaff ? userType : 'school'} notifying them of the withdrawal:`}
            </Typography>
            <Controller
              name="sendEmailNotifications"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl sx={{ mb: 5 }}>
                  <FormControlLabel label="Send notification email" control={<Checkbox checked={value} onChange={onChange} />} />
                </FormControl>
              )}
            />

            <DialogActions>
              <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                Withdraw {sendEmailNotificationsWatch ? 'and send notification email' : 'and do not send notification email'}
              </LoadingButton>
              <Button variant="contained" onClick={onToggle}>
                Cancel
              </Button>
            </DialogActions>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
