import { useState, MouseEventHandler } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { ProfileAvatar } from 'components/Profile/ProfileAvatar';
import { AuthUser } from 'utils/user';
import { useAppSelector } from 'app/hooks';
import HasAccess from 'permissions/HasAccess';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { ProductsAccess } from 'permissions/ProductsAccess';
import { ENROLLER_RECRUIT } from 'permissions/constants/ProductLevels';
import {
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_AGENT,
  ROLE_TYPE_PARENT,
  ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
  ROLE_TYPE_INSTITUTION_STAFF,
  ROLE_TYPE_INSTITUTION_ADMIN,
} from 'permissions/constants/Roles';
import { useNavigate } from 'react-router-dom';

export interface ProfileMenuProps {
  hideLogin?: boolean;
  slug: string;
}

export const ProfileMenu = ({ hideLogin = false, slug }: ProfileMenuProps) => {
  const { user, isAuthenticated, logout } = useAuth0<AuthUser>();

  const schoolSlug = useAppSelector((state) => state.school.slug);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const navigate = useNavigate();

  const onProfileAvatarClick: MouseEventHandler<HTMLButtonElement> = (event) => setAnchorEl(event.currentTarget);
  const onClose: MouseEventHandler<HTMLElement> = () => setAnchorEl(null);
  const onLogoutClick: MouseEventHandler<HTMLLIElement> = (event) => {
    onClose(event);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // TODO: Work out how to do this with Links instead of relying on render order
  // Currently this sporadically renders the menu popover in a stuck state after navigation otherwise
  const closeAndNavigate = (to: string) => (event) => {
    onClose(event);
    setTimeout(() => navigate(to), 0);
  };

  if (hideLogin) {
    return <></>;
  }
  if (!isAuthenticated) {
    return <Link href={`/${slug}/login`}>Login</Link>;
  }

  return (
    <>
      <Tooltip title="Profile Menu">
        <IconButton onClick={onProfileAvatarClick}>
          <ProfileAvatar />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={onClose}
        sx={{ p: 2 }}
        slotProps={{ paper: { sx: { width: '268px' } } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <HasAccess
          // Hide for guest users
          for="role"
          name={[
            ROLE_TYPE_APPLICANT,
            ROLE_TYPE_AGENT,
            ROLE_TYPE_PARENT,
            ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
            ROLE_TYPE_INSTITUTION_STAFF,
            ROLE_TYPE_INSTITUTION_ADMIN,
          ]}
        >
          {/* TODO: Refactor to Link */}
          {/* <MenuItem {...{ component: Link }} href="/profile" onClick={onClose}> */}
          <MenuItem onClick={closeAndNavigate('/profile')}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <ProfileAvatar />
              {user !== undefined && (
                <Stack>
                  <Typography variant="subtitle1" fontSize="16px" color="black" fontWeight="700">
                    {user.userData.firstName} {user?.userData.lastName}
                  </Typography>
                  <Typography variant="subtitle2" fontSize="14px" color="#414141">
                    {user.email}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </MenuItem>
          <Divider component="li" />
        </HasAccess>

        {schoolSlug && (
          <HasAccess for="role" name={[ROLE_TYPE_INSTITUTION_ADMIN]}>
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              {/* TODO: Refactor to Link */}
              {/* <MenuItem href={`/${schoolSlug}/settings/permissions`} {...{ component: Link }} onClick={onClose}> */}
              <MenuItem onClick={closeAndNavigate(`/${schoolSlug}/settings/permissions`)}>
                <ListItemIcon>
                  <PeopleOutlinedIcon />
                </ListItemIcon>
                <ListItemText>User Settings</ListItemText>
              </MenuItem>
            </ProductsAccess>
            {/* TODO: Refactor to Link */}
            {/* <MenuItem href={`/${schoolSlug}/settings/school`} {...{ component: Link }} onClick={onClose}> */}
            <MenuItem onClick={closeAndNavigate(`/${schoolSlug}/settings/school`)}>
              <ListItemIcon>
                <SchoolOutlinedIcon />
              </ListItemIcon>
              <ListItemText>School Settings</ListItemText>
            </MenuItem>
            {/* TODO: Refactor to Link */}
            {/* <MenuItem href={`/${schoolSlug}/settings/application`} {...{ component: Link }} onClick={onClose}> */}
            <MenuItem onClick={closeAndNavigate(`/${schoolSlug}/settings/application`)}>
              <ListItemIcon>
                <ArticleOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Application Settings</ListItemText>
            </MenuItem>
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              {/* TODO: Refactor to Link */}
              {/* <MenuItem href={`/${schoolSlug}/settings/financial`} {...{ component: Link }} onClick={onClose}> */}
              <MenuItem onClick={closeAndNavigate(`/${schoolSlug}/settings/financial`)}>
                <ListItemIcon>
                  <LocalAtmOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Financial Settings</ListItemText>
              </MenuItem>
            </ProductsAccess>
            <Divider component="li" />
          </HasAccess>
        )}
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
