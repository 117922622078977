import { useState } from 'react';
import { useWorkflow } from 'contexts/WorkflowContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import Loader from '../Loader';

const ExportDropDown = ({ currentlyExportingId, isExporting, onExport, disabled, color, variant }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    state: { workflow },
  } = useWorkflow();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  if (!workflow) return <Loader />;

  return (
    <>
      <Button endIcon={<ArrowDropDownIcon />} disabled={disabled} variant={variant} color={color} onClick={handleClick}>
        {(currentlyExportingId && `Exporting ${workflow.export_formats[currentlyExportingId]}...`) || 'Export'}
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.keys(workflow.export_formats).map((id) => {
          const name = workflow.export_formats[id];

          return (
            <MenuItem
              key={id}
              value={name}
              disabled={isExporting}
              onClick={() => {
                onExport(id);
              }}
            >
              Download {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ExportDropDown;
