import Lang from 'lang.js';
import { store } from 'app/store';
import { Provider } from 'react-redux';
import styled, { ThemeProvider as LegacyThemeProvider } from 'styled-components';
import { BusinessToggles } from 'businessToggles/BusinessToggles';
import legacyTheme from 'config/theme';
import { AppRouter } from 'app/AppRouter';
import LangContext from 'app/LangContext';
import translations from 'lang/lang.json';
import { setLocale } from 'app/actions/userActions';
import { ThemeProvider } from '@mui/material';
import { theme } from 'customTheme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { enNZ } from 'date-fns/locale';

const { user } = store.getState();
const { locale, localeLabel, localeImg } = user;
export const lang = new Lang({});
// @ts-expect-error TS(2345): Argument of type '{ "en.Study": { "location ": str... Remove this comment to see the full error message
lang.setMessages(translations);
store.dispatch(setLocale(locale, localeLabel, localeImg, lang));

const Snackbar = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontSize: '2rem',
    lineHeight: '3rem',
  },
}));

export const Enroller = () => {
  const content = (translationKey: string, params = {}, fallback = '') => {
    return lang.has(translationKey) ? lang.get(translationKey, params) : fallback;
  };

  return (
    <SnackbarProvider maxSnack={3} Components={{ success: Snackbar, error: Snackbar }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LegacyThemeProvider theme={legacyTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enNZ}>
              <LangContext.Provider value={{ lang, content }}>
                <BusinessToggles>
                  <AppRouter />
                </BusinessToggles>
              </LangContext.Provider>
            </LocalizationProvider>
          </LegacyThemeProvider>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
};
