import { Branding } from '../branding.interface';
import goLogo from './go-logo.svg';

const primaryMain = '#577b27';

export const greenheartBranding: Branding = {
  appHeaderLogoSrc: goLogo,
  appHeaderLogoSx: { width: '64.5px', height: '50px' },
  showAppHeaderSchoolSelector: false,
  footer: <></>,
  fontFamily: 'Lato, sans-serif',
  paletteOptions: {
    primary: {
      main: primaryMain,
      light: '#74a535',
      dark: '#486620',
      contrastText: '#ffffff',
    },
    titleGradientColor1: primaryMain,
    titleGradientColor2: primaryMain,
  },
};
