import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, Stack, SxProps, TextField, Typography } from '@mui/material';
import { MRT_RowData, MRT_TableInstance, MaterialReactTable } from 'material-react-table';
import { ReactNode } from 'react';

interface DataTableProps {
  tableHeading?: string;
  tableActions?: ReactNode;
  sx?: SxProps;
  table: MRT_TableInstance<MRT_RowData>;
  globalFilter: string;
  setGlobalFilter: (newValue: string) => void;
  entityName: { singular: string; plural: string };
}

export const DataTable = ({ tableHeading, tableActions, sx, table, globalFilter, setGlobalFilter, entityName }: DataTableProps) => {
  const totalRows = table.getRowModel().rows.length;
  const totalRowsLabel = `${totalRows} ${totalRows === 1 ? entityName.singular : entityName.plural}`;

  return (
    <Stack sx={sx ?? {}}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        pb={3}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[100]}` }}
      >
        <Stack direction="column" justifyContent="space-between" alignItems="left" gap={3}>
          {!!tableHeading && (
            <Typography display="inline" variant="h4" sx={{ color: (theme) => theme.palette.primary.main }}>
              {tableHeading}
            </Typography>
          )}
          <Stack gap={1} direction="row" alignItems="center">
            <TextField
              sx={{ minWidth: '30%' }}
              size="small"
              placeholder={`Search ${entityName.plural}`}
              variant="outlined"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton sx={{ visibility: globalFilter ? 'visible' : 'hidden' }} onClick={() => setGlobalFilter('')}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <Typography fontSize="12px" color={(theme) => theme.palette.grey[600]}>
              {totalRowsLabel}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          {/* TODO: Add filters here */}
          {tableActions}
        </Stack>
      </Stack>
      <MaterialReactTable table={table} />
    </Stack>
  );
};
