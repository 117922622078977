import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'app/PrivateRoute';
import { StartApplication } from 'views/Applicants/StartApplication';
import { Workflow } from 'components/Workflow/Workflow';
import { StartScreen } from 'workflow/views/StartScreen';
import React from 'react';
import { ApplicantProvider } from 'contexts/ApplicantContext';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';

export interface SchoolWorkflowRouteParams extends SchoolRouteParams {
  applicant: string;
}

export const SchoolWorkflowRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<StartScreen />} />
      <Route
        path="applicant"
        element={
          <PrivateRoute>
            <ApplicantProvider>
              <StartApplication />
            </ApplicantProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="applicant/:applicant/*"
        element={
          <PrivateRoute>
            <ApplicantProvider>
              <Workflow />
            </ApplicantProvider>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
