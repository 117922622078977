import { Branding } from '../branding.interface';
import defaultLogo from 'images/icons/favicon-180.png';
import { EnrollerFooter } from './EnrollerFooter';

const primaryMain = '#3EAD47';

export const enrollerBranding: Branding = {
  appHeaderLogoSrc: defaultLogo,
  appHeaderLogoSx: { width: '32px', height: '32px' },
  showAppHeaderSchoolSelector: true,
  footer: <EnrollerFooter />,
  fontFamily: 'Inter, sans-serif',
  paletteOptions: {
    primary: {
      main: primaryMain,
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    titleGradientColor1: primaryMain,
    titleGradientColor2: '#335f33',
  },
};
