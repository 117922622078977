import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DocumentsTable from './DocumentsTable';
import { UploadDocumentsDialog } from './uploadDocuments/UploadDocumentsDialog';
import HasAccess from '../permissions/HasAccess';
import { PERMISSION_UPLOAD_DOCUMENTS } from '../permissions/constants/Roles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getErrorMessage } from '../utils/errors';
import { useStatelessGet } from '../hooks/useStatelessGet';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import { useNotificationMessages } from '../hooks/useNotificationMessages';
import { useParams } from 'react-router-dom';
import { ApplicantRouteParams } from '../components/Workflow/Workflow';

export const ApplicationDocuments = ({ onToggle }) => {
  const { slug: schoolSlug, application: applicationId } = useParams() as ApplicantRouteParams;
  const [documents, setDocuments] = useState<any[]>([]);
  const [toggleUploadDialog, setToggleUploadDialog] = useState(false);
  const sourceUrl = `/schools/${schoolSlug}/applications/${applicationId}/files`;
  const sourceParams = useMemo(() => new URLSearchParams({ all: '1' }), []);
  const getDocuments = useStatelessGet<ResponseEnvelope<any[]>>(sourceUrl);
  const { showErrorMessage } = useNotificationMessages();

  const handleToggleUploadDialog = () => {
    setToggleUploadDialog(!toggleUploadDialog);
  };

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await getDocuments(sourceParams);
      setDocuments(response.data);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getDocuments, showErrorMessage, sourceParams]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Application documents
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DocumentsTable files={documents} onUpdate={fetchDocuments} />
        <UploadDocumentsDialog
          active={toggleUploadDialog}
          onToggle={handleToggleUploadDialog}
          onSuccess={fetchDocuments}
          postUrl={sourceUrl}
          fieldName="application_file"
        />
      </DialogContent>
      <DialogActions>
        <HasAccess for="permission" name={[PERMISSION_UPLOAD_DOCUMENTS]}>
          <Button onClick={handleToggleUploadDialog}>Upload documents</Button>
        </HasAccess>
        <Button variant="contained" onClick={onToggle}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};
