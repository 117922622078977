import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer } from 'react';
import { Applicant } from 'types/Applicant';
import { AsyncStatus } from 'types/AsyncStatus';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { useStatelessPut } from 'hooks/useStatelessPut';
import { useParams } from 'react-router-dom';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';

type ApplicantAction =
  | { type: 'start applicant update'; updates: Partial<Applicant> }
  | { type: 'finish applicant update'; applicant: Applicant }
  | { type: 'fail applicant update'; error: unknown }
  | { type: 'start applicant read' }
  | { type: 'finish applicant read'; applicant: Applicant }
  | { type: 'fail applicant read'; error: unknown };
type ApplicantState = {
  status: AsyncStatus;
  applicant: Applicant | null;
};
interface ApplicantContextData {
  state: ApplicantState;
  updateApplicant: (data: Applicant, notify?: boolean) => Promise<void>;
  loadApplicant: () => Promise<void>;
}

export const ApplicantContext = createContext<ApplicantContextData | undefined>(undefined);

const applicantReducer = (state: ApplicantState, action: ApplicantAction): ApplicantState => {
  switch (action.type) {
    case 'start applicant read':
    case 'start applicant update':
      return { ...state, status: 'pending' };
    case 'finish applicant read':
    case 'finish applicant update':
      return {
        status: 'success',
        applicant: action.applicant,
      };
    case 'fail applicant read':
    case 'fail applicant update':
      console.error(action.error);
      return {
        status: 'error',
        applicant: null,
      };
  }
};

interface ApplicantProviderProps {
  id?: string;
}

export const ApplicantProvider = ({ id, children }: PropsWithChildren<ApplicantProviderProps>) => {
  const { showSuccessMessage, showErrorMessage } = useNotificationMessages();
  const { applicant: paramApplicantId, slug: schoolSlug } = useParams() as SchoolWorkflowRouteParams;
  const [state, dispatch] = useReducer(applicantReducer, {
    status: 'idle',
    applicant: null,
  });
  // this is because Applicant and School User routes behave differently
  const applicantId = paramApplicantId || id;
  const fetchApplicant = useStatelessGet<ResponseEnvelope<Applicant>>(`/schools/${schoolSlug}/applicants/${applicantId}`);
  const modifyApplicant = useStatelessPut<ResponseEnvelope<Applicant>>(`/schools/${schoolSlug}/applicants/${applicantId}`);

  const loadApplicant = useCallback(async () => {
    dispatch({ type: 'start applicant read' });

    try {
      const response = await fetchApplicant();
      dispatch({
        type: 'finish applicant read',
        applicant: response.data,
      });
    } catch (error) {
      dispatch({ type: 'fail applicant read', error });
    }
  }, [fetchApplicant]);

  const updateApplicant = useCallback(
    async (data: Applicant, notify = false) => {
      dispatch({ type: 'start applicant update', updates: data });

      try {
        const response = await modifyApplicant(data);
        dispatch({
          type: 'finish applicant update',
          applicant: response.data,
        });
        if (notify) showSuccessMessage('Applicant updated successfully');
      } catch (error) {
        dispatch({ type: 'fail applicant update', error });
        if (notify) showErrorMessage(`Applicant failed to update: ${error}`);
      }
    },
    [modifyApplicant, showErrorMessage, showSuccessMessage],
  );

  useEffect(() => {
    // only try load an applicant when have an id
    if (applicantId && state.status === 'idle') {
      loadApplicant();
    }
  }, [applicantId, loadApplicant, state.status]);

  return <ApplicantContext.Provider value={{ state, updateApplicant, loadApplicant }}>{children}</ApplicantContext.Provider>;
};

export const useApplicant = () => {
  const context = useContext(ApplicantContext);

  if (context === undefined) {
    throw new Error('useApplicant must be used within a ApplicantProvider');
  }

  return context;
};
