import { createSelector } from 'reselect';
import { asc, desc } from 'utils/sort';

const agentsSelector = (state) => state.agents.agents;
const sortField = (state) => state.agents.agentsSortField;
const sortOrder = (state) => state.agents.agentsSortOrder;

/**
 * Sorted agents
 * @param  {Function} agentsSelector Function that returns all agents
 * @param  {String}   sortField     Field to sort by
 * @param  {String}   sortOrder     Sort order: asc|desc
 * @return {Array}                  Sorted array of Leads
 */
export const sortedAgents = createSelector(agentsSelector, sortField, sortOrder, (agents, sortField, sortOrder) => {
  if (agents && agents.length) {
    const sortFunc = sortOrder === 'asc' ? asc(sortField) : desc(sortField);

    return agents.sort(sortFunc);
  }

  return [];
});
