import * as types from 'app/constants/ActionTypes';

export const userInitialState = {
  locale: 'en',
  localeLabel: 'EN',
  localeImg: 'gb',
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  status: 'default',
  error: null,
  registerStatus: '',
  registerMessage: '',
  schools: [],
  sharedApplicantId: null,
  sharedApplicationId: null,
  roles: [],
  additional_details: {},
  primary_market_country_id: null,
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case types.USER_SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
        localeLabel: action.label,
        localeImg: action.img,
      };
    case types.ERROR_SHOW:
      return {
        ...state,
        status: 'error',
        registerStatus: 'error',
      };
    case types.ERROR_HIDE:
      return {
        ...state,
        status: 'default',
        registerStatus: 'default',
      };
    case types.USER_REGISTER:
      return {
        ...state,
        email: action.email,
        registerStatus: 'loading',
      };
    case types.USER_REGISTER_IDLE:
      return {
        ...state,
        registerStatus: 'idle',
      };
    case types.USER_REGISTER_RESPONSE: {
      const registerState = { ...state };

      if (action.payload.status === 'success') {
        registerState.registerStatus = 'success';
        registerState.registerMessage = 'Success';
      } else {
        registerState.registerStatus = 'error';
        registerState.registerMessage = 'Error';
      }

      return {
        ...registerState,
      };
    }
    case types.AUTO_LOGOUT:
      return {
        ...state,
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        account_type: 'Public',
        registerStatus: '',
        registerMessage: '',
        schools: [],
        sharedApplicantId: null,
        sharedApplicationId: null,
        roles: [],
        contracted_with_enroller: false,
        contracted_with_educatius: false,
      };
    default:
      return {
        ...state,
      };
  }
};
