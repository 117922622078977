import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface MonthAndYearFieldProps {
  label: string;
  disabled: boolean;
  value: any[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const MonthAndYearField = ({ label, disabled, value, onChange, error, name, readOnly }: MonthAndYearFieldProps) => {
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <FormControl fullWidth size="small" data-cy-field-type="month-and-year--year">
        <FormLabel id={`${name}-years-label`}>Years</FormLabel>
        <TextField
          size="small"
          type="number"
          aria-labelledby={`${name}-years-label`}
          value={value[0]}
          error={!!error}
          disabled={disabled}
          onChange={(event) => {
            const fieldValues = value.slice();
            fieldValues[0] = event.target.value;
            onChange(fieldValues);
          }}
          inputProps={{ readOnly }}
        />
      </FormControl>
      <FormControl fullWidth size="small" data-cy-field-type="month-and-year--month">
        <FormLabel id={`${name}-months-label`}>Months</FormLabel>
        <TextField
          size="small"
          type="number"
          aria-labelledby={`${name}-months-label`}
          value={value[1]}
          error={!!error}
          disabled={disabled}
          onChange={(event) => {
            const fieldValues = value.slice();
            fieldValues[1] = event.target.value;
            onChange(fieldValues);
          }}
          inputProps={{ readOnly }}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default MonthAndYearField;
