import { FormBuilder } from 'components/FormBuilder/FormBuilder';
import { useGet } from 'hooks/useGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import Loader from 'components/Loader';
import { Typography } from '@mui/material';
import { getErrorMessage } from 'utils/errors';
import { FormBuilderField } from '../FormBuilder/types/FormBuilderField';
import { useApplication } from '../../contexts/ApplicationContext';
import { formatStepData } from './utils/formatStepData';
import { getCustomQuestionAnswers } from './utils/getCustomQuestionAnswers';
import { useNavigate } from 'react-router-dom';
import NewStepActions from './NewStepActions';
import { convertApiFieldsToBuilderFields } from '../FormBuilder/utils/convertApiFieldsToBuilderFields';
import { ApiFormBuilderField } from '../FormBuilder/types/api/ApiFormField';
import { convertApiActionsToBuilderActions } from 'components/FormBuilder/utils/convertApiActionsToBuilderActions';
import { FormBuilderAction } from '../FormBuilder/types/FormBuilderAction';
import { ApiFormBuilderAction } from '../FormBuilder/types/api/ApiFormBuilderAction';
import Content from 'components/Translation/Content';

export interface ApiFormBuilderStep {
  fields: ApiFormBuilderField[];
  actions: ApiFormBuilderAction[];
  locked: boolean;
}

export interface ApiFormBuilderValidationRule {
  type: 'required' | 'min' | 'max' | 'email' | 'equal' | 'regex' | 'changed';
  occurrence: 'always';
  value?: number;
  message?: string;
  occurrenceValue?: string;
  occurrenceCondition?: string;
  occurrenceReference?: string;
}

export const WorkflowFormBuilder = ({ fieldsUrl, previousStepUrl, showPostSubmissionMessage, workflowEndpoint, heading, headingTranslationKey }) => {
  const [loading, getFields] = useGet<ResponseEnvelope<ApiFormBuilderStep>>(fieldsUrl);
  const [fields, setFields] = useState<FormBuilderField[]>([]);
  const [readOnly, setReadOnly] = useState(false);
  const [actions, setActions] = useState<FormBuilderAction[]>([]);
  const [error, setError] = useState(false);
  const [actionId, setActionId] = useState<string | null>(null);
  const { showErrorMessage } = useNotificationMessages();
  const navigate = useNavigate();
  const {
    state: { application },
    loadApplication,
  } = useApplication();

  const submitUrl = actionId ? `${fieldsUrl}/actions/${actionId}` : fieldsUrl;
  const questions = application?.custom_questions || {};
  const fieldsMap = useMemo(() => {
    const map = new Map<string, FormBuilderField>();
    fields.forEach((field) => map.set(field.slug, field));
    return map;
  }, [fields]);

  const fetchFields = useCallback(async () => {
    try {
      const response = await getFields();
      setFields(convertApiFieldsToBuilderFields(response.data.fields));
      setActions(convertApiActionsToBuilderActions(response.data.actions));
      setReadOnly(response.data.locked);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
      setError(true);
    }
  }, [getFields, showErrorMessage]);

  const preSubmit = (data) => {
    return {
      step_data: formatStepData(data, fieldsMap),
      custom_question_answers: getCustomQuestionAnswers(data, fieldsMap, questions),
    };
  };

  const postSubmit = ({ meta }) => {
    loadApplication();

    if (meta?.advance) {
      const nextStageSlug = meta.next_step.stage.slug;
      const nextStepSlug = meta.next_step.slug;

      navigate(`../stages/${nextStageSlug}/steps/${nextStepSlug}`);
    } else {
      showPostSubmissionMessage(meta?.post_submission_message);
    }
  };

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  if (loading || !fields.length) return <Loader center />;

  if (error) return <Typography color="error">Could not load form</Typography>;

  return (
    <FormBuilder
      {...{
        fieldsMap,
        submitUrl,
        preSubmit,
        postSubmit,
        readOnly,
        formHeading: () => (
          <Typography variant="h6" component="h2">
            <Content name={headingTranslationKey}>{heading || ''}</Content>
          </Typography>
        ),
        formActions: (loading, submit, getValues) => (
          <NewStepActions
            actions={actions}
            previousStepUrl={previousStepUrl}
            isFormLoading={loading}
            submitWithoutValidation={() => {
              const formValues = getValues();
              submit(formValues);
            }}
            setActionId={setActionId}
            workflowEndpoint={workflowEndpoint}
          />
        ),
      }}
    />
  );
};
