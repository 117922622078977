import { PropsWithChildren } from 'react';
import { ApplicationContext } from './ApplicationContext';
import { ApplicantContext } from './ApplicantContext';

export const FakeApplicationProvider = ({ children }: PropsWithChildren) => (
  <ApplicationContext.Provider
    value={{
      state: { status: 'idle', application: null },
      updateApplication: () => Promise.resolve(),
      loadApplication: () => Promise.resolve(),
    }}
  >
    {children}
  </ApplicationContext.Provider>
);

// TODO: this exists because the `FormConsumer` assumes workflow access but is used for non-workflow forms
// TODO: convert all non-workflow forms to standard react-hook-form forms
export const FakeApplicantAndApplicationProvider = ({ children }: PropsWithChildren) => {
  return (
    <FakeApplicationProvider>
      <ApplicantContext.Provider
        value={{
          state: { status: 'idle', applicant: null },
          updateApplicant: () => Promise.resolve(),
          loadApplicant: () => Promise.resolve(),
        }}
      >
        {children}
      </ApplicantContext.Provider>
    </FakeApplicationProvider>
  );
};
