import Lang from 'lang.js';
import { sharedAppStore } from 'app/store';
import { Provider } from 'react-redux';
import { ThemeProvider as LegacyThemeProvider } from 'styled-components';
import { BusinessToggles } from 'businessToggles/BusinessToggles';
import LangContext from 'app/LangContext';
import legacyTheme from 'config/theme';
import { SharedAppRouter } from 'app/SharedAppRouter';
import translations from 'lang/lang.json';
import { setLocale } from 'app/actions/userActions';
import { ThemeProvider } from '@mui/material';
import { theme } from 'customTheme';

const { user } = sharedAppStore.getState();
const { locale, localeLabel, localeImg } = user;
const lang = new Lang({});
// @ts-expect-error TS(2345): Argument of type '{ "en.Study": { "location ": str... Remove this comment to see the full error message
lang.setMessages(translations);
sharedAppStore.dispatch(setLocale(locale, localeLabel, localeImg, lang));

export const SharedEnroller = () => {
  const content = (translationKey: string, params = {}, fallback = '') => {
    return lang.has(translationKey) ? lang.get(translationKey, params) : fallback;
  };

  return (
    <Provider store={sharedAppStore}>
      <ThemeProvider theme={theme}>
        <LegacyThemeProvider theme={legacyTheme}>
          <LangContext.Provider value={{ lang, content }}>
            <BusinessToggles>
              <SharedAppRouter />
            </BusinessToggles>
          </LangContext.Provider>
        </LegacyThemeProvider>
      </ThemeProvider>
    </Provider>
  );
};
