import { get } from 'lodash';
import * as ActionTypes from 'app/constants/ActionTypes';

/**
 * Show error message
 * @return {Function}
 */
export function show(error) {
  return function (dispatch) {
    if (error instanceof Error) {
      console.error(error);

      return;
    }

    const payload = {
      type: ActionTypes.ERROR_SHOW,
    };
    let message = get(error, 'entity.error') || get(error, 'status.text') || get(error, 'entity.message') || get(error, 'error');

    if (!error.url || (!error.url.match('/login') && !error.url.match('/shared-application-tokens'))) {
      if (error.status.code === 401) {
        payload.type = ActionTypes.AUTO_LOGOUT;
      }
    }

    console.error(error);

    if (error.entity.errors) {
      // Example error object
      // {
      // 	"message": "The given data was invalid.",
      // 	"errors": {
      // 		"last_name": [
      // 			"The last name field is required."
      // 		],
      // 		"email": [
      // 			"The email must be a valid email address."
      // 		]
      // 	}
      // }
      // TODO: update error format through out the codebase
      // this is a temporary work around until we scope out the rest of the changes
      // currently would have to make changes in ~100 places
      if (!get(error, 'entity.errors[0].detail')) {
        const parsedErrors = [];
        Object.values(error.entity.errors).forEach((value) =>
          // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          parsedErrors.push(value),
        );

        message = parsedErrors.flat().join(' ');
      }
    }

    // @ts-expect-error TS(2339): Property 'errorMessage' does not exist on type '{ ... Remove this comment to see the full error message
    payload.errorMessage = message;

    dispatch(payload);
  };
}

export function hide() {
  return {
    type: ActionTypes.ERROR_HIDE,
  };
}
