import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useMemo } from 'react';
import { Industry, Programme, ProgrammeIntake, SelectedOption } from './MultiLevelMultiSelectField';
import { Box, Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack } from '@mui/material';

interface SecondaryPanelProps {
  industryOptions: Industry[];
  selectedIndustryValue: Industry['value'] | null;
  selectedProgrammeValue: Programme['value'] | null;
  selectedOptions: SelectedOption[];
  onChange: (intake: ProgrammeIntake['value'], value: SelectedOption) => void;
  onSelect: (industry: Industry['value'] | null, programme: Programme['value'] | null) => void;
}

export const SecondaryPanel = ({
  selectedProgrammeValue,
  selectedIndustryValue,
  industryOptions,
  onChange,
  onSelect,
  selectedOptions,
}: SecondaryPanelProps) => {
  const selectedProgramme = useMemo(() => {
    const industry = industryOptions.find((industry) => industry.value === selectedIndustryValue);
    return industry?.children?.find((programme) => programme.value === selectedProgrammeValue) || null;
  }, [selectedIndustryValue, selectedProgrammeValue, industryOptions]);

  const programmeHasReachedMaxSelectedChildren = () => {
    if (!selectedProgramme) return false;
    // Find the currently selected leaves
    const currentlySelectedChildrenCount = selectedOptions.filter((sv) => sv.programme === selectedProgramme.value).length;
    return currentlySelectedChildrenCount >= selectedProgramme.maxSelectedChildrenIfChildSelected!;
  };

  const industryHasReachedMaxSelectedChildren = () => {
    const currentIndustry = industryOptions.find((s) => s.value === selectedIndustryValue);
    const maxSelectedChildren = currentIndustry?.maxSelectedChildren;
    // Treat falsy maxSelectedChildren as "no upper limit for this industry"
    if (!maxSelectedChildren) return false;
    const currentlySelectedChildrenCount = selectedOptions.filter((sv) => sv.industry === selectedIndustryValue).length;
    return currentlySelectedChildrenCount >= maxSelectedChildren;
  };

  const onIntakeSelect = (intake: ProgrammeIntake, disabled: boolean) => {
    if (disabled) return;

    const selectedOption: SelectedOption = {
      industry: industryOptions.find((industry) => industry.value === selectedIndustryValue)!.value,
      programme: selectedProgramme!.value,
      intake: intake.value,
      name: selectedProgramme!.label,
      details: intake.label,
      provider: intake.provider,
      ...(intake.providerName && { providerName: intake.providerName }),
    };
    onChange(intake.value, selectedOption);
  };

  /**
   * An intake should be disabled if
   * (This is getting ridiculous)
   * 1. It is specifically specified in the data that it should be disabled, this should override all other conditions
   * 2. It is not currently checked (otherwise you couldn't uncheck it)
   * 3. Other intake(s) for the same programme have been selected, and (overriding any limitations imposed at industry level)
   * the programme's maxSelectedChildrenIfChildSelected has been reached
   * 4. There is already the maximum number of intakes checked for that "industry" or higher
   * 5. 4 are already selected (global maximum)
   */
  const getDisabledStateForIntake = (item: ProgrammeIntake, intakeMap: Map<string, SelectedOption>) => {
    const disabledInData = item.disabled === true;
    const intakeIsSelected = intakeMap.has(item.value);
    const globalIntakeMaxReached = selectedOptions.length >= 4;
    const intakeMaxReached = selectedProgramme!.maxSelectedChildrenIfChildSelected
      ? programmeHasReachedMaxSelectedChildren()
      : industryHasReachedMaxSelectedChildren();
    return disabledInData || (!intakeIsSelected && (intakeMaxReached || globalIntakeMaxReached));
  };

  const intakeMap = useMemo(
    () => selectedOptions.reduce((map, intake) => map.set(intake.intake, intake), new Map<string, SelectedOption>()),
    [selectedOptions],
  );

  const minChildSelection = selectedProgramme?.minSelectedChildrenIfChildSelected;
  const maxChildSelection = selectedProgramme?.maxSelectedChildrenIfChildSelected;
  const renderMinMaxSubheaders = Boolean(minChildSelection || maxChildSelection);

  return (
    <Box sx={{ overflowX: 'hidden', flex: 1, width: '50%' }}>
      <List>
        <ListItem disablePadding sx={{ display: { md: 'none' } }}>
          <ListItemButton onClick={() => onSelect(null, null)}>
            <ListItemIcon>
              <ChevronLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Back" sx={{ textTransform: 'uppercase', ml: 1 }}></ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider component="li" sx={{ display: { md: 'none' } }} />
        {selectedProgramme && (
          <ListSubheader sx={{ mt: 2, mb: 1, lineHeight: 'inherit' }}>
            {`${selectedProgramme.label}${selectedProgramme.children?.[0]?.providerName?.replace(/^/, ' at ') || ''}`}
          </ListSubheader>
        )}
        {renderMinMaxSubheaders && (
          <ListSubheader>
            <Stack direction="row" justifyContent="space-evenly">
              {Boolean(minChildSelection) && <Box>Minimum: {minChildSelection}</Box>}
              {Boolean(maxChildSelection) && <Box>Maximum: {maxChildSelection}</Box>}
            </Stack>
          </ListSubheader>
        )}
        {selectedProgramme &&
          selectedProgramme.children.map((item) => (
            <ListItem key={item.value} disablePadding>
              <ListItemButton onClick={() => onIntakeSelect(item, getDisabledStateForIntake(item, intakeMap))}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={intakeMap.has(item.value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-item-${item.value}` }}
                    disabled={getDisabledStateForIntake(item, intakeMap)}
                  />
                </ListItemIcon>
                <ListItemText id={`checkbox-list-item-${item.value}`} primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
