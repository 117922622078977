import Lang from 'lang.js';
import translations from 'lang/lang.json';

const lang = new Lang({});

// @ts-expect-error TS(2345): Argument of type '{ "en.Study": { "location ": str... Remove this comment to see the full error message
lang.setMessages(translations);

export default function content(translationKey, params = {}, fallback = '') {
  return lang.has(translationKey) ? lang.get(translationKey, params) : fallback;
}
