import * as Sentry from '@sentry/react';

const Logger = function (options = {}) {
  // @ts-expect-error TS(2683): 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  this.options = { ...options };
};

Logger.prototype = {
  /**
   * Log error or message
   * See http://sentry.readthedocs.org/en/latest/developer/client/index.html for all options
   *
   * @param {Error|String} errorOrMessage
   * @param {Object} [options]
   *   @param {String} [options.logger]
   *   @param {String} [options.level]
   *   @param {Object|Array} [options.tags]
   *   @param {Array} [options.modules]
   *   @param {Object} [options.extra]
   */
  log: function (errorOrMessage, options) {
    Sentry.captureException(errorOrMessage, this._options(options));
  },

  /**
   * Fatal level log
   *
   * @param {Error|String} errorOrMessage
   * @param {Object} [options]
   */
  fatal: function (errorOrMessage, options) {
    Sentry.captureException(
      errorOrMessage,
      this._options(options, {
        level: 'fatal',
      }),
    );
  },

  /**
   * Error level log
   *
   * @param {Error|String} errorOrMessage
   * @param {Object} [options]
   */
  error: function (errorOrMessage, options) {
    Sentry.captureException(
      errorOrMessage,
      this._options(options, {
        level: 'error',
      }),
    );
  },

  /**
   * Warning level log
   *
   * @param {Error|String} errorOrMessage
   * @param {Object} [options]
   */
  warning: function (errorOrMessage, options) {
    Sentry.captureException(
      errorOrMessage,
      this._options(options, {
        level: 'warning',
      }),
    );
  },

  /**
   * Info level log
   *
   * @param {Error|String} errorOrMessage
   * @param {Object} [options]
   */
  info: function (errorOrMessage, options) {
    Sentry.captureException(
      errorOrMessage,
      this._options(options, {
        level: 'info',
      }),
    );
  },

  /**
   * Debug level log
   *
   * @param {Error|String} errorOrMessage
   * @param {Object} [options]
   */
  debug: function (errorOrMessage, options) {
    Sentry.captureException(
      errorOrMessage,
      this._options(options, {
        level: 'debug',
      }),
    );
  },

  /**
   * Log error or message
   * See http://sentry.readthedocs.org/en/latest/developer/client/index.html for all options
   *
   * @param {String} message
   */
  message: function (message) {
    Sentry.captureMessage(message);
  },

  /**
   *
   * @param {Object} [options1]
   * @param {Object} [options2]
   * @returns {Object}
   * @private
   */
  _options: function (options1, options2) {
    return {
      ...this.options,
      ...options1,
      ...options2,
    };
  },
};

export default Logger;
